import { useTranslation } from 'react-i18next';

import eventType from '../eventType';
import UserField from './UserField';

const DelayEvent = (props) => {
  const { event } = props;
  const { t } = useTranslation();
  return (
    <>
      {event.delay && (
        <div>
          <span>{t('Delay')}: </span>
          <span className="font-medium">
            {event.delay.durationInMinutes} min
          </span>
        </div>
      )}

      <UserField event={event} />
    </>
  );
};

DelayEvent.propTypes = {
  event: eventType.isRequired,
};

export default DelayEvent;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from '../../../components/Icon';
import Label from '../../../components/Label';
import Tooltip from '../../../components/Tooltip';
import OrganisationDetailsSectionCard from '../../shared/OrganisationDetailsSectionCard';

const BasicInformationCard = (props) => {
  const { organisation, topRightContent } = props;

  const { t } = useTranslation();

  return (
    <OrganisationDetailsSectionCard
      title={t('Basic Information')}
      topRightContent={topRightContent}
    >
      <div className="grid gap-4 pb-2 pt-4 md:grid-cols-2">
        <div className="flex flex-col">
          <span className="mb-1 text-xs text-grey-700">{t('Name')}</span>
          <span className="text-sm font-medium">{organisation.name}</span>
        </div>

        <div className="flex flex-col">
          <div className="mb-1 flex items-center gap-2 text-xs text-grey-700">
            <span>{t('Public Name')}</span>
            <Tooltip
              text={t(
                'Name of the organisation that will be displayed publicly to customers',
              )}
            >
              <Icon
                className="h-4 w-4 text-grey-500 duration-200 hover:text-ui-info-blue"
                icon="infoFilled"
              />
            </Tooltip>
          </div>
          <span className="text-sm font-medium">{organisation.publicName}</span>
        </div>
        <div className="flex flex-col">
          <span className="mb-1 text-xs text-grey-700">{t('Address')}</span>
          <span className="text-sm font-medium">{organisation.address}</span>
        </div>
        <div className="flex flex-col">
          <span className="mb-1 text-xs text-grey-700">{t('Shortcode')}</span>
          <span className="text-sm font-medium">{organisation.shortCode}</span>
        </div>

        <div className="flex flex-col md:col-span-2">
          <span className="mb-1 text-xs text-grey-700">{t('Type')}</span>
          <span className="text-sm font-medium">
            <Label size="s" text={t('Client')} variant="purple" />
          </span>
        </div>
      </div>
    </OrganisationDetailsSectionCard>
  );
};

BasicInformationCard.propTypes = {
  organisation: PropTypes.shape({
    address: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    publicName: PropTypes.string,
    shortCode: PropTypes.string,
  }).isRequired,
  topRightContent: PropTypes.oneOfType([PropTypes.object]),
};

BasicInformationCard.defaultProps = {
  topRightContent: undefined,
};

export default BasicInformationCard;

import { useMemo } from 'react';

import ObjectHelper from '../../../helpers/ObjectHelper';
import usePageLocationQuery from '../../../hooks/usePageLocationQuery';
import usePageSizeLocationQuery from '../../../hooks/usePageSizeLocationQuery';

const useResourceQuery = ({ itemsPerPage = 15, params = {}, resourceUrl }) => {
  const page = usePageLocationQuery() || 1;
  const pageSize = usePageSizeLocationQuery() || itemsPerPage;

  const allParams = useMemo(() => {
    const pagination = {
      skip: pageSize * page - pageSize,
      take: pageSize,
    };

    if (params) {
      return {
        ...ObjectHelper.removeNullableFields(params),
        ...pagination,
      };
    }

    return pagination;
  }, [page, pageSize, params]);

  return useMemo(
    () => ({
      queryKey: [resourceUrl, allParams],
      page,
      pageSize,
      allParams,
    }),
    [resourceUrl, allParams, page, pageSize],
  );
};

export default useResourceQuery;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../../components/Checkbox';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';

const NotifyCustomer = (props) => {
  const { isDisabled, notifyCustomerStatuses } = props;
  const { control, watch } = useFormContext();

  const status = watch('status');
  const unitSpecialCase = watch('unitSpecialCase');

  const { t } = useTranslation();

  const canSendStatusChangeEmail = useMemo(() => {
    if (unitSpecialCase) {
      return (
        unitSpecialCase === DeliveryTaskUnitStatus.ReturnToClient ||
        unitSpecialCase === DeliveryTaskUnitStatus.Lost
      );
    }

    return notifyCustomerStatuses.includes(status);
  }, [notifyCustomerStatuses, status, unitSpecialCase]);

  const finalIsDisabled = isDisabled || !canSendStatusChangeEmail;

  return (
    <Controller
      name="notifyCustomer"
      control={control}
      render={({ field: { onChange, value, ...controllerProps } }) => (
        <div className="flex py-2.5 items-center gap-2">
          <Checkbox
            checked={value}
            disabled={finalIsDisabled}
            onChange={(val) => onChange(val)}
            {...controllerProps}
          />
          <span
            className={cn(
              'text-sm font-medium',
              finalIsDisabled && 'opacity-40',
            )}
          >
            {t('Send e-mail update to customer')}
          </span>
        </div>
      )}
    />
  );
};

NotifyCustomer.propTypes = {
  isDisabled: PropTypes.bool,
  notifyCustomerStatuses: PropTypes.arrayOf(PropTypes.string),
};

NotifyCustomer.defaultProps = {
  isDisabled: false,
  notifyCustomerStatuses: [],
};

export default NotifyCustomer;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { Card } from '../../../components/Card';
import OrganisationType from '../../../enums/OrganisationType';
import PlatformFeature from '../../../enums/PlatformFeature';
import UserRole from '../../../enums/UserRole';
import Workflow from '../../../enums/Workflow';
import useCustomToast from '../../../hooks/useCustomToast';
import usePlatformFeaturesTranslations from '../../../hooks/usePlatformFeaturesTranslations';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useIsRole } from '../../../providers/UserProvider';
import PlatformFeaturesSwitch from './PlatformFeaturesSwitch';

const OrganisationPlatformFeaturesList = ({
  isClient,
  organisationId,
  workflow,
}) => {
  const { toastInfo, toastSuccess } = useCustomToast();
  const { t } = useTranslation();
  const translations = usePlatformFeaturesTranslations();

  const { fetch } = useFetch();
  const queryClient = useQueryClient();

  const organisationFeaturesUrl = isClient
    ? `/clients/${organisationId}/features`
    : `/carriers/${organisationId}/features`;

  const { data: allFeaturesData, isLoading: isAllFeaturesLoading } = useQuery(
    ['/features'],
    async () => {
      const response = await fetch('/features', {
        method: 'GET',
      });
      return response.json();
    },
  );

  const { data: companyFeaturesData, isLoading: isCompanyFeaturesLoading } =
    useQuery([organisationFeaturesUrl], async () => {
      const response = await fetch(organisationFeaturesUrl, {
        method: 'GET',
      });
      return response.json();
    });

  const companyFeatures = useMemo(
    () => companyFeaturesData?.data?.features || [],
    [companyFeaturesData?.data?.features],
  );

  const allFeatures = useMemo(
    () => allFeaturesData?.data || [],
    [allFeaturesData?.data],
  );

  const { toastFetchError } = useToastFetchError();
  const addFeatureMutation = useMutation(
    async (featureCode) => {
      const response = await fetch(organisationFeaturesUrl, {
        body: {
          featureCode,
        },
        method: 'PATCH',
      });
      return response.json();
    },
    {
      onError: (error) => toastFetchError(error),
      onSuccess: (response) => {
        if (response?.success) {
          toastSuccess(t('Feature enabled.'));
          queryClient.invalidateQueries([organisationFeaturesUrl]);
        }
      },
    },
  );

  const removeFeatureMutation = useMutation(
    async (featureCode) => {
      const response = await fetch(
        `${organisationFeaturesUrl}/${featureCode}`,
        {
          method: 'DELETE',
        },
      );
      return response.json();
    },
    {
      onError: (error) => toastFetchError(error),
      onSuccess: (response) => {
        if (response?.success) {
          toastInfo(t('Feature disabled.'));
          queryClient.invalidateQueries([`${organisationFeaturesUrl}`]);
        }
      },
    },
  );

  const isSuperAdmin = useIsRole(UserRole.Superadmin);

  const featuresFilteredByOrganisationType = allFeatures.filter((feature) =>
    feature.type.includes(
      isClient ? OrganisationType.Client : OrganisationType.Carrier,
    ),
  );

  if (isAllFeaturesLoading || isCompanyFeaturesLoading) {
    return (
      <div className="grid gap-4">
        <Skeleton wrapper={Card} height={84} />
        <Skeleton wrapper={Card} height={84} />
        <Skeleton wrapper={Card} height={84} />
        <Skeleton wrapper={Card} height={84} />
        <Skeleton wrapper={Card} height={84} />
      </div>
    );
  }

  return (
    <ul className="grid gap-4">
      {featuresFilteredByOrganisationType.length === 0 ? (
        <span>{t('No features available')}</span>
      ) : (
        featuresFilteredByOrganisationType.map((feature) => {
          const isFeatureEnabled = companyFeatures.some(
            (companyFeature) => companyFeature.code === feature.code,
          );

          const description = translations[`${feature.code}:description`];
          const isAutoTourCheckoutHidden =
            feature.code === PlatformFeature.AutoTourCheckout &&
            workflow === Workflow.Operations;

          if (isAutoTourCheckoutHidden) {
            return null;
          }

          return (
            <li key={feature.code}>
              <Card className="py-5">
                <div className="flex flex-col justify-between gap-3 lg:flex-row lg:gap-4">
                  <div>
                    <div className="mb-1 font-medium text-sm">
                      {translations[`${feature.code}:name`] || feature.code}
                    </div>
                    <div
                      className={cn(
                        'text-sm',
                        description ? 'text-grey-700' : 'text-grey-500',
                      )}
                    >
                      {description || 'No description available'}
                    </div>
                  </div>

                  <PlatformFeaturesSwitch
                    code={feature.code}
                    isDisabled={isSuperAdmin || isCompanyFeaturesLoading}
                    isInitiallyChecked={isFeatureEnabled}
                    onDisable={(code) => removeFeatureMutation.mutate(code)}
                    onEnable={(code) => addFeatureMutation.mutate(code)}
                  />
                </div>
              </Card>
            </li>
          );
        })
      )}
    </ul>
  );
};

OrganisationPlatformFeaturesList.propTypes = {
  isClient: PropTypes.bool,
  organisationId: PropTypes.string,
  workflow: PropTypes.oneOf(Object.values(Workflow)),
};

OrganisationPlatformFeaturesList.defaultProps = {
  isClient: false,
  organisationId: undefined,
  workflow: undefined,
};

export default OrganisationPlatformFeaturesList;

import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import GuestLayout from '../layout/GuestLayout';
import { useUser } from '../providers/UserProvider';

const GuestApp = () => {
  const location = useLocation();
  const fromLocation = location?.state?.from;

  const redirectUrl = useMemo(() => {
    if (fromLocation?.pathname && fromLocation?.search) {
      return `${fromLocation?.pathname}${fromLocation?.search}`;
    }
    if (fromLocation?.pathname) {
      return fromLocation?.pathname;
    }
    return '/';
  }, [fromLocation?.pathname, fromLocation?.search]);

  const { user } = useUser();

  // disallow authenticated user access to guest routes
  if (user) {
    return <Navigate replace to={redirectUrl} />;
  }

  return <GuestLayout />;
};

export default GuestApp;

import moment from 'moment/moment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useCopyToClipboard from './useCopyToClipboard';
import useCustomToast from './useCustomToast';
import useDeliveryUnitStatusTranslations from './useDeliveryUnitStatusTranslations';

const useCopyUnitInfoToClipboard = () => {
  const { t } = useTranslation();
  const { toastSuccess } = useCustomToast();
  const copyToClipboard = useCopyToClipboard();
  const unitStatusTranslations = useDeliveryUnitStatusTranslations();

  return useCallback(
    async (unit) => {
      await copyToClipboard(
        t(
          `Unit code: {{code}} / Unit status and change time: {{status}}, {{statusChangeTime}} / Address: {{address}} / Execution date: {{executionDate}} / Execution slot: {{timeFrom}}-{{timeTo}}`,
          {
            code: unit.code,
            status: unitStatusTranslations[unit.status],
            statusChangeTime: moment(unit.statusChangeTime).format(
              'DD.MM.YYYY. HH:mm',
            ),
            address: unit.address,
            executionDate: moment(unit.date).format('DD.MM.YYYY.'),
            timeFrom: moment(unit.timeFrom).format('HH:mm'),
            timeTo: moment(unit.timeTo).format('HH:mm'),
          },
        ),
      );
      toastSuccess(t('Unit info copied to clipboard.'));
    },
    [copyToClipboard, t, toastSuccess, unitStatusTranslations],
  );
};

export default useCopyUnitInfoToClipboard;

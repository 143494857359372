import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import { Card } from '../../components/Card';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import OrganisationType from '../../enums/OrganisationType';
import CreateMemberForm from '../../features/members/CreateMemberForm';

const NewSuperadmin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);

  const backLink = `/superadmins`;

  useEffect(() => {
    if (redirect) {
      navigate(backLink, {
        replace: true,
      });
    }
  }, [backLink, navigate, redirect]);

  return (
    <Page>
      <Titlebar
        backLink={backLink}
        icon={<Icon className="h-5 w-5 text-ui-blue" icon="userAdd" />}
        textPrimary={t('New Member')}
        titleRightContent={
          <Button
            as={Link}
            className="w-full lg:w-fit"
            text={t('Cancel')}
            to={backLink}
            variant="outlineBlack"
          />
        }
      />
      <Page.Content centerContent variant="grey">
        <div className="w-full max-w-[480px]">
          <Card className="px-4 py-5 sm:p-8">
            <CreateMemberForm
              organisationId={undefined}
              organisationName={undefined}
              organisationType={OrganisationType.Superadmin}
              onCancel={() => {
                navigate(backLink);
              }}
              onSuccess={() => {
                setRedirect(true);
              }}
            />
          </Card>
        </div>
      </Page.Content>
    </Page>
  );
};

export default NewSuperadmin;

import PropTypes from 'prop-types';
import { useEffect, useId, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import Skeleton from 'react-loading-skeleton';
import { useDebounce, usePrevious } from 'react-use';

import FadingOverlay from '../../../components/FadingOverlay';
import Icon from '../../../components/Icon';
import ObjectHelper from '../../../helpers/ObjectHelper';
import useInfiniteScrollQuery from '../../../hooks/useInfiniteScrollQuery';
import { useUser } from '../../../providers/UserProvider';
import DriverItem from './DriverItem';
import DriverSearch from './DriverSearch';
import ListCount from './ListCount';

const AssignDriverTab = (props) => {
  const {
    hubId,
    isDriverAssignmentPerTourWarehouseEnabled,
    onDriverSelect,
    selectedDriverId,
    showSubcarrierFilter,
  } = props;
  const { t } = useTranslation();
  const { user } = useUser();
  const carrierId = user?.carrier?.id;

  const [subcarrierId, setSubcarrierId] = useState('');
  const driverSearchInput = useRef(null);

  const listId = useId();
  const listDomId = `drivers-list-${listId}`;
  const root = document.getElementById(listDomId);
  const { inView, ref: bottomRef } = useInView({
    rootMargin: '0px 0px 50px 0px',
    root,
  });
  const previousInView = usePrevious(inView);

  const [driverSearchValue, setDriverSearchValue] = useState('');
  const [debouncedDriverSearchValue, setDebouncedDriverSearchValue] =
    useState('');

  const params = useMemo(
    () =>
      ObjectHelper.removeNullableFields({
        ...(isDriverAssignmentPerTourWarehouseEnabled && { hubId }),
        name: debouncedDriverSearchValue,
        ...(subcarrierId && { subcarrierId }),
      }),
    [
      debouncedDriverSearchValue,
      isDriverAssignmentPerTourWarehouseEnabled,
      subcarrierId,
      hubId,
    ],
  );

  const [, cancel] = useDebounce(
    () => {
      setDebouncedDriverSearchValue(driverSearchValue.trim());
    },
    300,
    [driverSearchValue],
  );

  useEffect(
    () => () => {
      if (cancel) {
        cancel();
      }
    },
    [cancel],
  );

  const {
    count,
    flattenedData: drivers,
    isFetchingNextPage,
    isLoading,
    onBottomReached,
  } = useInfiniteScrollQuery({
    params,
    url: `/carriers/${carrierId}/drivers`,
  });

  useEffect(() => {
    if (inView && !previousInView) {
      onBottomReached();
    }
  }, [inView, previousInView, onBottomReached]);

  const infoMessage = useMemo(() => {
    if (count > 0) {
      return `${count} ${t('Drivers available')}`;
    }

    if (!isFetchingNextPage) {
      return t('No Drivers found');
    }

    return '';
  }, [count, isFetchingNextPage, t]);

  return (
    <div>
      <div className="mb-4">
        <DriverSearch
          ref={driverSearchInput}
          searchValue={driverSearchValue}
          showSubcarrierFilter={showSubcarrierFilter}
          subcarrierId={subcarrierId}
          onSearch={(newSearchValue) => {
            setDriverSearchValue(newSearchValue);
          }}
          onSubcarrierChange={(newSubcarrierId) => {
            setSubcarrierId(newSubcarrierId);
          }}
        />
      </div>
      <div className="h-[400px] overflow-y-scroll" id={listDomId}>
        {!isLoading && <ListCount>{infoMessage}</ListCount>}
        {isLoading ? (
          <FadingOverlay>
            <div className="flex flex-col gap-2">
              <Skeleton height={32} />
              <Skeleton height={32} />
              <Skeleton height={32} />
              <Skeleton height={32} />
              <Skeleton height={32} />
            </div>
          </FadingOverlay>
        ) : (
          drivers.map((driver) => (
            <DriverItem
              driver={driver}
              isSelected={driver.id === selectedDriverId}
              key={driver.id}
              onItemClick={(record) =>
                onDriverSelect(
                  record.id === selectedDriverId ? undefined : record.id,
                )
              }
            />
          ))
        )}
        <div ref={bottomRef}>
          {isFetchingNextPage && !isLoading && (
            <div className="flex h-11 w-full items-center justify-center">
              <Icon
                className="h-6 w-6 animate-spin text-primary-dark"
                icon="loading"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AssignDriverTab.propTypes = {
  isDriverAssignmentPerTourWarehouseEnabled: PropTypes.bool,
  onDriverSelect: PropTypes.func,
  selectedDriverId: PropTypes.string,
  showSubcarrierFilter: PropTypes.bool,
  hubId: PropTypes.string,
};

AssignDriverTab.defaultProps = {
  isDriverAssignmentPerTourWarehouseEnabled: false,
  onDriverSelect: () => {},
  selectedDriverId: undefined,
  showSubcarrierFilter: true,
  hubId: undefined,
};

export default AssignDriverTab;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Collapse from '../Collapse';
import Icon from '../Icon';

const DrawerTextRow = (props) => {
  const { className, collapsedContent, content, title } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div
        className={cn(
          'flex flex-row items-center justify-between text-grey-700 gap-1 min-h-[24px] min-w-0',
          collapsedContent && 'cursor-pointer',
          className,
        )}
        onClick={() => {
          if (!collapsedContent) {
            return;
          }
          setIsOpen(!isOpen);
        }}
      >
        <div className="font-medium min-w-[64px] break-words flex-1">
          {title}
        </div>
        <div
          className={cn(
            'flex-3 text-right font-medium text-primary-dark flex items-center justify-end break-all',
            collapsedContent && 'gap-4',
          )}
        >
          {content}
          {collapsedContent && (
            <Icon
              className="w-3 h-3 text-primary-dark"
              icon={isOpen ? 'chevronUp' : 'chevronDown'}
            />
          )}
        </div>
      </div>
      {collapsedContent && (
        <Collapse isOpen={isOpen}>
          <div className="pt-2 font-normal">{collapsedContent}</div>
        </Collapse>
      )}
    </div>
  );
};

DrawerTextRow.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  collapsedContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};

DrawerTextRow.defaultProps = {
  collapsedContent: undefined,
  className: undefined,
  content: undefined,
};

export default DrawerTextRow;

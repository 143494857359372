import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import InfiniteScrollSelect from '../../../components/InfiniteScrollSelect';

const SubcarrierSelect = (props) => {
  const {
    carrierId,
    carrierName,
    className,
    dropdownClassname,
    flatRight,
    onChange,
    required,
    value,
  } = props;

  const { t } = useTranslation();

  const prependOptions = [
    {
      dropdownLabel: `${t('All')} (${carrierName})`,
      label: t('All'),
      value: '',
    },
  ];

  const transformOptionFn = useCallback(
    (subcarrier) => ({
      label: `${subcarrier.shortCode} (${subcarrier.name})`,
      value: subcarrier.id,
    }),
    [],
  );

  return (
    <InfiniteScrollSelect
      singleItemUrl="/subcarriers/"
      url={`/carriers/${carrierId}/subcarriers`}
      className={className}
      id="subcarrier-select"
      value={value}
      onChange={onChange}
      transformOptionFn={transformOptionFn}
      prependOptions={prependOptions}
      placeholder={t('Subcarriers')}
      dropdownClassname={dropdownClassname}
      flatRight={flatRight}
      required={required}
    />
  );
};

SubcarrierSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  carrierId: PropTypes.string,
  carrierName: PropTypes.string,
  value: PropTypes.string,
  flatRight: PropTypes.bool,
  dropdownClassname: PropTypes.string,
  required: PropTypes.bool,
};

SubcarrierSelect.defaultProps = {
  className: undefined,
  onChange: undefined,
  carrierId: undefined,
  value: undefined,
  carrierName: undefined,
  flatRight: undefined,
  dropdownClassname: undefined,
  required: false,
};

export default SubcarrierSelect;

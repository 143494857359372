import { useTranslation } from 'react-i18next';

import DeliveryOption from '../../../../enums/DeliveryOption';
import useDeliveryOptionsTranslations from '../../../../hooks/useDeliveryOptionsTranslations';
import eventType from '../eventType';

const ExecutionTypeField = (props) => {
  const { event } = props;
  const { t } = useTranslation();

  const deliveryOption =
    event.deliveryOption === null
      ? DeliveryOption.Direct
      : event.deliveryOption;
  const { nameTranslations: deliveryOptionTranslations } =
    useDeliveryOptionsTranslations();

  const translatedCode =
    deliveryOptionTranslations[deliveryOption] || deliveryOption;

  return (
    <div>
      <span>{t('Execution type')}: </span>
      <span className="font-medium">
        {translatedCode}{' '}
        {event.additionalStatusInformation &&
          `(${event.additionalStatusInformation})`}
      </span>
    </div>
  );
};

ExecutionTypeField.propTypes = {
  event: eventType.isRequired,
};

export default ExecutionTypeField;

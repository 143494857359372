import cn from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';

import { offsetModifier } from '../../helpers/popperModifiers';
import useDropdown from '../../hooks/useDropdown';
import Button from '../Button';
import Icon from '../Icon';
import ListItem from './ListItem';

const hoursArray = Array.from({ length: 24 }, (_, index) => index);

const minutes = [0, 15, 30, 45];

function leftPad(str, length) {
  const paddingLength = length - str.length;
  if (paddingLength <= 0) {
    return str;
  }
  const padding = '0'.repeat(paddingLength);
  return `${padding}${str}`;
}

function mapTimeToString(time) {
  if (time >= 0 && time <= 9) {
    return leftPad(String(time), 2);
  }
  return String(time);
}

const TimePicker = (props) => {
  const {
    className,
    'data-test': dataTest,
    error,
    maxTime,
    minTime,
    name,
    onChange,
    value,
  } = props;

  const [time, setTime] = useState({
    hours: typeof value?.hours === 'number' ? value.hours : undefined,
    minutes: typeof value?.minutes === 'number' ? value.minutes : undefined,
  });

  const isEmpty = time.hours === undefined && time.minutes === undefined;

  const dropdownId = useId();
  const inputRef = useRef(undefined);
  const [popperElement, setPopperElement] = useState(null);
  const [popperReferenceElement, setPopperReferenceElement] = useState(null);
  const { attributes, styles } = usePopper(
    popperReferenceElement,
    popperElement,
    {
      modifiers: [offsetModifier],
      placement: 'bottom-start',
    },
  );

  useEffect(() => {
    setTime({
      hours: typeof value?.hours === 'number' ? value.hours : undefined,
      minutes: typeof value?.minutes === 'number' ? value.minutes : undefined,
    });
  }, [value]);

  const { getRootProps, isOpen, toggle } = useDropdown({
    onOpen: () => {
      setTime({
        hours: typeof value?.hours === 'number' ? value.hours : undefined,
        minutes: typeof value?.minutes === 'number' ? value.minutes : undefined,
      });
    },
  });
  const { t } = useTranslation();
  const formattedValue =
    value?.hours !== undefined && value?.minutes !== undefined
      ? `${mapTimeToString(value.hours)}:${mapTimeToString(value.minutes)}`
      : '';

  const getAvailableMinutes = useCallback(
    (hour) => {
      if (minTime !== undefined && maxTime !== undefined) {
        return minutes.filter(
          (minute) =>
            hour >= minTime.hours &&
            minute >= minTime.minutes &&
            hour <= maxTime.hours &&
            minute <= maxTime.minutes,
        );
      }

      if (minTime !== undefined) {
        if (minTime.hours !== hour) {
          return minutes;
        }
        return minutes.filter((minute) => minute >= minTime.minutes);
      }

      if (maxTime !== undefined) {
        if (maxTime.hours !== hour) {
          return minutes;
        }
        return minutes.filter((minute) => minute <= maxTime.minutes);
      }

      return minutes;
    },
    [maxTime, minTime],
  );

  return (
    <div className={cn('relative', className)} {...getRootProps()}>
      <div className="relative" ref={setPopperReferenceElement}>
        <input
          autoComplete="off"
          className={cn(
            'flex w-full cursor-text items-center justify-between gap-3 truncate rounded-md border px-3 py-2.5 text-sm text-primary-dark placeholder:text-grey-700 focus:border-primary-yellow disabled:cursor-default disabled:bg-grey-200 disabled:opacity-40',
            error ? 'border-ui-red' : 'border-grey-500',
          )}
          data-test={dataTest}
          data-test-value={formattedValue}
          name={name}
          id={name}
          placeholder={t('Select time')}
          ref={inputRef}
          value={formattedValue}
          onClick={toggle}
          readOnly
        />
        <div className="absolute bottom-0 right-3 top-0 flex items-center justify-center text-grey-700">
          <Icon className={cn('h-4 w-4 cursor-pointer')} icon="clock" />
        </div>
      </div>

      {isOpen && (
        <div
          id={`dropdown-${dropdownId}`}
          ref={setPopperElement}
          style={{
            ...styles.popper,
          }}
          {...attributes.popper}
          className={cn(
            'z-30 flex h-80 w-full max-w-[145px] flex-col overflow-auto rounded-md bg-white shadow-elevation-400',
            className,
          )}
        >
          <div className="flex h-[264px] overflow-hidden border-b-[1px] border-grey-200 py-3">
            <ul className="h-full overflow-auto px-3">
              {hoursArray.map((hour) => {
                const hourValue = mapTimeToString(hour);
                const isDisabled =
                  (minTime !== undefined && hour < minTime.hours) ||
                  (maxTime !== undefined && hour > maxTime.hours);
                return (
                  <ListItem
                    data-test="time-picker-hour"
                    key={hourValue}
                    isSelected={time.hours === hour}
                    isDisabled={isDisabled}
                    value={hourValue}
                    onClick={() => {
                      const availableMinutes = getAvailableMinutes(hour);
                      setTime({
                        hours: hour,
                        minutes:
                          availableMinutes.length > 0
                            ? availableMinutes[0]
                            : undefined,
                      });
                    }}
                  />
                );
              })}
            </ul>
            <hr className="h-full border-r-[1px] border-grey-300" />
            <ul className="h-full overflow-auto px-3">
              {minutes.map((minute) => {
                const minuteValue = mapTimeToString(minute);
                const isDisabled =
                  (minTime !== undefined &&
                    time?.hours !== undefined &&
                    time.hours === minTime.hours &&
                    minute < minTime.minutes) ||
                  (maxTime !== undefined &&
                    time?.hours !== undefined &&
                    time.hours === maxTime.hours &&
                    minute > maxTime.minutes);

                return (
                  <ListItem
                    data-test="time-picker-minute"
                    key={minuteValue}
                    isDisabled={isDisabled}
                    isSelected={time.minutes === minute}
                    value={minuteValue}
                    onClick={() => {
                      setTime({
                        hours: time.hours,
                        minutes: minute,
                      });
                    }}
                  />
                );
              })}
            </ul>
          </div>
          <div className="flex gap-2 p-3 pl-4 flex-wrap">
            <Button
              className="flex-1"
              size="s"
              variant="ghostBlue"
              text={t('Reset')}
              disabled={isEmpty}
              onClick={() => {
                onChange({
                  hours: undefined,
                  minutes: undefined,
                });
                toggle();
              }}
            />
            <Button
              className="flex-1"
              disabled={time.hours === undefined || time.minutes === undefined}
              size="s"
              variant="solidBlue"
              text={t('OK')}
              data-test="time-picker-ok"
              onClick={() => {
                onChange(time);
                toggle();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

TimePicker.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  name: PropTypes.string,
  value: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
  }),
  className: PropTypes.string,
  'data-test': PropTypes.string,
  onChange: PropTypes.func,
  minTime: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
  }),
  maxTime: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
  }),
};

TimePicker.defaultProps = {
  error: undefined,
  name: undefined,
  value: undefined,
  className: undefined,
  'data-test': 'time-picker',
  onChange: () => {},
  minTime: undefined,
  maxTime: undefined,
};

export default TimePicker;

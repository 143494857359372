import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../../components/Icon';

const variantsMap = {
  blue: 'text-ui-info-blue',
  grey: 'text-grey-700',
};

const CardDetailsRow = (props) => {
  const { icon, label, value, variant } = props;

  return (
    <div className="flex flex-row gap-4 py-4 text-sm last:pb-0 sm:items-center">
      <div className="flex flex-1 items-center gap-4 self-start text-grey-900">
        <Icon className={cn('h-5 w-5', variantsMap[variant])} icon={icon} />
        <span>{label}</span>
      </div>
      <span className="text-right font-medium text-primary-dark">{value}</span>
    </div>
  );
};

CardDetailsRow.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  variant: PropTypes.oneOf(Object.keys(variantsMap)),
};

CardDetailsRow.defaultProps = {
  value: undefined,
  variant: 'grey',
};

export default CardDetailsRow;

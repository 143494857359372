import LocalStorage from './enums/LocalStorage';
import LocalStorageHelper from './helpers/LocalStorageHelper';

function invokeServiceWorkerUpdateFlow(registration) {
  window.dispatchEvent(
    new CustomEvent('show-new-version-toast', {
      detail: {
        onClick: () => {
          if (registration.waiting) {
            // let waiting Service Worker know it should became active
            registration.waiting.postMessage('SKIP_WAITING');
          }
        },
      },
    }),
  );
}

const registerServiceWorker = () => {
  // custom event is used to detect when the service worker is ready because the load event would be fired before the toast handler is ready
  window.addEventListener('version-toast-handler-ready', async () => {
    try {
      // register the service worker from the file specified
      const registration =
        await navigator.serviceWorker.register('/service-worker.js');

      let hasActiveServiceWorker = registration.active !== null;

      // ensure the case when the updatefound event was missed is also handled
      // by re-invoking the prompt when there's a waiting Service Worker
      // this is a scenario when the user refreshes the page when there is a new service worker available, and the old one still active
      if (registration.waiting) {
        invokeServiceWorkerUpdateFlow(registration);
      }

      // detect Service Worker update available and wait for it to become installed
      registration.addEventListener('updatefound', () => {
        // eslint-disable-next-line no-console
        console.log('Service Worker updatefound');
        if (registration.installing) {
          // wait until the new Service worker is actually installed (ready to take over)
          registration.installing.addEventListener('statechange', () => {
            if (registration.waiting) {
              // if there's an existing controller (previous Service Worker), show the prompt
              if (navigator.serviceWorker.controller) {
                invokeServiceWorkerUpdateFlow(registration);
              } else {
                // otherwise it's the first install or its not yet controlled, nothing to do
                // eslint-disable-next-line no-console
                console.log('Service Worker initialized for the first time');
              }
            }
          });
        }
      });

      let refreshing = false;

      // detect controller change and refresh the page
      navigator.serviceWorker.addEventListener('controllerchange', async () => {
        // "The controllerchange event of the ServiceWorkerContainer interface fires when the document's associated ServiceWorkerRegistration acquires a new active worker."
        // this is the initial installation in which clients are "claimed", no need for refresh here because it is immediately the latest version
        if (!hasActiveServiceWorker) {
          hasActiveServiceWorker = true;
          return;
        }

        // this check is needed so we dont get into refresh loop
        if (!refreshing) {
          // this makes the useBlocker skip beforeunload event (if applied, e.g. while in form.) because...
          // ...we want to ignore it if the user clicks on "update app, the app will refresh" button
          LocalStorageHelper.setItem(LocalStorage.SkipBeforeUnload, true);
          window.location.reload();
          refreshing = true;
        }
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error during service worker registration:', e);
    }
  });
};

export default registerServiceWorker;

import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Label from '../../../components/Label';
import Breakpoint from '../../../enums/Breakpoint';
import TourStopStatus from '../../../enums/TourStopStatus';
import useWindowSize from '../../../hooks/useWindowSize';
import TourStopDetailsDrawer from '../../tours/TourStopDetailsDrawer';

const TourStopDetails = ({
  clientName,
  hasBlockedUnits,
  status,
  tasks,
  tour,
  tourStopId,
}) => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { width } = useWindowSize();

  const isMobile = width < Breakpoint.LG;

  return (
    <>
      <TourStopDetailsDrawer
        tourStopId={tourStopId}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        tour={tour}
      />
      <div className="ml-8 mr-4 flex items-center">
        <div className="mr-3 flex items-center gap-2">
          <Icon
            className="h-4 w-4 text-grey-700"
            icon={tasks.length > 1 ? 'peopleOutlined' : 'userOutlined'}
          />
          <span className="font-normal text-sm text-primary-dark">
            {tasks.length > 1
              ? t('Multiple Customers')
              : `${tasks[0]?.units[0]?.firstName} ${tasks[0]?.units[0]?.lastName}`}
          </span>
        </div>
      </div>
      <div className="flex flex-col items-start lg:flex-row w-full flex-wrap lg:items-center lg:justify-between gap-4 lg:gap-2">
        <div className="ml-8 mr-4 flex items-center">
          {hasBlockedUnits && (
            <Icon
              className="h-5 w-5 mr-1 text-ui-warning-orange"
              icon="warning"
            />
          )}
          {tasks[0].units.length > 0 && (
            <div className="mr-3 flex items-center gap-1">
              <Icon className="h-4 w-4 text-grey-700" icon="parcel" />
              <span className="font-medium text-primary-dark">
                {tasks[0].units?.length}
              </span>
            </div>
          )}
          <Label
            size="s"
            text={clientName}
            variant={status === TourStopStatus.Pending ? 'grey' : 'white'}
          />
        </div>
        <Button
          data-test="stop-details-button"
          icon="list"
          size={isMobile ? 'm' : 's'}
          text={t('Stop Details')}
          variant="outlineBlack"
          onClick={() => setIsDrawerOpen(true)}
          className="print:hidden"
        />
      </div>
    </>
  );
};

TourStopDetails.propTypes = {
  clientName: PropTypes.string,
  tourStopId: PropTypes.string,
  tasks: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  hasBlockedUnits: PropTypes.bool,
  tour: PropTypes.shape({
    vehicle: PropTypes.string,
    date: PropTypes.string,
    shift: PropTypes.number,
  }).isRequired,
  status: PropTypes.oneOf(Object.values(TourStopStatus)).isRequired,
};

TourStopDetails.defaultProps = {
  clientName: undefined,
  tourStopId: undefined,
  tasks: undefined,
  hasBlockedUnits: false,
};

export default TourStopDetails;

import { fetch } from 'whatwg-fetch';

import ScanLabelSize from '../enums/ScanLabelSize';
import { usePackageScanningSettings } from '../providers/PackageScanningSettingsProvider';
import useAsyncFn from './useAsyncFn';

export const isLabelaryEnabled =
  import.meta.env.MODE === 'development' || import.meta.env.MODE === 'staging';

const useLabelary = () => {
  const { labelSize } = usePackageScanningSettings();

  return useAsyncFn(async (zpl) => {
    if (!isLabelaryEnabled) {
      return '';
    }

    const formData = new FormData();
    formData.append('file', zpl);

    const lebelarySize =
      labelSize === ScanLabelSize.Large ? '4x6' : '3.93701x1.9685';

    const response = await fetch(
      `https://api.labelary.com/v1/printers/8dpmm/labels/${lebelarySize}/0/`,
      {
        body: formData,
        headers: {},
        method: 'POST',
      },
    );

    if (!response.ok) {
      // create error object and reject if not a 2xx response code
      const err = new Error(`HTTP status code: ${response.status}`);
      err.response = response;
      err.status = response.status;
      throw err;
    }

    const blob = await response.blob();
    return URL.createObjectURL(blob);
  });
};

export default useLabelary;

import PropTypes from 'prop-types';

const postcodeGroupPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  postcodes: PropTypes.arrayOf(PropTypes.string),
  hub: PropTypes.shape({
    id: PropTypes.string,
  }),
});

export default postcodeGroupPropType;

import PropTypes from 'prop-types';

import imagePropType from './imagePropType';
import unitPropType from './unitPropType';

const tourStopUnitPropType = PropTypes.shape({
  // no id on tour stop unit, only on unit!
  attempt: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  executionTime: PropTypes.string,
  failureReason: PropTypes.string,
  failureReasonNote: PropTypes.string,
  deliveryOptions: PropTypes.arrayOf(PropTypes.string),
  deliveryOption: PropTypes.string,
  deliveryOptionNote: PropTypes.string,
  images: PropTypes.arrayOf(imagePropType),
  signature: imagePropType,
  unit: unitPropType.isRequired,
  executionLocation: PropTypes.shape({
    coordinates: PropTypes.arrayOf(PropTypes.number),
  }),
});

export default tourStopUnitPropType;

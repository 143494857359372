import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CardListContainer from '../../../components/CardListContainer';
import UnitDetailsDrawer from '../../shared/UnitDetailsDrawer';
import UnitStatusUpdate from '../../shared/UnitStatusUpdate';
import CancelUnitModal from '../CancelUnitModal';
import UnitCard from '../UnitCard';
import UpdateUnitCode from '../UpdateUnitCode';

const UnitsCardList = (props) => {
  const { data, isInitialLoading, isLoading } = props;
  const [unitForCodeUpdate, setUnitForCodeUpdate] = useState(null);
  const [unitForStatusUpdate, setUnitForStatusUpdate] = useState(null);
  const [unitForCodeCancel, setUnitForCodeCancel] = useState(null);
  const [unitForDetailsView, setUnitForDetailsView] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location) {
      // Close drawers when location changes
      setUnitForCodeUpdate(null);
      setUnitForStatusUpdate(null);
      setUnitForDetailsView(null);
    }
  }, [location]);

  return (
    <>
      <UpdateUnitCode
        isOpen={!!unitForCodeUpdate}
        unit={unitForCodeUpdate}
        onClose={() => setUnitForCodeUpdate(null)}
      />
      <UnitStatusUpdate
        isOpen={!!unitForStatusUpdate}
        units={[unitForStatusUpdate]}
        onClose={() => setUnitForStatusUpdate(null)}
      />
      <CancelUnitModal
        isOpen={!!unitForCodeCancel}
        unit={unitForCodeCancel}
        onClose={() => setUnitForCodeCancel(null)}
      />
      <UnitDetailsDrawer
        isOpen={!!unitForDetailsView}
        unitId={unitForDetailsView?.id}
        onClose={() => setUnitForDetailsView(null)}
      />
      <CardListContainer
        isEmpty={data.length === 0}
        isLoading={isLoading}
        isInitialLoading={isInitialLoading}
        isMultipleExpandable
        id="units"
      >
        {data.map((unit) => (
          <UnitCard
            onUpdateUnitCodeClick={() => setUnitForCodeUpdate(unit)}
            onUpdateUnitStatusClick={() => setUnitForStatusUpdate(unit)}
            onUnitCancel={() => setUnitForCodeCancel(unit)}
            onDetailsClick={() => setUnitForDetailsView(unit)}
            unit={unit}
            key={unit.id || unit.code}
          />
        ))}
      </CardListContainer>
    </>
  );
};

UnitsCardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
};

UnitsCardList.defaultProps = {
  data: undefined,
  isLoading: false,
  isInitialLoading: false,
};

export default UnitsCardList;

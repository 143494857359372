import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import PrinterStatus from '../../../enums/PrinterStatus';
import BrowserPrintStatus from '../../../lib/browser-print/enums/BrowserPrintStatus';
import { useBrowserPrint } from '../../../lib/browser-print/hooks/useBrowserPrint';
import { usePackageScanningSettings } from '../../../providers/PackageScanningSettingsProvider';
import BrowserPrintLoader from '../BrowserPrintLoader';
import FeedSettingsButton from '../FeedSettingsButton';
import InfoBox from '../InfoBox';
import PrinterLoader from '../PrinterLoader';
import ScanFeed from '../ScanFeed';

const ScanningMainContent = () => {
  const { t } = useTranslation();
  const { hubType } = usePackageScanningSettings();
  const {
    currentPrinter,
    currentPrinterStatus,
    print,
    printers,
    resetCurrentPrinterState,
    status,
  } = useBrowserPrint();

  if (status !== BrowserPrintStatus.Ready) {
    return <BrowserPrintLoader status={status} />;
  }

  if (!hubType) {
    return (
      <InfoBox
        dataTest="no-hub-set-warning"
        icon={
          <Icon
            className="h-12 w-12 text-ui-warning-orange"
            icon="infoFilled"
          />
        }
        text={t('Please contact your Manager.')}
        title={t('No Hub set for the current user')}
      />
    );
  }

  const hasAvailablePrinters = printers.length > 0;
  if (!hasAvailablePrinters) {
    return (
      <InfoBox
        dataTest="no-printers-available"
        icon={<Icon className="h-12 w-12 text-ui-red" icon="report" />}
        text={t(
          'Please check if the printer is connected and refresh this page.',
        )}
        title={t('No Printers Available')}
      >
        <Button
          icon="reload"
          text={t('Reload')}
          variant="outlineBlack"
          onClick={() => window.location.reload()}
        />
      </InfoBox>
    );
  }

  if (!currentPrinter) {
    return (
      <InfoBox
        dataTest="no-printer-selected"
        icon={
          <Icon
            className="h-12 w-12 text-ui-warning-orange"
            icon="infoFilled"
          />
        }
        text={t('Please select a printer to continue.')}
        title={t('No Printer Selected')}
      >
        <FeedSettingsButton />
      </InfoBox>
    );
  }

  if (
    currentPrinterStatus !== PrinterStatus.Ready &&
    currentPrinterStatus !== PrinterStatus.Printing &&
    currentPrinterStatus !== PrinterStatus.Printed &&
    currentPrinterStatus !== PrinterStatus.Error
  ) {
    return <PrinterLoader />;
  }

  return (
    <ScanFeed
      print={print}
      printerStatus={currentPrinterStatus}
      resetPrinterState={resetCurrentPrinterState}
    />
  );
};

export default ScanningMainContent;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const variantsMap = {
  dark: 'text-ui-blue',
  white: 'text-white',
};

const LinkButton = forwardRef((props, ref) => {
  const { as: Component, className, onClick, text, variant, ...rest } = props;

  return (
    <Component
      ref={ref}
      type="button"
      className={cn(
        'text-sm font-medium text-ui-blue underline transition hover:opacity-70 disabled:cursor-not-allowed disabled:opacity-40',
        variantsMap[variant],
        className,
      )}
      onClick={onClick}
      {...rest}
    >
      {text}
    </Component>
  );
});

LinkButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(variantsMap)),
  as: PropTypes.elementType,
};

LinkButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  text: PropTypes.string,
  className: undefined,
  variant: undefined,
  as: 'button',
};

export default LinkButton;

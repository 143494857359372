const PrinterErrorType = {
  RibbonOut: 'ribbonOut',
  PaperOut: 'paperOut',
  HeadOpen: 'headOpen',
  Paused: 'paused',
  Offline: 'offline',
  ConnectionError: 'connectionError',
};

export default PrinterErrorType;

class RoutingConfigHelper {
  static routingConfigType = {
    MainCarrierConfig: 'mainCarrierConfig',
    CustomCarrierConfig: 'customCarrierConfig',
    MainHubConfig: 'mainHubConfig',
    CustomHubConfig: 'customHubConfig',
    MainPostcodeGroupConfig: 'mainPostcodeGroupConfig',
    CustomPostcodeGroupConfig: 'customPostcodeGroupConfig',
  };

  static getRoutingConfigType = (routingConfig) => {
    if (routingConfig.postcodeGroup && routingConfig.days !== null) {
      return this.routingConfigType.CustomPostcodeGroupConfig;
    }

    if (routingConfig.postcodeGroup && routingConfig.days === null) {
      return this.routingConfigType.MainPostcodeGroupConfig;
    }

    if (routingConfig.days !== null && routingConfig.hub !== null) {
      return this.routingConfigType.CustomHubConfig;
    }

    if (routingConfig.days === null && routingConfig.hub !== null) {
      return this.routingConfigType.MainHubConfig;
    }

    if (routingConfig.days !== null && routingConfig.hub === null) {
      return this.routingConfigType.CustomCarrierConfig;
    }

    if (routingConfig.days === null && routingConfig.hub === null) {
      return this.routingConfigType.MainCarrierConfig;
    }
    return undefined;
  };
}

export default RoutingConfigHelper;

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function useIndexedOptions(rootOptions, injectAllOptionForSelectableGroups) {
  const { t } = useTranslation();
  const selectGroupSelectAll = t('selectGroupSelectAll');

  const indexOptions = useCallback(
    (options, path = []) =>
      options.map((option, index) => {
        const currentPath = path.concat(index);

        if (option.options) {
          const {
            inputIcon,
            inputIconClassname,
            label,
            labelIcon,
            labelIconClassname,
            options: currentOptions,
            ...currentOption
          } = option;

          const newOptions = [
            ...(option.options.length > 1 &&
            option.isGroupSelectable &&
            injectAllOptionForSelectableGroups
              ? [
                  {
                    ...currentOption,
                    inputIcon: inputIcon || labelIcon,
                    inputIconClassname:
                      inputIconClassname || labelIconClassname,
                    dropdownLabel: selectGroupSelectAll,
                  },
                ]
              : []),
            ...currentOptions,
          ];
          return {
            ...currentOption,
            label,
            labelIcon,
            labelIconClassname,
            inputIcon,
            inputIconClassname,
            options: indexOptions(newOptions, currentPath),
            index,
            path: currentPath,
            id: currentPath?.join('-'),
          };
        }

        return {
          ...option,
          index,
          path: currentPath,
          id: currentPath?.join('-'),
        };
      }),
    [injectAllOptionForSelectableGroups, selectGroupSelectAll],
  );

  return useMemo(() => indexOptions(rootOptions), [indexOptions, rootOptions]);
}

export default useIndexedOptions;

const FailureReason = {
  NoOneAtTheAddress: 'noOneAtTheAddress',
  AddressNotFound: 'addressNotFound',
  StoreClosed: 'storeClosed',
  NameNotOnTheDoorbell: 'nameNotOnTheDoorbell',
  ForceMajeure: 'forceMajeure',
  RefusedByCustomer: 'refusedByCustomer',
  Other: 'other',
  LackOfTime: 'lackOfTime',
  Damaged: 'damaged',
};

export default FailureReason;

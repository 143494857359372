import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card, CardHeader } from '../../../components/Card';
import ProgressRing from '../../../components/ProgressRing';
import RatingImage from '../../../components/RatingImage';
import useRatingTranslations from '../../../hooks/useRatingTranslations';

const Section = ({ children }) => (
  <div className="flex items-center rounded-md bg-grey-100 p-4 lg:h-[104px]">
    {children}
  </div>
);

Section.propTypes = {
  children: PropTypes.node,
};

Section.defaultProps = {
  children: undefined,
};

const MemberPerformance = (props) => {
  const { rating, score, totalTours } = props;
  const { t } = useTranslation();
  const getRatingTranslation = useRatingTranslations();

  if (rating === null || rating === undefined) {
    return null;
  }

  if (score === null || score === undefined) {
    return null;
  }

  return (
    <Card>
      <CardHeader border={false} title={t('Driver Performance')} />
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <Section>
          <div className="flex w-full items-center justify-between">
            <div className="text-xs leading-none text-grey-700">
              {t('Tours Completed')}
            </div>
            <div className="flex items-center justify-center p-2 text-[26px] font-semibold text-primary-dark">
              {totalTours}
            </div>
          </div>
        </Section>
        <Section>
          <div className="flex w-full items-center justify-between">
            <div className="text-xs leading-none text-grey-700">
              {t('Score')}
            </div>
            <ProgressRing progress={score} radius={28} strokeWidth={4} />
          </div>
        </Section>
        <Section>
          <div className="flex w-full items-center justify-between">
            <div>
              <div className="mb-1 text-xs text-grey-700">{t('Rating')}</div>
              <div className="text-sm leading-none text-primary-dark">
                {getRatingTranslation(rating)}
              </div>
            </div>
            <RatingImage rating={rating} />
          </div>
        </Section>
      </div>
    </Card>
  );
};

MemberPerformance.propTypes = {
  rating: PropTypes.string,
  score: PropTypes.number,
  totalTours: PropTypes.number,
};

MemberPerformance.defaultProps = {
  rating: undefined,
  score: undefined,
  totalTours: undefined,
};

export default MemberPerformance;

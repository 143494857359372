import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import Button from '../../components/Button';
import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import UserRole from '../../enums/UserRole';
import CustomersMembersFilters from '../../features/clients/CustomerMembersFilters';
import MembersCardList from '../../features/shared/MembersCardList';
import MembersTable from '../../features/shared/MembersTable';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import Resource from '../../lib/api/Resource';
import { useIsRole } from '../../providers/UserProvider';

const ClientMembers = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const [searchParams] = useSearchParams();
  const isAdmin = useIsRole(UserRole.Admin);

  const searchQuery = searchParams.get('search');

  const hasFiltersApplied = !!searchQuery;

  const { fetch } = useFetch();
  const { data, error, isError, isLoading } = useQuery(
    [`/clients/`, id],
    async () => {
      const response = await fetch(`/clients/${id}`, {
        method: 'GET',
      });
      return response.json();
    },
  );

  const organisation = data?.data || null;

  const params = useMemo(
    () => ({
      name: searchQuery,
    }),
    [searchQuery],
  );

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        backLink={`/clients/${organisation?.id}`}
        isActive={hasFiltersApplied}
        menu={<CustomersMembersFilters />}
        textPrimary={`${t('Members')} (${organisation?.userCount})`}
        textSecondary={`${organisation?.name} (${organisation?.shortCode})`}
        isLoading={isLoading}
        titleRightContent={
          isAdmin && (
            <Button
              as={Link}
              className="w-full lg:w-fit"
              icon="userAdd"
              text={t('New Member')}
              to="new"
              variant="solidBlue"
            />
          )
        }
      />

      <Page.Content variant={isDesktop ? 'white' : 'grey'}>
        <Resource params={params} resourceUrl={`/clients/${id}/users`}>
          {isDesktop ? <MembersTable isCustomer /> : <MembersCardList />}
        </Resource>
      </Page.Content>
    </Page>
  );
};

export default ClientMembers;

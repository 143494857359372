import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useRoutingDaysTranslations from '../../../hooks/useRoutingDaysTranslations';
import routingConfigurationPropType from '../../../prop-types/routingConfigurationPropType';

const RoutingItem = ({ isCarrier, routing }) => {
  const { t } = useTranslation();
  const routingDaysTranslations = useRoutingDaysTranslations();

  const isMain = !routing.days;

  return (
    <div className="flex flex-col gap-3">
      <div className="text-sm font-medium">
        {isCarrier ? t('Carrier') : t('Hub')} {isMain ? t('Main') : t('Custom')}{' '}
        <span className="font-normal">
          <span>
            (
            {isMain
              ? t('All days')
              : routing.days
                  .map((day) => routingDaysTranslations[day.substring(0, 3)])
                  .join(', ')}
            )
          </span>
          {routing.postcodeGroup && (
            <span> - {routing.postcodeGroup.name}</span>
          )}
        </span>
      </div>
      <div
        className={cn(
          'flex flex-col md:flex-row flex-nowrap md:flex-wrap md:gap-2 md:divide-x md:divide-grey-500 py-2 px-3 rounded-md',
          isMain && 'bg-grey-100',
          !isMain && 'bg-white border border-grey-300',
        )}
      >
        <div className="text-sm text-primary-dark">
          {t('Max km / Tour')}:{' '}
          <span className="font-medium">
            {routing.maxDistancePerTourKm
              ? `${routing.maxDistancePerTourKm} km`
              : t('No limit')}
          </span>
        </div>
        <div className="text-sm text-primary-dark md:pl-2">
          {t('Max number of stops / Tour')}:{' '}
          <span className="font-medium">
            {routing.maxDeliveriesPerTour
              ? routing.maxDeliveriesPerTour
              : t('No limit')}
          </span>
        </div>
        <div className="text-sm text-primary-dark md:pl-2">
          {t('Service time duration')}:{' '}
          <span className="font-medium">{routing.serviceTime} sec</span>
        </div>
        <div className="text-sm text-primary-dark md:pl-2">
          {t('Minimise number of Tours')}:{' '}
          <span className="font-medium">
            {routing.minimizeNumberOfTours ? 'ON' : 'OFF'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default RoutingItem;

RoutingItem.propTypes = {
  isCarrier: PropTypes.bool,
  routing: routingConfigurationPropType.isRequired,
};

RoutingItem.defaultProps = {
  isCarrier: false,
};

import * as Sentry from '@sentry/react';
import { useCallback } from 'react';

import UserHelper from '../helpers/UserHelper';
import getCurrentLanguage from '../translations/getCurrentLanguage';

const useSentry = () => {
  const setSentryUser = useCallback((user) => {
    const username = UserHelper.getInitials(user);
    const userData = { role: user.role, username };
    // eslint-disable-next-line no-console
    console.log('set sentry user', userData);
    Sentry.setUser(userData);
  }, []);

  const unsetSentryUser = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('unset sentry user');
    Sentry.setUser(null);
  }, []);

  const showReportDialog = useCallback((eventId) => {
    Sentry.showReportDialog({ eventId, lang: getCurrentLanguage() });
  }, []);

  const sendSentryMessage = useCallback((message) => {
    Sentry.captureMessage(message);
  }, []);

  return {
    setSentryUser,
    showReportDialog,
    unsetSentryUser,
    sendSentryMessage,
  };
};

export default useSentry;

import moment from 'moment/moment';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import DeliveryOption from '../enums/DeliveryOption';
import DeliveryTaskUnitStatus from '../enums/DeliveryTaskUnitStatus';
import UnitProgress from '../enums/UnitProgress';
import UnitSortType from '../enums/UnitSortType';
import UnitsSearchBy from '../enums/UnitsSearchBy';
import useDateQuery from './useDateQuery';
import useUnitProgress from './useUnitProgress';

const useUnitsPageSearchParams = () => {
  const unitProgress = useUnitProgress();
  const [searchParams, setSearchParams] = useSearchParams({
    searchBy: UnitsSearchBy.Code,
  });

  const hubIdQuery = searchParams.get('hubId') || '';
  const unitProgressSearch = searchParams.get('progress');
  const unitProgressQuery = Object.values(UnitProgress).includes(
    unitProgressSearch,
  )
    ? unitProgressSearch
    : undefined;

  const unitStatusSearch = searchParams.get('status') || '';
  const unvalidatedUnitStatusQuery = Object.values(
    DeliveryTaskUnitStatus,
  ).includes(unitStatusSearch)
    ? unitStatusSearch
    : undefined;

  const isValidUnitStatus =
    unvalidatedUnitStatusQuery !== undefined && unitProgressQuery !== undefined
      ? unitProgress[unitProgressQuery]?.statuses?.includes(unitStatusSearch)
      : // true because validation is not needed if progress is undefined
        true;

  const unitStatusQuery = isValidUnitStatus ? unvalidatedUnitStatusQuery : '';

  const deliveryOptionQuery = searchParams.get('deliveryOption') || '';
  const postponeReasonQuery = searchParams.get('postponeReason') || '';
  const failureReasonQuery = searchParams.get('failureReason') || '';
  const returnReasonQuery = searchParams.get('returnReason') || '';

  const searchByQuery = searchParams.get('searchBy') || '';
  const searchQuery = searchParams.get('search') || '';

  const shiftQuery = searchParams.get('shiftNumber') || '';
  const clientIdQuery = searchParams.get('clientId') || '';
  const secondAttemptOnlyQuery = searchParams.get('secondAttemptOnly') || '';

  const startDateQuery = useDateQuery('startDate');
  const startDate = startDateQuery
    ? moment(startDateQuery).format('YYYY-MM-DD')
    : undefined;

  const endDateQuery = useDateQuery('endDate');
  const endDate = endDateQuery
    ? moment(endDateQuery).format('YYYY-MM-DD')
    : undefined;

  const sortTypeQuery = searchParams.get('sortType');
  const currentSortType = useMemo(() => {
    if (sortTypeQuery === UnitSortType.LatestStatusChange) {
      return UnitSortType.LatestStatusChange;
    }
    if (sortTypeQuery === UnitSortType.EarliestImport) {
      return UnitSortType.EarliestImport;
    }

    return UnitSortType.LatestExecutionDate;
  }, [sortTypeQuery]);

  useEffect(() => {
    if (!Object.values(UnitsSearchBy).includes(searchByQuery)) {
      searchParams.set('searchBy', UnitsSearchBy.Code);
      setSearchParams(searchParams, {
        replace: true,
      });
    }
  }, [searchByQuery, searchParams, setSearchParams]);

  const isFilterApplied = useMemo(
    () =>
      !!startDate ||
      !!endDate ||
      !!unitStatusQuery ||
      !!deliveryOptionQuery ||
      !!unitProgressQuery ||
      !!postponeReasonQuery ||
      !!failureReasonQuery ||
      !!returnReasonQuery ||
      !!hubIdQuery ||
      !!shiftQuery ||
      !!clientIdQuery ||
      !!searchQuery,
    [
      clientIdQuery,
      deliveryOptionQuery,
      endDate,
      failureReasonQuery,
      hubIdQuery,
      postponeReasonQuery,
      returnReasonQuery,
      searchQuery,
      shiftQuery,
      startDate,
      unitProgressQuery,
      unitStatusQuery,
    ],
  );

  const allParams = {
    clientId: clientIdQuery,
    startDate,
    endDate,
    status: unitStatusQuery,
    deliveryOption:
      deliveryOptionQuery === DeliveryOption.Direct
        ? // "null" is used for filtering direct delivery option
          'null'
        : deliveryOptionQuery,
    progress: unitProgressQuery,
    postponeReason: postponeReasonQuery,
    failureReason: failureReasonQuery,
    returnReason: returnReasonQuery,
    hubId: hubIdQuery,
    shiftNumber: shiftQuery,
    ...(searchQuery && { search: searchQuery }),
    ...(searchQuery && { searchBy: searchByQuery }),
    orderBy: currentSortType,
    secondAttemptOnly: secondAttemptOnlyQuery,
  };

  return {
    hubIdQuery,
    unitProgressQuery,
    unitStatusQuery,
    deliveryOptionQuery,
    returnReasonQuery,
    failureReasonQuery,
    postponeReasonQuery,
    shiftQuery,
    clientIdQuery,
    startDateQuery,
    endDateQuery,
    currentSortType,
    searchParams,
    setSearchParams,
    searchQuery,
    searchByQuery,
    allParams,
    isFilterApplied,
    secondAttemptOnlyQuery,
  };
};

export default useUnitsPageSearchParams;

import { useTranslation } from 'react-i18next';

import Meta from '../../app/Meta';
import PrinterSetupModal from '../../features/package-scanning/PrinterSetupModal';
import ScanningMainContent from '../../features/package-scanning/ScanningMainContent';
import ScanningPageLayout from '../../features/package-scanning/ScanningPageLayout';

const PackageScanning = () => {
  const { t } = useTranslation();

  const title = t('Package Scanning');

  return (
    <>
      <Meta title={title} />
      <PrinterSetupModal />
      <ScanningPageLayout>
        <div className="mx-auto w-full max-w-[696px] py-6">
          <ScanningMainContent />
        </div>
      </ScanningPageLayout>
    </>
  );
};

export default PackageScanning;

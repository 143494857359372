import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import InputLayout from '../../../components/InputLayout';
import Modal from '../../../components/Modal';
import Select from '../../../components/Select';
import ScanLabelSize from '../../../enums/ScanLabelSize';
import createZebraPrinter from '../../../lib/browser-print/createZebraPrinter';
import { useBrowserPrint } from '../../../lib/browser-print/hooks/useBrowserPrint';
import { usePackageScanningSettings } from '../../../providers/PackageScanningSettingsProvider';

const PrinterSetupModal = () => {
  const { t } = useTranslation();
  const {
    isPrinterSetupModalOpen: isOpen,
    labelSize,
    setIsPrinterSetupModalOpen,
    setLabelSize,
  } = usePackageScanningSettings();
  const { currentPrinter, printers, setCurrentPrinter } = useBrowserPrint();

  const onClose = () => {
    setIsPrinterSetupModalOpen(false);
  };

  const [newUid, setNewUid] = useState(currentPrinter?.uid || '');
  const [newSize, setNewSize] = useState(labelSize);

  const labelSizeOptions = [
    { label: `${t('Large')} (105x148 mm)`, value: ScanLabelSize.Large },
    { label: `${t('Small')} (100x50 mm)`, value: ScanLabelSize.Small },
  ];

  const options = useMemo(
    () =>
      printers.map((printer) => ({
        label: printer.name,
        value: printer.uid,
      })),
    [printers],
  );

  return (
    <Modal
      body={
        <div className="flex flex-col gap-4" data-test="feed-settings">
          <InputLayout label={t('Printer')}>
            <Select
              noOptionsMessage={t('No Printers Available')}
              options={options}
              placeholder={t('Select Printer')}
              value={newUid}
              onChange={setNewUid}
            />
          </InputLayout>
          <InputLayout label="Label Size">
            <Select
              data-test="label-size-select"
              options={labelSizeOptions}
              placeholder={t('Select Size')}
              value={newSize}
              onChange={setNewSize}
              required
            />
          </InputLayout>
        </div>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Save Settings')}
            variant="solidBlue"
            onClick={() => {
              const newPrinter = printers.find(
                (printer) => printer.uid === newUid,
              );
              if (newSize !== labelSize) {
                setLabelSize(newSize);
              }
              if (newPrinter && newPrinter.uid !== currentPrinter?.uid) {
                setCurrentPrinter(createZebraPrinter(newPrinter));
              } else if (!newPrinter) {
                setCurrentPrinter(undefined);
              }
              onClose();
            }}
          />
        </div>
      }
      header={
        <span className="flex items-center gap-2">
          <Icon className="h-6 w-6 text-grey-700" icon="printer" />
          <span>{t('Printer Setup')}</span>
        </span>
      }
      isOpen={isOpen}
      onClose={onClose}
      onOpen={() => {
        setNewUid(currentPrinter?.uid || '');
        setNewSize(labelSize);
      }}
    />
  );
};

export default PrinterSetupModal;

import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const CardIcon = (props) => {
  const { className, icon } = props;

  return <Icon className={cn('h-5 w-5', className)} icon={icon} />;
};

CardIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

CardIcon.defaultProps = {
  className: '',
};

export default CardIcon;

import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputLayout from '../InputLayout';
import Select from '../Select';
import { optionPropType } from '../Select/Option';

const FormSelect = (props) => {
  const {
    defaultValue,
    disabled,
    error,
    id,
    isLoading,
    label,
    name,
    onBottomReached,
    onChange: propOnChange,
    onSearch,
    options,
    placeholder,
    readOnly,
    required,
    useLocalSearch,
    ...rest
  } = props;

  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();

  const generateValidators = useCallback(() => {
    const validators = {};
    if (required) {
      if (typeof required === 'string') {
        validators.required = required;
      } else {
        validators.required = t('Please choose a value.');
      }
    } else {
      validators.required = false;
    }
    return validators;
  }, [required, t]);

  const errorInput = error || (errors[name] && errors[name].message);

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      disabled={disabled}
      render={({ field: { onChange, ref, ...controllerProps } }) => (
        <InputLayout
          error={errorInput}
          id={id}
          label={label}
          required={required}
        >
          <Select
            error={errorInput}
            id={id}
            isLoading={isLoading}
            label={label}
            options={options}
            onBottomReached={onBottomReached}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required}
            onChange={(value) => {
              propOnChange(value);
              onChange(value);
            }}
            onSearch={onSearch}
            useLocalSearch={useLocalSearch}
            {...controllerProps}
            {...rest}
          />
        </InputLayout>
      )}
      rules={generateValidators()}
    />
  );
};

FormSelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(optionPropType),
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  isLoading: PropTypes.bool,
  onBottomReached: PropTypes.func,
  onSearch: PropTypes.func,
  useLocalSearch: PropTypes.bool,
};

FormSelect.defaultProps = {
  defaultValue: '',
  disabled: false,
  error: false,
  id: '',
  label: '',
  name: '',
  onChange: () => {},
  options: [],
  placeholder: '',
  readOnly: false,
  required: false,
  isLoading: false,
  onBottomReached: undefined,
  onSearch: undefined,
  useLocalSearch: undefined,
};

export default FormSelect;

import PropTypes from 'prop-types';

const FadingOverlay = ({ children }) => (
  <div className="relative">
    {children}
    <div className="absolute bottom-0 left-0 right-0 top-0  z-10 bg-gradient-to-b from-transparent to-white" />
  </div>
);

FadingOverlay.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FadingOverlay;

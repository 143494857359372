const GroceriesTourStatus = {
  Active: 'active',
  CanceledAdmin: 'canceledAdmin',
  Completed: 'completed',
  CompletedAdmin: 'completedAdmin',
  Created: 'created',
  NotAssignedDriver: 'notAssignedDriver',
  Pending: 'pending',
};

export default GroceriesTourStatus;

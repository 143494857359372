import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormCheckbox from '../../../components/FormCheckbox';
import FormInput from '../../../components/FormInput';

const NumberNoLimitFormInput = forwardRef((props, ref) => {
  const {
    checkboxName,
    error,
    id,
    inlineLabel,
    label,
    max,
    min,
    name,
    placeholder,
    ...rest
  } = props;

  const {
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const errorInput = error || (errors[name] && errors[name].message);

  const noLimit = watch(checkboxName);

  const { t } = useTranslation();

  useEffect(() => {
    if (noLimit) {
      clearErrors(name);
    }
  }, [clearErrors, name, noLimit]);

  return (
    <div>
      <label
        className="mb-2 flex gap-1 text-sm font-normal text-primary-dark"
        htmlFor={id}
      >
        {label}*
      </label>
      <div className="flex">
        <FormCheckbox
          label={t('No limit')}
          name={checkboxName}
          onChange={() => {
            setValue(name, '');
          }}
        />
      </div>
      <div className="mt-2">
        <FormInput
          key={noLimit}
          disabled={noLimit}
          hasError={!!errorInput}
          id={id}
          placeholder={placeholder}
          ref={ref}
          type="number"
          inlineLabel={inlineLabel}
          min={min}
          max={max}
          required={!noLimit}
          name={name}
          {...rest}
        />
      </div>
    </div>
  );
});

NumberNoLimitFormInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  label: PropTypes.string,
  inlineLabel: PropTypes.string,
  name: PropTypes.string,
  checkboxName: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

NumberNoLimitFormInput.defaultProps = {
  error: undefined,
  id: '',
  label: '',
  inlineLabel: '',
  name: '',
  checkboxName: '',
  placeholder: '',
  min: undefined,
  max: undefined,
};

export default NumberNoLimitFormInput;

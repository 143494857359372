import cn from 'classnames';
import PropTypes from 'prop-types';

import Switch from '../Switch';

const OnOffSwitch = (props) => {
  const { checked, disabled, onChange } = props;

  return (
    <div className="flex items-center gap-2">
      <span
        className={cn(
          'text-sm font-medium',
          checked ? 'text-grey-700' : 'text-grey-900',
          disabled && 'opacity-40',
        )}
      >
        OFF
      </span>
      <Switch checked={checked} disabled={disabled} onChange={onChange} />
      <span
        className={cn(
          'text-sm font-medium',
          checked ? 'text-grey-900' : 'text-grey-700',
          disabled && 'opacity-40',
        )}
      >
        ON
      </span>
    </div>
  );
};

OnOffSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

OnOffSwitch.defaultProps = {
  disabled: false,
};

export default OnOffSwitch;

import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Switch from '../../../components/Switch';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import UnitEventType from '../../../enums/UnitEventType';
import UnitProgress from '../../../enums/UnitProgress';
import AddUnitNote from '../../units/AddUnitNote';
import UnitEventHistory from '../../units/UnitEventHistory';
import eventType from '../../units/UnitEventHistory/eventType';

const statusNeutralEvents = [
  UnitEventType.TimeSlotChange,
  UnitEventType.AddressChange,
  UnitEventType.TrackingTimeSlotChange,
  UnitEventType.ReferenceUpdate,
  UnitEventType.Routing,
  UnitEventType.Note,
  UnitEventType.DeliveryConsentUpdate,
  UnitEventType.Delay,
];

const UnitDetailsEventHistory = ({ unit }) => {
  const { t } = useTranslation();

  const [isAddNoteOpen, setIsAddNoteOpen] = useState(false);
  const [showOnlyStatusChangingEvents, setShowOnlyStatusChangingEvents] =
    useState(false);

  const events = useMemo(
    () =>
      showOnlyStatusChangingEvents
        ? unit.events?.filter(
            (event) => !statusNeutralEvents.includes(event.type),
          )
        : unit.events,
    [unit, showOnlyStatusChangingEvents],
  );

  return (
    <>
      <div className="flex flex-col">
        <div>
          <Button
            isFullWidth
            icon="plus"
            iconSize="s"
            size="s"
            variant="outlineBlue"
            onClick={() => setIsAddNoteOpen(true)}
            text={t('Add Note')}
          />
        </div>

        <div className="border-grey-200 border-b mt-4 mb-6" />

        <div>
          <div className="flex items-center text-grey-700 justify-between" />
          <div className="flex items-center gap-3 mb-6">
            <Switch
              checked={showOnlyStatusChangingEvents}
              onChange={(checked) => {
                setShowOnlyStatusChangingEvents(checked);
              }}
            />
            <span className="text-grey-700 text-sm">
              {t('Show only status-changing events')}
            </span>
          </div>
          <div>
            <UnitEventHistory events={events} />
          </div>
        </div>
      </div>

      <AddUnitNote
        showBackButton
        transparentBackdrop
        isOpen={isAddNoteOpen}
        onClose={() => {
          setIsAddNoteOpen(false);
        }}
        unitId={unit.id}
      />
    </>
  );
};

UnitDetailsEventHistory.propTypes = {
  unit: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    status: PropTypes.oneOf(Object.values(DeliveryTaskUnitStatus)),
    statusChangeTime: PropTypes.string,
    number: PropTypes.number,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    progress: PropTypes.oneOf(Object.values(UnitProgress)),
    shiftNumber: PropTypes.number,
    unitsInTask: PropTypes.number,
    eventNote: PropTypes.string,
    date: PropTypes.string,
    task: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
    }),
    events: PropTypes.arrayOf(eventType),
    address: PropTypes.string,
    hub: PropTypes.shape({
      id: PropTypes.string,
      shortCode: PropTypes.string,
    }),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    createdAt: PropTypes.string,
    tours: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        vehicle: PropTypes.string,
        tourStop: PropTypes.shape({
          id: PropTypes.string,
          sequence: PropTypes.number,
          windowFrom: PropTypes.string,
          windowTo: PropTypes.string,
        }),
      }),
    ),
  }),
};

UnitDetailsEventHistory.defaultProps = {
  unit: undefined,
};
export default UnitDetailsEventHistory;

import PropTypes from 'prop-types';

const imagePropType = PropTypes.shape({
  id: PropTypes.string,
  signedUrl: PropTypes.string,
  apiUrl: PropTypes.string,
  mimeType: PropTypes.string,
  originalName: PropTypes.string,
  encoding: PropTypes.string,
  size: PropTypes.number,
});

export default imagePropType;

import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from '../../../components/Icon';
import Tooltip from '../../../components/Tooltip';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import UnitProgress from '../../../enums/UnitProgress';
import useDeliveryUnitStatusTranslations from '../../../hooks/useDeliveryUnitStatusTranslations';
import useUnitProgress from '../../../hooks/useUnitProgress';

const UnitsStatusAndChangeTime = ({ unit }) => {
  const deliveryUnitStatusTranslations = useDeliveryUnitStatusTranslations();
  const { t } = useTranslation();
  const unitProgress = useUnitProgress();
  const progress = unitProgress[unit.progress];

  return (
    <div className="flex items-center gap-2">
      <Tooltip text={unitProgress[progress.value].label}>
        <Icon
          className={cn(progress.labelIconClassname, 'w-4 h-4')}
          icon={progress.labelIcon}
        />
      </Tooltip>
      <div className="text-sm">
        <span>{deliveryUnitStatusTranslations[unit.status]}</span>{' '}
        <span className="whitespace-nowrap">
          {moment(unit.statusChangeTime).format("DD.MM.'YY. HH:mm")}
        </span>
      </div>
      {unit.unchangedStatusForSignificantTime && (
        <Tooltip
          placement="top"
          size="sm"
          text={t('Unit has been in this status for more than 3 working days.')}
        >
          <Icon icon="reminder" className="w-4 h-4 text-ui-red" />
        </Tooltip>
      )}
    </div>
  );
};

UnitsStatusAndChangeTime.propTypes = {
  unit: PropTypes.shape({
    status: PropTypes.oneOf(Object.values(DeliveryTaskUnitStatus)),
    statusChangeTime: PropTypes.string,
    progress: PropTypes.oneOf(Object.values(UnitProgress)),
    unchangedStatusForSignificantTime: PropTypes.bool,
  }).isRequired,
};

export default UnitsStatusAndChangeTime;

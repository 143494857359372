import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AddressInput from '../AddressInput';

const FormAddressInput = (props) => {
  const {
    disabled,
    error,
    icon,
    id,
    label,
    name,
    onChange: propOnChange,
    onSelect,
    placeholder,
    required,
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();

  const generateValidators = useCallback(() => {
    const validators = {};
    if (required) {
      validators.required = t('This field is required.');
    } else {
      validators.required = false;
    }

    return validators;
  }, [required, t]);

  const errorInput = error || (errors[name] && errors[name].message);

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field: { onBlur, onChange, ...controllerProps } }) => (
        <AddressInput
          disabled={disabled}
          errorInput={errorInput}
          icon={icon}
          id={id}
          label={label}
          placeholder={placeholder}
          required={required}
          onChange={(address) => {
            onChange(address);
            propOnChange(address);
          }}
          onSelect={(address) => {
            onChange(address);
            onSelect(address);
            onBlur();
          }}
          onBlur={onBlur}
          {...controllerProps}
        />
      )}
      rules={generateValidators()}
    />
  );
};

FormAddressInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

FormAddressInput.defaultProps = {
  disabled: undefined,
  error: undefined,
  icon: '',
  id: '',
  label: '',
  onChange: () => {},
  onSelect: () => {},
  placeholder: '',
  required: false,
};

export default FormAddressInput;

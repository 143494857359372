import cn from 'classnames';
import PropTypes from 'prop-types';

const variantsMap = {
  blue: 'bg-ui-blue',
  green: 'bg-ui-green-dark',
};

const Switch = (props) => {
  const { checked, disabled, onChange, variant } = props;

  return (
    <button
      className={cn(
        'relative flex h-5 w-[34px] flex-shrink-0 items-center rounded-xl transition-all disabled:opacity-40',
        checked ? variantsMap[variant] : 'bg-grey-500',
      )}
      disabled={disabled}
      type="button"
      onClick={() => onChange(!checked)}
    >
      <div
        className={cn(
          'absolute m-0.5 h-4 w-4 rounded-full bg-white transition-all',
          checked ? 'left-[14px]' : 'left-0',
        )}
      />
    </button>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.keys(variantsMap)),
};

Switch.defaultProps = {
  disabled: false,
  variant: 'blue',
};

export default Switch;

import cn from 'classnames';
import PropTypes from 'prop-types';

const TabAssignment = (props) => {
  const { currentTabId, onTabClick, tabs } = props;
  return (
    <div className="flex h-12 rounded-md bg-grey-100">
      {tabs.map((tab) => (
        <button
          className={cn(
            currentTabId === tab.id &&
              'rounded-md bg-white font-medium shadow-elevation-100',
            'm-1 flex-1 text-sm text-primary-dark',
          )}
          key={tab.id}
          type="button"
          onClick={() => {
            onTabClick(tab.id);
          }}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

TabAssignment.propTypes = {
  currentTabId: PropTypes.string,
  onTabClick: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, label: PropTypes.string }),
  ),
};

TabAssignment.defaultProps = {
  currentTabId: undefined,
  onTabClick: () => {},
  tabs: [],
};

export default TabAssignment;

import cn from 'classnames';
import PropTypes from 'prop-types';

const InputLayout = (props) => {
  const { children, error, id, inputCounter, label, labelClassName, required } =
    props;

  return (
    <div className="flex flex-col">
      {label && (
        <label
          className={cn(
            'mb-2 flex gap-1 text-xs font-medium text-primary-dark',
            labelClassName,
          )}
          htmlFor={id}
        >
          {label}
          {required && '*'}
        </label>
      )}
      {children}
      {(error || inputCounter) && (
        <div className="flex justify-between items-center gap-2 mt-2">
          <div className="text-sm text-ui-red" data-test="input-layout-error">
            {error}
          </div>
          <div className="text-xs relative text-right text-grey-500 self-start">
            {inputCounter}
          </div>
        </div>
      )}
    </div>
  );
};

InputLayout.propTypes = {
  children: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelClassName: PropTypes.string,
  required: PropTypes.bool,
  inputCounter: PropTypes.string,
};

InputLayout.defaultProps = {
  children: null,
  error: '',
  id: '',
  label: '',
  labelClassName: '',
  inputCounter: undefined,
  required: false,
};

export default InputLayout;

import cn from 'classnames';
import PropTypes from 'prop-types';

const CardItem = (props) => {
  const { children, className, title } = props;

  return (
    <div className={cn(className, 'w-full')}>
      <h3 className="m-0 mb-2 text-sm font-normal text-grey-700">{title}</h3>
      {children}
    </div>
  );
};

CardItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};

CardItem.defaultProps = {
  children: undefined,
  className: '',
  title: undefined,
};

export default CardItem;

/* eslint-disable react/no-unstable-nested-components */
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import IconButton from '../../../components/IconButton';
import NewTable from '../../../components/NewTable';
import Tooltip from '../../../components/Tooltip';
import CheckoutType from '../../../enums/CheckoutType';
import UserRole from '../../../enums/UserRole';
import { useUser } from '../../../providers/UserProvider';
import ShiftLabel from '../../shared/ShiftLabel';
import TourAssignment from '../../shared/TourAssignment';
import TourDetails from '../../shared/TourDetails';
import TourStatusInfo from '../../tours/TourStatusInfo';

const FixedToursTable = (props) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const userRole = user?.role;
  const isAssignmentCellVisible =
    userRole === UserRole.Admin ||
    userRole === UserRole.OperationsManager ||
    userRole === UserRole.HubManager;
  const location = useLocation();

  const columns = useMemo(() => {
    const allColumns = [
      {
        accessorKey: 'vehicle',
        header: t('Tour Code'),
        size: 150,
        cell: (info) => info.getValue(),
      },
      {
        size: 200,
        header: t('Assigned to'),
        key: 'assignment',
        cell: (info) => <TourAssignment tour={info.row.original} />,
      },
      {
        size: 200,
        header: t('Status'),
        accessorKey: 'status',
        cell: (info) => {
          const tour = info.row.original;
          return (
            <TourStatusInfo
              isManualCheckout={tour.checkOutType === CheckoutType.Manual}
              isDeleyed={tour.delayCount > 0}
              completedStops={tour.completedStops}
              totalStops={tour.totalStops}
              tourStatus={tour.status}
            />
          );
        },
      },
      {
        size: 300,
        header: t('Tour Details'),
        cell: (info) => <TourDetails tour={info.row.original} />,
      },
      {
        header: t('Date'),
        accessorKey: 'date',
        cell: (info) => moment(info.getValue()).format('DD.MM.YYYY'),
      },
      {
        header: t('Start'),
        cell: (info) => {
          const shiftNumber = info.row.original.shift;

          return (
            <span className="flex items-center gap-1">
              <div>
                <ShiftLabel shiftNumber={shiftNumber} />
              </div>
              <span className="lg:hidden xl:inline">
                {moment(info.row.original.shiftStart).format('HH:mm')}
              </span>
            </span>
          );
        },
      },
      {
        header: t('Actions'),
        cell: (info) => (
          <Tooltip placement="top" size="sm" text={t('Tour Details')}>
            <IconButton
              as={Link}
              icon="pageView"
              state={{
                backLink: location.pathname + location.search,
              }}
              to={{
                pathname: `/tours/${info.row.original.id}`,
              }}
              variant="ghostBlack"
            />
          </Tooltip>
        ),
        size: 80,
        enableResizing: false,
      },
    ];

    return allColumns.filter((column) => {
      if (!isAssignmentCellVisible) {
        return column.key !== 'assignment';
      }

      return column;
    });
  }, [isAssignmentCellVisible, location.pathname, location.search, t]);

  return <NewTable columns={columns} {...props} />;
};

FixedToursTable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

FixedToursTable.defaultProps = {
  children: undefined,
  className: '',
};

export default FixedToursTable;

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../../../components/Select';
import SelectVariant from '../../../components/Select/SelectVariant';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useUser } from '../../../providers/UserProvider';

const ClientSelect = (props) => {
  const {
    carrierName,
    className,
    error,
    name,
    onBlur,
    onChange,
    organisationId,
    placeholder,
    required,
    showAllOption,
    value,
    variant,
    ...rest
  } = props;

  const { user } = useUser();
  const { fetch } = useFetch();
  const { t } = useTranslation();
  const url = organisationId
    ? `/carriers/${organisationId}/clients`
    : '/clients';

  const { data: fetchedClients, isLoading } = useQuery([url], async () => {
    const response = await fetch(url);
    return response.json();
  });

  const clients = useMemo(
    () => (Array.isArray(fetchedClients?.data) ? fetchedClients?.data : []),
    [fetchedClients],
  );

  const options = useMemo(() => {
    const tempOptions = [];
    if (showAllOption) {
      tempOptions.push({
        dropdownLabel: `${t('All')} (${carrierName || user?.carrier?.name})`,
        label: t('All'),
        value: '',
      });
    }

    const clientOptions = clients.map((client) => ({
      label: client.name,
      dropdownLabel: `${client.publicName} (${client.name})`,
      value: client.id,
    }));

    return [...tempOptions, ...clientOptions];
  }, [carrierName, clients, showAllOption, t, user?.carrier?.name]);

  return (
    <Select
      variant={variant}
      className={className}
      isLoading={isLoading}
      options={options}
      placeholder={placeholder || t('Client')}
      value={value}
      onChange={onChange}
      error={error}
      required={required}
      onBlur={onBlur}
      name={name}
      {...rest}
    />
  );
};

ClientSelect.propTypes = {
  carrierName: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  organisationId: PropTypes.string,
  placeholder: PropTypes.string,
  showAllOption: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  required: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(SelectVariant)),
};

ClientSelect.defaultProps = {
  carrierName: undefined,
  className: undefined,
  onChange: undefined,
  onBlur: undefined,
  organisationId: undefined,
  placeholder: undefined,
  showAllOption: undefined,
  value: undefined,
  name: undefined,
  error: undefined,
  required: false,
  variant: undefined,
};

export default ClientSelect;

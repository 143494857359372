import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../Button';
import Page from '../Page';

const PageLayout = (props) => {
  const { children } = props;
  return (
    <Page>
      <Page.Content>
        <div className="mt-[170px] flex flex-col justify-center">
          {children}
        </div>
      </Page.Content>
    </Page>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

const ErrorPage = ({ error }) => {
  const { t } = useTranslation();
  const statusCode = error?.data?.statusCode;

  if (statusCode === 503) {
    return (
      <PageLayout>
        <div className="px-6 text-center text-grey-900" data-test="page-503">
          <h1 className="text-2xl">503</h1>
          <div className="font-medium">
            {t('Service currently unavailable.')}
          </div>
          <div>{t('Please try again later.')}</div>
          <div className="mt-6 flex justify-center">
            <Button
              data-test="page-refresh"
              icon="reload"
              text={t('Refresh page')}
              variant="outlineBlack"
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
        </div>
      </PageLayout>
    );
  }

  if (statusCode === 400) {
    return (
      <PageLayout>
        <div className="px-6 text-center text-grey-900" data-test="page-400">
          <div className="font-semibold">
            {t('This page isn’t working right now.')}
          </div>
          <div>
            {t('If the problem continues, please contact app administrator.')}
          </div>
        </div>
        <div className="mt-6 flex justify-center">
          <Button
            as={Link}
            data-test="go-to-main-page"
            text={t('Go to Main Page')}
            to="/"
            variant="outlineBlack"
          />
        </div>
      </PageLayout>
    );
  }

  if (statusCode === 403) {
    return (
      <PageLayout>
        <div className="px-6 text-center text-grey-900" data-test="page-403">
          <div>{t("You don't have authorisation to view this page.")}</div>
        </div>
        <div className="mt-6 flex justify-center">
          <Button
            as={Link}
            data-test="go-to-main-page"
            text={t('Go to Main Page')}
            to="/"
            variant="outlineBlack"
          />
        </div>
      </PageLayout>
    );
  }

  if (statusCode === 404) {
    return (
      <PageLayout>
        <div className="px-6 text-center text-grey-900" data-test="page-404">
          <div>{t('Page not found')}.</div>
        </div>
        <div className="mt-6 flex justify-center">
          <Button
            as={Link}
            data-test="go-to-main-page"
            text={t('Go to Main Page')}
            to="/"
            variant="outlineBlack"
          />
        </div>
      </PageLayout>
    );
  }

  if (statusCode === 500) {
    return (
      <PageLayout>
        <div className="px-6 text-center text-grey-900" data-test="page-500">
          <div className="font-semibold">
            {t('Internal server error occurred.')}
          </div>
          <div>{t('Please refresh this page to try again.')}</div>
        </div>
        <div className="mt-6 flex justify-center">
          <Button
            data-test="page-refresh"
            icon="reload"
            text={t('Refresh page')}
            variant="outlineBlack"
            onClick={() => {
              window.location.reload();
            }}
          />
        </div>
      </PageLayout>
    );
  }

  return (
    <Page>
      <Page.Content>
        <div
          className="mt-[170px] flex flex-col justify-center"
          data-test="error-page-default"
        >
          <span className="px-6 text-center text-grey-900">
            {t('Error fetching data. Please refresh this page to try again.')}
          </span>
          <div className="mt-6 flex justify-center">
            <Button
              data-test="page-refresh"
              icon="reload"
              text={t('Refresh page')}
              variant="outlineBlack"
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
        </div>
      </Page.Content>
    </Page>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.shape({
    data: PropTypes.shape({
      statusCode: PropTypes.number,
    }),
  }),
};

ErrorPage.defaultProps = {
  error: undefined,
};

export default ErrorPage;

import PropTypes from 'prop-types';

const CollapsedInfo = (props) => {
  const { instruction, note, text } = props;
  return (
    <div>
      <div
        className="flex justify-between text-sm"
        data-test="collapsed-info-text"
      >
        {text}
      </div>
      {instruction && (
        <div
          className="mt-2 text-sm break-all text-grey-700"
          data-test="collapsed-info-instruction"
        >
          {instruction}
        </div>
      )}
      {note && (
        <div className="mt-2 text-sm break-all" data-test="collapsed-info-note">
          {note}
        </div>
      )}
    </div>
  );
};

CollapsedInfo.propTypes = {
  text: PropTypes.string,
  note: PropTypes.string,
  instruction: PropTypes.string,
};

CollapsedInfo.defaultProps = {
  text: undefined,
  note: undefined,
  instruction: undefined,
};

export default CollapsedInfo;

import moment from 'moment';

import ScanLabelSize from '../enums/ScanLabelSize';
import getLargeLabelTemplate from '../zebra-templates/getLargeLabelTemplate';
import getSmallLabelTemplate from '../zebra-templates/getSmallLabelTemplate';

export default class LabelHelper {
  static transformParcelToLabelZpl(unitData, isCentral, labelSize) {
    const { data: unit } = unitData;

    const narrowBarWidth = this.calculateNarrowBarWidth(unit.code);
    const barcodeHorizontalPosition =
      this.getBarcodeHorizontalPositionFromExternalId(
        unit.code,
        narrowBarWidth,
      );

    const note = `Note: ${unit?.note || ''}`;

    if (unit.latestTour) {
      const templateData = {
        addressNote: unit.addressNote || '',
        attempt: unit.attempt,
        barcodeHorizontalPosition,
        city: unit.city,
        date: moment(unit.latestTour.date).format('DD.MM.YYYY.'),
        externalId: unit.code,
        logoZplCode: unit.client.logoZplCode,
        name: `${unit.firstName} ${unit.lastName}`,
        narrowBarWidth,
        note,
        orderId: unit.task.code,
        parcelNumber: unit.number,
        postcode: unit.postcode,
        shift: unit.shiftNumber,
        street: unit.street,
        totalParcelsInDelivery: unit.unitsInTask,
        tourIdentifier: unit.latestTour.vehicle.slice(-6),
        tourStop: unit.latestTour.tourStop.sequence,
        tourStopsCount: unit.latestTour.totalStops,
        hubShortCode: unit.hub.shortCode,
      };

      return labelSize === ScanLabelSize.Large
        ? getLargeLabelTemplate(templateData, isCentral)
        : getSmallLabelTemplate(templateData, isCentral);
    }

    const templateData = {
      addressNote: unit.addressNote || '',
      attempt: unit.attempt,
      barcodeHorizontalPosition,
      city: unit.city,
      date: moment(unit.date).format('DD.MM.YYYY.'),
      externalId: unit.code,
      logoZplCode: unit.client.logoZplCode,
      name: `${unit.firstName} ${unit.lastName}`,
      narrowBarWidth,
      note,
      orderId: unit.task.code,
      parcelNumber: unit.number,
      postcode: unit.postcode,
      street: unit.street,
      totalParcelsInDelivery: unit.unitsInTask,
      hubShortCode: unit.hub?.shortCode,
    };

    return labelSize === ScanLabelSize.Large
      ? getLargeLabelTemplate(templateData, true)
      : getSmallLabelTemplate(templateData, true);
  }

  /**
   * based on https://stackoverflow.com/a/30225854
   */
  static getBarcodeHorizontalPositionFromExternalId(
    externalId,
    narrowBarWidth,
  ) {
    const stopCharLength = 13;
    const charUnits = 11;
    // dots per mm
    const resolution = 8;
    const labelWidthInMm = 101.6;
    const labelWidthInDots = resolution * labelWidthInMm;

    // >:
    const barcodeStartLength = 2;
    const barcodeWidth =
      ((barcodeStartLength + externalId.toString().length) * charUnits +
        stopCharLength) *
      narrowBarWidth;

    return Math.round((labelWidthInDots - barcodeWidth) / 2);
  }

  static calculateNarrowBarWidth(externalId) {
    return externalId.toString().length > 15 ? 2 : 3;
  }
}

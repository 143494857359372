import PropTypes from 'prop-types';

import UserRole from '../../enums/UserRole';
import { useUser } from '../../providers/UserProvider';

const Allow = ({ children, roles }) => {
  const { user } = useUser();
  const isAllowed = roles.some((role) => user?.role === role);

  if (!isAllowed) {
    return null;
  }

  return children;
};

Allow.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(
    PropTypes.oneOf([
      UserRole.Superadmin,
      UserRole.Admin,
      UserRole.OperationsManager,
      UserRole.Driver,
      UserRole.Customer,
      UserRole.HubManager,
      UserRole.Crew,
    ]),
  ),
};

Allow.defaultProps = {
  children: undefined,
  roles: [],
};

export default Allow;

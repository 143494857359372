import ErrorPage from '../ErrorPage';

const MaintenancePage = () => (
  <ErrorPage
    error={{
      data: {
        statusCode: 503,
      },
    }}
  />
);

export default MaintenancePage;

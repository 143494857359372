/* eslint-disable jsx-a11y/no-autofocus */
import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Icon from '../../../components/Icon';
import LoadingSpinner from '../../../components/LoadingSpinner';
import BarcodeInputStatus from './BarcodeInputStatus';

const BarcodeInput = forwardRef((props, ref) => {
  const { autoFocus, disabled, onChange, onKeyDown, status, value } = props;

  return (
    <span
      className={cn('relative flex items-center', disabled && 'opacity-40')}
    >
      <input
        autoComplete="off"
        autoFocus={autoFocus}
        className={cn(
          'peer h-10 w-full rounded-md border px-3 indent-6 text-sm text-primary-dark outline-none placeholder:text-grey-700 focus:border-primary-yellow disabled:bg-grey-200',
          status === BarcodeInputStatus.Pending && 'border-grey-500',
          status === BarcodeInputStatus.Loading &&
            'border-transparent bg-primary-yellow-light',
          status === BarcodeInputStatus.Success &&
            'border-transparent bg-ui-green-dark-light',
          status === BarcodeInputStatus.AlreadyScanned &&
            'border-transparent bg-ui-info-blue-light',
          status === BarcodeInputStatus.Error &&
            'border-transparent bg-ui-red-light',
          status === BarcodeInputStatus.ScanError && 'border-ui-red',
        )}
        data-test="barcode-input"
        data-test-status={status}
        disabled={disabled}
        ref={ref}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />

      <div className="absolute left-3 peer-focus:text-primary-yellow">
        {status !== BarcodeInputStatus.Pending &&
        status !== BarcodeInputStatus.ScanError ? (
          <>
            {status === BarcodeInputStatus.Loading && (
              <span className="block h-4 w-4">
                <LoadingSpinner className="fill-primary-dark" />
              </span>
            )}
            {status === BarcodeInputStatus.Success && (
              <Icon className="h-4 w-4 text-ui-green" icon="checkmark" />
            )}
            {status === BarcodeInputStatus.Error && (
              <Icon className="h-4 w-4 text-ui-red" icon="xCircleOutlined" />
            )}
            {status === BarcodeInputStatus.AlreadyScanned && (
              <Icon className="h-4 w-4 text-ui-info-blue" icon="checkmark" />
            )}
          </>
        ) : (
          <Icon className="h-4 w-4 text-grey-900" icon="startTime" />
        )}
      </div>
    </span>
  );
});

BarcodeInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  status: PropTypes.string,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
};

BarcodeInput.defaultProps = {
  disabled: false,
  onChange: () => {},
  onKeyDown: () => {},
  status: undefined,
  value: '',
  autoFocus: true,
};

export default BarcodeInput;

import cn from 'classnames';
import PropTypes from 'prop-types';

const ProgressRing = ({
  backgroundStroke,
  backgroundStrokeWidth,
  progress,
  radius,
  stroke,
  strokeWidth,
}) => {
  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  const strokeDashoffset = circumference - (progress / 100) * circumference;
  const diameter = radius * 2;
  const derivedBackgroundStrokeWidth =
    backgroundStrokeWidth !== undefined
      ? backgroundStrokeWidth
      : strokeWidth / 2;

  return (
    <div
      className="relative"
      style={{ height: `${diameter}px`, width: `${diameter}px` }}
    >
      <svg height={diameter} width={diameter}>
        <circle
          className={cn('origin-center rotate-[270deg]', backgroundStroke)}
          cx={radius}
          cy={radius}
          fill="transparent"
          r={normalizedRadius}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeWidth={derivedBackgroundStrokeWidth}
          style={{ strokeDashoffset: 0 }}
        />
        <circle
          className={cn('origin-center rotate-[270deg]', stroke)}
          cx={radius}
          cy={radius}
          fill="transparent"
          r={normalizedRadius}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeWidth={strokeWidth}
          style={{ strokeDashoffset }}
        />
      </svg>
      <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-base font-medium text-primary-dark">
        {progress}
        <span className="text-xs">%</span>
      </span>
    </div>
  );
};

ProgressRing.propTypes = {
  backgroundStroke: PropTypes.string,
  backgroundStrokeWidth: PropTypes.number,
  progress: PropTypes.number,
  radius: PropTypes.number.isRequired,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number.isRequired,
};

ProgressRing.defaultProps = {
  backgroundStroke: 'stroke-grey-300',
  backgroundStrokeWidth: undefined,
  progress: 0,
  stroke: 'stroke-grey-900',
};

export default ProgressRing;

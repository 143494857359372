import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/Button';
import IconButton from '../../../components/IconButton';
import ScannerType from '../../../enums/ScannerType';
import PrinterSetupButton from '../PrinterSetupButton';
import ScannerSetupModal from '../ScannerSetupModal';
import ScanningHubTypeSelect from '../ScanningHubTypeSelect';

const ScanTitlebar = () => {
  const { i18n, t } = useTranslation();
  const [isScannerSetupModalOpen, setIsScannerSetupModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <ScannerSetupModal
        onClose={() => {
          setIsScannerSetupModalOpen(false);
        }}
        isOpen={isScannerSetupModalOpen}
        onSubmit={(newScannerType) => {
          if (newScannerType === ScannerType.SymbolLS2208) {
            navigate('/package-scanning/scanner-configuration/symbol');
          } else {
            navigate('/package-scanning/scanner-configuration/inateck');
          }
        }}
      />

      <div className="flex flex-col justify-between gap-4 border-b border-t border-grey-300 bg-white p-4 lg:flex-row lg:items-center">
        <span className="text-base font-medium uppercase tracking-wide text-primary-dark">
          {t('Live Scan Feed')}
        </span>
        <div className="flex flex-wrap gap-4 lg:flex-row lg:items-center">
          <ScanningHubTypeSelect />
          <div className="h-10 w-[1px] bg-grey-300" />
          <PrinterSetupButton />
          <div className="h-10 w-[1px] bg-grey-300" />
          <Button
            icon="scanning"
            text={t('Scanning Configuration')}
            variant="outlineBlack"
            onClick={() => {
              setIsScannerSetupModalOpen(true);
            }}
          />
          <IconButton
            icon="support"
            variant="outlineBlack"
            onClick={() => {
              if (i18n.language === 'en') {
                window.open(
                  'https://urbify.notion.site/Printer-scanner-setup-EN-5ac6e70c6ec843428c0f3f937eed72db',
                  '_blank',
                );
              } else {
                window.open(
                  'https://urbify.notion.site/Drucker-DE-d8e7206ee0634ea7a27bf1d12ff2f01d',
                  '_blank',
                );
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ScanTitlebar;

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useLogout from '../hooks/useLogout';
import { useUser } from '../providers/UserProvider';

const ProtectedApp = () => {
  const location = useLocation();
  const { isUserValid, user } = useUser();
  const logout = useLogout();
  const { t } = useTranslation();

  useEffect(() => {
    if (user && !isUserValid) {
      logout(t('User logged out. Please log in again.'));
    }
  }, [isUserValid, logout, t, user]);

  if (!user) {
    return <Navigate replace state={{ from: location }} to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedApp;

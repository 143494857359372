import PropTypes from 'prop-types';

const clientPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shortCode: PropTypes.string.isRequired,
  publicName: PropTypes.string.isRequired,
  bundling: PropTypes.bool.isRequired,
});

export default clientPropType;

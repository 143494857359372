import cn from 'classnames';
import PropTypes from 'prop-types';

const Shadow = ({
  className,
  hideRightShadow,
  inlineScroll,
  isTableStickedLeft,
  isTableStickedRight,
  style,
  totalLeftStickyWidth,
  totalRightStickyWidth,
}) => (
  <div
    className={cn(
      'overflow-hidden top-0 left-0 right-0 bottom-0 absolute w-full pointer-events-none',
      className,
    )}
    style={style}
  >
    {/* left shadow */}
    <div
      className={cn(
        'left-0 absolute bg-transparent pointer-events-none transition duration-200 h-full',
        isTableStickedLeft ? ' opacity-0' : ' opacity-100',
      )}
      style={{
        boxShadow: '4px 0px 7px 0px rgba(0, 0, 0, 0.05)',
        width: totalLeftStickyWidth,
      }}
    />
    {/* right shadow */}
    {!hideRightShadow && (
      <div
        className={cn(
          'absolute bg-transparent pointer-events-none transition duration-200 h-full',
          isTableStickedRight ? ' opacity-0' : ' opacity-100',
          inlineScroll ? 'right-[var(--scrollbar-width)]' : 'right-0',
        )}
        style={{
          boxShadow: '-4px 0px 7px 0px rgba(0, 0, 0, 0.05)',
          width: totalRightStickyWidth,
        }}
      />
    )}
  </div>
);

Shadow.propTypes = {
  style: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  isTableStickedLeft: PropTypes.bool.isRequired,
  isTableStickedRight: PropTypes.bool.isRequired,
  totalLeftStickyWidth: PropTypes.number.isRequired,
  totalRightStickyWidth: PropTypes.number.isRequired,
  className: PropTypes.string,
  inlineScroll: PropTypes.bool,
  hideRightShadow: PropTypes.bool,
};

Shadow.defaultProps = {
  className: undefined,
  style: {},
  inlineScroll: false,
  hideRightShadow: false,
};
export default Shadow;

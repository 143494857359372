import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

const Collapse = (props) => {
  const { children, isOpen } = props;

  return (
    <AnimateHeight duration={300} height={isOpen ? 'auto' : 0}>
      {children}
    </AnimateHeight>
  );
};

Collapse.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

Collapse.defaultProps = {
  children: undefined,
  isOpen: false,
};

export default Collapse;

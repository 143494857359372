const BarcodeInputStatus = {
  AlreadyScanned: 'alreadyScanned',
  Error: 'error',
  ScanError: 'scanError',
  Loading: 'loading',
  Pending: 'pending',
  Success: 'success',
};

export default BarcodeInputStatus;

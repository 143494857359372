import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DatePicker from '../DatePicker';
import InputLayout from '../InputLayout';

const FormDatePicker = (props) => {
  const {
    disabled,
    id,
    label,
    name,
    onChange: onDateChange,
    required,
    ...rest
  } = props;

  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext();

  const { t } = useTranslation();

  const generateValidators = useCallback(() => {
    const validators = {};

    if (required) {
      validators.required = t('Please choose a value.');
    }

    return validators;
  }, [required, t]);

  const error = errors[name] && errors[name].message;

  return (
    <InputLayout error={error} id={id} label={label} required={required}>
      <Controller
        contorl={control}
        name={name}
        disabled={disabled}
        render={({ field: { onChange, ...controllerProps } }) => (
          <DatePicker
            {...rest}
            error={!!error}
            onCalendarClose={() => {
              trigger(name);
            }}
            onChange={(e) => {
              onChange(e);
              if (onDateChange) {
                onDateChange(e);
              }
              // this triggers validation twice on date change, but it's needed when clear button is pressed
              trigger(name);
            }}
            {...controllerProps}
          />
        )}
        rules={generateValidators()}
      />
    </InputLayout>
  );
};

FormDatePicker.propTypes = {
  dateFormat: PropTypes.string,
  id: PropTypes.string,
  isClearable: PropTypes.bool,
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholderText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
};

FormDatePicker.defaultProps = {
  dateFormat: 'dd.MM.yyyy',
  id: undefined,
  isClearable: false,
  label: undefined,
  minDate: new Date(),
  name: '',
  onChange: undefined,
  placeholderText: '',
  required: false,
  disabled: false,
  value: undefined,
};

export default FormDatePicker;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '../../../components/Button';
import FormInput from '../../../components/FormInput';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import PlatformFeature from '../../../enums/PlatformFeature';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import OrganisationDetailsSettingsCard from '../OrganisationDetailsSettingsCard';

const OrganisationEmailCustomisation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogoImgError, setIsLogoImgError] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const { toastSuccess } = useCustomToast();
  const queryClient = useQueryClient();

  const { data: companyFeaturesData, isLoading: isCompanyFeaturesLoading } =
    useQuery([`/clients/${id}/features`], async () => {
      const response = await fetch(`/clients/${id}/features`, {
        method: 'GET',
      });
      return response.json();
    });

  const companyFeatures = useMemo(
    () => companyFeaturesData?.data?.features || [],
    [companyFeaturesData?.data?.features],
  );

  const isEmailCustomisationEnabled = useMemo(
    () =>
      !!companyFeatures.find(
        (feature) => feature.code === PlatformFeature.CustomEmailFields,
      ),
    [companyFeatures],
  );

  const { data: companyEmailSettingsResponse } = useQuery(
    [`/clients/${id}/email-settings`],
    async () => {
      const response = await fetch(`/clients/${id}/email-settings`, {
        method: 'GET',
      });
      return response.json();
    },
    { enabled: isEmailCustomisationEnabled },
  );

  const companyEmailSettings = companyEmailSettingsResponse?.data;

  const methods = useForm({
    defaultValues: {
      logoUrl: '',
      headerColor: '',
      footerCompanyName: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    formState: { isValid },
    handleSubmit,
    reset,
    watch,
  } = methods;
  const logoUrl = watch('logoUrl');
  const headerColor = watch('headerColor');

  useEffect(() => {
    if (isModalOpen) {
      reset({
        logoUrl: companyEmailSettings?.logoUrl || '',
        headerColor: companyEmailSettings?.headerColor || '',
        footerCompanyName: companyEmailSettings?.footerCompanyName || '',
      });
    }
  }, [
    companyEmailSettings?.footerCompanyName,
    companyEmailSettings?.headerColor,
    companyEmailSettings?.logoUrl,
    isModalOpen,
    reset,
  ]);

  const isValidHex = useMemo(
    () => /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(headerColor),
    [headerColor],
  );

  const mutation = useMutation(
    (body) =>
      fetch(`/clients/${id}/email-settings`, {
        body: {
          ...body,
          headerColor:
            !isValidHex || body.headerColor === ''
              ? '#FFFFFF'
              : body.headerColor,
        },
        method: companyEmailSettings ? 'PATCH' : 'POST',
      }),
    {
      onError: (error) => {
        toastFetchError(error);
        setIsModalOpen(false);
      },
      onSuccess: () => {
        toastSuccess(t('Customer e-mail customisation updated.'));
        queryClient.invalidateQueries({
          queryKey: [`/clients/${id}/features`],
        });
        queryClient.invalidateQueries({
          queryKey: [`/clients/${id}/email-settings`],
        });
        setIsModalOpen(false);
      },
    },
  );

  return (
    <FormProvider {...methods}>
      <Modal
        body={
          <div className="flex flex-col gap-5 divide-y divide-grey-200">
            <div className="flex flex-col gap-3">
              <FormInput
                label="Logo"
                name="logoUrl"
                id="logoUrl"
                required
                placeholder={t('Paste image URL here')}
                validate={() => {
                  if (isLogoImgError) {
                    return t('Please enter valid image url.');
                  }
                  return true;
                }}
              />
              <div className="flex items-center px-4 py-5 bg-grey-100 h-[80px] justify-center">
                {logoUrl ? (
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img
                      className="h-full w-full max-w-[384px] max-h-[48px]"
                      src={logoUrl}
                      onError={() => {
                        setIsLogoImgError(true);
                      }}
                      onLoad={() => {
                        setIsLogoImgError(false);
                      }}
                    />
                  </div>
                ) : (
                  <div className="text-sm text-grey-700">{t('Preview')}</div>
                )}
              </div>
            </div>
            <div className="pt-5 flex flex-col gap-3">
              <div className="flex gap-3 items-end">
                <div className="flex-1">
                  <FormInput
                    label={t('Header Background Color')}
                    name="headerColor"
                    id="headerColor"
                    placeholder={t('Enter color HEX here')}
                  />
                </div>
                <div
                  className="h-[40px] w-[40px] flex items-center justify-center flex-0"
                  style={{
                    backgroundColor: isValidHex ? `${headerColor}` : '#F6F6F6',
                  }}
                >
                  {isValidHex ? '' : '?'}
                </div>
              </div>
              <div className="flex items-center gap-2 text-sm text-grey-700">
                <Icon className="h-4 w-4 text-ui-info-blue" icon="infoFilled" />
                <span>
                  {t('Background color by default is white (#FFFFFF)')}
                </span>
              </div>
            </div>
            <div className="pt-5 flex flex-col gap-3">
              <FormInput
                label={t('Signature')}
                name="footerCompanyName"
                id="footerCompanyName"
                required
                placeholder={t('Enter signature')}
              />
              <div className="flex items-center gap-2 text-sm text-grey-700">
                <Icon className="h-4 w-4 text-ui-info-blue" icon="infoFilled" />
                <span>
                  {t(
                    'We recommend using the public name or a commonly known abbreviation',
                  )}
                </span>
              </div>
            </div>
          </div>
        }
        footer={
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Button
              className="order-last sm:order-none"
              data-test="modal-button-cancel"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => setIsModalOpen(false)}
            />
            <Button
              data-test="modal-button-action"
              disabled={!isValid}
              text={t('Save')}
              variant="solidBlue"
              onClick={handleSubmit(mutation.mutate)}
            />
          </div>
        }
        header={
          <div className="flex items-center gap-2">
            <Icon className="h-6 w-6 text-grey-700" icon="brush" />
            <span>{t('Customer e-mail Customisation')}</span>
          </div>
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <OrganisationDetailsSettingsCard
        text={
          <div className="flex items-center gap-2">
            <Icon className="h-4 w-4 text-grey-500" icon="infoFilled" />
            <span>
              {t(
                'Set up customization to start sending customer-branded e-mails, in the meantime, Urbify-branded e-mails are being sent',
              )}
            </span>
          </div>
        }
        title={t('Customer e-mail Customisation')}
      >
        {!isCompanyFeaturesLoading && (
          <Button
            disabled={!isEmailCustomisationEnabled}
            className="w-full"
            text={companyEmailSettings ? t('Update') : t('Set up')}
            variant="outlineBlack"
            onClick={() => setIsModalOpen(true)}
          />
        )}
      </OrganisationDetailsSettingsCard>
    </FormProvider>
  );
};

export default OrganisationEmailCustomisation;

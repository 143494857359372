import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useCustomToast from '../../hooks/useCustomToast';
import { useListSelection } from '../../providers/ListSelectionProvider';
import Checkbox from '../Checkbox';
import Tooltip from '../Tooltip';

const SelectAllRowsCheckbox = (props) => {
  const { data } = props;
  const {
    disabledCheckboxTooltipMessage,
    getCheckboxItemData,
    isSelectionDisabled,
    isSelectionVisible,
    maxSelectedItems,
    selectItem,
    selectedItems,
    unselectItem,
  } = useListSelection();
  const { toastError } = useCustomToast();
  const { t } = useTranslation();

  const isDisabled = data.length < 1 || isSelectionDisabled;

  const filteredData = useMemo(() => {
    if (isDisabled) {
      return [];
    }

    if (getCheckboxItemData) {
      return data.filter((record) => !getCheckboxItemData(record).disabled);
    }

    return data;
  }, [data, getCheckboxItemData, isDisabled]);

  if (!isSelectionVisible) {
    return null;
  }

  const unselectedPageItems = filteredData.filter(
    (record) => !selectedItems.find((item) => item.id === record.id),
  );

  const isSelectAllValid =
    unselectedPageItems.length + selectedItems.length <= maxSelectedItems;

  const isChecked =
    filteredData.length > 0 &&
    filteredData.every((record) =>
      selectedItems.some((item) => item.id === record.id),
    );

  const selectAll = () => {
    filteredData.forEach((record) => selectItem(record));
  };

  const unselectAll = () => {
    filteredData.forEach((record) => unselectItem(record.id));
  };

  return (
    <Tooltip
      hidden={
        !isDisabled || !disabledCheckboxTooltipMessage || data.length === 0
      }
      text={disabledCheckboxTooltipMessage}
    >
      <Checkbox
        checked={!isDisabled && isChecked}
        data-test="select-all-rows-checkbox"
        disabled={isDisabled}
        onChange={(e) => {
          if (e.target.checked) {
            if (!isSelectAllValid) {
              toastError(
                t(
                  'The maximum number of selected items is {{maxSelectedItems}}.',
                  {
                    maxSelectedItems,
                  },
                ),
              );
              return;
            }
            selectAll();
            return;
          }

          unselectAll();
        }}
      />
    </Tooltip>
  );
};

SelectAllRowsCheckbox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
};

SelectAllRowsCheckbox.defaultProps = {
  data: [],
};

export default SelectAllRowsCheckbox;

import { Loader, LoaderStatus } from '@googlemaps/js-api-loader';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete from 'react-places-autocomplete';

import useCustomToast from '../../hooks/useCustomToast';
import Input from '../Input';
import InputLayout from '../InputLayout';

const loader = new Loader({
  apiKey: import.meta.env.VITE_GMAPS_KEY,
  version: 'weekly',
});

const InputBlock = (props) => {
  const { children, errorInput, icon, id, inputProps, label, required } = props;

  return (
    <InputLayout error={errorInput} id={id} label={label} required={required}>
      <div className="relative">
        <Input
          hasError={Boolean(errorInput)}
          icon={icon}
          iconFocusColor="ui-info-blue"
          {...inputProps}
        />
        {children}
      </div>
    </InputLayout>
  );
};

InputBlock.propTypes = {
  errorInput: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.any,
  children: PropTypes.node,
};

InputBlock.defaultProps = {
  errorInput: undefined,
  icon: undefined,
  id: undefined,
  label: undefined,
  required: undefined,
  inputProps: undefined,
  children: undefined,
};

const AddressInput = forwardRef((props, ref) => {
  const {
    disabled,
    errorInput,
    icon,
    id,
    label,
    onBlur,
    onChange,
    onFocus,
    onSelect,
    placeholder,
    required,
    value,
  } = props;

  const [googleMapsStatus, setGoogleMapsStatus] = useState(loader.status);
  const { toastError } = useCustomToast();

  const { t } = useTranslation();

  useEffect(() => {
    if (googleMapsStatus !== LoaderStatus.INITIALIZED) {
      return;
    }

    setGoogleMapsStatus(LoaderStatus.LOADING);
    loader
      .importLibrary('places')
      .then(() => {
        setGoogleMapsStatus(LoaderStatus.SUCCESS);
        // eslint-disable-next-line no-console
        console.log('[AddressInput] - Google maps loaded');
      })
      .catch(() => {
        setGoogleMapsStatus(LoaderStatus.FAILURE);
        // eslint-disable-next-line no-console
        console.log('[AddressInput] - Google maps loading failed');
      });
  }, [googleMapsStatus, toastError]);

  if (googleMapsStatus !== LoaderStatus.SUCCESS) {
    const inputProps = {
      disabled: true,
      id,
      placeholder: t('Loading...'),
      value: '',
    };

    return (
      <InputBlock
        inputProps={inputProps}
        errorInput={errorInput}
        icon={icon}
        label={label}
        required={required}
      />
    );
  }

  return (
    <PlacesAutocomplete
      highlightFirstSuggestion
      ref={ref}
      value={value}
      onChange={onChange}
      onSelect={onSelect}
    >
      {({ getInputProps, getSuggestionItemProps, loading, suggestions }) => {
        const inputProps = getInputProps({
          autoComplete: 'off',
          disabled,
          id,
          onBlur,
          onFocus,
          placeholder,
        });

        return (
          <InputBlock
            inputProps={inputProps}
            errorInput={errorInput}
            icon={icon}
            label={label}
            required={required}
          >
            <div className="absolute left-0 top-full z-[100] overflow-hidden rounded-md bg-white shadow-[0_2px_14px_0_rgba(0,0,0,0.1)]">
              {loading && <div className="p-2.5">{t('Loading...')}</div>}
              {suggestions.map((suggestion) => (
                <div
                  data-test={`suggestion-${suggestion.placeId}`}
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion, {
                    className: cn(
                      'p-2.5',
                      suggestion.active &&
                        'bg-ui-blue text-white cursor-pointer',
                    ),
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              ))}
            </div>
          </InputBlock>
        );
      }}
    </PlacesAutocomplete>
  );
});

AddressInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorInput: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
};

AddressInput.defaultProps = {
  className: undefined,
  disabled: undefined,
  errorInput: undefined,
  icon: undefined,
  id: undefined,
  label: undefined,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  onSelect: undefined,
  placeholder: undefined,
  required: undefined,
  value: '',
};

export default AddressInput;

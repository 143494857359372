import { useCallback, useState } from 'react';

import useEvent from './useEvent';

const useWindowSize = () => {
  const [state, setState] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const handleResize = useCallback(
    () =>
      setState({
        height: window.innerHeight,
        width: window.innerWidth,
      }),
    [],
  );

  useEvent('resize', handleResize);

  return state;
};

export default useWindowSize;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ProgressRing from '../../../components/ProgressRing';
import RatingImage from '../../../components/RatingImage';
import useRatingTranslations from '../../../hooks/useRatingTranslations';

const Section = ({ children }) => (
  <div className="flex h-full flex-row items-center justify-between rounded-md bg-grey-100 p-4 sm:p-6">
    {children}
  </div>
);

Section.propTypes = {
  children: PropTypes.node,
};

Section.defaultProps = {
  children: undefined,
};

const TourPerformance = (props) => {
  const { rating, score } = props;
  const { t } = useTranslation();
  const getRatingTranslation = useRatingTranslations();

  if (rating === null || rating === undefined) {
    return null;
  }

  if (score === null || score === undefined) {
    return null;
  }

  return (
    <div
      className="flex flex-col gap-4 rounded-md bg-white p-4 shadow-elevation-100"
      data-test="tour-performance"
    >
      <div className="text-sm uppercase text-grey-900">
        {t('Tour Performance')}
      </div>
      <div className="grid grid-rows-2 gap-4 sm:grid-cols-2 sm:grid-rows-1">
        <Section>
          <div className="text-xs leading-none text-grey-900">{t('Score')}</div>
          <ProgressRing progress={score} radius={28} strokeWidth={4} />
        </Section>
        <Section>
          <div data-test="rating">
            <div className="text-xs font-medium text-grey-700">
              {t('Rating')}
            </div>
            <div className="text-sm text-grey-900">
              {getRatingTranslation(rating)}
            </div>
          </div>
          <RatingImage rating={rating} />
        </Section>
      </div>
    </div>
  );
};

TourPerformance.propTypes = {
  rating: PropTypes.string,
  score: PropTypes.number,
};

TourPerformance.defaultProps = {
  rating: undefined,
  score: undefined,
};

export default TourPerformance;

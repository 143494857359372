import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Alert from '../../components/Alert';
import Button from '../../components/Button';
import CheckoutType from '../../enums/CheckoutType';
import GroceriesTourHelper from '../../helpers/GroceriesTourHelper';
import useGroceriesTourStatusTranslations from '../../hooks/useGroceriesTourStatusTranslations';
import GroceriesTourStatusIcon from './GroceriesTourStatusIcon';

const GroceriesTourStatusCard = ({ isAdmin, onResolveTourStatus, tour }) => {
  const { t } = useTranslation();
  const tourStatusTranslations = useGroceriesTourStatusTranslations();
  const tourStatusName = tourStatusTranslations[tour.status];

  const isUnresolved = GroceriesTourHelper.isUnresolved(tour);

  return (
    <div className="flex flex-col gap-4 rounded-md bg-white p-4 shadow-elevation-100">
      <div className="text-sm uppercase text-grey-900">{t('Status')}</div>
      <div className="flex items-center gap-2">
        {!isUnresolved && (
          <>
            <GroceriesTourStatusIcon status={tour.status} />
            <span
              className="text-sm text-grey-900"
              data-test="tour-status-name"
            >
              {tourStatusName}
            </span>
          </>
        )}

        {isUnresolved && isAdmin && (
          <Button
            data-test="resolve-tour-status"
            text={t('Resolve Status')}
            variant="solidRed"
            onClick={onResolveTourStatus}
          />
        )}

        {isUnresolved && !isAdmin && (
          <div className="w-full rounded-md bg-grey-100 p-4 font-normal text-primary-dark">
            {t('To resolve status contact your Admin.')}
          </div>
        )}
      </div>
      {tour.checkoutType === CheckoutType.Auto && (
        <Alert
          data-test="tour-checkout-auto"
          message={t('Tour checked out automatically.')}
          variant="info"
        />
      )}
      {tour.checkoutType === CheckoutType.Manual && (
        <Alert
          data-test="tour-checkout-manual"
          message={t('Tour checked out by Manager.')}
          variant="info"
        />
      )}
    </div>
  );
};

GroceriesTourStatusCard.propTypes = {
  isAdmin: PropTypes.bool,
  onResolveTourStatus: PropTypes.func,
  tour: PropTypes.shape({
    checkoutType: PropTypes.oneOf(Object.values(CheckoutType)),
    date: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

GroceriesTourStatusCard.defaultProps = {
  isAdmin: false,
  onResolveTourStatus: () => {},
};

export default GroceriesTourStatusCard;

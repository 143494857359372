import useScript from 'react-script-hook';

const browserPrintFilePath = `/vendor/BrowserPrint-3.1.250.min.js`;
const browserPrintZebraFilePath = `/vendor/BrowserPrint-Zebra-1.1.250.min.js`;

const useBrowserPrintScripts = () => {
  const [browserPrintLoading] = useScript({
    checkForExisting: true,
    src: browserPrintFilePath,
  });

  const [browserPrintZebraLoading] = useScript({
    checkForExisting: true,
    src: browserPrintZebraFilePath,
  });

  return browserPrintLoading || browserPrintZebraLoading;
};

export default useBrowserPrintScripts;

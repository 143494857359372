import cn from 'classnames';
import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';
import { useDebounce, useMeasure } from 'react-use';

const Variant = {
  White: 'white',
  Grey: 'grey',
  Wide: 'wide',
};

const variantMap = {
  [Variant.White]: 'lg:mx-8 lg:my-4',
  [Variant.Grey]: 'bg-grey-100 p-4 lg:rounded-md lg:mx-8 lg:my-4',
  [Variant.Wide]: 'lg:mx-6 lg:my-0 lg:mb-8',
};

const PageContent = (props) => {
  const { centerContent, children, variant } = props;

  const ref = useRef(null);
  const [measureRef, { width: measureWidth }] = useMeasure();

  useDebounce(
    () => {
      if (ref.current) {
        const width = ref.current.clientWidth || 0;
        document.documentElement.style.setProperty(
          // used for table calculation
          '--page-content-width',
          `${width}px`,
        );
      }
    },
    30,
    [measureWidth],
  );

  // layout effect intentionally, to be used immediately, before any scroll restoration
  useLayoutEffect(() => {
    if (ref.current) {
      const width = ref.current.clientWidth || 0;
      document.documentElement.style.setProperty(
        // used for table calculation
        '--page-content-width',
        `${width}px`,
      );
    }
  }, []);

  return (
    <div
      ref={(el) => {
        measureRef(el);
        ref.current = el;
      }}
      className={cn(
        'flex flex-1 flex-col overflow-hidden',
        centerContent && 'items-center sm:justify-center',
        variantMap[variant],
      )}
      id="page-content"
    >
      {children}
    </div>
  );
};

PageContent.propTypes = {
  centerContent: PropTypes.bool,
  children: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(Variant)),
};

PageContent.defaultProps = {
  centerContent: false,
  children: undefined,
  variant: Variant.White,
};

export default PageContent;

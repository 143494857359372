import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';

const SocketIdContext = React.createContext(undefined);

/**
 * Used for mocking in Cypress
 */
const mockIo = window.io;

const SocketIdProvider = (props) => {
  const { children } = props;
  const [socketId, setSocketId] = useState(mockIo ? 'mock' : undefined);

  useEffect(() => {
    // used for cypress testing
    window.socketId = socketId;
  }, [socketId]);

  const value = useMemo(
    () => ({ socketId, setSocketId }),
    [socketId, setSocketId],
  );

  return (
    <SocketIdContext.Provider value={value}>
      {children}
    </SocketIdContext.Provider>
  );
};

SocketIdProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SocketIdProvider;

export const useSocketId = () => {
  const context = useContext(SocketIdContext);
  if (context === undefined) {
    throw new Error('useSocketId must be used within a SocketIdProvider');
  }
  return context;
};

import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import Button from '../../components/Button';
import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import MembersFilters from '../../features/organisations/MembersFilters';
import MembersCardList from '../../features/shared/MembersCardList';
import MembersTable from '../../features/shared/MembersTable';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import Resource from '../../lib/api/Resource';
import { useUser } from '../../providers/UserProvider';

const MyOrganisationMembers = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;
  const { fetch } = useFetch();
  const [searchParams] = useSearchParams();

  const searchQuery = searchParams.get('search');
  const hubIdQuery = searchParams.get('hubId');
  const roleQuery = searchParams.get('role');
  const subcarrierIdQuery = searchParams.get('subcarrierId');

  const hasFiltersApplied = !!(
    searchQuery ||
    hubIdQuery ||
    roleQuery ||
    subcarrierIdQuery
  );

  const { user } = useUser();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isLoading,
  } = useQuery([`/carriers/${user.carrier.id}`], async () => {
    const response = await fetch(`/carriers/${user.carrier.id}`);
    return response.json();
  });

  const organisation = fetchedOrganisation?.data || null;
  const params = useMemo(
    () => ({
      name: searchQuery,
      role: roleQuery,
      subcarrierId: subcarrierIdQuery,
      hubId: hubIdQuery,
    }),
    [roleQuery, searchQuery, hubIdQuery, subcarrierIdQuery],
  );

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        backLink="/my-organisation"
        isActive={hasFiltersApplied}
        menu={<MembersFilters organisation={organisation} />}
        textPrimary={`${t('Members')} (${organisation?.userCount})`}
        textSecondary={organisation?.name}
        isLoading={isLoading}
        titleRightContent={
          <Button
            as={Link}
            className="w-full lg:w-fit"
            icon="userAdd"
            text={t('New Member')}
            to="new"
            variant="solidBlue"
          />
        }
      />
      <Page.Content variant={isDesktop ? 'white' : 'grey'}>
        <Resource params={params} resourceUrl="/users">
          {isDesktop ? (
            <MembersTable organisationType={organisation?.type} />
          ) : (
            <MembersCardList />
          )}
        </Resource>
      </Page.Content>
    </Page>
  );
};

export default MyOrganisationMembers;

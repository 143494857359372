import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import TaskType from '../enums/TaskType';

const useTaskTypeTranslations = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      [TaskType.Delivery]: t('Delivery'),
      [TaskType.Pickup]: t('Pickup'),
    }),
    [t],
  );
};

export default useTaskTypeTranslations;

import { detectOverflow } from '@popperjs/core';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  cloneElement,
  useCallback,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';

import { offsetModifier } from '../../helpers/popperModifiers';
import useClickOutside from '../../hooks/useClickOutside';
import { useFloatingContainer } from '../../providers/FloatingContainerProvider';
import Portal from '../Portal';
import PopoverInner from './PopoverInner';

const Popover = (props) => {
  const { children, className, content, placement, title } = props;

  const popperReferenceElementRef = useRef(null);
  const [popperReferenceElement, setPopperReferenceElement] = useState(null);
  const popperElementRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [isOverflowing, setIsOverflowing] = useState(false);
  const { floatingContainer } = useFloatingContainer();
  const floatingContainerOverflowModifier = useMemo(
    () => ({
      enabled: true,
      phase: 'main',
      requiresIfExists: ['offset'],
      fn({ state }) {
        const overflow = detectOverflow(state, {
          elementContext: 'reference',
          boundary: floatingContainer,
        });
        // if positive, its overflowing
        if (
          overflow.top > 0 ||
          overflow.bottom > 0 ||
          overflow.left > 0 ||
          overflow.right > 0
        ) {
          setIsOverflowing(true);
          return;
        }
        setIsOverflowing(false);
      },
    }),
    [floatingContainer],
  );

  const modifiers = useMemo(() => {
    const tempModifiers = [
      {
        name: 'preventOverflow',
        options: {
          boundary: 'clippingParents',
          padding: 16,
        },
      },
      offsetModifier,
    ];
    if (floatingContainer) {
      tempModifiers.push(floatingContainerOverflowModifier);
    }

    return tempModifiers;
  }, [floatingContainer, floatingContainerOverflowModifier]);

  const { attributes, styles } = usePopper(
    popperReferenceElement,
    popperElement,
    {
      modifiers,
      placement,
    },
  );

  const id = useId();
  const parentClassName = `popover-parent-${id}`;

  const onClickOutside = (e) => {
    const isClickOnPopper = e.target.closest(`.${CSS.escape(parentClassName)}`);

    if (isClickOnPopper) {
      return;
    }

    setIsOpen(false);
  };

  useClickOutside(popperElementRef, onClickOutside);

  const renderChild = useCallback(
    () =>
      cloneElement(children, {
        className: cn(parentClassName, children?.props?.className || ''),
        ref: (node) => {
          popperReferenceElementRef.current = node;
          setPopperReferenceElement(node);
        },
        onClick: () => {
          setIsOpen(!isOpen);
        },
      }),
    [children, isOpen, parentClassName],
  );

  const isReferenceHidden =
    attributes.popper?.['data-popper-reference-hidden'] === true;

  return (
    <>
      {renderChild()}
      {isOpen && (
        <Portal>
          <PopoverInner
            className={cn(
              className,
              (isReferenceHidden || isOverflowing) && 'hidden',
            )}
            style={styles.popper}
            ref={(el) => {
              setPopperElement(el);
              popperElementRef.current = el;
            }}
            onCloseClick={() => {
              setIsOpen(false);
            }}
            title={title}
            content={content}
            {...attributes.popper}
          />
        </Portal>
      )}
    </>
  );
};

Popover.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'bottom',
    'right',
    'left',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end',
  ]),
  title: PropTypes.string,
  isInsideTable: PropTypes.bool,
};

Popover.defaultProps = {
  children: undefined,
  className: undefined,
  content: undefined,
  placement: 'auto',
  title: undefined,
  isInsideTable: false,
};

export default Popover;

import cn from 'classnames';
import { useMemo } from 'react';

import Alert from '../../components/Alert';
import LoadingSpinner from '../../components/LoadingSpinner';
import PrinterStatus from '../../enums/PrinterStatus';
import PrinterSetupModal from '../../features/package-scanning/PrinterSetupModal';
import ScanFeed from '../../features/package-scanning/ScanFeed';
import ScanningPageLayout from '../../features/package-scanning/ScanningPageLayout';
import { AsyncStatus } from '../../hooks/useAsyncFn';
import useLabelary, { isLabelaryEnabled } from '../../hooks/useLabelary';

const LabelaryLogo = () => (
  <div className="flex flex-col items-center">
    <span className="flex items-center gap-2">
      <svg height="16" id="logo" viewBox="0 0 16 16" width="16">
        <path d="M 0 0 H 12 V 4 H 4 V 12 H 0 Z" fill="currentColor" />
        <path d="M 16 16 H 4 V 12 H 12 V 4 H 16 Z" fill="currentColor" />
        <path d="M 6 6 H 10 V 10 H 6 Z" fill="currentColor" />
      </svg>
      <span>LABELARY</span>
    </span>
    <span className="text-grey-700">Virtual Printer</span>
  </div>
);

const VirtualPrinterPackageScanning = () => {
  const { data, error, reset, runAsyncFn: printLabel, status } = useLabelary();

  const printerStatus = useMemo(() => {
    if (status === AsyncStatus.Loading) {
      return PrinterStatus.Printing;
    }
    if (status === AsyncStatus.Error) {
      return PrinterStatus.Error;
    }
    if (status === AsyncStatus.Success) {
      return PrinterStatus.Printed;
    }
    return PrinterStatus.Ready;
  }, [status]);

  const renderPrinter = () => {
    if (printerStatus === PrinterStatus.Printing) {
      return <LoadingSpinner />;
    }

    if (printerStatus === PrinterStatus.Error) {
      return <Alert message={error.status} variant="error" />;
    }

    if (printerStatus === PrinterStatus.Printed) {
      return (
        <img
          alt="printer"
          className="h-full w-auto max-w-full animate-[appearFromAbove_1s_forwards] object-contain"
          src={data}
        />
      );
    }

    return <LabelaryLogo />;
  };

  return (
    <>
      <PrinterSetupModal />
      <ScanningPageLayout>
        <div className="flex flex-1 gap-4 p-4">
          <div
            className={cn(
              isLabelaryEnabled ? 'w-1/2' : 'mx-auto w-full max-w-[696px] py-6',
            )}
          >
            <ScanFeed
              print={printLabel}
              printerStatus={printerStatus}
              resetPrinterState={reset}
            />
          </div>
          {isLabelaryEnabled && (
            <div className="flex h-[calc(100vh_-_200px)] w-1/2 items-center justify-center overflow-hidden rounded-md bg-[#ffe9b647] p-4 shadow-elevation-200">
              {renderPrinter()}
            </div>
          )}
        </div>
      </ScanningPageLayout>
    </>
  );
};

export default VirtualPrinterPackageScanning;

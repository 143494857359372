import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import useEvent from '../../../hooks/useEvent';

const MobileListVisibleContent = forwardRef((props, ref) => {
  const { stickyElementRef } = props;
  const [clientRect, setClientRect] = useState({
    top: 0,
    height: 0,
  });

  const onScroll = useCallback(() => {
    if (stickyElementRef.current) {
      const boundingClientRect =
        stickyElementRef.current.getBoundingClientRect();
      setClientRect(boundingClientRect);
    }
  }, [stickyElementRef]);

  useEffect(() => {
    onScroll();
  }, [onScroll]);

  const top = clientRect.top + clientRect.height;

  useEvent('scroll', onScroll);
  return (
    <div
      ref={ref}
      className="fixed w-full left-0 h-full pointer-events-none opacity-0"
      style={{
        top: `${top}px`,
        height: `calc(100vh - ${top}px)`,
      }}
    />
  );
});

MobileListVisibleContent.propTypes = {
  stickyElementRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

MobileListVisibleContent.defaultProps = {
  stickyElementRef: undefined,
};

export default MobileListVisibleContent;

import moment from 'moment';

import GroceriesTourStatus from '../enums/GroceriesTourStatus';

export default class GroceriesTourHelper {
  static isUnresolved(tour) {
    const hasPotentiallyUnresolvedStatus =
      tour.status === GroceriesTourStatus.Created ||
      tour.status === GroceriesTourStatus.Pending ||
      tour.status === GroceriesTourStatus.NotAssignedDriver;

    const today = moment();
    const hasPastDate = today.isAfter(tour.date, 'day');

    return hasPotentiallyUnresolvedStatus && hasPastDate;
  }

  static isAssignmentEnabled(tour) {
    const isStatusUnresolved = this.isUnresolved(tour);
    return (
      isStatusUnresolved ||
      (tour.status !== GroceriesTourStatus.CompletedAdmin &&
        tour.status !== GroceriesTourStatus.Completed &&
        tour.status !== GroceriesTourStatus.CanceledAdmin &&
        tour.status !== GroceriesTourStatus.Active)
    );
  }
}

import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ActivityType from '../../enums/ActivityType';
import Breakpoint from '../../enums/Breakpoint';
import DelayReason from '../../enums/DelayReason';
import DelayType from '../../enums/DelayType';
import UserRole from '../../enums/UserRole';
import useWindowSize from '../../hooks/useWindowSize';
import { useUser } from '../../providers/UserProvider';
import Button from '../Button';
import Icon from '../Icon';
import ActivityIcon from './ActivityIcon';

const ActivityItem = (props) => {
  const { activityItem, isRecent, markAsRead, tourLink } = props;

  const { isGroceries, user } = useUser();
  const userRole = user.role;

  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.SM;

  const labels = useMemo(
    () => ({
      [ActivityType.LateCheckIn]: t('Late Check in'),
      [ActivityType.CheckIn]: t('Tour Start'),
      [ActivityType.CheckOut]: t('Tour End'),
      [ActivityType.ReportDelay]: {
        [DelayType.Tour]: t('Tour Delay'),
        [DelayType.GroceryTour]: t('Tour Delay'),
        [DelayType.TourStop]: t('Tour Stop Delay'),
      },
      [ActivityType.TourReport]: t('Tour Report'),
      [ActivityType.TourCanceledAdmin]: t('Tour End'),
      [ActivityType.TourCompletedAdmin]: t('Tour End'),
      [ActivityType.ManualCheckOut]: t('Tour End'),
      [ActivityType.AutoCheckOut]: t('Tour End'),
      [ActivityType.TourRequestManualScanning]: t(
        'Manual Parcel Scanning request',
      ),
    }),
    [t],
  );

  const userText = useMemo(
    () =>
      activityItem.user?.deletedAt || !activityItem.user ? (
        <span className="text-grey-700">{t('Deleted user')}</span>
      ) : (
        `${activityItem.user?.firstName} ${activityItem.user?.lastName}`
      ),
    [activityItem.user, t],
  );

  const messages = useMemo(
    () => ({
      [ActivityType.LateCheckIn]: (
        <span>
          {userText} {t("didn't Check-in on time")}
        </span>
      ),
      [ActivityType.CheckIn]: t('Tour started, Driver checked in'),
      [ActivityType.CheckOut]: t('Tour completed, Driver checked out'),
      [ActivityType.AutoCheckOut]: t(
        'Tour completed, automatic Check-out occurred',
      ),
      [ActivityType.ReportDelay]: {
        [DelayType.Tour]: (
          <span>
            {userText} {t('reported delay on Tour')}
          </span>
        ),
        [DelayType.GroceryTour]: (
          <span>
            {userText} {t('reported delay on Tour')}
          </span>
        ),
        [DelayType.TourStop]: (
          <span>
            {userText} {t('reported a delivery issue')}
          </span>
        ),
      },
      [ActivityType.TourReport]: (
        <span>
          {userText} {t('left an after-tour issue report')}
        </span>
      ),
      [ActivityType.TourCanceledAdmin]: t('Tour cancelled by Admin'),
      [ActivityType.TourCompletedAdmin]: t('Tour completed by Admin'),
      [ActivityType.ManualCheckOut]: t('Tour completed, Manager checked out.'),
      [ActivityType.TourRequestManualScanning]: (
        <span>
          {userText} {t('requested Manual parcel scanning')}
        </span>
      ),
    }),
    [t, userText],
  );

  const delayMessages = useMemo(
    () => ({
      [DelayReason.OccupiedLoadingRamps]: t('Occupied loading ramps'),
      [DelayReason.IncompleteTour]: t('Incomplete Tour'),
      [DelayReason.MissingBoxes]: t('Missing boxes'),
      [DelayReason.TooManyBoxes]: t('Too many boxes'),
      [DelayReason.TourNotReadyOnTime]: t('Tour not ready on time'),
      [DelayReason.WrongAddress]: t('Wrong address'),
      [DelayReason.IncorrectNavigation]: t('Incorrect navigation'),
      [DelayReason.DeliveryWindowTooSmall]: t('Delivery window too small'),
      [DelayReason.TooLongStopAtCustomer]: t('Too long stop at customer'),
      [DelayReason.AggressiveCustomer]: t('Aggressive customer'),
      [DelayReason.Other]: activityItem?.delay?.description,
    }),
    [activityItem?.delay?.description, t],
  );

  const title =
    activityItem.type === ActivityType.ReportDelay
      ? labels[activityItem.type][activityItem.delay?.type]
      : labels[activityItem.type];

  const message =
    activityItem.type === ActivityType.ReportDelay
      ? messages[activityItem.type][activityItem.delay?.type]
      : messages[activityItem.type];

  return (
    <div
      className={cn(
        'relative flex  gap-4 p-4 last:after:hidden sm:flex-nowrap after:absolute after:content-[""] after:h-[1px] after:w-[calc(100%_-_32px)] after:bg-grey-300 after:left-[16px] after:bottom-0',
        activityItem.type === ActivityType.TourRequestManualScanning &&
          'bg-ui-warning-orange-light',
      )}
      data-test={`activity-item-${activityItem.id}`}
    >
      <ActivityIcon activityType={activityItem.type} isRecent={isRecent} />
      <div className="flex flex-col gap-3 w-full">
        <div className="flex flex-1 flex-col gap-1 text-sm">
          <span className="text-sm text-grey-700">{`${moment(
            activityItem.createdAt,
          ).format('HH:mm - DD.MM.YYYY')} • ${title}`}</span>
          <span className="font-medium text-primary-dark">{message}</span>
          {(activityItem.details ||
            activityItem.type === ActivityType.ReportDelay) && (
            <div className="flex gap-1 text-grey-700">
              {`${t('Report')}:`}
              <span className="text-primary-dark">
                {activityItem.type === ActivityType.ReportDelay
                  ? delayMessages[activityItem?.delay?.reason]
                  : activityItem.details}
              </span>
            </div>
          )}
          {tourLink && (
            <div className="flex items-center gap-2 text-grey-700">
              <span>{`${t('Tour')}:`}</span>
              <Icon className="h-4 w-4 text-ui-blue" icon="link" />
              <Link
                className="break-all font-medium text-ui-blue no-underline"
                state={{ backLink: '/activity' }}
                to={{
                  pathname: isGroceries
                    ? `/grocery-tours/${activityItem.groceryTour.id}`
                    : `/tours/${activityItem.tour?.id}`,
                }}
              >
                {isGroceries
                  ? activityItem.groceryTour.tourNumber
                  : activityItem.tour?.vehicle}
              </Link>
            </div>
          )}
        </div>
        {(userRole === UserRole.OperationsManager ||
          userRole === UserRole.HubManager) &&
          !activityItem.read &&
          markAsRead && (
            <div className="w-full sm:w-auto">
              <Button
                icon="checkmark"
                isFullWidth={isMobile}
                size="s"
                text={t('Mark as Read')}
                variant="outlineBlue"
                onClick={() => markAsRead(activityItem.id)}
              />
            </div>
          )}
      </div>
    </div>
  );
};

ActivityItem.propTypes = {
  activityItem: PropTypes.shape({
    createdAt: PropTypes.string,
    delay: PropTypes.shape({
      description: PropTypes.string,
      reason: PropTypes.string,
      type: PropTypes.string,
    }),
    details: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tour: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      vehicle: PropTypes.string,
    }),
    groceryTour: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      tourNumber: PropTypes.string,
    }),
    read: PropTypes.bool,
    type: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      deletedAt: PropTypes.string,
    }),
  }).isRequired,
  isRecent: PropTypes.bool,
  markAsRead: PropTypes.func,
  tourLink: PropTypes.bool,
};

ActivityItem.defaultProps = {
  isRecent: false,
  markAsRead: undefined,
  tourLink: false,
};

export default ActivityItem;

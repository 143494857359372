import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import UserCard from './UserCard';

const UserCardContainer = (props) => {
  const { user } = props;

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const { data: driverQuery, isLoading } = useQuery(
    [`/users/${user.id}`],
    async () => {
      const response = await fetch(`/users/${user.id}`, {
        method: 'GET',
      });
      return response.json();
    },
    {
      onError: (error) => toastFetchError(error),
    },
  );

  const subcarrier = driverQuery?.data?.subcarrier?.shortCode;
  const phoneNumber = driverQuery?.data?.phoneNumber;

  return (
    <UserCard
      user={{ ...user, phoneNumber, subcarrier }}
      isLoading={isLoading}
    />
  );
};

UserCardContainer.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default UserCardContainer;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardContent,
  CardHeader,
  CardIcon,
  CardItem,
} from '../../../components/Card';
import metersToKilometers from '../../../helpers/metersToKilometers';

const PickupInfoCard = (props) => {
  const { tour } = props;
  const { t } = useTranslation();
  const hours = tour.duration / 60;
  const renderHours = Math.floor(hours);
  const minutes = (hours - renderHours) * 60;
  const renderMinutes = Math.round(minutes);

  return (
    <Card>
      <CardHeader title={t('Pickup Information')} />
      <CardContent className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
        <CardItem title={t('Hub Name')}>
          <div className="flex gap-2">
            <CardIcon className="text-primary-yellow" icon="warehouse" />
            {tour.hub?.name}
          </div>
        </CardItem>
        <CardItem title={t('Drive Duration')}>
          <div className="flex gap-2">
            <CardIcon className="text-primary-yellow" icon="car" />
            {tour.duration >= 60 && `${renderHours} h `}
            {`${renderMinutes} min`}
          </div>
        </CardItem>
        <CardItem title={t('Tour Stops')}>
          <div className="flex gap-2">
            <CardIcon className="text-primary-yellow" icon="tourStop" />
            {tour.totalStops || 'N/A'}
          </div>
        </CardItem>
        <CardItem title={t('Hub Location')}>
          <div className="flex gap-2">
            <CardIcon className="text-primary-yellow" icon="locationOn" />
            <span className="break-words">{tour.hub?.address}</span>
          </div>
        </CardItem>
        <CardItem title={t('Drive Distance')}>
          <div className="flex gap-2">
            <CardIcon className="text-primary-yellow" icon="distance" />
            {tour.distance ? `${metersToKilometers(tour.distance)} km` : 'N/A'}
          </div>
        </CardItem>
      </CardContent>
    </Card>
  );
};

PickupInfoCard.propTypes = {
  tour: PropTypes.shape({
    totalStops: PropTypes.number,
    boxes: PropTypes.number,
    distance: PropTypes.number,
    duration: PropTypes.number,
    type: PropTypes.string,
    waRamp: PropTypes.string,
    waSpur: PropTypes.string,
    hub: PropTypes.shape({
      address: PropTypes.string,
      name: PropTypes.string,
    }),
    weight: PropTypes.number,
  }),
};

PickupInfoCard.defaultProps = {
  tour: undefined,
};

export default PickupInfoCard;

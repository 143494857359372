import PropTypes from 'prop-types';

import FormInput from '../../../components/FormInput';
import Icon from '../../../components/Icon';

const ShortcodeInput = (props) => {
  const { disabled, disclaimerMessage, ...rest } = props;

  return (
    <div className="grid gap-2 sm:grid-cols-2 sm:gap-4">
      <div>
        <FormInput {...rest} disabled={disabled} type="text" />
      </div>
      <div className="flex gap-2 text-sm font-normal text-grey-700 sm:mt-6">
        <Icon className="mt-0.5 h-4 w-4 text-ui-info-blue" icon="infoFilled" />
        <span>{disclaimerMessage}</span>
      </div>
    </div>
  );
};

ShortcodeInput.propTypes = {
  disabled: PropTypes.bool,
  disclaimerMessage: PropTypes.string,
};

ShortcodeInput.defaultProps = {
  disabled: false,
  disclaimerMessage: '',
};

export default ShortcodeInput;

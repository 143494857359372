import { detectOverflow } from '@popperjs/core';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { cloneElement, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { useClickAway } from 'react-use';

import Breakpoint from '../../enums/Breakpoint';
import { offsetModifier, sameWidth } from '../../helpers/popperModifiers';
import useWindowSize from '../../hooks/useWindowSize';
import * as icons from '../../icons';
import { useFloatingContainer } from '../../providers/FloatingContainerProvider';
import Icon from '../Icon';

const Dropdown = (props) => {
  const { children, className, isDisabled, menu, placement } = props;

  const [popperReferenceElement, setPopperReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { width } = useWindowSize();
  const isSmallerScreen = width < Breakpoint.LG;

  const [isOverflowing, setIsOverflowing] = useState(false);
  const { floatingContainer } = useFloatingContainer();
  const floatingContainerOverflowModifier = useMemo(
    () => ({
      enabled: true,
      phase: 'main',
      requiresIfExists: ['offset'],
      fn({ state }) {
        const overflow = detectOverflow(state, {
          elementContext: 'reference',
          boundary: floatingContainer,
        });
        // if positive, its overflowing
        if (
          overflow.top > 0 ||
          overflow.bottom > 0 ||
          overflow.left > 0 ||
          overflow.right > 0
        ) {
          setIsOverflowing(true);
          return;
        }
        setIsOverflowing(false);
      },
    }),
    [floatingContainer],
  );

  const modifiers = useMemo(() => {
    const tempModifiers = [offsetModifier];

    if (floatingContainer) {
      tempModifiers.push(floatingContainerOverflowModifier);
    }

    if (isSmallerScreen) {
      tempModifiers.push(sameWidth);
    }

    return tempModifiers;
  }, [floatingContainer, isSmallerScreen, floatingContainerOverflowModifier]);

  const { attributes, styles: popperStyles } = usePopper(
    popperReferenceElement,
    popperElement,
    {
      modifiers,
      placement,
    },
  );

  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useClickAway(containerRef, (e) => {
    if (isOpen && !popperReferenceElement.contains(e.target.parentElement)) {
      setIsOpen(false);
    }
  });

  const toggle = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const menuItems = useMemo(() => {
    if (!isOpen) {
      return [];
    }

    return typeof menu === 'function' ? menu() : menu;
  }, [isOpen, menu]);

  return (
    <div
      className={cn('relative inline-block overflow-hidden', className)}
      ref={setPopperReferenceElement}
    >
      {typeof children === 'function'
        ? children({ isOpen, toggle })
        : cloneElement(children, {
            ...children.props,
            onClick: () => {
              if (isDisabled) {
                return;
              }
              if (children.props.onClick) {
                children.props.onClick();
              }
              toggle();
            },
          })}

      {isOpen &&
        createPortal(
          <ul
            className={cn(
              'absolute left-0 z-30 m-0 w-[240px] min-w-[240px] list-none rounded-md bg-white p-2 shadow-elevation-300',
              isOverflowing && 'opacity-0 pointer-events-none',
            )}
            ref={(ref) => {
              containerRef.current = ref;
              setPopperElement(ref);
            }}
            style={popperStyles.popper}
            {...attributes.popper}
          >
            {menuItems.map((menuItem) => (
              <li
                key={
                  typeof menuItem.text === 'string'
                    ? menuItem.text
                    : menuItem.key
                }
              >
                <div
                  data-test={menuItem.dataTest}
                  className={cn(
                    'flex w-full items-center gap-2 rounded-md px-3 py-[10px] text-sm font-medium text-primary-dark ',
                    menuItem.isActive
                      ? 'bg-ui-blue text-white'
                      : 'hover:bg-grey-200',
                    !menuItem.isDisabled && 'cursor-pointer',
                    menuItem.isDisabled && 'cursor-not-allowed opacity-40',
                  )}
                  onClick={(e) => {
                    if (menuItem.isDisabled) {
                      e.stopPropagation();
                      return;
                    }
                    if (menuItem.onClick) {
                      menuItem.onClick(e);
                    }

                    setIsOpen(false);
                  }}
                >
                  {menuItem.icon && (
                    <Icon
                      className={cn('h-4 w-4', menuItem.iconClassName)}
                      icon={menuItem.icon}
                    />
                  )}
                  <div className={cn('text-left', menuItem.textClassName)}>
                    {menuItem.text}
                  </div>
                </div>
              </li>
            ))}
          </ul>,
          document.body,
        )}
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  className: PropTypes.string,
  menu: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.oneOf(Object.keys(icons)),
        iconClassName: PropTypes.string,
        onClick: PropTypes.func,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        textClassName: PropTypes.string,
        dataTest: PropTypes.string,
        isDisabled: PropTypes.bool,
        key: PropTypes.string,
      }),
    ),
    PropTypes.func,
  ]),
  isDisabled: PropTypes.bool,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'bottom',
    'right',
    'left',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end',
  ]),
};

Dropdown.defaultProps = {
  className: undefined,
  isDisabled: false,
  menu: [],
  placement: 'bottom-start',
};

export default Dropdown;

const PlatformFeature = {
  AutoTourCheckout: 'autoTourCheckout',
  DeliveryDetails: 'deliveryDetails',
  DeliveryProof: 'deliveryProof',
  // driverAssignmentPerTourWarehouse intentionally left intact for backward compatibility
  DriverAssignmentPerTourWarehouse: 'driverAssignmentPerTourWarehouse',
  PrependOnImport: 'prependOnImport',
  ShowAdditionalTourFields: 'showAdditionalTourFields',
  CustomEmailFields: 'customEmailFields',
};

export default PlatformFeature;

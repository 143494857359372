import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../../components/Dropdown/Dropdown';
import Icon from '../../../components/Icon';
import IconButton from '../../../components/IconButton';
import Tooltip from '../../../components/Tooltip';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import useCopyUnitInfoToClipboard from '../../../hooks/useCopyUnitInfoToClipboard';

const UnitsTableActionsCell = ({
  onUnitCancel,
  onUnitDetailsClick,
  onUpdateUnitCodeClick,
  onUpdateUnitStatus,
  row: { original: record },
}) => {
  const { t } = useTranslation();
  const copyUnitInfoToClipboard = useCopyUnitInfoToClipboard();

  const isCancelable = record.status === DeliveryTaskUnitStatus.Created;
  const isCanceled = record.status === DeliveryTaskUnitStatus.Cancelled;
  const codeNotEditable =
    (record.attempt === 1 && !!record.latestTour) || record.attempt > 1;

  const menuItems = [
    {
      icon: 'codeScan',
      iconClassName: 'text-grey-700',
      onClick: () => {
        if (!isCanceled && !codeNotEditable) {
          onUpdateUnitCodeClick(record);
        }
      },
      text: t('Update Unit code'),
      isDisabled: codeNotEditable || isCanceled,
    },
    {
      icon: 'copy',
      iconClassName: 'text-grey-700',
      onClick: async () => {
        copyUnitInfoToClipboard(record);
      },
      text: t('Copy info to clipboard'),
    },
    {
      icon: 'xCircleOutlined',
      iconClassName: 'text-ui-red',
      onClick: () => {
        if (!isCanceled) {
          onUnitCancel(record);
        }
      },
      textClassName: 'w-full',
      isDisabled: !isCancelable,
      text: (
        <Tooltip
          hidden={isCancelable}
          text={t(
            "Unit has already arrived to Urbify, to cancel, update its status to 'Return to client: canceled'",
          )}
        >
          <div className="flex items-center w-full justify-between flex-1 gap-1">
            <span>{t('Cancel unit')}</span>
            {!isCancelable && (
              <Icon icon="infoFilled" className="h-4 w-4 text-grey-700" />
            )}
          </div>
        </Tooltip>
      ),
      key: 'cancel',
    },
  ];

  return (
    <div
      className="flex flex-row items-center justify-end gap-1"
      key={record.id}
    >
      <Tooltip placement="top" size="sm" text={t('Unit Details')}>
        <IconButton
          icon="parcelView"
          iconSize="s"
          size="s"
          variant="ghostBlack"
          onClick={() => onUnitDetailsClick(record)}
        />
      </Tooltip>
      <Tooltip
        hidden={isCanceled}
        placement="top"
        size="sm"
        text={t('Update Unit Status')}
      >
        <IconButton
          icon="updateUnitStatus"
          iconSize="s"
          size="s"
          variant="ghostBlack"
          disabled={isCanceled}
          onClick={() => {
            if (!isCanceled) {
              onUpdateUnitStatus(record);
            }
          }}
        />
      </Tooltip>
      <Dropdown menu={menuItems}>
        <div className="flex">
          <Tooltip placement="top" size="sm" text={t('Other options')}>
            <IconButton
              icon="moreVert"
              iconSize="s"
              size="s"
              variant="ghostBlack"
            />
          </Tooltip>
        </div>
      </Dropdown>
    </div>
  );
};

UnitsTableActionsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      status: PropTypes.oneOf(Object.values(DeliveryTaskUnitStatus)),
      statusChangeTime: PropTypes.string,
      address: PropTypes.string,
      date: PropTypes.string,
      timeFrom: PropTypes.string,
      timeTo: PropTypes.string,
      attempt: PropTypes.number,
      latestTour: PropTypes.shape({}),
    }),
  }).isRequired,
  onUpdateUnitCodeClick: PropTypes.func.isRequired,
  onUpdateUnitStatus: PropTypes.func.isRequired,
  onUnitDetailsClick: PropTypes.func.isRequired,
  onUnitCancel: PropTypes.func.isRequired,
};

export default UnitsTableActionsCell;

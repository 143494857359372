import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import TaskStatus from '../enums/TaskStatus';

const useTaskStatusTranslations = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      [TaskStatus.Opened]: t('Opened'),
      [TaskStatus.Active]: t('Active'),
      [TaskStatus.Closed]: t('Closed'),
    }),
    [t],
  );
};

export default useTaskStatusTranslations;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Icon from '../../../components/Icon';
import Tooltip from '../../../components/Tooltip';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import UnitProgress from '../../../enums/UnitProgress';
import TasksUnitsHelper from '../../../helpers/TasksUnitsHelper';
import useUnitProgress from '../../../hooks/useUnitProgress';

const UnitProgressIcon = ({
  currentUnitStatus,
  isTourActiveOrCompleted,
  isUnitRouted,
  unitStatus,
}) => {
  const unitProgress = useUnitProgress();

  const mapStatusToProgress =
    TasksUnitsHelper.reverseUnitProgressToStatusMapping(unitProgress);

  const mappedProgress = mapStatusToProgress[unitStatus];

  const getItemProgress = useMemo(() => {
    if (
      unitStatus === DeliveryTaskUnitStatus.Postponed ||
      unitStatus === DeliveryTaskUnitStatus.Retry ||
      unitStatus === DeliveryTaskUnitStatus.Created ||
      unitStatus === DeliveryTaskUnitStatus.Arrived ||
      unitStatus === DeliveryTaskUnitStatus.ArrivedLocal
    ) {
      if (currentUnitStatus === DeliveryTaskUnitStatus.InDelivery) {
        return UnitProgress.RoutingPending;
      }

      if (currentUnitStatus === DeliveryTaskUnitStatus.InClarification) {
        if (!isUnitRouted) {
          return UnitProgress.RoutingPending;
        }

        if (isUnitRouted && isTourActiveOrCompleted) {
          return UnitProgress.RoutingPending;
        }

        return UnitProgress.RoutingDone;
      }
    }

    if (isUnitRouted === undefined) {
      return mapStatusToProgress[unitStatus][0];
    }

    if (isUnitRouted) {
      return mappedProgress.find(
        (status) => status !== UnitProgress.RoutingPending,
      );
    }

    return mappedProgress.find((status) => status !== UnitProgress.RoutingDone);
  }, [
    unitStatus,
    isUnitRouted,
    mappedProgress,
    currentUnitStatus,
    isTourActiveOrCompleted,
    mapStatusToProgress,
  ]);

  return (
    <Tooltip text={unitProgress[getItemProgress].label}>
      <Icon
        className={cn(
          'h-4 w-4',
          unitProgress[getItemProgress].labelIconClassname,
        )}
        icon={unitProgress[getItemProgress].labelIcon}
      />
    </Tooltip>
  );
};

UnitProgressIcon.propTypes = {
  unitStatus: PropTypes.string,
  isUnitRouted: PropTypes.bool,
  currentUnitStatus: PropTypes.string,
  isTourActiveOrCompleted: PropTypes.bool,
};

UnitProgressIcon.defaultProps = {
  unitStatus: undefined,
  isUnitRouted: undefined,
  currentUnitStatus: undefined,
  isTourActiveOrCompleted: false,
};

export default UnitProgressIcon;

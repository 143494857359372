import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import { useUser } from '../../../providers/UserProvider';

const AlreadyAssignedWarning = (props) => {
  const { tour } = props;
  const { t } = useTranslation();

  const { isSubcarrierUser } = useUser();

  if (tour.driver) {
    return (
      <div className="mb-4 flex gap-1">
        <Alert
          message={t(
            'Driver is assigned. Making changes will override the currently selected Driver.',
          )}
          variant="warningOrange"
        />
      </div>
    );
  }

  if (tour.subcarrier && !isSubcarrierUser) {
    return (
      <div className="mb-4 flex gap-1">
        <Alert
          message={t(
            'Subcarrier is assigned. Making changes will override the currently selected Subcarrier.',
          )}
          variant="warningOrange"
        />
      </div>
    );
  }

  return null;
};

AlreadyAssignedWarning.propTypes = {
  tour: PropTypes.shape({
    driver: PropTypes.shape({
      id: PropTypes.string,
    }),
    subcarrier: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

AlreadyAssignedWarning.defaultProps = {
  tour: undefined,
};

export default AlreadyAssignedWarning;

import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import { usePackageScanningSettings } from '../../../providers/PackageScanningSettingsProvider';

const FeedSettingsButton = () => {
  const { t } = useTranslation();
  const { setIsPrinterSetupModalOpen } = usePackageScanningSettings();

  return (
    <Button
      data-test="feed-settings-big-button"
      icon="settings"
      text={t('Printer Setup')}
      variant="outlineBlack"
      onClick={() => {
        setIsPrinterSetupModalOpen(true);
      }}
    />
  );
};

export default FeedSettingsButton;

import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardContent,
  CardHeader,
  CardIcon,
} from '../../../components/Card';
import PickupInfoCard from '../../tours/PickupInfoCard';
import TourStatusCard from '../../tours/TourStatusCard';

const CustomerFixedTourDetails = (params) => {
  const { tour } = params;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <div className="grid gap-4 lg:grid-cols-3">
        <TourStatusCard tour={tour} />
        <Card>
          <CardHeader title={t('Date')} />
          <CardContent>
            <div className="flex gap-2">
              <CardIcon className="text-grey-500" icon="date" />
              <span>{moment(tour.date).format('DD.MM.YYYY')}</span>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title={t('Start Time')} />
          <CardContent>
            <div className="flex gap-2">
              <CardIcon className="text-grey-500" icon="startTime" />
              <span>{`${moment(tour.shiftStart).format('HH:mm')}`}</span>
            </div>
          </CardContent>
        </Card>
      </div>

      <PickupInfoCard tour={tour} />
    </div>
  );
};

export default CustomerFixedTourDetails;

import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

import useFormInputValidationRules from '../../hooks/useFormInputValidationRules';
import Input from '../Input';
import InputLayout from '../InputLayout';

const FormInput = forwardRef((props, ref) => {
  const {
    disabled,
    error,
    icon,
    id,
    label,
    max,
    maxLength,
    min,
    minLength,
    name,
    pattern,
    placeholder,
    required,
    type,
    url,
    validate,
    ...rest
  } = props;

  const {
    formState: { errors },
    register,
  } = useFormContext();

  const rules = useFormInputValidationRules({
    maxLength,
    minLength,
    pattern,
    required,
    type,
    url,
    validate,
    min,
    max,
  });
  const errorInput = error || (errors[name] && errors[name].message);

  return (
    <InputLayout error={errorInput} id={id} label={label} required={required}>
      <Input
        disabled={disabled}
        hasError={!!errorInput}
        icon={icon}
        id={id}
        placeholder={placeholder}
        ref={ref}
        type={type}
        {...register(name, rules())}
        {...rest}
      />
    </InputLayout>
  );
});

FormInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  iconFocusColor: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxLength: PropTypes.shape({
    message: PropTypes.string,
    value: PropTypes.number,
  }),
  minLength: PropTypes.shape({
    message: PropTypes.string,
    value: PropTypes.number,
  }),
  name: PropTypes.string,
  pattern: PropTypes.shape({
    message: PropTypes.string,
    value: PropTypes.instanceOf(RegExp),
  }),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['hidden', 'text', 'email', 'password', 'number']),
  url: PropTypes.bool,
  validate: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
};

FormInput.defaultProps = {
  disabled: false,
  error: undefined,
  icon: undefined,
  iconFocusColor: undefined,
  id: '',
  label: '',
  maxLength: null,
  minLength: null,
  name: '',
  pattern: undefined,
  placeholder: '',
  required: false,
  type: 'text',
  url: false,
  validate: null,
  min: undefined,
  max: undefined,
};

export default FormInput;

import { useEffect } from 'react';

const useConfirmTabClose = (showPrompt) => {
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (showPrompt) {
        // Cancel the event
        // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [showPrompt]);
};

export default useConfirmTabClose;

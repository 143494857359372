import { detectOverflow } from '@popperjs/core';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { usePrevious } from 'react-use';

import CSSTransitionStage from '../../enums/CSSTransitionStage';
import useCSSTransition from '../../hooks/useCSSTransition';
import { useFloatingContainer } from '../../providers/FloatingContainerProvider';

const Tooltip = forwardRef(
  (
    {
      children,
      className,
      defaultVisible,
      hidden,
      onMouseLeave: propOnMouseLeave,
      placement,
      showArrow,
      size,
      text,
      ...rest
    },
    ref,
  ) => {
    const [popperReferenceElement, setPopperReferenceElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [isVisible, setIsVisible] = useState(defaultVisible);
    const previousText = usePrevious(text);

    const customModifier = useMemo(
      () => ({
        name: 'offset',
        options: {
          offset: () => [0, showArrow ? 10 : 6],
        },
      }),
      [showArrow],
    );

    const preventWidthOverflowModifier = useMemo(() => {
      const padding = 10;
      return {
        name: 'preventOverflow',
        options: {
          padding,
        },
        effect: (data) => {
          const { state } = data;
          const scrollbar = window.innerWidth - document.body.clientWidth;
          const maxWidth = window.innerWidth - scrollbar - padding * 2;
          if (maxWidth <= 400) {
            state.elements.popper.style.maxWidth = `${maxWidth}px`;
          }
        },
      };
    }, []);

    const arrowModifier = useMemo(
      () => ({ name: 'arrow', options: { element: arrowElement } }),
      [arrowElement],
    );

    const [isOverflowing, setIsOverflowing] = useState(false);
    const { floatingContainer } = useFloatingContainer();
    const floatingContainerOverflowModifier = useMemo(
      () => ({
        enabled: true,
        phase: 'main',
        requiresIfExists: ['offset'],
        fn({ state }) {
          const overflow = detectOverflow(state, {
            elementContext: 'reference',
            boundary: floatingContainer,
          });
          // if positive, its overflowing
          if (
            overflow.top > 0 ||
            overflow.bottom > 0 ||
            overflow.left > 0 ||
            overflow.right > 0
          ) {
            setIsOverflowing(true);
            return;
          }
          setIsOverflowing(false);
        },
      }),
      [floatingContainer],
    );

    const modifiers = useMemo(
      () =>
        floatingContainer
          ? [
              customModifier,
              preventWidthOverflowModifier,
              arrowModifier,
              floatingContainerOverflowModifier,
            ]
          : [customModifier, preventWidthOverflowModifier, arrowModifier],
      [
        arrowModifier,
        floatingContainerOverflowModifier,
        customModifier,
        floatingContainer,
        preventWidthOverflowModifier,
      ],
    );

    const {
      attributes,
      styles: popperStyles,
      update,
    } = usePopper(popperReferenceElement, popperElement, {
      modifiers,
      placement,
    });

    useImperativeHandle(
      ref,
      () => ({
        update,
      }),
      [update],
    );

    useEffect(() => {
      if (previousText !== text && update) {
        update();
        return;
      }

      if (update) {
        update();
      }
    }, [previousText, text, update]);

    const timeoutRef = useRef(null);

    useEffect(
      () => () => {
        clearTimeout(timeoutRef.current);
      },
      [],
    );

    const onMouseEnter = useCallback(() => {
      timeoutRef.current = setTimeout(() => {
        setIsVisible(true);
      }, 50);
    }, []);

    const onMouseLeave = useCallback(() => {
      setIsVisible(false);
      clearTimeout(timeoutRef.current);
      propOnMouseLeave();
    }, [propOnMouseLeave]);

    const { shouldMount, stage } = useCSSTransition(isVisible, 150);

    const dataPopperPlacement = attributes.popper?.['data-popper-placement'];
    const isReferenceHidden =
      attributes.popper?.['data-popper-reference-hidden'] === true;
    return (
      <>
        <span
          className={className}
          ref={setPopperReferenceElement}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </span>
        {!hidden &&
          shouldMount &&
          ReactDOM.createPortal(
            <div
              className={cn(
                'pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-30',
                (isOverflowing || isReferenceHidden) &&
                  'opacity-0 pointer-events-auto',
              )}
            >
              <div
                className={cn(
                  'pointer-events-none rounded-md bg-grey-900 text-center text-white shadow-elevation-300 transition-[opacity] max-w-[400px] relative z-0',
                  stage === CSSTransitionStage.To && 'opacity-90',
                  stage === CSSTransitionStage.From && 'opacity-0',
                  size === 'sm' && 'px-2 py-1 text-xs',
                  size === 'md' && 'px-2 py-[6px] text-sm',
                )}
                ref={setPopperElement}
                style={popperStyles.popper}
                {...attributes.popper}
                {...rest}
              >
                {showArrow && (
                  <div
                    ref={setArrowElement}
                    style={popperStyles.arrow}
                    className={cn(
                      "absolute invisible before:absolute w-4 h-4 before:w-4 before:h-4 bg-inherit before:bg-inherit before:content-[''] before:visible before:rotate-45 before:left-0 before:top-0 before:right-0 before:bottom-0",
                      {
                        '-bottom-[6px]': dataPopperPlacement?.startsWith('top'),
                        '-top-[6px]': dataPopperPlacement?.startsWith('bottom'),
                        '-left-[4px]': dataPopperPlacement?.startsWith('right'),
                        '-right-[4px]': dataPopperPlacement?.startsWith('left'),
                      },
                    )}
                  />
                )}
                <div className="z-0 relative">{text}</div>
              </div>
            </div>,
            document.body,
          )}
      </>
    );
  },
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
  size: PropTypes.oneOf(['sm', 'md']),
  text: PropTypes.node.isRequired,
  defaultVisible: PropTypes.bool,
  onMouseLeave: PropTypes.func,
  showArrow: PropTypes.bool,
};

Tooltip.defaultProps = {
  className: '',
  hidden: false,
  placement: 'top',
  size: 'md',
  defaultVisible: false,
  onMouseLeave: () => {},
  showArrow: false,
};

export default Tooltip;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

const TabsCard = (props) => {
  const { defaultActiveTabIndex, onTabChange, tabs } = props;

  const [activeTabIndex, setActiveTabIndex] = useState(defaultActiveTabIndex);

  return (
    <div className="rounded-md bg-white py-4 shadow-elevation-100">
      <div className="flex flex-row lg:px-4 print:hidden">
        {tabs.map((tab, index) => {
          const { label } = tab;
          return (
            <button
              className={cn(
                `min-w-[120px] truncate border-b-2 pb-4 text-sm uppercase px-4`,
                activeTabIndex === index
                  ? 'border-b-2 border-primary-yellow text-center font-semibold text-primary-dark'
                  : 'border-transparent',
              )}
              data-test={tab.dataTest}
              key={label}
              type="button"
              onClick={() => {
                setActiveTabIndex(index);
                onTabChange(index);
              }}
            >
              {label}
            </button>
          );
        })}
      </div>
      <div className="lg:mx-4 border-b border-b-grey-300" />
      {tabs[activeTabIndex].content}
    </div>
  );
};

TabsCard.propTypes = {
  defaultActiveTabIndex: PropTypes.number,

  onTabChange: PropTypes.func,

  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      label: PropTypes.string.isRequired,
      'data-test': PropTypes.string,
    }),
  ),
};

TabsCard.defaultProps = {
  defaultActiveTabIndex: 0,
  onTabChange: () => {},
  tabs: [],
};

export default TabsCard;

import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Button from '../../../components/Button';
import DebouncedSearch from '../../../components/DebouncedSearch';
import Breakpoint from '../../../enums/Breakpoint';
import useWindowSize from '../../../hooks/useWindowSize';
import ClientSelect from '../ClientSelect';

const HubFilters = (props) => {
  const { carrierName, organisationId } = props;
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const [searchParams, setSearchParams] = useSearchParams();
  const clientIdQuery = searchParams.get('clientId') || '';
  const nameQuery = searchParams.get('name') || '';
  const hasFiltersApplied = !!(clientIdQuery || nameQuery);

  const onSelectChange = useCallback(
    (queryKey, queryValue) => {
      searchParams.set('page', '1');
      if (!queryValue) {
        searchParams.delete(queryKey);
      } else {
        searchParams.set(queryKey, queryValue);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return (
    <div className="flex w-full flex-col gap-4 lg:flex-row lg:flex-wrap lg:items-center">
      <div className="flex flex-1 flex-col gap-4 lg:flex-row lg:flex-wrap lg:items-center">
        <ClientSelect
          className="lg:w-[160px]"
          carrierName={carrierName}
          organisationId={organisationId}
          showAllOption
          value={clientIdQuery}
          onChange={(clientId) => {
            onSelectChange('clientId', clientId);
          }}
        />
        <DebouncedSearch
          className="lg:w-[200px]"
          placeholder={`${t('Find Hub')}...`}
          searchValue={nameQuery}
          onSearch={(newSearchValue) => {
            if (newSearchValue === nameQuery) {
              return;
            }
            searchParams.set('page', '1');
            if (!newSearchValue) {
              searchParams.delete('name');
            } else {
              searchParams.set('name', newSearchValue?.trim());
            }
            setSearchParams(searchParams);
          }}
        />
      </div>
      <Button
        disabled={!hasFiltersApplied}
        text={t('Reset')}
        variant={isDesktop ? 'ghostBlack' : 'outlineBlack'}
        onClick={() => setSearchParams(new URLSearchParams())}
      />
    </div>
  );
};

HubFilters.propTypes = {
  carrierName: PropTypes.string,
  organisationId: PropTypes.string,
};

HubFilters.defaultProps = {
  carrierName: undefined,
  organisationId: undefined,
};

export default HubFilters;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Breakpoint from '../../../enums/Breakpoint';
import useWindowSize from '../../../hooks/useWindowSize';

const CustomDateRangeDropdown = ({
  children,
  className,
  onLastWeekClick,
  onThisWeekClick,
  onTodayClick,
  onTomorrowClick,
  onYesterdayClick,
}) => {
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.SM;

  if (isMobile) {
    return <div className={className}>{children}</div>;
  }

  return (
    <div className={cn('py-1.5 px-3', className)}>
      <div className="flex gap-3">
        <div className="py-2.5 flex flex-col items-start gap-1 min-w-[120px]">
          <Button
            size="xs"
            onClick={onTodayClick}
            text={t('Today')}
            variant="ghostBlack"
          />
          <Button
            size="xs"
            text={t('Tomorrow')}
            variant="ghostBlack"
            onClick={onTomorrowClick}
          />
          <Button
            size="xs"
            text={t('Yesterday')}
            variant="ghostBlack"
            onClick={onYesterdayClick}
          />
          <Button
            size="xs"
            text={t('This week')}
            variant="ghostBlack"
            onClick={onThisWeekClick}
          />
          <Button
            size="xs"
            text={t('Last week')}
            variant="ghostBlack"
            onClick={onLastWeekClick}
          />
        </div>
        <div className="my-2.5 border-r border-grey-200" />
        <div>{children}</div>
      </div>
    </div>
  );
};

CustomDateRangeDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onTodayClick: PropTypes.func.isRequired,
  onTomorrowClick: PropTypes.func.isRequired,
  onYesterdayClick: PropTypes.func.isRequired,
  onThisWeekClick: PropTypes.func.isRequired,
  onLastWeekClick: PropTypes.func.isRequired,
};

CustomDateRangeDropdown.defaultProps = {
  className: undefined,
};

export default CustomDateRangeDropdown;

import { useMutation, useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import MemberStatusType from '../../enums/MemberStatusType';
import UserRole from '../../enums/UserRole';
import Workflow from '../../enums/Workflow';
import ChangeMemberStatusConfirmationModal from '../../features/members/ChangeMemberStatusConfirmationModal';
import MemberDetailsTitlebarContent from '../../features/members/MemberDetailsTitlebarContent';
import MemberInfo from '../../features/members/MemberInfo';
import MemberPerformance from '../../features/members/MemberPerformance';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';
import { useUser } from '../../providers/UserProvider';

const MemberDetails = () => {
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  const { id: userId } = useParams();
  const { user: loggedInUser } = useUser();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const { toastSuccess } = useCustomToast();
  const [isChangeMemberStatusModalOpen, setIsChangeMemberStatusModalOpen] =
    useState(false);
  const { state } = useLocation();

  const {
    data: fetchedUser,
    error,
    isError,
    isLoading,
  } = useQuery([`/users/${userId}`], async () => {
    const response = await fetch(`/users/${userId}`);
    return response.json();
  });

  const member = fetchedUser?.data;
  const isGroceriesMember =
    member?.organisation?.workflow === Workflow.Groceries;

  const mutation = useMutation(
    async () => {
      const url =
        member.status === MemberStatusType.Active
          ? `/users/${member.id}/deactivate`
          : `/users/${member.id}/activate`;
      const response = await fetch(url, {
        method: 'POST',
      });

      return response.json();
    },
    {
      onError: toastFetchError,
      onSuccess: ({ data }) => {
        toastSuccess(
          data.status === MemberStatusType.Active
            ? t('Member set to active.')
            : t('Member set to inactive.'),
        );
        setRedirect(true);
      },
    },
  );

  const membersListRoute = useMemo(() => {
    if (state?.backLink) {
      return state?.backLink;
    }
    if (loggedInUser.role === UserRole.Superadmin) {
      if (member?.client) {
        return `/clients/${member?.client?.id}/members`;
      }
      if (member?.role === UserRole.Superadmin) {
        return `/superadmins`;
      }
      return `/organisations/${member?.carrier?.id}/members`;
    }

    if (member?.client) {
      return `/clients/${member?.client?.id}/members`;
    }

    return `/my-organisation/members`;
  }, [
    loggedInUser.role,
    member?.carrier?.id,
    member?.client,
    member?.role,
    state?.backLink,
  ]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (redirect) {
    return <Navigate replace to={membersListRoute} />;
  }

  const memberFullName = `${member?.firstName || ''} ${member?.lastName || ''}`;

  return (
    <>
      <ChangeMemberStatusConfirmationModal
        memberStatus={member?.status}
        handleChange={mutation.mutate}
        isOpen={isChangeMemberStatusModalOpen}
        onClose={() => setIsChangeMemberStatusModalOpen(false)}
      />
      <Page>
        <Titlebar
          backLink={membersListRoute}
          textPrimary={t('Member Details')}
          textSecondary={memberFullName}
          isLoading={isLoading}
          titleRightContent={
            <div className="flex flex-col gap-4 lg:flex-row lg:items-center">
              <MemberDetailsTitlebarContent
                memberStatus={member?.status}
                memberId={member?.id}
                memberRole={member?.role}
                onChangeStatus={() => setIsChangeMemberStatusModalOpen(true)}
              />
            </div>
          }
        />

        <Page.Content variant="grey">
          {isLoading ? (
            <div className="grid gap-4">
              <Skeleton wrapper={Card} height={156} />
              <Skeleton wrapper={Card} height={98} />
              <Skeleton wrapper={Card} height={98} />
            </div>
          ) : (
            <div className="grid gap-4">
              {!isGroceriesMember && (
                <MemberPerformance
                  rating={member?.rating}
                  score={member?.score}
                  totalTours={member?.totalCompletedOrders}
                />
              )}
              <MemberInfo user={member} />
            </div>
          )}
        </Page.Content>
      </Page>
    </>
  );
};

export default MemberDetails;

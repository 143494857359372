const getSmallLabelTemplate = (templateData, isCentral) => `
^FX small ${isCentral ? 'central' : 'local'} template

^FX print speed 5
^PR5,5
  
^XA~TA000~JSN^LT0^MNW^MTD^PON^PMN^LH0,0^JMA~SD8^JUS^LRN
^FX enables UTF-8
^CI28
^XA
^MMT
^PW799
^LL0400
^FX Shifts all label content to the left or the right.
^LS0
^FT332,96^A0N,34,33^FH\\^FD${templateData.name}^FS
^FT332,138^A0N,34,33^FH\\^FD${templateData.addressNote}^FS
^FX Formats the current field as a text block which wraps text across multiple lines.
^FB450,3,8,L
^FT332,${templateData.addressNote ? '262' : '220'}
^A0N,34,33
^FD
${templateData.street}\\& 
${templateData.postcode} ${templateData.city}
^FS
^FT12,35^A0N,28,28^FH\\^FD${templateData.orderId}^FS
^FO12,43^GB771,0,2^FS
^FT12,262^A0N,85,84^FH\\^FD${
  isCentral ? templateData.hubShortCode : templateData.tourIdentifier
}^FS
${
  !isCentral &&
  `^FO12,110^GB138,78,78^FS
^FT12,172^A0N,62,62^FR^FH\\^FD${templateData.tourStop < 10 ? ' ' : ''}${
    templateData.tourStop
  }/${templateData.tourStopsCount}^FS
  ^FT202,172^A0N,62,62^FH\\^FDS${templateData.shift}^FS
  `
}
^FT12,89^A0N,28,28^FH\\^FDParcel: ${templateData.parcelNumber}/${
  templateData.totalParcelsInDelivery
}  Attempt: ${templateData.attempt}^FS
^BY${templateData.narrowBarWidth},3,70^FT${
  templateData.barcodeHorizontalPosition
},360^BCN,,Y,N
^FD>:${templateData.externalId}^FS
^FT652,35^A0N,28,28^FB139,1,0,R^FH\\^FD${templateData.date}^FS
^PQ1,0,1,Y^XZ
`;

export default getSmallLabelTemplate;

import UserRole from '../enums/UserRole';

export default class UserHelper {
  static getInitials(user) {
    return `${user?.firstName?.charAt(0)}.${user?.lastName?.charAt(0)}.`;
  }

  static labelVariantMap = {
    [UserRole.Superadmin]: 'grey',
    [UserRole.Admin]: 'watermelon',
    [UserRole.OperationsManager]: 'orange',
    [UserRole.Driver]: 'purple',
    [UserRole.Crew]: 'blue',
    [UserRole.HubManager]: 'green',
    [UserRole.Customer]: 'blue',
  };
}

import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import TabAssignment from '../../../components/TabAssignment';
import TourHelper from '../../../helpers/TourHelper';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useUser } from '../../../providers/UserProvider';
import AlreadyAssignedWarning from './AlreadyAssignedWarning';
import AssignDriverTab from './AssignDriverTab';
import AssignSubcarrierTab from './AssignSubcarrierTab';

const Tab = {
  Driver: 'driver',
  Subcarrier: 'subcarrier',
};

const TourAssignmentModal = ({ isOpen, onClose, tour }) => {
  const [currentTabId, setCurrentTabId] = useState(Tab.Driver);
  const tourId = tour.id;

  const { t } = useTranslation();

  const { isGroceries, isSubcarrierUser } = useUser();

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();

  const isDriverAssignmentPerTourWarehouseEnabled = !isGroceries;

  const [selectedDriverId, setSelectedDriverId] = useState(undefined);
  const [selectedSubcarrierId, setSelectedSubcarrierId] = useState(undefined);

  const queryClient = useQueryClient();
  const onSuccess = () => {
    if (isGroceries) {
      queryClient.invalidateQueries({
        queryKey: ['/grocery-tours'],
      });
      queryClient.invalidateQueries({
        queryKey: [`/grocery-tours/${tourId}`],
      });
    } else {
      queryClient.invalidateQueries({
        queryKey: ['/tours'],
      });
      queryClient.invalidateQueries({
        queryKey: [`/tours/${tourId}`],
      });
    }

    onClose();
  };

  const setDriverMutation = useMutation(
    () => {
      const url = isGroceries
        ? `/grocery-tours/${tourId}/drivers`
        : `/tours/${tourId}/driver`;

      return fetch(url, {
        body: {
          ...(isGroceries && { userId: selectedDriverId }),
          ...(!isGroceries && { driverId: selectedDriverId }),
        },
        method: isGroceries ? 'PUT' : 'PATCH',
      });
    },
    {
      onError: toastFetchError,
      onSuccess,
    },
  );

  const setSubcarrierMutation = useMutation(
    () => {
      const url = isGroceries
        ? `/grocery-tours/${tourId}/subcarriers`
        : `/tours/${tourId}/subcarrier`;

      return fetch(url, {
        body: {
          ...(isGroceries && { id: selectedSubcarrierId }),
          ...(!isGroceries && { subcarrierId: selectedSubcarrierId }),
        },
        method: isGroceries ? 'PUT' : 'PATCH',
      });
    },
    {
      onError: toastFetchError,
      onSuccess,
    },
  );

  useEffect(() => {
    if (tour) {
      setSelectedDriverId(tour.driver?.id);
      setSelectedSubcarrierId(tour.subcarrier?.id);
    }

    return () => {
      setSelectedDriverId(undefined);
      setSelectedSubcarrierId(undefined);
    };
  }, [isOpen, tour]);

  return (
    <Modal
      body={
        <div>
          {!isSubcarrierUser && (
            <div className="mb-5">
              <TabAssignment
                currentTabId={currentTabId}
                tabs={[
                  {
                    id: Tab.Driver,
                    label: t('Assign Driver'),
                  },
                  {
                    id: Tab.Subcarrier,
                    label: t('Assign Subcarrier'),
                  },
                ]}
                onTabClick={(id) => {
                  setCurrentTabId(id);

                  if (currentTabId !== id) {
                    setSelectedDriverId(tour?.driver?.id);
                    setSelectedSubcarrierId(tour?.subcarrier?.id);
                  }
                }}
              />
            </div>
          )}
          <div className="mb-4 text-sm">
            <div>
              <span className="text-grey-700">{t('Tour Code')}:</span>{' '}
              {TourHelper.getTourCode(tour)}
            </div>
          </div>
          {tour && <AlreadyAssignedWarning tour={tour} />}
          {currentTabId === Tab.Driver ? (
            <AssignDriverTab
              isDriverAssignmentPerTourWarehouseEnabled={
                isDriverAssignmentPerTourWarehouseEnabled
              }
              selectedDriverId={selectedDriverId}
              showSubcarrierFilter={!isSubcarrierUser}
              hubId={tour?.hub?.id}
              onDriverSelect={(id) => setSelectedDriverId(id)}
            />
          ) : (
            <AssignSubcarrierTab
              selectedSubcarrierId={selectedSubcarrierId}
              onSubcarrierSelect={(id) => {
                setSelectedSubcarrierId(id);
              }}
            />
          )}
        </div>
      }
      data-test="assign-driver-modal"
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          {currentTabId === Tab.Driver ? (
            <Button
              data-test="modal-button-action"
              disabled={
                !selectedDriverId ||
                selectedDriverId === tour?.driver?.id ||
                setDriverMutation.isLoading
              }
              text={t('Assign')}
              variant="solidBlue"
              onClick={setDriverMutation.mutate}
            />
          ) : (
            <Button
              data-test="modal-button-action"
              disabled={
                !selectedSubcarrierId ||
                selectedSubcarrierId === tour?.subcarrier?.id ||
                setSubcarrierMutation.isLoading
              }
              text={t('Assign')}
              variant="solidBlue"
              onClick={setSubcarrierMutation.mutate}
            />
          )}
        </div>
      }
      header={t('Assign to Tour')}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

TourAssignmentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tour: PropTypes.shape({
    id: PropTypes.string,
    vehicle: PropTypes.string,
    date: PropTypes.string,
    shift: PropTypes.number,
    driver: PropTypes.shape({
      id: PropTypes.string,
    }),
    subcarrier: PropTypes.shape({
      id: PropTypes.string,
    }),
    hub: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default TourAssignmentModal;

const createZebraPrinter = (device) => {
  const zebraPrinter = new window.Zebra.Printer(device);
  // config timeout is overridden because it creates loop in case of unsuccessful connection to printer (buggy official SDK implementation)
  zebraPrinter.configTimeout = () => {};

  // overriding buggy SDK implementation which does not handle exception when calling getStatus fails
  zebraPrinter.isPrinterReady = async () => {
    // getStatus returns Printer Status, providing an interface for parsing and representing the status of the printer
    const status = await zebraPrinter.getStatus();

    if (!status) {
      return Promise.reject(new Error('Error getting printer status'));
    }
    // Printer.Status.isPrinterReady function returns boolean marking whether the printer is ready to print or not
    if (status.isPrinterReady()) {
      // getMessage returns a string describing the state of the printer in english.
      // its value can be Offline, Paper Out, Head Open, Ribbon Out, Paused or Ready
      return status.getMessage();
    }

    throw status.getMessage();
  };

  zebraPrinter.sendAsync = (data) =>
    new Promise((resolve, reject) => {
      zebraPrinter.send(data, resolve, reject);
    });
  return zebraPrinter;
};

export default createZebraPrinter;

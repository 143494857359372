import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useUser } from '../../../providers/UserProvider';

const DeleteSubcarrierConfirmationModal = ({ id, isOpen, onClose }) => {
  const { t } = useTranslation();
  const { toastSuccess } = useCustomToast();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const queryClient = useQueryClient();
  const { user } = useUser();

  const mutation = useMutation(
    () =>
      fetch(`/subcarriers/${id}`, {
        method: 'DELETE',
      }),
    {
      onError: toastFetchError,
      onSuccess: () => {
        toastSuccess(t('Subcarrier is successfully deleted.'));
        onClose();
        queryClient.invalidateQueries({
          queryKey: [`/carriers/${user.carrier.id}/subcarriers`],
        });
      },
    },
  );

  return (
    <Modal
      body={
        <p>
          {t(
            'Note that this action is permanent. In order to delete subcarrier, no members should be assigned to it.',
          )}
        </p>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Delete')}
            variant="solidRed"
            onClick={mutation.mutate}
          />
        </div>
      }
      header={
        <div className="flex items-center gap-2">
          <Icon className="h-6 w-6 text-ui-red" icon="delete" />
          <span>{t('Delete Subcarrier?')}</span>
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

DeleteSubcarrierConfirmationModal.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteSubcarrierConfirmationModal;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

import InfiniteScrollSelect from '../../../components/InfiniteScrollSelect';
import { useUser } from '../../../providers/UserProvider';

const CarriersSelect = (props) => {
  const { className, onChange, value } = props;

  const { user } = useUser();
  const { carrier } = user;
  const { t } = useTranslation();
  const [mountedValue, setMountedValue] = useState(undefined);
  // enable mounted query only if it's not carrier
  const enableMountedQuery = !!mountedValue && mountedValue !== 'null';

  useMount(() => {
    setMountedValue(value);
  });

  const prependOptions = [
    {
      label: carrier.name,
      value: 'null',
    },
  ];

  const transformOptionFn = useCallback(
    (subcarrier) => ({
      label: `${subcarrier.shortCode} (${subcarrier.name})`,
      value: subcarrier.id,
    }),
    [],
  );

  return (
    <InfiniteScrollSelect
      singleItemUrl="/subcarriers/"
      url={`/carriers/${carrier.id}/subcarriers`}
      className={cn('lg:w-[160px]', className)}
      id="carriers-select"
      value={value}
      onChange={onChange}
      prependOptions={prependOptions}
      placeholder={t('Carrier')}
      transformOptionFn={transformOptionFn}
      isMountedValueQueryEnabled={enableMountedQuery}
      data-test="carriers-select"
      groupLabel={t('Subcarriers')}
    />
  );
};

CarriersSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

CarriersSelect.defaultProps = {
  className: undefined,
  onChange: undefined,
  value: undefined,
};

export default CarriersSelect;

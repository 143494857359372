import cn from 'classnames';
import PropTypes from 'prop-types';

const CardContent = (props) => {
  const { children, className } = props;
  return (
    <div
      className={cn(
        'mt-4 flex items-center text-sm font-medium text-grey-900',
        className,
      )}
    >
      {children}
    </div>
  );
};

CardContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CardContent.defaultProps = {
  children: undefined,
  className: '',
};

export default CardContent;

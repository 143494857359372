import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/Button';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import CreateMemberForm from '../../features/members/CreateMemberForm';
import useFetch from '../../lib/api/hooks/useFetch';

const OrganisationNewMember = () => {
  const { id } = useParams();
  const { fetch } = useFetch();

  const {
    data: fetchedOrganisation,
    error,
    isError,
  } = useQuery([`/carriers/${id}`], async () => {
    const response = await fetch(`/carriers/${id}`);
    return response.json();
  });

  const organisation = fetchedOrganisation?.data || null;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);

  const backLink = `/organisations/${id}/members`;

  useEffect(() => {
    if (redirect) {
      navigate(backLink, {
        replace: true,
      });
    }
  }, [backLink, navigate, redirect]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (!organisation) {
    return null;
  }

  return (
    <Page>
      <Titlebar
        backLink={backLink}
        icon={<Icon className="h-5 w-5 text-ui-blue" icon="userAdd" />}
        textPrimary={t('New Member')}
        titleRightContent={
          <Button
            as={Link}
            className="w-full lg:w-fit"
            text={t('Cancel')}
            to={backLink}
            variant="outlineBlack"
          />
        }
      />
      <Page.Content centerContent variant="grey">
        <div className="w-full max-w-[480px]">
          <Card className="px-4 py-5 sm:p-8">
            <CreateMemberForm
              organisationId={organisation.id}
              organisationName={organisation.name}
              organisationType={organisation.type}
              onCancel={() => {
                navigate(backLink);
              }}
              onSuccess={() => {
                setRedirect(true);
              }}
            />
          </Card>
        </div>
      </Page.Content>
    </Page>
  );
};

export default OrganisationNewMember;

import cn from 'classnames';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import Meta from '../../app/Meta';
import Breakpoint from '../../enums/Breakpoint';
import useDropdown from '../../hooks/useDropdown';
import useWindowSize from '../../hooks/useWindowSize';
import { useTitlebarHeight } from '../../providers/TitlebarHeightProvider';
import BadgeIndicator from '../BadgeIndicator';
import Collapse from '../Collapse';
import IconButton from '../IconButton';
import Overlay from '../Overlay';

const variantsMap = {
  default: {
    className: 'lg:px-8',
    verticalSpacingClassName: 'lg:pb-4 lg:pt-5',
  },
  small: {
    className: 'lg:px-6',
    verticalSpacingClassName: 'lg:py-6',
  },
};

const Titlebar = (props) => {
  const {
    backLink,
    generateMeta,
    icon,
    isActive,
    isLoading,
    menu,
    showTitleRightContentInCollapse,
    subContent,
    textPrimary,
    textSecondary,
    titleRightContent,
    variant,
  } = props;

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const { getRootProps, isOpen, toggle } = useDropdown();

  const {
    setSubContentElement,
    setTitlebarElement,
    subContentElementHeight,
    titlebarElementHeight,
  } = useTitlebarHeight();

  const fullTitle = textSecondary
    ? `${textSecondary} - ${textPrimary}`
    : textPrimary;

  const hasMobileDropdownMenu = !!(
    (titleRightContent && showTitleRightContentInCollapse) ||
    menu
  );

  return (
    <>
      {generateMeta && <Meta title={fullTitle} />}
      <div
        className={cn(
          'sticky top-[var(--topbar-height)] z-10 flex w-full flex-col bg-white lg:sticky lg:h-auto',
          subContentElementHeight > 0 && 'border-grey-300 lg:border-b',
          variantsMap[variant]?.className,
        )}
        {...getRootProps()}
      >
        <div
          className={cn(
            variant === 'default' &&
              'border-b-2 border-b-grey-300 lg:border-b-2 lg:border-b-grey-200',
            isOpen
              ? 'border-b-transparent'
              : 'transition-[border-bottom-color] delay-300',
          )}
        >
          <div
            className={cn(
              'flex flex-col gap-4 bg-white px-4 py-2 lg:px-0 lg:transition-none',
              variantsMap[variant]?.verticalSpacingClassName,
            )}
            id="titlebar"
            ref={setTitlebarElement}
          >
            <div className="flex min-h-[32px] w-full flex-1 items-center justify-between gap-4 lg:min-h-[40px] lg:max-w-full">
              <div className="flex w-full flex-1 items-center gap-2">
                {backLink && (
                  <Link to={backLink}>
                    <IconButton
                      icon="arrowBack"
                      iconSize={isDesktop ? 'm' : 's'}
                      size={isDesktop ? 'm' : 's'}
                      variant="ghostGrey"
                    />
                  </Link>
                )}
                {isLoading ? (
                  <div className="w-full leading-none lg:max-w-[500px]">
                    <Skeleton height={isDesktop ? 28 : 24} />
                  </div>
                ) : (
                  <div>
                    {isDesktop ? (
                      <div className="flex flex-1 items-center gap-3 break-words">
                        {icon}
                        <h1 className="break-all text-xl font-semibold text-primary-dark">
                          {fullTitle || <>&nbsp;</>}
                        </h1>
                      </div>
                    ) : (
                      <div className="flex flex-1 items-center gap-2 overflow-hidden">
                        {icon}
                        {textSecondary ? (
                          <div className="flex flex-col gap-1 overflow-hidden text-primary-dark">
                            <h1 className="truncate text-base font-semibold">
                              {textPrimary || <>&nbsp;</>}
                            </h1>
                            <h1 className="truncate text-sm font-normal">
                              {textSecondary}
                            </h1>
                          </div>
                        ) : (
                          <h1 className="break-all text-base font-semibold text-primary-dark">
                            {textPrimary || <>&nbsp;</>}
                          </h1>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {!isDesktop && hasMobileDropdownMenu && (
                  <div className="ml-auto">
                    <BadgeIndicator
                      isVisible={isActive && !isOpen}
                      variant="warning"
                    >
                      <IconButton
                        icon={isOpen ? 'close' : 'moreHoriz'}
                        iconSize="s"
                        size="s"
                        variant="ghostBlack"
                        onClick={toggle}
                      />
                    </BadgeIndicator>
                  </div>
                )}
              </div>
              {(isDesktop || !showTitleRightContentInCollapse) &&
                !isLoading &&
                titleRightContent}
            </div>
            {isDesktop && menu}
          </div>
          {subContent && (
            <div className="z-[-1]" ref={setSubContentElement}>
              {subContent}
            </div>
          )}
        </div>
        {!isDesktop && hasMobileDropdownMenu && (
          <div
            className={cn(
              'fixed z-10 w-full bg-white',
              isOpen && 'border-b-2 border-b-grey-300',
            )}
            style={{
              top: `calc(var(--topbar-height) + ${titlebarElementHeight}px)`,
            }}
          >
            <Collapse className="flex flex-col gap-4" isOpen={isOpen}>
              <div className="flex flex-col gap-4 p-4 pt-1 lg:pt-4">
                {showTitleRightContentInCollapse && titleRightContent}
                {menu}
              </div>
            </Collapse>
          </div>
        )}
        {!isDesktop && isOpen && <Overlay onClick={toggle} />}
      </div>
    </>
  );
};

Titlebar.propTypes = {
  backLink: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  generateMeta: PropTypes.bool,
  icon: PropTypes.node,
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  menu: PropTypes.node,
  subContent: PropTypes.node,
  textPrimary: PropTypes.string,
  textSecondary: PropTypes.string,
  titleRightContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  variant: PropTypes.oneOf(Object.keys(variantsMap)),
  showTitleRightContentInCollapse: PropTypes.bool,
};

Titlebar.defaultProps = {
  backLink: undefined,
  generateMeta: true,
  icon: undefined,
  isActive: false,
  isLoading: false,
  menu: undefined,
  subContent: undefined,
  textPrimary: '',
  textSecondary: '',
  titleRightContent: undefined,
  variant: 'default',
  showTitleRightContentInCollapse: true,
};

export default Titlebar;

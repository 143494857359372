import moment from 'moment';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DatePicker from '../../../components/DatePicker';
import DatePickerVariant from '../../../components/DatePicker/DatePickerVariant';
import LinkButton from '../../../components/LinkButton';
import SelectVariant from '../../../components/Select/SelectVariant';
import useTasksPageSearchParams from '../../../hooks/useTasksPageSearchParams';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import ClientSelect from '../../shared/ClientSelect';
import CustomDateRangeDropdown from '../../shared/CustomDateRangeDropdown/CustomDateRangeDropdown';
import HubSelect from '../../shared/HubSelect';
import ShiftsSelect from '../../shared/ShiftsSelect';
import TasksSortDropdown from '../TasksSortDropdown';
import TasksStatusSelect from '../TasksStatusSelect';
import TasksTypeSelect from '../TasksTypeSelect';

const TasksTableFilters = forwardRef((props, ref) => {
  const { count } = props;

  const {
    clientIdQuery,
    currentSortType,
    endDateQuery,
    hubIdQuery,
    searchByQuery,
    searchParams,
    searchQuery,
    setSearchParams,
    shiftQuery,
    startDateQuery,
    statusQuery,
    typeQuery,
  } = useTasksPageSearchParams();
  const { selectedItems } = useListSelection();
  const datepickerRef = useRef(null);
  const [startDateState, setStartDateState] = useState(startDateQuery);
  const [endDateState, setEndDateState] = useState(endDateQuery);

  const { t } = useTranslation();

  const isSelectionActive = selectedItems.length > 0;

  const onSelectChange = useCallback(
    (queryKey, queryValue) => {
      searchParams.set('page', '1');
      if (!queryValue) {
        searchParams.delete(queryKey);
      } else {
        searchParams.set(queryKey, queryValue);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const customCalendarContainer = (calendarProps) => (
    <CustomDateRangeDropdown
      onTodayClick={() => {
        setStartDateState(moment().toDate());
        datepickerRef?.current.setOpen(false);
      }}
      onTomorrowClick={() => {
        setStartDateState(moment().add(1, 'days').toDate());
        setEndDateState(moment().add(1, 'days').toDate());
        datepickerRef?.current.setOpen(false);
      }}
      onYesterdayClick={() => {
        setStartDateState(moment().subtract(1, 'days').toDate());
        setEndDateState(moment().subtract(1, 'days').toDate());
        datepickerRef?.current.setOpen(false);
      }}
      onThisWeekClick={() => {
        setStartDateState(moment().startOf('week').toDate());
        setEndDateState(moment().endOf('week').toDate());
        datepickerRef?.current.setOpen(false);
      }}
      onLastWeekClick={() => {
        setStartDateState(
          moment().subtract(1, 'week').startOf('week').toDate(),
        );
        setEndDateState(moment().subtract(1, 'week').endOf('week').toDate());
        datepickerRef?.current.setOpen(false);
      }}
      {...calendarProps}
    />
  );

  return (
    <div
      className="flex w-full flex-col flex-wrap justify-between gap-3 lg:flex-row relative px-4 py-2 border-grey-300 rounded-t-md border items-center"
      ref={ref}
    >
      <div className="flex flex-col flex-wrap gap-2 lg:flex-row lg:items-center">
        <div className="flex justify-center items-center">
          <div className="text-xs w-[104px] leading-4" title={count}>
            <div className="text-grey-700 align-middle block">
              {t('Task count')}:{' '}
            </div>
            <div className="font-medium text-primary-dark align-middle">
              {count}
            </div>
          </div>
        </div>
        <DatePicker
          variant={DatePickerVariant.Filter}
          dateFormat="dd.MM.yy"
          isClearable
          name="date"
          placeholderText={t('Start date → End date')}
          required
          selectsRange
          startDate={startDateState}
          endDate={endDateState}
          onChange={([selectedStartDate, selectedEndDate]) => {
            if (selectedStartDate) {
              setStartDateState(selectedStartDate);
              setEndDateState(null);
            }

            if (selectedEndDate) {
              setEndDateState(selectedEndDate);
              datepickerRef.current?.setOpen(false);
            }

            if (!selectedStartDate && !selectedEndDate) {
              setStartDateState(null);
              setEndDateState(null);

              searchParams.set('page', '1');
              searchParams.delete('startDate');
              searchParams.delete('endDate');
              setSearchParams(searchParams);
            }
          }}
          selected={startDateState}
          minWidth={188}
          ref={datepickerRef}
          calendarContainer={customCalendarContainer}
          onCalendarClose={() => {
            if (startDateState && !endDateState) {
              setEndDateState(startDateState);
            }

            if (startDateState) {
              const formattedStartDate =
                startDateState && moment(startDateState).format('YYYY-MM-DD');
              const formattedEndDate =
                endDateState && moment(endDateState).format('YYYY-MM-DD');

              searchParams.set('page', '1');
              searchParams.set('startDate', formattedStartDate);
              searchParams.set(
                'endDate',
                formattedEndDate || formattedStartDate,
              );
              setSearchParams(searchParams);
            }
          }}
        />

        <ShiftsSelect
          className="lg:max-w-[100px]"
          variant={SelectVariant.Filter}
          value={shiftQuery}
          onChange={(value) => {
            onSelectChange('shiftNumber', value);
          }}
        />

        <TasksStatusSelect
          className="lg:max-w-[220px]"
          variant={SelectVariant.Filter}
          value={statusQuery}
          onChange={(value) => {
            onSelectChange('status', value);
          }}
        />

        <TasksTypeSelect
          className="lg:max-w-[220px]"
          variant={SelectVariant.Filter}
          value={typeQuery}
          onChange={(value) => {
            onSelectChange('type', value);
          }}
        />

        <HubSelect
          disabled={isSelectionActive}
          variant={SelectVariant.Filter}
          className="max-w-[220px]"
          value={hubIdQuery}
          onChange={(hubId) => {
            onSelectChange('hubId', hubId);
          }}
        />

        <ClientSelect
          variant={SelectVariant.Filter}
          className="max-w-[220px]"
          value={clientIdQuery}
          onChange={(clientId) => {
            onSelectChange('clientId', clientId);
          }}
        />

        <LinkButton
          disabled={isSelectionActive}
          text={t('Clear All')}
          onClick={() => {
            const newParams = new URLSearchParams({
              page: 1,
              sortType: currentSortType,
              searchBy: searchByQuery,
              search: searchQuery,
            });

            setStartDateState(null);
            setEndDateState(null);

            setSearchParams(newParams);
          }}
        />
      </div>
      <div className="flex flex-col gap-4 lg:flex-row">
        <TasksSortDropdown />
      </div>
    </div>
  );
});

TasksTableFilters.propTypes = {
  count: PropTypes.number,
};

TasksTableFilters.defaultProps = {
  count: 0,
};

export default TasksTableFilters;

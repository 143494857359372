import PropTypes from 'prop-types';

import bad from './assets/bad.png';
import excellent from './assets/excellent.png';
import good from './assets/good.png';
import notSoGood from './assets/notSoGood.png';
import veryGood from './assets/veryGood.png';

const sizesMap = {
  m: {
    className: 'h-14 w-14',
  },
  s: {
    className: 'h-6 w-6',
  },
};

const ratingImages = {
  bad,
  excellent,
  good,
  notSoGood,
  veryGood,
};

const RatingImage = ({ rating, size }) => {
  const src = ratingImages[rating];

  if (!src) {
    return null;
  }

  return <img alt="rating" className={sizesMap[size].className} src={src} />;
};

RatingImage.propTypes = {
  rating: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizesMap)),
};

RatingImage.defaultProps = {
  rating: undefined,
  size: 'm',
};

export default RatingImage;

const KeyCode = {
  ArrowDown: 40,
  ArrowUp: 38,
  BackQuote: 192,
  BackSlash: 220,
  CloseBracket: 221,
  Comma: 188,
  Dash: 189,
  Enter: 13,
  Equals: 187,
  OpenBracket: 219,
  Period: 190,
  Quote: 222,
  Semicolon: 186,
  Slash: 191,
  Tab: 9,
};

export default KeyCode;

import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import InputLayout from '../../../components/InputLayout';
import Modal from '../../../components/Modal';
import Select from '../../../components/Select';
import ScannerType from '../../../enums/ScannerType';

const ScannerSetupModal = ({
  isOpen,
  onClose: propOnClose,
  onSubmit,
  scannerType: currentScannerType,
}) => {
  const { t } = useTranslation();

  const [scannerType, setScannerType] = useState(currentScannerType);
  const options = [
    { label: ScannerType.SymbolLS2208, value: ScannerType.SymbolLS2208 },
    { label: ScannerType.InateckBCST60, value: ScannerType.InateckBCST60 },
  ];

  const onClose = () => {
    propOnClose();
    setScannerType(undefined);
  };

  return (
    <Modal
      body={
        <div className="flex flex-col gap-2" data-test="feed-settings">
          <InputLayout label={t('Scanner')}>
            <Select
              options={options}
              placeholder={t('Select scanner device')}
              value={scannerType || ''}
              onChange={setScannerType}
              required
            />
          </InputLayout>
        </div>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Save and Continue')}
            variant="solidBlue"
            disabled={scannerType === undefined}
            onClick={() => {
              onSubmit(scannerType);
            }}
          />
        </div>
      }
      header={
        <span className="flex items-center gap-2">
          <Icon className="h-6 w-6 text-grey-700" icon="scanning" />
          <span>{t('Scanner Setup')}</span>
        </span>
      }
      isOpen={isOpen}
      onClose={onClose}
      onOpen={() => {
        setScannerType(currentScannerType);
      }}
    />
  );
};

ScannerSetupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  scannerType: PropTypes.oneOf(Object.values(ScannerType)),
};

ScannerSetupModal.defaultProps = {
  scannerType: undefined,
};

export default ScannerSetupModal;

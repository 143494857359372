export default class ObjectHelper {
  static removeNullableFields(object) {
    return Object.keys(object)
      .filter(
        (key) =>
          object[key] !== undefined &&
          object[key] !== null &&
          object[key] !== '',
      )
      .reduce(
        (accumulatedObject, key) => ({
          ...accumulatedObject,
          [key]: object[key],
        }),
        {},
      );
  }
}

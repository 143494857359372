import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  forwardRef,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { mergeRefs } from 'react-merge-refs';
import { usePopper } from 'react-popper';
import { usePrevious } from 'react-use';

import Breakpoint from '../../enums/Breakpoint';
import {
  offsetModifier,
  preventOverflowModifier,
  sameWidth,
} from '../../helpers/popperModifiers';
import useClickOutside from '../../hooks/useClickOutside';
import useDropdown from '../../hooks/useDropdown';
import useWindowSize from '../../hooks/useWindowSize';
import Icon from '../Icon';
import Option, { optionPropType } from './Option';
import SelectContext from './SelectContext';

const negativeYOffsetModifier = {
  name: 'offset',
  options: {
    offset: ({ placement }) => {
      // for dropdowns facing down (start)
      if (placement === 'right-start' || placement === 'left-start') {
        return [-8, 0];
      }
      // for dropdowns facing up (end)
      if (placement === 'right-end' || placement === 'left-end') {
        return [8, 0];
      }

      return [];
    },
  },
};

const CascadingGroup = forwardRef((props, ref) => {
  const { indent, mode, option } = props;

  const {
    activePath,
    getOptionProps,
    inputFilterValue,
    previousInputFilterValue,
    selectId,
    selectedOption,
  } = useContext(SelectContext);
  const activeSubpath = activePath?.slice(0, option.path.length);
  const selectedSubpath = selectedOption?.path?.slice(0, option.path.length);
  const pathString = option.path.join('-');
  const isActive = pathString === activeSubpath?.join('-');
  const isSelected = pathString === selectedSubpath?.join('-');

  const prevIsSelected = usePrevious(isSelected);
  const prevIsActive = usePrevious(isActive);
  const [popperReferenceElement, setPopperReferenceElement] = useState(null);
  const internalRef = useRef();

  const dropdownId = useId();
  const [popperElement, setPopperElement] = useState(null);
  const { attributes, styles, update } = usePopper(
    popperReferenceElement,
    popperElement,
    {
      strategy: 'fixed',
      placement: 'right-start',
      modifiers: [negativeYOffsetModifier],
    },
  );

  useEffect(() => {
    if (internalRef.current && isSelected && option.options) {
      internalRef.current.scrollIntoView({
        block: 'nearest',
      });
    }
  }, [isSelected, option.options, update, pathString, option]);

  const { close, getRootProps, isOpen, open } = useDropdown({
    closeOnOutsideClick: false,
  });

  useEffect(() => {
    // needed for fixing the reposition based on parent dropdown
    if (inputFilterValue !== previousInputFilterValue && update) {
      setTimeout(
        () => {
          update();
        },
        // the furthest path must be updated last
        (option.path?.length || 1) * 10,
      );
    }
  }, [
    close,
    inputFilterValue,
    option.path?.length,
    previousInputFilterValue,
    update,
  ]);

  useEffect(() => {
    if (isActive && !isOpen) {
      open();
    }
  }, [isActive, isOpen, open]);

  useEffect(() => {
    if (
      (isSelected && prevIsSelected !== isSelected) ||
      (isActive && prevIsActive !== isActive)
    ) {
      open();
    }
  }, [close, isActive, isOpen, isSelected, open, prevIsActive, prevIsSelected]);

  useEffect(() => {
    if (activePath !== undefined && !isActive) {
      close();
    }
  }, [activePath, close, isActive]);

  const { onClick, onMouseEnter } = getOptionProps(option);

  const isReferenceHidden =
    attributes.popper?.['data-popper-reference-hidden'] === true;

  return (
    <div
      key={option.groupLabel}
      className="relative"
      ref={mergeRefs([ref, internalRef, getRootProps().ref])}
    >
      <div
        data-option-id={option.id}
        className="relative"
        ref={setPopperReferenceElement}
        onMouseEnter={onMouseEnter}
        tabIndex={-1}
      >
        <div
          className={cn(
            'mx-2 flex flex-1 cursor-pointer flex-row items-center justify-between gap-2 break-words rounded-md px-3 py-2.5 text-sm font-medium',
            isActive && !isSelected && 'bg-grey-200 text-grey-900',
            isSelected && 'bg-ui-blue text-white',
            indent && 'pl-6',
          )}
          onClick={() => {
            if (option.isGroupSelectable) {
              onClick();
            }
          }}
        >
          <span className="flex items-center gap-2">
            {option?.labelIcon && (
              <Icon
                className={cn(
                  'h-4 w-4',
                  option?.labelIconClassname,
                  isSelected && 'text-white',
                )}
                icon={option.labelIcon}
              />
            )}
            <span>{option.groupLabel}</span>
          </span>
          <Icon className={cn('h-3 w-3')} icon="chevronRight" />
        </div>
        {isOpen &&
          createPortal(
            // data-select-group is required for click outside to work
            <div
              data-select-group={selectId}
              data-test="select-cascade-group"
              id={`dropdown-${dropdownId}`}
              ref={setPopperElement}
              style={{
                ...styles.popper,
                maxWidth: popperReferenceElement?.clientWidth,
              }}
              {...attributes.popper}
              className={cn(
                'z-30 flex max-h-[273px] w-full flex-col overflow-auto rounded-md bg-white py-2 shadow-elevation-300',
                isReferenceHidden && 'opacity-0',
              )}
            >
              <Options options={option.options} mode={mode} />
            </div>,
            document.body,
          )}
      </div>
    </div>
  );
});

CascadingGroup.propTypes = {
  option: optionPropType,
  isSelected: PropTypes.bool,
  mode: PropTypes.oneOf(['cascade', 'vertical']),
  indent: PropTypes.bool,
};

CascadingGroup.defaultProps = {
  option: undefined,
  isSelected: false,
  mode: undefined,
  indent: false,
};

const VerticalGroup = (props) => {
  const { indent, mode, option } = props;
  const { activePath, getOptionProps, selectId, selectedOption } =
    useContext(SelectContext);
  const { onClick, onMouseEnter } = getOptionProps(option);

  const isActive =
    option.isGroupSelectable &&
    activePath?.length === 1 &&
    activePath?.toString() === option.path?.toString();

  const isSelected =
    option.isGroupSelectable &&
    selectedOption?.path?.length === 1 &&
    selectedOption?.path?.toString() === option.path?.toString();

  return (
    // data-select-group is required for click outside to work
    <div key={option.groupLabel} data-select-group={selectId}>
      <span
        onMouseEnter={onMouseEnter}
        data-option-id={option.id}
        tabIndex={-1}
        className={cn(
          'mx-2 block px-3 py-2.5 text-sm font-medium rounded-md',
          option.isGroupSelectable && isSelected && 'bg-ui-blue text-white',
          option.isGroupSelectable &&
            isActive &&
            !isSelected &&
            'bg-grey-200 text-grey-900 cursor-pointer',
          !option.isGroupSelectable && !isSelected && 'text-grey-500',
          isSelected && option.isGroupSelectable && 'bg-ui-blue text-white',
          indent && 'pl-6',
        )}
        onClick={() => {
          if (option.isGroupSelectable) {
            onClick();
          }
        }}
      >
        <span className="flex items-center gap-2">
          {option?.labelIcon && (
            <Icon
              className={cn(
                'h-4 w-4',
                option?.labelIconClassname,
                isSelected && 'text-white',
              )}
              icon={option.labelIcon}
            />
          )}
          {option.groupLabel}
        </span>
      </span>

      <Options indent options={option.options} mode={mode} />
    </div>
  );
};

VerticalGroup.propTypes = {
  option: optionPropType,
  mode: PropTypes.oneOf(['cascade', 'vertical']),
  indent: PropTypes.bool,
};

VerticalGroup.defaultProps = {
  option: undefined,
  mode: undefined,
  indent: false,
};

const Group = (props) => {
  const { indent, mode, option } = props;
  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.LG;
  const groupMode = isMobile ? 'vertical' : mode;

  if (groupMode === 'vertical') {
    return <VerticalGroup indent={indent} option={option} mode={groupMode} />;
  }

  return <CascadingGroup indent={indent} option={option} mode={mode} />;
};

Group.propTypes = {
  option: optionPropType,
  mode: PropTypes.oneOf(['cascade', 'vertical']),
  indent: PropTypes.bool,
};

Group.defaultProps = {
  option: undefined,
  mode: 'cascade',
  indent: false,
};

const Options = (props) => {
  const { indent, mode, options } = props;
  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.LG;
  const { activePath, getOptionProps, value } = useContext(SelectContext);

  return options.map((option) => {
    if (option.options) {
      const groupMode = isMobile ? 'vertical' : option.mode || mode;

      return (
        <Group
          indent={indent}
          key={option.groupLabel}
          mode={groupMode}
          option={option}
        />
      );
    }

    const optionProps = getOptionProps(option);
    return (
      <Option
        isActive={option.path.join('-') === activePath?.join('-')}
        isSelected={option.value === value}
        isDisabled={option.isDisabled}
        key={option.value}
        option={option}
        indent={indent}
        onClick={optionProps.onClick}
        onMouseEnter={optionProps.onMouseEnter}
      />
    );
  });
};

Options.propTypes = {
  options: PropTypes.arrayOf(optionPropType),
  mode: PropTypes.oneOf(['cascade', 'vertical']),
  indent: PropTypes.bool,
};

Options.defaultProps = {
  options: [],
  mode: 'cascade',
  indent: false,
};

const Dropdown = ({
  className,
  minWidth,
  mode,
  noOptionsMessage,
  onBottomReached,
  options,
  placement,
  popperReferenceElement,
}) => {
  const { t } = useTranslation();
  const [popperElement, setPopperElement] = useState(null);
  const { close, selectId } = useContext(SelectContext);

  const root = document.getElementById(`dropdown-${selectId}`);

  const { inView, ref: bottomRef } = useInView({
    rootMargin: '0px 0px 10px 0px',
    root,
  });
  const previousInView = usePrevious(inView);

  const modifiers = [offsetModifier, preventOverflowModifier];
  const { width } = useWindowSize();
  const isSmallerScreen = width < Breakpoint.LG;

  if (isSmallerScreen) {
    modifiers.push(sameWidth);
  }

  const { attributes, styles } = usePopper(
    popperReferenceElement,
    popperElement,
    {
      modifiers,
      placement,
    },
  );

  useEffect(() => {
    if (inView && !previousInView) {
      onBottomReached();
    }
  }, [onBottomReached, inView, previousInView]);

  const ref = useRef(null);
  useClickOutside(ref, (e) => {
    const isClickOnInput = e.target.closest(
      `input[data-select-id="${selectId}"]`,
    );
    const isClickOnInnerSelectElements = e.target.closest(
      `[data-select-group="${selectId}"]`,
    );

    if (isClickOnInnerSelectElements || isClickOnInput) {
      return;
    }
    close();
  });

  return (
    <div
      data-select-group={selectId}
      data-test="select-dropdown"
      id={`dropdown-${selectId}`}
      ref={(el) => {
        setPopperElement(el);
        ref.current = el;
      }}
      style={{
        ...styles.popper,
        minWidth: minWidth || undefined,
      }}
      {...attributes.popper}
      className={cn(
        'z-30 flex max-h-[273px] flex-col overflow-auto rounded-md bg-white py-2 shadow-elevation-300',
        className,
      )}
    >
      {options.length ? (
        <Options options={options} mode={mode} />
      ) : (
        <span className="bg-white px-4 py-2.5 text-center text-sm text-grey-700">
          {noOptionsMessage || t('No options')}
        </span>
      )}
      <div ref={bottomRef} />
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  isSameWidth: PropTypes.bool,
  minWidth: PropTypes.number,
  noOptionsMessage: PropTypes.string,
  options: PropTypes.arrayOf(optionPropType),
  placement: PropTypes.string,
  popperReferenceElement: PropTypes.oneOfType([PropTypes.object]),
  onBottomReached: PropTypes.func,
  mode: PropTypes.oneOf(['cascade', 'vertical']),
};

Dropdown.defaultProps = {
  className: '',
  isSameWidth: false,
  minWidth: 240,
  noOptionsMessage: undefined,
  options: [],
  placement: 'bottom-start',
  popperReferenceElement: null,
  onBottomReached: () => {},
  mode: 'cascade',
};

export default Dropdown;

import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import InfiniteScrollSelect from '../../../components/InfiniteScrollSelect';
import SelectVariant from '../../../components/Select/SelectVariant';
import UserRole from '../../../enums/UserRole';
import {
  useIsRole,
  useUser,
  useUserHubId,
} from '../../../providers/UserProvider';

const HubSelect = (props) => {
  const {
    className,
    disabled,
    onChange,
    organisationId: customOrganisationId,
    placeholder,
    value,
    variant,
  } = props;

  const { user } = useUser();
  const { t } = useTranslation();

  const organisationId = customOrganisationId || user?.carrier?.id;
  const isHubManager = useIsRole(UserRole.HubManager);
  const userhubId = useUserHubId();
  const transformOptionFn = useCallback(
    (hub) => ({
      label: `${hub.shortCode} (${hub.name})`,
      value: hub.id,
    }),
    [],
  );

  return (
    <InfiniteScrollSelect
      variant={variant}
      singleItemUrl="/hubs/"
      url={`/carriers/${organisationId}/hubs`}
      className={className}
      disabled={isHubManager || disabled}
      id="hub-select"
      placeholder={placeholder || t('Hub')}
      value={isHubManager ? userhubId : value}
      onChange={onChange}
      transformOptionFn={transformOptionFn}
      data-test="hub-select"
    />
  );
};

HubSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  organisationId: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(SelectVariant)),
};

HubSelect.defaultProps = {
  className: undefined,
  onChange: undefined,
  organisationId: undefined,
  disabled: undefined,
  placeholder: undefined,
  value: undefined,
  variant: undefined,
};

export default HubSelect;

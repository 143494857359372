import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import PrinterStatus from '../../../enums/PrinterStatus';
import ScanLabelSize from '../../../enums/ScanLabelSize';
import ScannerType from '../../../enums/ScannerType';
import BrowserPrintStatus from '../../../lib/browser-print/enums/BrowserPrintStatus';
import { useBrowserPrint } from '../../../lib/browser-print/hooks/useBrowserPrint';
import { usePackageScanningSettings } from '../../../providers/PackageScanningSettingsProvider';
import largeInateckBcst60ScannerConfigTemplate from '../../../zebra-templates/largeInateckBcst60ScannerConfigTemplate';
import largeSymbolLS2208ScannerConfigTemplate from '../../../zebra-templates/largeSymbolLS2208ScannerConfigTemplate';
import {
  smallInateckBcst60ScannerConfigTemplate1,
  smallInateckBcst60ScannerConfigTemplate2,
  smallInateckBcst60ScannerConfigTemplate3,
} from '../../../zebra-templates/smallInateckBcst60ScannerConfigTemplates';
import {
  smallSymbolLS2208ScannerConfigTemplate1,
  smallSymbolLS2208ScannerConfigTemplate2,
  smallSymbolLS2208ScannerConfigTemplate3,
} from '../../../zebra-templates/smallSymbolLS2208ScannerConfigTemplates';
import PrinterInfo from '../PrinterInfo';
import PrinterSetupModal from '../PrinterSetupModal';

const PrintScannerConfiguration = ({ scannerType }) => {
  const { t } = useTranslation();
  const { currentPrinter, currentPrinterStatus, print, status } =
    useBrowserPrint();
  const { labelSize } = usePackageScanningSettings();
  const { setIsPrinterSetupModalOpen } = usePackageScanningSettings();

  const renderPicker = () => {
    if (status === BrowserPrintStatus.Pending) {
      return <Skeleton height={76} />;
    }

    if (status === BrowserPrintStatus.ConnectionError) {
      return (
        <Alert
          message={t(
            'An error occurred while attempting to connect to your Zebra Printer. You may not have Zebra Browser Print installed, or it may not be running. Install Zebra Browser Print, or start the Zebra Browser Print Service, and refresh this page.',
          )}
          variant="error"
        />
      );
    }

    return (
      <div
        className={cn(
          'flex flex-col rounded-md border border-grey-300 p-4 text-sm',
          !currentPrinter && 'border-ui-red',
        )}
      >
        <div className="flex w-full items-center justify-between">
          <div className="pr-2 text-sm">
            <span className="text-grey-700">{t('Selected Printer')}: </span>
            <span className="font-medium text-primary-dark">
              {currentPrinter ? currentPrinter?.name : t('No printer')}
            </span>
          </div>
          <Button
            text={currentPrinter ? t('Change Printer') : t('Printer Setup')}
            variant={currentPrinter ? 'ghostBlue' : 'outlineRed'}
            onClick={() => setIsPrinterSetupModalOpen(true)}
          />
        </div>

        {currentPrinter && (
          <div>
            <div className="flex w-full flex-1 items-center pt-2 text-sm text-grey-700">
              <Icon className="h-4 w-4 text-ui-info-blue" icon="infoFilled" />
              <span className="pl-2">
                {labelSize === ScanLabelSize.Small
                  ? t('Small label (100x50 mm) is selected.')
                  : t('Large label (105x148 mm) is selected.')}
              </span>
            </div>
            <hr className="my-4 border-grey-200" />
            <div className="w-full">
              <PrinterInfo status={currentPrinterStatus} />
            </div>
          </div>
        )}
      </div>
    );
  };

  const isButtonDisabled =
    !currentPrinter ||
    !(
      currentPrinterStatus === PrinterStatus.Ready ||
      currentPrinterStatus === PrinterStatus.Printed
    );

  return (
    <>
      <PrinterSetupModal />
      <div>
        <div className="mb-4">{renderPicker()}</div>
        <div className="flex flex-col items-start">
          {labelSize === ScanLabelSize.Small ? (
            <div className="flex w-full flex-col gap-4 text-sm font-medium text-primary-dark">
              <div className="flex items-center justify-between">
                <span>{t('Configuration label')} 1</span>
                <Button
                  variant="outlineBlack"
                  disabled={isButtonDisabled}
                  text={t('Print Configuration')}
                  onClick={() => {
                    print(
                      scannerType === ScannerType.SymbolLS2208
                        ? smallSymbolLS2208ScannerConfigTemplate1
                        : smallInateckBcst60ScannerConfigTemplate1,
                    );
                  }}
                />
              </div>
              <div className="flex items-center justify-between">
                <span>{t('Configuration label')} 2</span>
                <Button
                  variant="outlineBlack"
                  disabled={isButtonDisabled}
                  text={t('Print Configuration')}
                  onClick={() => {
                    print(
                      scannerType === ScannerType.SymbolLS2208
                        ? smallSymbolLS2208ScannerConfigTemplate2
                        : smallInateckBcst60ScannerConfigTemplate2,
                    );
                  }}
                />
              </div>
              <div className="flex items-center justify-between">
                <span>{t('Configuration label')} 3 </span>
                <Button
                  variant="outlineBlack"
                  disabled={isButtonDisabled}
                  text={t('Print Configuration')}
                  onClick={() => {
                    print(
                      scannerType === ScannerType.SymbolLS2208
                        ? smallSymbolLS2208ScannerConfigTemplate3
                        : smallInateckBcst60ScannerConfigTemplate3,
                    );
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              <Button
                variant="outlineBlack"
                disabled={isButtonDisabled}
                text={t('Print Configuration')}
                onClick={() => {
                  print(
                    scannerType === ScannerType.SymbolLS2208
                      ? largeSymbolLS2208ScannerConfigTemplate
                      : largeInateckBcst60ScannerConfigTemplate,
                  );
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

PrintScannerConfiguration.propTypes = {
  scannerType: PropTypes.oneOf(Object.values(ScannerType)).isRequired,
};

export default PrintScannerConfiguration;

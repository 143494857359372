import { useTranslation } from 'react-i18next';

import DeliveryTaskUnitStatus from '../../../../enums/DeliveryTaskUnitStatus';
import useDeliveryUnitStatusTranslations from '../../../../hooks/useDeliveryUnitStatusTranslations';
import eventType from '../eventType';

const StatusField = (props) => {
  const { event } = props;
  const { t } = useTranslation();

  const deliveryUnitStatusTranslations = useDeliveryUnitStatusTranslations();

  return (
    event.status && (
      <div>
        {t('Status')}:{' '}
        <span className="font-medium">
          {event.status === DeliveryTaskUnitStatus.ReturnToClient
            ? t('Return to Client')
            : deliveryUnitStatusTranslations[event.status]}
          {event.status === DeliveryTaskUnitStatus.Missorted &&
            event.additionalStatusInformation &&
            ` (${event.additionalStatusInformation})`}
        </span>
      </div>
    )
  );
};

StatusField.propTypes = {
  event: eventType.isRequired,
};

export default StatusField;

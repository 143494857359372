import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import Button from '../../components/Button';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import OrganisationsCardList from '../../features/organisations/OrganisationsCardList';
import OrganisationsFilters from '../../features/organisations/OrganisationsFilters';
import OrganisationsTable from '../../features/organisations/OrganisationsTable';
import useWindowSize from '../../hooks/useWindowSize';
import Resource from '../../lib/api/Resource';

const Organisations = () => {
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const [searchParams] = useSearchParams();
  const nameFilter = searchParams.getAll('name');

  const params = useMemo(
    () => ({
      name: nameFilter,
    }),
    [nameFilter],
  );

  return (
    <Page>
      <Titlebar
        menu={<OrganisationsFilters />}
        textPrimary={t('Organisations')}
        titleRightContent={
          <Button
            as={Link}
            className="w-full lg:w-fit"
            icon="domainAdd"
            text={t('New Organisation')}
            to="new"
            variant="solidBlue"
          />
        }
      />
      <Page.Content variant={isDesktop ? 'white' : 'grey'}>
        <Resource params={params} resourceUrl="/carriers">
          {isDesktop ? <OrganisationsTable /> : <OrganisationsCardList />}
        </Resource>
      </Page.Content>
    </Page>
  );
};

export default Organisations;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

const StatusChangeConfirmation = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal
      body={
        <p>
          {t(
            'Note that this action will update Unit status to damaged, and the Unit will be blocked from routing.',
          )}
        </p>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Confirm')}
            variant="solidBlue"
            onClick={onConfirm}
          />
        </div>
      }
      header={<span>{t('Mark Unit As Damaged')}?</span>}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

StatusChangeConfirmation.propTypes = {
  onConfirm: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

StatusChangeConfirmation.defaultProps = {
  onConfirm: () => {},
};

export default StatusChangeConfirmation;

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import OrganisationPlatformFeaturesList from '../../features/shared/OrganisationPlatformFeaturesList';
import useFetch from '../../lib/api/hooks/useFetch';

const OrganisationPlatformFeatures = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { fetch } = useFetch();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isLoading,
  } = useQuery([`/carriers/${id}`], async () => {
    const response = await fetch(`/carriers/${id}`);
    return response.json();
  });

  const organisation = fetchedOrganisation?.data || null;

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        backLink={`/organisations/${id}`}
        textPrimary={t('Platform Features')}
        textSecondary={organisation?.name}
        isLoading={isLoading}
      />
      <Page.Content variant="grey">
        <OrganisationPlatformFeaturesList
          workflow={organisation.workflow}
          organisationId={id}
        />
      </Page.Content>
    </Page>
  );
};

export default OrganisationPlatformFeatures;

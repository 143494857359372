import EnvLabel from '../../../components/EnvLabel';
import Menu from '../Menu';
import Logo from './Logo';

const Sidebar = () => (
  <div className="relative bottom-0 left-0 top-0 z-10 flex print:hidden">
    <div className="sticky top-0 flex max-h-screen flex-1 flex-col gap-4 overflow-hidden border-r border-grey-300 bg-grey-100 p-4 w-[80px]">
      <div className="flex gap-1 flex-col items-center">
        <div className="p-2">
          <Logo />
        </div>
        <EnvLabel env={import.meta.env.VITE_ENV_LABEL} />
      </div>
      <div className="flex flex-1 flex-col py-4">
        <Menu collapsed />
      </div>
    </div>
  </div>
);

export default Sidebar;

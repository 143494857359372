import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Button from '../../components/Button';
import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import UserRole from '../../enums/UserRole';
import Routings from '../../features/routings';
import RoutingConfigurationForm from '../../features/routings/RoutingConfigurationForm';
import useFetch from '../../lib/api/hooks/useFetch';
import Allow from '../../lib/rbac/Allow';
import { useUser } from '../../providers/UserProvider';

const MyOrganisationRoutings = () => {
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const { user } = useUser();
  const { state } = useLocation();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isLoading,
  } = useQuery([`/carriers/${user.carrier.id}`], async () => {
    const response = await fetch(`/carriers/${user.carrier.id}`);
    return response.json();
  });

  const {
    data: fetchedRoutings,
    isError: isRoutingsError,
    isLoading: isRoutingsLoading,
  } = useQuery(
    [`/carriers/${user.carrier.id}/routing-configurations`],
    async () => {
      const response = await fetch(
        `/carriers/${user.carrier.id}/routing-configurations`,
      );
      return response.json();
    },
  );

  const organisation = fetchedOrganisation?.data || null;

  const backLink = useMemo(() => {
    if (state?.backLink) {
      return state?.backLink;
    }
    return '/my-organisation';
  }, [state]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        backLink={backLink}
        textPrimary={t('Routing Configuration')}
        textSecondary={organisation?.name}
        isLoading={isLoading}
        titleRightContent={
          <Allow roles={[UserRole.Admin]}>
            <Button
              disabled={fetchedRoutings?.data?.length === 0}
              className="w-full lg:w-fit"
              icon="plus"
              text={t('New Configuration')}
              variant="solidBlue"
              onClick={() => {
                setIsModalOpen(true);
              }}
            />
            <RoutingConfigurationForm
              isOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
              }}
              organisationId={user.carrier.id}
              allRoutingConfigs={fetchedRoutings?.data}
            />
          </Allow>
        }
      />
      <Page.Content variant="grey">
        <Routings
          isLoading={isRoutingsLoading}
          routings={fetchedRoutings?.data}
          isFetchError={isRoutingsError}
          organisationId={user.carrier.id}
        />
      </Page.Content>
    </Page>
  );
};

export default MyOrganisationRoutings;

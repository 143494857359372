import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const sizesMap = {
  m: {
    buttonClassName: 'w-4 h-4',
    className: 'py-[1px] text-sm px-[11px]',
    iconClassName: 'w-2.5 h-2.5',
  },
  s: {
    buttonClassName: 'w-3 h-3',
    className: 'py-[1px] text-sm px-[7px]',
    iconClassName: 'w-2.5 h-2.5',
  },
  xs: {
    buttonClassName: 'w-3 h-3',
    className: 'text-xs px-[7px]',
    iconClassName: 'w-2 h-2',
  },
};

export const labelVariantsMap = {
  blue: 'bg-ui-blue-light text-ui-blue border-ui-blue-light',
  green: 'bg-ui-green-dark-light text-ui-green-dark border-ui-green-dark-light',
  grey: 'bg-grey-200 text-grey-700 border-transparent',
  greyOutline: 'bg-grey-200 text-grey-900 border-grey-500',
  lightBlue:
    'bg-ui-info-blue-light text-ui-info-blue border-ui-info-blue-light',
  orange:
    'bg-ui-warning-orange-light text-ui-warning-orange border-ui-warning-orange-light',
  primary: 'bg-primary-dark text-white border-transparent',
  purple: 'bg-ui-purple-light text-ui-purple border-ui-purple-light',
  watermelon: 'bg-ui-red-light text-ui-red border-ui-red-light',
  white: 'bg-white text-grey-900 border-transparent',
};

const Label = (props) => {
  const { className, onDismissClick, size, text, variant } = props;

  return (
    <span
      className={cn(
        'inline-flex w-fit items-center gap-1.5 rounded-md border font-medium',
        sizesMap[size].className,
        labelVariantsMap[variant],
        className,
      )}
    >
      {text}
      {onDismissClick && (
        <button
          className={cn(
            sizesMap[size].buttonClassName,
            'flex items-center justify-center',
          )}
          type="button"
          onClick={onDismissClick}
        >
          <Icon className={sizesMap[size].iconClassName} icon="close" />
        </button>
      )}
    </span>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  onDismissClick: PropTypes.func,
  size: PropTypes.oneOf(Object.keys(sizesMap)),
  text: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(labelVariantsMap)),
};

Label.defaultProps = {
  className: '',
  onDismissClick: undefined,
  size: 'm',
  text: '',
  variant: 'primary',
};

export default Label;

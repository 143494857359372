import eventType from '../eventType';
import NoteField from './NoteField';
import StatusField from './StatusField';
import UserField from './UserField';

const FixedEvent = (props) => {
  const { event } = props;
  return (
    <>
      <StatusField event={event} />
      {event.changeNote && <NoteField note={event.changeNote} />}
      <UserField event={event} />
    </>
  );
};

FixedEvent.propTypes = {
  event: eventType.isRequired,
};

export default FixedEvent;

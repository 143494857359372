import PropTypes from 'prop-types';

import Avatar from '../../../components/Avatar';
import Label from '../../../components/Label';
import ListChoice from '../../../components/ListChoice';

const DriverItem = ({ driver, isSelected, onItemClick }) => {
  const driverInitials =
    driver.firstName.substring(0, 1) + driver.lastName.substring(0, 1);

  return (
    <ListChoice
      data-test={`assign-driver-list-item-${driver.id}`}
      isSelected={isSelected}
      key={driver.id}
      onClick={() => onItemClick(driver)}
    >
      <div className="flex items-center gap-2">
        <Avatar text={driverInitials} />
        <span className="flex-1 break-words text-sm font-medium">
          {driver?.firstName} {driver?.lastName}
        </span>
        {driver?.subcarrier?.shortCode && (
          <Label
            size="xs"
            text={driver?.subcarrier?.shortCode}
            variant="primary"
          />
        )}
      </div>
    </ListChoice>
  );
};

DriverItem.propTypes = {
  driver: PropTypes.shape({
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    subcarrier: PropTypes.shape({
      shortCode: PropTypes.string,
    }),
  }).isRequired,
  isSelected: PropTypes.bool,
  onItemClick: PropTypes.func,
};

DriverItem.defaultProps = {
  isSelected: false,
  onItemClick: () => {},
};

export default DriverItem;

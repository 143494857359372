import { useCallback, useEffect } from 'react';

import LocalStorage from '../../enums/LocalStorage';
import LocalStorageHelper from '../../helpers/LocalStorageHelper';
import useConfirmTabClose from '../../hooks/useConfirmTabClose';
import useFetch from '../../lib/api/hooks/useFetch';

const BrowserClosePrompt = () => {
  const { fetchRequestsActive } = useFetch();

  useEffect(() => {
    try {
      // initial setup for this variable. always set SkipBeforeUnload to false on load for useConfirmTabClose to work properly
      LocalStorageHelper.setItem(LocalStorage.SkipBeforeUnload, false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, []);

  // used for applying app updates (serviceWorkerRegistration.js),
  // where the user explicitly allows the refresh to happen
  const getShouldIgnoreBeforeUnload = useCallback(() => {
    try {
      return (
        JSON.parse(
          LocalStorageHelper.getItem(LocalStorage.SkipBeforeUnload),
        ) === true
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  }, []);

  const showPrompt = fetchRequestsActive > 0 && !getShouldIgnoreBeforeUnload();

  useConfirmTabClose(showPrompt);
  return null;
};

export default BrowserClosePrompt;

import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Drawer from '../../../components/Drawer';

const isShiftActive = (routingTime) => {
  const currentTime = moment();
  const shiftRoutingTime = moment(routingTime, 'HH:mm');

  return currentTime.isBefore(shiftRoutingTime);
};

const RoutingsDrawer = ({ isOpen, onClose, shifts }) => {
  const { t } = useTranslation();

  return (
    <Drawer
      data-test="routings-modal"
      body={
        <div className="flex flex-col gap-4 divide-y divide-grey-200">
          {shifts.map((shift) => {
            const isActive = isShiftActive(shift.routingTime);
            return (
              <div
                key={shift.id}
                className={cn(
                  'flex items-center justify-between pt-4 text-sm text-primary-dark first:pt-0',
                  !isActive && 'opacity-40',
                )}
                data-test={
                  isActive
                    ? `active-shift-${shift.id}`
                    : `inactive-shift-${shift.id}`
                }
              >
                <strong>{shift.routingTime}</strong>

                <div>
                  {shift.hub ? t('Hub Shift') : t('Carrier Shift')}{' '}
                  {shift.number} ({shift.startTime} - {shift.endTime})
                </div>
              </div>
            );
          })}
        </div>
      }
      header={<div>{t("Today's Routings")}</div>}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

RoutingsDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
      routingTime: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      deliveryWindowStart: PropTypes.string,
      deliveryWindowEnd: PropTypes.string,
    }),
  ),
};

RoutingsDrawer.defaultProps = {
  shifts: [],
};

export default RoutingsDrawer;

import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import IconButton from '../../../components/IconButton';
import Input from '../../../components/Input';
import InputLayout from '../../../components/InputLayout';
import useFormInputValidationRules from '../../../hooks/useFormInputValidationRules';

const Field = ({ id, index, name, onRemoveClick }) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
  } = useFormContext();

  const error = errors[name] && errors[name][index]?.reference?.message;

  const rules = useFormInputValidationRules({
    required: true,
  });

  return (
    <InputLayout key={id} error={error} label={t('Unit Code')} required>
      <div className="flex w-full items-center">
        <Input
          hasError={!!error}
          isFullWidth
          placeholder={t('Enter Unit code')}
          type="text"
          required
          {...register(`${name}.${index}.reference`, rules())}
        />
        {index !== 0 && (
          <IconButton
            className="ml-2"
            size="s"
            iconSize="s"
            icon="close"
            variant="ghostGrey"
            onClick={onRemoveClick}
          />
        )}
      </div>
    </InputLayout>
  );
};

Field.propTypes = {
  onRemoveClick: PropTypes.func,
  id: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
};

Field.defaultProps = {
  onRemoveClick: undefined,
  id: undefined,
  index: undefined,
  name: undefined,
};

export default Field;

import { useTranslation } from 'react-i18next';

import LinkButton from '../../../../components/LinkButton';
import UnitEventInitiator from '../../../../enums/UnitEventInitiator';
import UserPopover from '../../../shared/UserPopover';
import eventType from '../eventType';

const UserField = (props) => {
  const { event } = props;
  const { t } = useTranslation();

  if (event.initiator === UnitEventInitiator.User) {
    if (event.user.deletedAt) {
      return <div className="text-grey-700 text-sm">{t('Deleted user')}</div>;
    }

    return (
      <div className="relative">
        <UserPopover placement="bottom-start" user={event.user}>
          <LinkButton
            size="s"
            text={`${event.user.firstName} ${event.user.lastName}`}
          />
        </UserPopover>
      </div>
    );
  }

  if (event.initiator === UnitEventInitiator.System) {
    return <div className="text-grey-700 text-sm">{t('System automatic')}</div>;
  }

  if (event.initiator === UnitEventInitiator.EndCustomer) {
    return <div className="text-grey-700 text-sm">{t('Customer')}</div>;
  }

  if (event.initiator === UnitEventInitiator.Client) {
    return <div className="text-grey-700 text-sm">{t('Client')}</div>;
  }

  return null;
};

UserField.propTypes = {
  event: eventType.isRequired,
};

export default UserField;

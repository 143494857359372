/* eslint-disable jsx-a11y/no-autofocus */
import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const Textarea = forwardRef((props, ref) => {
  const {
    autoFocus,
    className,
    'data-test': dataTest,
    disabled,
    hasError,
    id,
    maxLength,
    name,
    onBlur,
    onChange,
    placeholder,
    readOnly,
    value,
  } = props;

  return (
    <div className="flex relative flex-col gap-2">
      <textarea
        id={id}
        autoFocus={autoFocus}
        className={cn(
          'min-h-[4em] w-full rounded-md border border-grey-500 px-3 py-2.5 text-sm text-primary-dark outline-none placeholder:text-grey-700 read-only:bg-grey-200 focus:border-primary-yellow disabled:bg-grey-200 disabled:opacity-40',
          !disabled && hasError && 'border-ui-red',
          className,
        )}
        data-test={dataTest}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        readOnly={readOnly}
        rows={3}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
      />
    </div>
  );
});

Textarea.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  'data-test': PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  maxLength: PropTypes.number,
  hasError: PropTypes.bool,
};

Textarea.defaultProps = {
  autoFocus: false,
  className: undefined,
  'data-test': undefined,
  disabled: false,
  readOnly: false,
  onChange: () => {},
  onBlur: () => {},
  placeholder: '',
  value: undefined,
  id: undefined,
  name: undefined,
  maxLength: 1000,
  hasError: false,
};

export default Textarea;

import onscan from 'onscan.js/onscan';
import { useCallback, useEffect } from 'react';

import KeyCode from '../enums/KeyCode';

// used for Cypress tests
if (import.meta.env.DEV) {
  window.onScan = onscan;
}

const allowedSpecialCharacters = [
  KeyCode.Comma,
  KeyCode.Dash,
  KeyCode.Period,
  KeyCode.Quote,
];

const useOnScan = (onScan) => {
  const onScanError = useCallback((e) => {
    if (e?.detail?.scanCode?.length > 6) {
      // eslint-disable-next-line no-console
      console.error(e?.detail);
    }
  }, []);

  useEffect(() => {
    onscan.attachTo(document, {
      ignoreIfFocusOn: 'input',
      keyCodeMapper: (event) => {
        const isCapsLockEnabled = event.getModifierState('CapsLock');
        const isShiftPressed = event.shiftKey;
        // disable rule because its part of library internals
        // eslint-disable-next-line no-underscore-dangle
        const normalizedKeyNum = onscan._getNormalizedKeyNum(event);
        if (allowedSpecialCharacters.includes(normalizedKeyNum)) {
          return event.key;
        }

        // Fall back to the default decoder in all other cases
        const char = onscan.decodeKeyEvent(event);
        if (!char) {
          return null;
        }
        if (isCapsLockEnabled && isShiftPressed) {
          return char.toUpperCase();
        }
        if (isCapsLockEnabled) {
          return char.toLowerCase();
        }
        return char;
      },
      minLength: 7,
      suffixKeyCodes: [KeyCode.Enter, KeyCode.Tab],
    });
    document.addEventListener('scan', onScan);
    document.addEventListener('scanError', onScanError);
    return () => {
      onscan.detachFrom(document);
      document.removeEventListener('scan', onScan);
      document.removeEventListener('scanError', onScanError);
    };
  }, [onScan, onScanError]);
};

export default useOnScan;

import eventType from '../eventType';
import UserField from './UserField';

const WrongDriverScanEvent = (props) => {
  const { event } = props;
  return <UserField event={event} />;
};

WrongDriverScanEvent.propTypes = {
  event: eventType.isRequired,
};

export default WrongDriverScanEvent;

import PropTypes from 'prop-types';

const ListCount = (props) => {
  const { children } = props;
  return (
    <div className="flex min-h-[40px] items-center top-0 bg-white sticky">
      <span className="px-2 text-sm text-grey-700">{children}</span>
    </div>
  );
};

ListCount.propTypes = {
  children: PropTypes.node,
};

ListCount.defaultProps = {
  children: undefined,
};

export default ListCount;

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSearchParams } from 'react-router-dom';

import FadingOverlay from '../../../components/FadingOverlay';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import TourStop from './index';

const TourStops = (props) => {
  const { tour } = props;

  const { t } = useTranslation();

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();

  const queryKey = `/tours/${tour.id}/stops`;
  const { data, isLoading } = useQuery(
    [queryKey],
    async () => {
      const response = await fetch(queryKey, {
        method: 'GET',
      });
      return response.json();
    },
    {
      onError: (error) => toastFetchError(error),
    },
  );

  const tourStops = data?.data || [];

  const [searchParams] = useSearchParams();

  return (
    <div className="lg:px-4">
      {isLoading ? (
        <FadingOverlay>
          <div className="flex flex-col gap-4">
            <Skeleton height={120} />
            <Skeleton height={120} />
            <Skeleton height={120} />
          </div>
        </FadingOverlay>
      ) : (
        <>
          {tourStops.length === 0 && (
            <div className="mt-4 flex items-center rounded-md bg-grey-100 p-4 text-sm">
              {t('No recorded stops for this Tour.')}
            </div>
          )}
          {tourStops.length > 0 &&
            tourStops.map((stop) => (
              <TourStop
                tour={tour}
                stop={stop}
                highlight={searchParams.get('stopId') === stop.id}
                key={stop.id}
                status={stop.status}
                queryKey={queryKey}
              />
            ))}
        </>
      )}
    </div>
  );
};

TourStops.propTypes = {
  tour: PropTypes.shape({
    id: PropTypes.string,
    vehicle: PropTypes.string,
    date: PropTypes.string,
    shift: PropTypes.number,
  }).isRequired,
};

export default TourStops;

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import FormAddressInput from '../../../components/FormAddressInput';
import FormCheckbox from '../../../components/FormCheckbox';
import FormCheckboxGroup from '../../../components/FormCheckboxGroup';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import HubType from '../../../enums/HubType';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useUser } from '../../../providers/UserProvider';
import ShortcodeInput from '../../shared/ShortcodeInput';
import ImportDeliveryFields from './ImportDeliveryFields';
import ShiftsFields from './ShiftsFields';

const HubForm = (props) => {
  const {
    address,
    clientId,
    email,
    isEditMode,
    isSubmitting,
    name,
    onCancel,
    onIsDirtyChange,
    onSubmit,
    postCodes,
    shortCode,
    type,
  } = props;

  const { t } = useTranslation();
  const { isGroceries, user } = useUser();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();

  const organisations = [
    {
      label: user.carrier.name,
      value: user.carrier.id,
    },
  ];

  const methods = useForm({
    defaultValues: {
      address,
      clientId,
      email,
      isCustomerOwnedChecked: !!clientId,
      name,
      organisationId: user.carrier.id,
      postCodes,
      shortCode,
      type,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    formState: { isDirty },
    handleSubmit,
    watch,
  } = methods;

  const isCustomerOwnedChecked = watch('isCustomerOwnedChecked');

  const { data: allClientsQueryData } = useQuery(
    ['/clients'],
    async () => {
      const response = await fetch('/clients', {
        method: 'GET',
      });
      return response.json();
    },
    {
      enabled: isCustomerOwnedChecked,
      onError: (error) => toastFetchError(error),
    },
  );

  const clients = useMemo(
    () =>
      allClientsQueryData?.data?.map((client) => ({
        label: `${client.name} (${client.shortCode})`,
        value: client.id,
      })),
    [allClientsQueryData?.data],
  );

  const onFormSubmit = (values) => {
    let data = values;

    if (isGroceries) {
      data = { ...data, postCodes: [] };
    }

    if (!isGroceries) {
      data = { ...data, email: null };
    }

    if (!isCustomerOwnedChecked) {
      data = {
        ...data,
        clientId: null,
      };
    }

    onSubmit(data);
  };

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-5"
        noValidate
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <div className="flex flex-col gap-8">
          <div>
            <h3 className="grey-200 mb-5 text-base font-semibold">
              {t('Basic Information')}
            </h3>
            <div className="flex flex-col gap-4">
              <FormInput id="name" label={t('Name')} name="name" required />
              <FormAddressInput
                disabled={isEditMode}
                id="address"
                label={t('Location')}
                name="address"
                required={!isEditMode}
              />
            </div>
          </div>
          <div>
            <h3 className="grey-200 mb-5 text-base font-semibold">
              {t('Organisation')}
            </h3>
            <div className="flex flex-col gap-4">
              <FormSelect
                readOnly
                id="organisation-select"
                label={t('Organisation')}
                name="organisationId"
                options={organisations}
                required
              />
              <FormCheckbox
                label={t('Client owned')}
                name="isCustomerOwnedChecked"
              />
              {isCustomerOwnedChecked && (
                <FormSelect
                  label={t('Client')}
                  name="clientId"
                  options={clients || []}
                  placeholder={t('Select Client')}
                  required
                />
              )}
            </div>
          </div>
          <div>
            <h3 className="grey-200 mb-5 text-base font-semibold">
              {t('Setup')}
            </h3>
            <div className="flex flex-col gap-4">
              <ShortcodeInput
                disabled={isEditMode}
                disclaimerMessage={t(
                  'Shortcode must be exactly 3 characters long.',
                )}
                id="shortCode"
                label={t('Shortcode')}
                maxLength={{
                  message: t('Shortcode must be exactly 3 characters long'),
                  value: 3,
                }}
                minLength={{
                  message: t('Shortcode must be exactly 3 characters long'),
                  value: 3,
                }}
                name="shortCode"
                pattern={{
                  message: t('Shortcode can only contain uppercase letters'),
                  value: /^[A-Z]+$/,
                }}
                placeholder={t('Enter shortcode')}
                required={!isEditMode}
              />
              <FormCheckboxGroup
                id="type"
                label={t('Type')}
                name="type"
                options={[
                  {
                    label: t('Central'),
                    value: HubType.Central,
                  },
                  {
                    label: t('Local'),
                    value: HubType.Local,
                  },
                ]}
                required
              />
              <hr className="text-grey-200" />
              <ImportDeliveryFields isGroceries={isGroceries} />
            </div>
          </div>
          {!isGroceries && <ShiftsFields />}
        </div>

        <hr className="text-grey-200" />
        <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
          <Button
            className="order-last sm:order-none sm:flex-1"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onCancel}
          />
          <Button
            className="sm:flex-1"
            data-test="modal-button-action"
            disabled={isSubmitting}
            text={isEditMode ? t('Save Changes') : t('Create Hub')}
            type="submit"
            variant="solidBlue"
          />
        </div>
      </form>
    </FormProvider>
  );
};

HubForm.propTypes = {
  address: PropTypes.string,
  clientId: PropTypes.string,
  email: PropTypes.string,
  isEditMode: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  name: PropTypes.string,
  onCancel: PropTypes.func,
  onIsDirtyChange: PropTypes.func,
  onSubmit: PropTypes.func,
  postCodes: PropTypes.arrayOf(PropTypes.string),
  shortCode: PropTypes.string,
  type: PropTypes.arrayOf(PropTypes.string),
  shifts: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
      routingTime: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      deliveryWindowStart: PropTypes.string,
      deliveryWindowEnd: PropTypes.string,
    }),
  ),
};

HubForm.defaultProps = {
  address: '',
  clientId: '',
  email: '',
  isEditMode: false,
  isSubmitting: false,
  name: '',
  onCancel: () => {},
  onIsDirtyChange: () => {},
  onSubmit: () => {},
  postCodes: [],
  shortCode: '',
  type: [],
  shifts: [],
};

export default HubForm;

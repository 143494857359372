import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Select from '../../../components/Select';
import SelectVariant from '../../../components/Select/SelectVariant';
import TaskStatus from '../../../enums/TaskStatus';
import useTaskStatusTranslations from '../../../hooks/useTaskStatusTranslations';

const TasksStatusSelect = (props) => {
  const { className, onChange, value, variant, ...rest } = props;
  const { t } = useTranslation();
  const statusTranslations = useTaskStatusTranslations();

  const taskStatusOptions = [
    {
      label: statusTranslations[TaskStatus.Opened],
      value: TaskStatus.Opened,
    },
    {
      label: statusTranslations[TaskStatus.Active],
      value: TaskStatus.Active,
    },
    { label: statusTranslations[TaskStatus.Closed], value: TaskStatus.Closed },
  ];

  return (
    <Select
      variant={variant}
      className={className}
      data-test="task-status-select"
      id="task-status-select"
      options={taskStatusOptions}
      placeholder={t('Task status')}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

TasksStatusSelect.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(SelectVariant)),
};

TasksStatusSelect.defaultProps = {
  className: undefined,
  value: undefined,
  onChange: undefined,
  variant: SelectVariant.Default,
};

export default TasksStatusSelect;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const RadioButton = forwardRef((props, ref) => {
  const {
    checked,
    'data-test': dataTest,
    disabled,
    id,
    label,
    name,
    onChange,
    value,
  } = props;

  return (
    <label
      className={cn(
        'flex flex-1 cursor-pointer items-center gap-2 py-2.5',
        disabled && 'pointer-events-none',
      )}
      htmlFor={id}
    >
      <input
        checked={checked}
        className="h-5 w-5 cursor-pointer appearance-none rounded-full border border-grey-500 bg-white checked:border-[6px] checked:border-transparent checked:border-ui-blue hover:border-grey-700 checked:hover:border-ui-blue disabled:bg-grey-300 disabled:opacity-40 flex-shrink-0"
        data-test={dataTest}
        disabled={disabled}
        id={id}
        name={name}
        ref={ref}
        type="radio"
        value={value}
        onChange={() => {
          onChange(value);
        }}
      />
      {label && (
        <div
          className={cn(
            'text-dark text-sm font-medium',
            disabled && 'opacity-40',
          )}
        >
          {label}
        </div>
      )}
    </label>
  );
});

RadioButton.propTypes = {
  checked: PropTypes.bool,
  'data-test': PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

RadioButton.defaultProps = {
  checked: false,
  'data-test': undefined,
  disabled: false,
  id: undefined,
  label: undefined,
  name: undefined,
  onChange: () => {},
  value: undefined,
};

export default RadioButton;

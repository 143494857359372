import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const variantsMap = {
  default: {
    className: 'text-grey-900 bg-grey-100',
    icon: 'infoFilled',
    iconClassName: 'text-grey-700',
  },
  error: {
    className: 'bg-ui-red-light',
    icon: 'report',
    iconClassName: 'text-ui-red',
  },
  errorLight: {
    className: 'bg-white text-ui-red',
    icon: 'report',
  },
  info: {
    className: 'bg-ui-info-blue-light',
    icon: 'infoFilled',
    iconClassName: 'text-ui-info-blue',
  },
  loading: {
    className: 'text-grey-900 bg-grey-100',
    icon: 'loading',
    iconClassName: 'text-grey-700 animate-spin',
  },
  loadingYellow: {
    className: 'bg-ui-yellow-light',
    icon: 'loading',
    iconClassName: 'animate-spin text-ui-notification-yellow',
  },
  success: {
    className: 'bg-ui-green-dark-light',
    icon: 'checkmarkCircleFilled',
    iconClassName: 'text-ui-green-dark',
  },
  warningOrange: {
    className: 'bg-ui-warning-orange-light',
    icon: 'warning',
    iconClassName: 'text-ui-warning-orange',
  },
  warningYellow: {
    className: 'bg-ui-yellow-light',
    icon: 'warning',
    iconClassName: 'text-ui-notification-yellow',
  },
};

const Alert = (props) => {
  const {
    alignCenter,
    className: propClassName,
    'data-test': dataTest,
    fontWeight,
    message,
    variant,
    ...rest
  } = props;

  const { className, icon, iconClassName } = variantsMap[variant] || [];

  return (
    <div
      className={cn(
        'flex flex-1 gap-2 rounded-md px-4 py-2.5 text-sm',
        className,
        propClassName,
        alignCenter && 'items-center',
        fontWeight === 'medium' && 'font-medium',
        fontWeight === 'normal' && 'font-normal',
      )}
      data-test={dataTest}
      {...rest}
    >
      {icon && <Icon className={cn('h-4 w-4', iconClassName)} icon={icon} />}
      <div className="text-sm">{message}</div>
    </div>
  );
};

Alert.propTypes = {
  className: PropTypes.string,
  'data-test': PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.oneOf(Object.keys(variantsMap)),
  alignCenter: PropTypes.bool,
  fontWeight: PropTypes.oneOf(['normal', 'medium']),
};

Alert.defaultProps = {
  className: '',
  'data-test': undefined,
  message: '',
  variant: 'default',
  fontWeight: 'medium',
  alignCenter: true,
};

export default Alert;

import * as Sentry from '@sentry/react';
import Modal from 'react-modal';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import createRoutes from '../routes/createRoutes';

Modal.setAppElement('#root');

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(createRoutes());

const App = () => <RouterProvider router={router} />;

export default App;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import HubType from '../../../enums/HubType';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';

const NonWorkingDatesModal = ({
  hubId,
  hubType,
  isOpen,
  nonWorkingDates,
  onClose,
}) => {
  const { t } = useTranslation();
  const { toastFetchError } = useToastFetchError();
  const { fetch } = useFetch();
  const { toastSuccess } = useCustomToast();
  const queryClient = useQueryClient();
  const [newDate, setNewDate] = useState(null);
  const methods = useForm({
    defaultValues: {
      nonWorkingDates,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    formState: { isDirty },
    reset,
    setValue,
    watch,
  } = methods;

  useEffect(() => {
    setValue('nonWorkingDates', nonWorkingDates);
  }, [nonWorkingDates, setValue]);

  const currentNonWorkingDates = watch('nonWorkingDates');

  const onModalClose = () => {
    reset();
    onClose();
  };

  const mutation = useMutation(
    () => {
      const formattedNonWorkingDates = methods
        .getValues()
        ?.nonWorkingDates.map((nonWorkingDate) =>
          moment(nonWorkingDate).format('YYYY-MM-DD'),
        );

      return fetch(`/hubs/${hubId}`, {
        body: {
          nonWorkingDates: formattedNonWorkingDates,
          type: hubType,
        },
        method: 'PATCH',
      });
    },
    {
      onError: (error) => {
        toastFetchError(error);
      },
      onSuccess: () => {
        onModalClose();
        toastSuccess(t('Hub data updated.'));

        queryClient.invalidateQueries({
          queryKey: [`/hubs/${hubId}`],
        });
      },
    },
  );

  const addNewDate = () => {
    if (!newDate) {
      return;
    }

    const isDateAlreadyInList = currentNonWorkingDates.find((nonWorkingDate) =>
      moment(nonWorkingDate).isSame(newDate, 'date'),
    );

    if (isDateAlreadyInList) {
      setNewDate(null);
      return;
    }

    const newFieldValue = [...currentNonWorkingDates, newDate];
    setValue('nonWorkingDates', newFieldValue, {
      shouldDirty: true,
      shouldValidate: true,
    });

    setNewDate(null);
  };

  const removeDate = (date) => {
    const momentDateForRemoval = moment(date);
    const modifiedArray = currentNonWorkingDates.filter(
      (nonWorkingDate) => !momentDateForRemoval.isSame(nonWorkingDate, 'date'),
    );
    setValue('nonWorkingDates', modifiedArray, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <Modal
      body={
        <FormProvider {...methods}>
          <div>
            <div className="mb-4">
              <span className="block text-xs font-medium text-primary-dark">
                {t('Add Non-working Date')}
              </span>
            </div>
            <div className="flex" data-test="date-picker-wrapper">
              <DatePicker
                className="flex-1"
                dateFormat="dd.MM.yyyy"
                isClearable
                minDate={new Date()}
                name="newDate"
                placeholderText={t('Select date')}
                required
                value={newDate}
                onChange={(date) => setNewDate(date)}
              />
              <Button
                className="ml-4"
                data-test="add-date-button"
                disabled={!newDate}
                icon="plus"
                text={t('Add')}
                variant="solidBlack"
                onClick={addNewDate}
              />
            </div>
            <hr className="mb-5 mt-6 border-grey-200" />
            {currentNonWorkingDates.length < 1 ? (
              <div className="text-center text-sm text-grey-700">
                <span>{t('No non-working dates added yet.')}</span>
              </div>
            ) : (
              <div>
                <div className="mb-4">
                  <span className="text-sm text-grey-700">
                    {t('Current Non-working Dates:')}
                  </span>
                </div>
                <div className="flex flex-col gap-2">
                  {currentNonWorkingDates.map((date) => {
                    const momentDate = moment(date);
                    const dateIso8601 = momentDate.format('YYYY-MM-DD');
                    return (
                      <span
                        className="flex items-center"
                        data-test={`non-working-date-row-${dateIso8601}`}
                        key={dateIso8601}
                      >
                        <span className="flex-1 text-sm font-medium text-primary-dark">
                          {`${momentDate
                            .format('dddd')
                            .substring(0, 3)} ${momentDate.format(
                            'DD.MM.YYYY',
                          )}`}
                        </span>
                        <Button
                          data-test="non-working-date-remove-button"
                          size="s"
                          text={t('Remove')}
                          variant="ghostBlue"
                          onClick={() => {
                            removeDate(date);
                          }}
                        />
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </FormProvider>
      }
      data-test="non-working-dates-modal"
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onModalClose}
          />
          <Button
            data-test="submit-non-working-dates"
            disabled={mutation.isLoading || !isDirty}
            text={t('Save')}
            variant="solidBlue"
            onClick={mutation.mutate}
          />
        </div>
      }
      header={
        <span className="flex items-center gap-2">
          <Icon className="h-6 w-6 text-grey-700" icon="date" />
          <span className="text-primary-dark">{t('Non-working Dates')}</span>
        </span>
      }
      isOpen={isOpen}
      onClose={onModalClose}
    />
  );
};

NonWorkingDatesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  nonWorkingDates: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  hubId: PropTypes.string,
  hubType: PropTypes.arrayOf(PropTypes.oneOf(Object.values(HubType))),
};

NonWorkingDatesModal.defaultProps = {
  nonWorkingDates: [],
  hubId: undefined,
  hubType: [],
};

export default NonWorkingDatesModal;

import PropTypes from 'prop-types';

import ScanTitlebar from '../ScanTitlebar';

const ScanningPageLayout = (props) => {
  const { children } = props;

  return (
    <div className="flex flex-1 flex-col bg-grey-100">
      <ScanTitlebar />
      {children}
    </div>
  );
};

ScanningPageLayout.propTypes = {
  children: PropTypes.node,
};

ScanningPageLayout.defaultProps = {
  children: undefined,
};

export default ScanningPageLayout;

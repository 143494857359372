import cn from 'classnames';
import PropTypes from 'prop-types';

const variantsMap = {
  error: 'bg-ui-red',
  warning: 'bg-ui-notification-yellow',
};
const BadgeIndicator = (props) => {
  const { children, className, isVisible, variant } = props;
  return (
    <div className={cn('relative inline-block', className)}>
      {children}
      {isVisible && (
        <div
          className={cn(
            'pointer-events-none absolute right-1.5 top-1.5 h-1.5 w-1.5 rounded-full',
            variantsMap[variant],
          )}
        />
      )}
    </div>
  );
};

BadgeIndicator.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  variant: PropTypes.oneOf(Object.keys(variantsMap)),
};

BadgeIndicator.defaultProps = {
  children: undefined,
  className: undefined,
  isVisible: true,
  variant: 'error',
};

export default BadgeIndicator;

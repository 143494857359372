const largeInateckBcst60ScannerConfigTemplate = `
^XA

^BY2,3,104^FT50,250^BCN,,N,N
^FDSTARTCOMMAND^FS
^FT20,280^A0,28,28^FB400,1,0,C
^FDEnter Setup\\&^FS

^BY2,3,104^FT450,250^BCN,,N,N
^FD/*ExitSave*/^FS
^FT420,280^A0,28,28^FB400,1,0,C
^FDExit with Save\\&^FS

^BY2,3,104^FT50,430^BCN,,N,N
^FD/*ExitQuit*/^FS
^FT20,460^A0,28,28^FB400,1,0,C
^FDExit without Save\\&^FS

^BY2,3,104^FT450,430^BCN,,N,N
^FD/*SetFun00*/^FS
^FT420,460^A0,28,28^FB400,1,0,C
^FDRestore Factory Setting\\&^FS

^BY2,3,104^FT50,650^BCN,,N,N
^FD/*SetFun86*/^FS
^FT20,680^A0,28,28^FB400,1,0,C
^FDUS Keyboard\\&^FS

^BY2,3,104^FT450,650^BCN,,N,N
^FD/*SetFun88*/^FS
^FT420,680^A0,28,28^FB400,1,0,C
^FDDE Keyboard\\&^FS

^BY2,3,104^FT50,860^BCN,,N,N
^FD/*SetFun14*/^FS
^FT20,900^A0,28,28^FB400,1,0,C
^FDEnable Manual Scanning Mode\\&^FS

^BY2,3,104^FT450,860^BCN,,N,N
^FD/*SetFun19*/^FS
^FT420,900^A0,28,28^FB400,1,0,C
^FDEnable Auto Induction Mode\\&^FS

^FX demo barcode
^BY2,3,104^FT110,1070^BCN,,Y,N
^FD>:0123456789-abcdyz_ABCDYZ^FS

^FT330,1130^A0N,28,28^FH\\^FDDemo barcode^FS


^XZ
`;

export default largeInateckBcst60ScannerConfigTemplate;

const largeSymbolLS2208ScannerConfigTemplate = `
^XA

^FX local template
^PR5

^FX set factory defaults
^FO250,20^GFA,8358,8358,42,,::::::::::::::::::::T0FC381C001C3FFE00E1FF81C7E03F01FF81C7FFC700387E007FE38FC,::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::,:::::::::::::::::::::::::::::K07,:::J0C718,J0IF8,J0IFC,J0IF8,K0F8001ChO018,J01FC01FF8O01IF8gJ07FFCL07FN01E,J03FE03FFCK018001IF8O0CT07IFL0FEN01E03,J07DE07FFEK038001IF8N03CT07IF8K0FEN01E0F,J078F07C3FK078001IF8N03CT07IF8K0FO01E0F,J030E0781FK078001EQ03CT07C07CK0FO01E0F,N0F80F00F80FF001EI01FC007E0FF01F8039FE01C007C03C03E03FC1FC0781C1E3FC1FC,N078I03FE1FF001EI03FF01FF8FF03FE07BFF03C007C03E0FF87FE3FF0781E1E3FC3FF,N07EI07FF1FF001EI07FF83FFCFF0IF07FEF03C007C03E1FFC7FE7FF8781E1E3FC7FF8,N07FE00F8F0FF001IF0F8F83E7CFF0F8F87FCF078007C01E1F3E3FCF8F8781E1E3FCF8F8,N03FFC0F07878001IF07078783E3C1F0787E0F878007C01E3E1E0F07078781E1E0F0F078,O0FFE0F07878001IFI07878003C1E03C7C07878007C01E3C0E0FI078781E1E0F0F8,O03FF1F07878001IF007F878003C1E03C7C078FI07C01E3C0F0F007F8781E1E0F07F8,P03F1IF878001EI03FF878003C1E03C7807CFI07C01E3IF0F03FF8781E1E0F07FF,Q0F9IF878001EI07FF878003C3E03C7803CFI07C03E3IF0F07FF8781E1E0F03FF8,N0F00F9IF878001EI0FC7878003C1E03C7803DEI07C03E3FFE0F0FC78781E1E0F007FC,N0F00F9FI078001EI0F07878003C1E03C7801FEI07C03C3CI0F0F078781E1E0FI07C,N0F80F8F07878001EI0F078781E3C1E07C7801FEI07C07C3C1E0F0F078783E1E0F0F03C,N07E3F0F8F878001EI0F0F87C3C3C1F0F87801FCI07IF83E1E0F0F0F87C3E1E0F0F87C,N07IF07FF07F001EI0IF83FFC3F0IF87800FCI07IF81FFE0F0IF87FFE1E0FC7FF8,N03FFE07FF03F001EI07FF81FF83F87FF07800F8I07FFE00FFC0F07FF83FFE1E0FE7FF8,O0FF801FC03F001EI03F3C0FF01F83FC07800F8I07FF8007F80F03F3C1F9E1E07E1FE,hK0F8,hK0F,hJ01F,hJ0FF,hJ0FE,hJ0FC,,::::^FS

^FX enter key
^FO100,220^GFA,15089,15089,79,,:::::::::::::::::::::::L0798C06FE0CDF079E3E63DF3063E78F1F31E3E1B0FBC61F1EC3EF187DE1F79F79F3063E79E1F61F31E31F3C63E1EC7CF18F9E3783060DE0C3EF183D83067861F7863E3CC7C79E0FB3C,:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::,:::::::::::::::::::gO03FFCR0E07CN0707FN03CQ07FEU077003CN07FEN0798,gO03FFCI018M0E0F8N060FF8M03CQ0IFJ03P067003CN0FFEN079C,gO03FFCI038M0E1FO0E1FFCM03CQ0IF8I07P067003CN0FFEN078C,gO038K038M0E3EN01C3C3Cg0F078I07P0E7R0FP078E,gO038006787E0F03300E3C03C1C1C01C381E0F8I3B981F01E607800F03C1E0FCE0C7733C0E700186780FI0FI0F00F00F78E,gO03800FFC7E3FC7F80E7807F1C1C01C78003FC7FBFBC3FC3FF1FE00F0387F9FCE1C7F7FE0C7003CFFC1FC00F003FC1FC1FF87,gO03FF8FFE7E3FC7F00FF00FF9E3C03C78007FE7F3FBC7FC7FF1FE00F0787F9FCE1C7F7FF0C7003CFFE3FE00FFC3FC3FE3FF87,gO03FF8F1E3879E7C00FF80E38E380387I071E7C3C3C71C70F3CF00IF8F3C70E1C7878F1C7003CF1E38E00FFC79E38E38787,gO03FF8F0E3870E7800FFC1E3CE380387J01E78383C01CF0F38700FFE0E1C70E1C707871C7003CF0E78F00FFC70E78F78787,gO03800F0E387FE7800FBC1FFCF7803878001FE78383C1FCF0F3FF00F7C0FFC70E1C70787187003CF0E7FF00F007FE7FF78787,gO03800F0E387FE7800F1E1FFC77003878183FE78383C7FCF0F3FF00F1E0FFC70E1C70787187003CF0E7FF00F007FE7FF78787,gO03800F0E387007800E1F1E007F0038781E78E78383CF1CF0F38I0F0F0E0070E1C70787387003CF0E78I0F0070078078787,gO03800F0E3870C7800E0F1E383F00383C3C71E78383CF1C70F38600F0F8E1870E1C70787387003CF0E78E00F0070C78E38787,gO03FFCF0E3E7DE7800E078F783E00381FFC7BE78383CF3C7FF3EF00F078FBC7CF7C70787307FF3CF0E3DE00F007DE3DE3FF87,gO03FFCF0E3F3FC7800E07CFF83E001C1FF87FE78383C7FE3FF1FE00F03C7F87CFFC70787307FF3CF0E3FE00F003FC3FE1FF87,gO03FFCF0E1F1F87800E03C3F01C001C07F03EE78383C3EE1EF0FC00F03E3F07C7DC70787707FF3CF0E0FC00F001F80FC0F38E,hR03C001CX0FhP0E,hR03CI0CV078EhP0E,hQ01F8I0EV07FEhO01C,hQ01F8I06V03FChO01C,hR0CJ02W06hP018,,:::::::::::::::::::::::::^FS


^FX german
^FO170,420^GFA,11222,11222,62,,::::::::::::003E70E00E7FE0739FC07CE1FCF83E1CFE1C0703E707F3E1C1FCF87381F01F0E7F070380F9C3F83EE0FE7C383F9F0E0FE7C387F1F0FE7FE73E03F9CF8,:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::,::::::::::::::::::::::::::::::::hR03F,hR0FFC,hQ01FFE,hQ01E1E,hQ03C0F0781988E3C07C18E,hQ038I0FE1FDFF7E1FE1FF,hQ078001FF1FDIFE3FE1FF8,hQ0783E3C71E1E38E3871C38,hQ0787F3871C1C38F00F1C38,hQ0787F3C71C1C3870FF1C38,hQ0380F3FF9C1C3871FF1C38,hQ038073801C1C3873C71C38,hQ03C0F3871C1C3873871C38,hQ01F3F1C71C1C38738F1C38,hR0FFE1FE1C1C3873FF1C38,hR07F80FC1C1C3871F71C38,,::::::::::::::::::::::::^FS

^FX us english
^FO167,590^GFA,12474,12474,63,,:::::::::::::::I03E70E00E7FE0739FC07CE1FCF83E1CFE1C0703E707F3E1C3F8F870FE3E1F0E7F070380FB83F87CE0FE7C383F9F0E1FC7C387F1F0FE7C1C3E03F9CF8,:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::,::::::::::::::::::::::::::gJ01C,::gJ0D98,gI01FFC,gJ0FF8,gJ03C0E0303FI07FEM030CI03K08603N06K0EQ018O03,gJ07E0E0707F800IFM071CI038I018F038K0187J01EQ01CO03,gJ0F70E070FFC00IFM071CI038I018F838K0387J01FQ01CO018,gJ0630E071E1E00EO07K038I038F838K0387J03FgH01C,gM0E071C0E00E0019E03CC70C1F03BC0030FC381E0277C738003F819C3807019980F01F02381C,gM0E071EJ0E001FF07FC71C3FC3FE0070FC387F87FFE7FC003B81IFE1FC3F9C3F83FC7FE0C,gM0E070FCI0FFC1FF8FFC71C7FC3FF0070FE38FFC7FFE7FE007381IFE3FE3F9C7FC7FC7FE0E,gM0E0707F800FFE1C78E1C71C70C3870070EF38E1C783870E0071C1C78E38E3E1C71E71C78E0E,gM0E0703FC00FFE1C39C1C71C7C03870060E739E1E703870E0071C1C78E3873C1CEI03C70E0E,gM0E07007E00F001C39C1C71C7F838700E0E7B9C0E703870E00E1E1C70E78F3C1CE001FC70E0E,gM0E07I0E00E001C39C1C71C1FC38700E0E3F9C0E703870E00FFE1C70E7FF381CE007FC70E0E,gM0E071C0E00E001C39C1C71C07E38700E0E1F9C0E703870E01FFE1C70E780381CE0071C70E0E,gM0F071E0E00E001C38E1C71C60E3870060E1F8E1C703870E01IF1C70E386381CF0EE1C70E0E,gM0F9F0F3E00IF1C38F7C71C70E3870070E0F8F3C703870E01C071C70E3CE381C71CF3C70E0E,gM07FE0FFC00IF1C387FC71C7FC3870070E0F87F8703E70E03C079C70E1FE381C7FC7FC70E0E,gM03FC07F800IF1C383DC71C3F83870070E0783F0703E70E038039C70E0FC381C1F83EE70E0C,hJ01CQ03hG01C,hI0E1CQ038h018,hI0FFCQ018h018,hI07F8Q018h03,hI03E,,:::::::::::::::::::::::::::::::::^FS

^FX override caps lock
^FO169,770^GFA,13454,13454,62,,::::::::::::::::::::J01F9C3801CFFE039F9FE700FF01CFCFCFF3FCE701F80FC0738070FF07E70FF3F07E1CFF07E0FF38707F83F387F9F8383FC3F3FC07E73F01FE73F,:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::,:::::::::::::::::::::::::::::::gO0FFR01E0038L0FES07O01CI03C0F8,gN03FF8Q01E0038K03FFS07O01CI03C1F,gN07FFCQ01E0038K07FF8R07O01CI03C3E,gN0781EU038K0787CR07O01CI03C7C,gN0F01E30183E06719CC07381EI0F03C0F819E01FI07I03E00F81C3803CF803C0C06,gN0F00F78387F07FBFDE1FF87F800EI03FC3FF07F8007I0FF81FE1C7803DF00FF0E0E,gN0E00F3838FF87F3FDE3FF87FC00EI07FE3FF8FFC007I0FFC3FE1CF003FE00FF8E0E,gN0E00F3C79E3C7F3F9E3C78F1C01EI070E3C3CE1C007001E3C78F1DE003FF01E38F1E,gN0E00F1C71C1C783C1E7838E1E01EK0E3C1CFJ07001C1E7001FC003FF01C3C71C,gN0E00F1C71C1C703C1E7839E0E01EJ0FE3C1CFFI07003C1E7001FC003F783C1C71C,gN0E00F1EF1FFC703C1E7839FFE00EI03FE381C7FC007003C0E7001FC003E783FFC7BC,gN0F00F0EE1FFC70381E7839FFE00E03C7EE3C1C1FE007003C1E7001FE003C3C3FFC3B8,gN0F01E0FE1C0070381E7838EJ0F03C70E3C1C03E007003C1E7001CE003C1E1C003F8,gN0781E07C1E1C70381E3878E1C007878F0E3C3CE0E007001C1C7871CF003C1E1C383F8,gN07FFC07C0F3C70381E3CF8F3C007FF879E3E78F1E007FF9F3C7CF1C7003C0F1E781F,gN03FF807C0FF870381E1FF87FC003FF07FE3FF87FC007FF8FF83FE1C3803C0F8FF81F,gO0FF003807F070381E0FB83FJ0FC03E73FF03F8007FF87F01FC1C3C03C0787E00E,iG03CgQ0E,iG03CgP01E,iG03CgP03C,iG03CgP0FC,iG038gP0F8,,:::::hQ073FFEM03800EI018,hQ063FFEM03800EI01C,hQ0E3FFEM03800EJ0C,hQ0C3CO03800EJ0E,hP01C3C0011E01F039C0E07C0E,hP01C3C003BF07FC3FF0E0FE07,hP0383C003FF87FC3FF8E1FF07,hP0383FFC3C78F1E3C78E3C787,hP0383FFC383801E3838E38387,hP0383FFC38380FE3838E383878,hP0383C0038387FE3838E3FF878,hP0383C003838FDE3838E3FF878,hP0383C003838E1E3838E380078,hP0383C003838E1E3838E3C387,hP0383FFE3838F3E3EF8E1E787,hP01C3FFE3838FFE3FF0E1FF07,hP01C3FFE38387EE3BE0E0FE07,hP01Cg0E,hQ0Cg0E,hQ0Eg0C,hQ06Y01C,hQ06Y018,,:::::::::::::::::::::::::::^FS

^FX demo barcode
^BY2,3,104^FT110,1120^BCN,,Y,N
^FD>:0123456789-abcdyz_ABCDYZ^FS

^FT330,1180^A0N,28,28^FH\\^FDDemo barcode^FS

^XZ
`;

export default largeSymbolLS2208ScannerConfigTemplate;

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ApiErrorCode from '../enums/ApiErrorCode';

const swap = (json) => {
  const ret = {};
  Object.keys(json).forEach((key) => {
    ret[json[key]] = key;
  });
  return ret;
};

const useApiErrorTranslation = () => {
  const { t } = useTranslation();

  const errorCodes = useMemo(
    () =>
      swap({
        ...ApiErrorCode,
      }),
    [],
  );

  const translateError = useCallback(
    (err) => {
      const { data } = err;
      if (!data) {
        return t('Unknown error occurred.');
      }

      if (data.errorCode && errorCodes[data.errorCode]) {
        return t(errorCodes[data.errorCode]);
      }
      return t('Unknown error occurred.');
    },
    [t, errorCodes],
  );

  return { translateError };
};

export default useApiErrorTranslation;

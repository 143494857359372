import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';

const CancelUnitModal = ({ isOpen, onClose, unit }) => {
  const { toastSuccess } = useCustomToast();
  const { toastFetchError } = useToastFetchError();
  const { fetch } = useFetch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async () => {
      const response = await fetch(`/units/${unit.id}`, {
        method: 'DELETE',
      });

      return response.json();
    },
    {
      onError: (error) => {
        toastFetchError(error);
      },
      onSuccess: (response) => {
        onClose();
        toastSuccess(
          `${t(`Unit {{unitCode}} canceled.`, {
            unitCode: unit?.code,
          })}`,
        );
        queryClient.invalidateQueries({
          queryKey: ['/units'],
        });

        if (response?.data?.id) {
          queryClient.invalidateQueries({
            queryKey: [`/units/${response?.data?.id}`],
          });
          queryClient.invalidateQueries({
            queryKey: [`/units/${response?.data?.id}`, 'details'],
          });
        }
      },
    },
  );

  if (!unit) {
    return null;
  }

  return (
    <Modal
      body={
        <div className="flex flex-col gap-6">
          <p>
            {t(
              "You're about to remove this Unit from the Task. If the Task is a single-unit type, it will be automatically closed. If it consists of multiple Units, the others will remain in their current progress state.",
            )}
          </p>
        </div>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t("Don't cancel")}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            disabled={mutation.isLoading}
            text={t('Cancel Unit')}
            variant="solidRed"
            onClick={mutation.mutate}
          />
        </div>
      }
      header={<span>{t('Cancel Unit?')}</span>}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

CancelUnitModal.propTypes = {
  unit: PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.string,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

CancelUnitModal.defaultProps = {
  unit: undefined,
};

export default CancelUnitModal;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../../components/Dropdown/Dropdown';
import Icon from '../../../components/Icon';
import IconButton from '../../../components/IconButton';
import Tooltip from '../../../components/Tooltip';
import TaskStatus from '../../../enums/TaskStatus';
import UnitProgress from '../../../enums/UnitProgress';
import TasksUnitsHelper from '../../../helpers/TasksUnitsHelper';
import useCopyTaskInfoToClipboard from '../../../hooks/useCopyTaskInfoToClipboard';

const TasksTableActionsCell = ({
  onUpdateAddressClick,
  onUpdateDateClick,
  row: { original: record },
}) => {
  const { t } = useTranslation();
  const copyTaskInfoToClipboard = useCopyTaskInfoToClipboard();

  const menuItems = () => {
    const isAddressEditable =
      TasksUnitsHelper.isTaskAddressActionEnabled(record);
    const isDateEditable =
      TasksUnitsHelper.isTaskExecutionDateActionEnabled(record);

    return [
      {
        icon: 'locationAddress',
        iconClassName: 'text-grey-700',
        onClick: () => {
          if (isAddressEditable) {
            onUpdateAddressClick(record);
          }
        },
        textClassName: 'w-full',
        isDisabled: !isAddressEditable,
        text: (
          <Tooltip
            hidden={isAddressEditable}
            text={t('Update address is available only for Tasks without Tour')}
          >
            <div className="flex items-center w-full justify-between flex-1 gap-1">
              <span>{t('Update Address')}</span>
              {!isAddressEditable && (
                <Icon icon="infoFilled" className="h-4 w-4 text-grey-700" />
              )}
            </div>
          </Tooltip>
        ),
        key: 'update-address',
      },
      {
        icon: 'dateRange',
        iconClassName: 'text-grey-700',
        onClick: () => {
          if (isDateEditable) {
            onUpdateDateClick(record);
          }
        },
        textClassName: 'w-full',
        isDisabled: !isDateEditable,
        text: (
          <Tooltip
            hidden={isDateEditable}
            text={t(
              'Update date is available only for tasks without a Tour and with an execution date no earlier than today',
            )}
          >
            <div className="flex items-center w-full justify-between flex-1 gap-1">
              <span>{t('Change Execution Date')}</span>
              {!isDateEditable && (
                <Icon icon="infoFilled" className="h-4 w-4 text-grey-700" />
              )}
            </div>
          </Tooltip>
        ),
        key: 'update-date',
      },
      {
        icon: 'copy',
        iconClassName: 'text-grey-700',
        onClick: async () => {
          copyTaskInfoToClipboard(record);
        },
        text: t('Copy info to clipboard'),
      },
    ];
  };

  return (
    <div
      className="flex flex-row items-center justify-end gap-1"
      key={record.id}
    >
      <Dropdown menu={menuItems}>
        <div className="flex">
          <Tooltip placement="top" size="sm" text={t('Task Options')}>
            <IconButton
              icon="moreVert"
              iconSize="s"
              size="s"
              variant="ghostBlack"
            />
          </Tooltip>
        </div>
      </Dropdown>
    </div>
  );
};

TasksTableActionsCell.propTypes = {
  onUpdateAddressClick: PropTypes.func.isRequired,
  onUpdateDateClick: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      status: PropTypes.oneOf(Object.values(TaskStatus)),
      address: PropTypes.string,
      units: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          progress: PropTypes.oneOf(Object.values(UnitProgress)),
        }),
      ),
    }),
  }).isRequired,
};

export default TasksTableActionsCell;

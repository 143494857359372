import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Button from '../../../components/Button';
import DebouncedSearch from '../../../components/DebouncedSearch';
import Breakpoint from '../../../enums/Breakpoint';
import useWindowSize from '../../../hooks/useWindowSize';

const CustomersMembersFilters = () => {
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get('search')?.trim() || '';

  const hasFiltersApplied = !!searchQuery;

  return (
    <div className="flex w-full flex-col gap-4 lg:flex-row lg:flex-wrap lg:items-center">
      <div className="flex flex-1 flex-col gap-4 lg:flex-row lg:flex-wrap lg:items-center">
        <DebouncedSearch
          placeholder={`${t('Find member')}...`}
          searchValue={searchQuery}
          onSearch={(newSearchValue) => {
            if (newSearchValue === searchQuery) {
              return;
            }
            searchParams.set('page', '1');
            if (!newSearchValue) {
              searchParams.delete('search');
            } else {
              searchParams.set('search', newSearchValue?.trim());
            }
            setSearchParams(searchParams);
          }}
        />
      </div>
      <Button
        disabled={!hasFiltersApplied}
        text={t('Reset')}
        variant={isDesktop ? 'ghostBlack' : 'outlineBlack'}
        onClick={() => setSearchParams(new URLSearchParams())}
      />
    </div>
  );
};

export default CustomersMembersFilters;

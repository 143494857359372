import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import LocalStorage from '../enums/LocalStorage';
import LocalStorageHelper from '../helpers/LocalStorageHelper';
import { useUser } from '../providers/UserProvider';
import useCustomToast from './useCustomToast';
import useSentry from './useSentry';

const useLogout = () => {
  const { unsetSentryUser } = useSentry();
  const { removeAllUserAuthData } = useUser();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { toastError } = useCustomToast();

  return useCallback(
    (message) => {
      LocalStorageHelper.removeItem(LocalStorage.ScanningHubType);
      unsetSentryUser();
      removeAllUserAuthData();
      queryClient.clear();
      navigate('/');

      if (message) {
        toastError(message);
      }
    },
    [navigate, queryClient, removeAllUserAuthData, toastError, unsetSentryUser],
  );
};

export default useLogout;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card } from '../../../components/Card';
import ProgressRing from '../../../components/ProgressRing';
import RatingImage from '../../../components/RatingImage';
import useRatingTranslations from '../../../hooks/useRatingTranslations';

const OrganisationPerformance = ({ className, performance }) => {
  const { t } = useTranslation();
  const getRatingTranslation = useRatingTranslations();

  if (!performance) {
    return null;
  }

  return (
    <div className={className} data-test="organisation-performance-overview">
      <Card>
        <div className="mb-4 hidden text-sm uppercase text-grey-900 md:block">
          {t('organisation performance')}
        </div>
        <div className="grid gap-4 md:grid-cols-3">
          <div className="flex items-center justify-between rounded-md bg-grey-100 p-6">
            <div className="text-xs text-grey-700">{t('Tours Completed')}</div>
            <div className="text-[26px]">
              <strong>{performance?.completed}</strong>/{performance?.total}
            </div>
          </div>
          <div className="flex items-center justify-between rounded-md bg-grey-100 p-6">
            <div className="text-xs text-grey-700">
              {t('Average Tour Score')}
            </div>
            <ProgressRing
              progress={performance?.score}
              radius={28}
              strokeWidth={4}
            />
          </div>
          <div className="flex items-center justify-between rounded-md bg-grey-100 p-6">
            <div>
              <div className="mb-1 text-xs text-grey-700">{t('Rating')}</div>
              <div className="text-sm">
                {getRatingTranslation(performance?.rating)}
              </div>
            </div>
            <div>
              <RatingImage rating={performance?.rating} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

OrganisationPerformance.propTypes = {
  className: PropTypes.string,
  performance: PropTypes.shape({
    active: PropTypes.number,
    completed: PropTypes.number,
    rating: PropTypes.string,
    score: PropTypes.number,
    total: PropTypes.number,
  }),
};

OrganisationPerformance.defaultProps = {
  className: '',
  performance: undefined,
};

export default OrganisationPerformance;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import * as icons from '../../icons';

const Icon = forwardRef((props, ref) => {
  const { className, 'data-test': dataTest, icon, onClick, ...rest } = props;

  const IconComponent = icons[icon];
  if (!IconComponent) {
    return null;
  }

  return (
    <IconComponent
      className={cn(
        'flex-shrink-0 fill-current',
        onClick && 'cursor-pointer',
        className,
      )}
      ref={ref}
      data-test={dataTest}
      {...(onClick && { onClick, role: 'button' })}
      {...rest}
    />
  );
});

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  onClick: PropTypes.func,
  'data-test': PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  onClick: undefined,
  'data-test': undefined,
};

export { icons };
export default Icon;

export const customLocalStorageSetItemEvent = 'localStorageSetItem';
export const customLocalStorageRemoveItemEvent = 'localStorageRemoveItem';

export default class LocalStorageHelper {
  static getItem(key) {
    return localStorage.getItem(key);
  }

  static setItem(key, val) {
    const event = new Event(customLocalStorageSetItemEvent);
    event.key = key;
    event.value = val;

    localStorage.setItem(key, val);
    document.dispatchEvent(event);
  }

  static removeItem(key) {
    const event = new Event(customLocalStorageRemoveItemEvent);
    event.key = key;
    event.value = null;

    localStorage.removeItem(key);
    document.dispatchEvent(event);
  }
}

import cn from 'classnames';
import PropTypes from 'prop-types';

const variantsMap = {
  primary: 'bg-ui-warning-orange-light text-ui-warning-orange',
  secondary: 'bg-ui-blue-light text-ui-blue',
  ternary: 'bg-ui-red-light text-ui-red',
};

const ActivityStatsItem = ({ title, value, variant }) => (
  <div className="flex items-center rounded-md bg-white p-6 shadow-elevation-100 lg:h-[104px]">
    <div className="flex w-full items-center justify-between">
      <div className="text-xs leading-none text-grey-700">{title}</div>
      <div
        className={cn(
          'flex min-h-[56px] min-w-[56px] items-center justify-center rounded-full p-2 text-[26px] font-semibold',
          variantsMap[variant],
        )}
      >
        {value}
      </div>
    </div>
  </div>
);

ActivityStatsItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  variant: PropTypes.oneOf(Object.keys(variantsMap)),
};

ActivityStatsItem.defaultProps = {
  title: '',
  value: null,
  variant: 'primary',
};

export default ActivityStatsItem;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../../components/Dropdown/Dropdown';
import IconButton from '../../../components/IconButton';
import Tooltip from '../../../components/Tooltip';
import TasksUnitsHelper from '../../../helpers/TasksUnitsHelper';
import useUnitsPageSearchParams from '../../../hooks/useUnitsPageSearchParams';
import { useResource } from '../../../lib/api/Resource';
import { useListSelection } from '../../../providers/ListSelectionProvider';

const UnitsTableHeaderActions = ({ onShowHideColumnsClick }) => {
  const { t } = useTranslation();
  const { enableSelection } = useListSelection();
  const { data: units } = useResource();

  const { searchParams, setSearchParams, unitStatusQuery } =
    useUnitsPageSearchParams();

  const unitStatuses = TasksUnitsHelper.getUniqueUnitStatuses(units);

  const menuItems = [
    {
      icon: 'edit',
      iconClassName: 'text-grey-700',
      onClick: () => {
        enableSelection();

        if (!unitStatusQuery) {
          if (unitStatuses.length === 1) {
            searchParams.set('status', units[0].status);
          } else {
            searchParams.delete('progress');
            searchParams.delete('status');
          }
          setSearchParams(searchParams);
        }
      },
      text: t('Bulk edit mode'),
    },
    {
      icon: 'visibilityOn',
      iconClassName: 'text-grey-700',
      onClick: onShowHideColumnsClick,
      text: t('Show/hide columns'),
    },
  ];

  return (
    <div className="flex">
      <Dropdown menu={menuItems}>
        <div className="flex">
          <Tooltip placement="top" size="sm" text={t('Table Actions')}>
            <IconButton
              icon="menu"
              iconSize="s"
              size="s"
              variant="ghostBlack"
            />
          </Tooltip>
        </div>
      </Dropdown>
    </div>
  );
};

UnitsTableHeaderActions.propTypes = {
  onShowHideColumnsClick: PropTypes.func.isRequired,
};

UnitsTableHeaderActions.defaultProps = {};

export default UnitsTableHeaderActions;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Button from '../../../components/Button';
import CollapsableCard from '../../../components/CollapsableCard';
import Label from '../../../components/Label';

const HubCard = (props) => {
  const { address, client, id, name, postCodes, shortCode } = props;
  const { t } = useTranslation();
  const location = useLocation();

  const importBy = postCodes?.length > 0 ? t('Postcodes') : t('E-mail');

  return (
    <CollapsableCard
      id={id}
      expandedContent={
        <div className="flex flex-col gap-1">
          <div className="flex flex-col gap-3 border-t border-grey-200 pb-4 pt-4">
            <span className="text-sm">{address}</span>
            <span className="text-sm">
              {t('Import by')} <span className="font-semibold">{importBy}</span>
              {postCodes?.length > 0 && ` (${postCodes.length})`}
            </span>
          </div>

          <div className="flex flex-col border-t-2 border-grey-200 pt-5">
            <Button
              as={Link}
              icon="pageView"
              isFullWidth
              text={t('View Hub Details')}
              to={`/hubs/${id}`}
              variant="outlineBlack"
              state={{
                backLink: location.pathname + location.search,
              }}
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-1">
        <div>
          <span className="text-base font-medium">{name}</span>{' '}
          {client && (
            <Label size="xs" text={client.shortCode} variant="greyOutline" />
          )}
        </div>
        <span className="text-base">{shortCode}</span>
      </div>
    </CollapsableCard>
  );
};

HubCard.propTypes = {
  address: PropTypes.string,
  client: PropTypes.oneOfType([PropTypes.object]),
  id: PropTypes.string,
  name: PropTypes.string,
  postCodes: PropTypes.arrayOf(PropTypes.string),
  shortCode: PropTypes.string,
};

HubCard.defaultProps = {
  address: undefined,
  client: undefined,
  id: undefined,
  name: undefined,
  postCodes: undefined,
  shortCode: undefined,
};

export default HubCard;

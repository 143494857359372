import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';

import Icon from '../Icon';

const optionShape = {
  dropdownLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  groupLabel: PropTypes.string,
  backButtonLabel: PropTypes.string,
  isGroupSelectable: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelIcon: PropTypes.string,
  labelIconClassname: PropTypes.string,
  inputIcon: PropTypes.string,
  inputIconClassname: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  indent: PropTypes.bool,
  id: PropTypes.string,
};

// handle recursive prop types for option groups
optionShape.options = PropTypes.arrayOf(PropTypes.shape(optionShape));
export const optionPropType = PropTypes.shape(optionShape);

const Option = forwardRef(
  (
    { indent, isActive, isDisabled, isSelected, onClick, onMouseEnter, option },
    ref,
  ) => {
    const internalRef = useRef();

    useEffect(() => {
      if (internalRef.current && isSelected) {
        internalRef.current.scrollIntoView({
          block: 'nearest',
        });
      }
    }, [isSelected]);
    const label = option.options
      ? option.groupLabel
      : option.label || option.label;

    return (
      <div
        className={cn(
          'mx-2 flex flex-1 cursor-pointer flex-row items-center justify-between gap-2 break-words rounded-md px-3 py-2.5 text-sm font-medium',
          isActive && !isSelected && 'bg-grey-200 text-grey-900',
          isSelected && 'bg-ui-blue text-white',
          isDisabled && 'opacity-40 pointer-events-none',
          indent && 'pl-6',
        )}
        data-test={`option-${option.value}`}
        data-test-active={isActive}
        data-option-id={option.id}
        ref={mergeRefs([ref, internalRef])}
        tabIndex={-1}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
      >
        <span className="flex items-center gap-2">
          {option?.labelIcon && (
            <Icon
              className={cn(
                'h-4 w-4',
                option?.labelIconClassname,
                isSelected && 'text-white',
              )}
              icon={option.labelIcon}
            />
          )}
          {option.dropdownLabel !== undefined && option.dropdownLabel !== ''
            ? option.dropdownLabel
            : label}
        </span>
        {option.options && (
          <Icon className={cn('h-3 w-3')} icon="chevronRight" />
        )}
      </div>
    );
  },
);

Option.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  indent: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  option: optionPropType,
};

Option.defaultProps = {
  isActive: false,
  isDisabled: false,
  indent: false,
  isSelected: false,
  onClick: () => {},
  onMouseEnter: () => {},
  option: undefined,
};

export default Option;

import { useMatches } from 'react-router-dom';

function convertPathToCamelCase(inputPath) {
  let newPath = inputPath.pathname;

  Object.entries(inputPath.params).forEach(([paramName, paramValue]) => {
    if (newPath.includes(paramValue)) {
      newPath = newPath.replace(paramValue, `{${paramName}}`);
    }
  });

  return newPath;
}

const useCurrentPath = () => {
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1];
  return convertPathToCamelCase(lastMatch);
};

export default useCurrentPath;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

const LogoutConfirmation = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal
      body={
        <p>
          {t(
            "You'll be signed out and will need to sign in again to access your account.",
          )}
        </p>
      }
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Sign Out')}
            variant="solidBlue"
            onClick={onConfirm}
          />
        </div>
      }
      header={<span>{t('Sign Out')}?</span>}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

LogoutConfirmation.propTypes = {
  onConfirm: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

LogoutConfirmation.defaultProps = {
  onConfirm: () => {},
};

export default LogoutConfirmation;

/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import IconButton from '../../../components/IconButton';
import Label from '../../../components/Label';
import NewTable from '../../../components/NewTable';
import Tooltip from '../../../components/Tooltip';

const HubsTable = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'shortCode',
        header: t('Shortcode'),
        size: 85,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'name',
        header: t('Hub Name'),
        size: 200,
        cell: (info) => (
          <div>
            {info.getValue()}{' '}
            {info.row.original.client && (
              <Label
                size="xs"
                text={info.row.original.client.shortCode}
                variant="greyOutline"
              />
            )}
          </div>
        ),
      },
      {
        size: 300,
        accessorKey: 'address',
        header: t('Location'),
        cell: (info) => info.getValue(),
      },
      {
        cell: (info) => {
          const isImportDeliveryByEmail = !!info.row.original.email;
          return isImportDeliveryByEmail
            ? t('E-mail')
            : `${t('Postcodes')} (${info.row.original.postCodes?.length || 0})`;
        },
        header: t('Import'),
      },
      {
        header: t('Actions'),
        cell: (info) => (
          <div className="flex justify-end">
            <Tooltip placement="top" size="sm" text={t('Hub Details')}>
              <IconButton
                as={Link}
                icon="pageView"
                state={{
                  backLink: location.pathname + location.search,
                }}
                to={{
                  pathname: `/hubs/${info.row.original?.id}`,
                }}
                variant="ghostBlack"
              />
            </Tooltip>
          </div>
        ),
        size: 60,
        enableResizing: false,
      },
    ],
    [location.pathname, location.search, t],
  );

  return (
    <NewTable
      columns={columns}
      noRecordsText={t('No Hubs linked to this Organisation')}
      {...props}
    />
  );
};

HubsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
};

HubsTable.defaultProps = {
  data: [],
  isLoading: false,
};

export default HubsTable;

import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

const FloatingContainerContext = React.createContext(undefined);

const FloatingContainerProvider = (props) => {
  const { children, floatingContainer } = props;

  const value = useMemo(() => ({ floatingContainer }), [floatingContainer]);

  return (
    <FloatingContainerContext.Provider value={value}>
      {children}
    </FloatingContainerContext.Provider>
  );
};

FloatingContainerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  floatingContainer: PropTypes.instanceOf(Element),
};

FloatingContainerProvider.defaultProps = {
  floatingContainer: undefined,
};

export default FloatingContainerProvider;

export const useFloatingContainer = () => {
  const context = useContext(FloatingContainerContext);
  if (context === undefined) {
    // intentionally return undefined, so the tooltips and dropdowns behave in global context
    return {
      floatingContainer: undefined,
    };
  }
  return context;
};

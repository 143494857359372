export const sameWidth = {
  effect: (data) => {
    const { state } = data;
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
  enabled: true,
  fn: (data) => {
    const { state } = data;
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  name: 'sameWidth',
  phase: 'beforeWrite',
  requires: ['computeStyles'],
};

export const offsetModifier = {
  name: 'offset',
  options: {
    offset: () => [0, 8],
  },
};

export const preventOverflowModifier = {
  name: 'preventOverflow',
  options: {
    padding: 10,
  },
};

import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDebounce, usePrevious } from 'react-use';

import Input from '../Input';

const DebouncedSearch = (props) => {
  const {
    autoFocus,
    className,
    'data-test': dataTest,
    debounceMs,
    minLength,
    onSearch,
    placeholder,
    searchValue,
    ...rest
  } = props;
  const [localSearch, setLocalSearch] = useState(searchValue);
  const previousSearchValue = usePrevious(searchValue);
  const searchInputRef = useRef();

  useEffect(() => {
    if (previousSearchValue !== searchValue && searchValue !== localSearch) {
      setLocalSearch(searchValue);
    }
  }, [localSearch, previousSearchValue, searchValue]);

  const [, cancel] = useDebounce(
    () => {
      if (localSearch.length < minLength && localSearch !== '') {
        return;
      }
      onSearch(localSearch);
    },
    debounceMs,
    [localSearch],
  );

  useEffect(() => () => {
    if (cancel) {
      cancel();
    }
  });

  return (
    <Input
      autoFocus={autoFocus}
      className={className}
      clearable
      data-test={dataTest}
      icon="search"
      placeholder={placeholder}
      ref={searchInputRef}
      value={localSearch}
      onChange={(e) => {
        setLocalSearch(e?.target?.value);
      }}
      {...rest}
    />
  );
};

DebouncedSearch.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  'data-test': PropTypes.string,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  searchValue: PropTypes.string,
  minLength: PropTypes.number,
  debounceMs: PropTypes.number,
};

DebouncedSearch.defaultProps = {
  autoFocus: true,
  className: undefined,
  'data-test': undefined,
  onSearch: () => {},
  placeholder: undefined,
  searchValue: '',
  minLength: 0,
  debounceMs: 500,
};

export default DebouncedSearch;

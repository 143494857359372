import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../../components/Icon';

const sizesMap = {
  m: 'h-10',
  s: 'h-8',
};

const DriverButton = forwardRef((props, ref) => {
  const { className, driver, onClick, size } = props;

  const { deletedAt, firstName, lastName } = driver;

  const fullName = `${firstName} ${lastName}`;
  const { t } = useTranslation();

  return (
    <button
      className={cn(
        'flex basis-auto w-full flex-1 flex-row items-center justify-between gap-1 rounded-md border px-2.5 py-[7px] outline-none font-medium',
        sizesMap[size],
        className,
        deletedAt
          ? 'bg-grey-100 border-grey-300 text-grey-700'
          : 'bg-white border-grey-500 text-primary-dark',
      )}
      ref={ref}
      type="button"
      onClick={() => {
        if (!deletedAt) {
          onClick(driver);
        }
      }}
      disabled={!!deletedAt}
    >
      <div className="flex min-w-0 flex-1 flex-row items-center gap-2">
        <div
          className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-medium"
          data-test="driver-button"
        >
          {deletedAt ? t('Deleted user') : fullName}
        </div>
      </div>
      {!deletedAt && (
        <div className="flex">
          <Icon className="h-4 w-4 text-grey-900" icon="call" />
        </div>
      )}
    </button>
  );
});

export default DriverButton;

DriverButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.keys(sizesMap)),
  driver: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    deletedAt: PropTypes.string,
  }).isRequired,
};

DriverButton.defaultProps = {
  className: '',
  onClick: () => {},
  size: 'm',
};

import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import LocalStorage from '../enums/LocalStorage';
import UserRole from '../enums/UserRole';
import Workflow from '../enums/Workflow';
import useEvent from '../hooks/useEvent';
import useLocalStorage from '../hooks/useLocalStorage';
import useSentry from '../hooks/useSentry';

const UserContext = createContext();

const validRoles = Object.values(UserRole);

const UserProvider = ({ children, initialValue }) => {
  const { setSentryUser } = useSentry();
  const [userState, setUserState, removeUserState] = useLocalStorage(
    LocalStorage.User,
    initialValue,
  );
  const [authState, setAuthState, removeAuthState] = useLocalStorage(
    LocalStorage.Auth,
    userState?.token,
  );
  const authRef = useRef(authState);

  const isSubcarrierUser = !!userState?.subcarrier;
  const isGroceries = userState?.carrier?.workflow === Workflow.Groceries;

  const getAuth = useCallback(() => authRef.current, []);

  const setAuth = useCallback(
    (value) => {
      authRef.current = value;
      setAuthState(value);
    },
    [setAuthState],
  );

  const setUser = useCallback(
    (value) => {
      setUserState(value);
      setAuth(value.token);
    },
    [setAuth, setUserState],
  );

  const removeAllUserAuthData = useCallback(() => {
    authRef.current = undefined;
    removeAuthState();
    removeUserState();
  }, [removeAuthState, removeUserState]);

  const onStorageChange = useCallback(
    (e) => {
      //  The key attribute is null when the change is caused by the storage clear() method.
      if (e.key === null) {
        removeAllUserAuthData();
      }

      if (e.key === LocalStorage.User && e.newValue === null) {
        removeAllUserAuthData();
      }

      if (e.key === LocalStorage.User && typeof e.newValue === 'string') {
        try {
          const newValue = JSON.parse(e.newValue);
          setUser(newValue);
        } catch (error) {
          removeAllUserAuthData();
        }
      }

      if (e.key === LocalStorage.Auth && e.newValue === null) {
        removeAllUserAuthData();
      }

      if (e.key === LocalStorage.Auth && typeof e.newValue === 'string') {
        try {
          const newValue = JSON.parse(e.newValue);
          setAuth(newValue);
        } catch (error) {
          removeAllUserAuthData();
        }
      }
    },
    [removeAllUserAuthData, setAuth, setUser],
  );

  const isUserValid = useMemo(() => {
    // superadmin has no organisation
    if (userState?.role === UserRole.Superadmin && userState.carrier === null) {
      return true;
    }

    const isRoleValid = validRoles.some((role) => userState?.role === role);
    const isWorkflowValid =
      userState?.carrier &&
      (userState.carrier.workflow === Workflow.Operations ||
        userState.carrier.workflow === Workflow.Groceries);

    return userState && isRoleValid && isWorkflowValid;
  }, [userState]);

  // The storage event of the Window interface fires when a storage area (localStorage) has been modified in the context of another document.
  // @see https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
  useEvent('storage', onStorageChange);

  useEffect(() => {
    if (userState) {
      setSentryUser(userState);
    }
  }, [userState, setSentryUser]);

  const value = useMemo(
    () => ({
      getAuth,
      isSubcarrierUser,
      removeAllUserAuthData,
      setAuth,
      setUser,
      user: userState,
      isGroceries,
      isUserValid,
    }),
    [
      getAuth,
      isSubcarrierUser,
      removeAllUserAuthData,
      setAuth,
      setUser,
      userState,
      isGroceries,
      isUserValid,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node,
  initialValue: PropTypes.shape({
    id: PropTypes.string,
    role: PropTypes.string,
    carrier: PropTypes.shape({
      id: PropTypes.string,
      workflow: PropTypes.string,
    }),
  }),
};

UserProvider.defaultProps = {
  children: null,
  initialValue: undefined,
};

export default UserProvider;

export const useUser = () => useContext(UserContext);

export const useIsRole = (role) => {
  const { user } = useUser();

  if (typeof role === 'string') {
    return user?.role === role;
  }

  return role.some((roleValue) => user?.role === roleValue);
};

export const useUserHubId = () => {
  const { user } = useUser();
  return user?.hub?.id || user?.managingHub?.id || '';
};

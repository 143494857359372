import 'react-phone-input-2/lib/style.css';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactPhoneInput from 'react-phone-input-2';

import InputLayout from '../InputLayout';

const PhoneInputWrapper = (props) => {
  const {
    disabled,
    error,
    id,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required,
    value,
    ...rest
  } = props;

  const {
    formState: { errors },
    trigger,
  } = useFormContext();

  const errorInput = error || (errors[name] && errors[name].message);

  return (
    <InputLayout error={errorInput} id={id} label={label} required={required}>
      <ReactPhoneInput
        onFocus={onFocus}
        autoFormat={false}
        buttonClass={cn(
          '!bg-transparent !border-grey-500 !border-none !inset-y-px !left-0.5 [&>div]:!bg-transparent',
          disabled && '!bg-grey-200',
        )}
        containerClass={cn(
          'react-tel-input font-sans',
          disabled && 'opacity-40',
        )}
        country="de"
        disableAreaCodes
        disabled={disabled}
        inputClass={cn(
          '!w-full !h-auto p-2.5 !leading-[1.3] !border-grey-500 !rounded-md',
          errorInput && '!border-ui-red',
          disabled && '!cursor-default !bg-grey-200',
        )}
        inputProps={{
          id,
          name,
        }}
        placeholder={placeholder}
        value={value}
        onBlur={() => {
          trigger(name);
        }}
        onChange={onChange}
        {...rest}
      />
    </InputLayout>
  );
};

PhoneInputWrapper.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
};

PhoneInputWrapper.defaultProps = {
  disabled: false,
  error: null,
  id: '',
  label: '',
  name: '',
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  placeholder: '',
  required: false,
  value: '',
};

const FormPhoneInput = (props) => {
  const { error, name, onFocus, required, ...rest } = props;
  const { t } = useTranslation();

  const { control } = useFormContext();

  const generateValidators = useCallback(() => {
    const validators = {};
    if (required) {
      validators.required = t('This field is required.');
    }

    return validators;
  }, [required, t]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...controllerProps } }) => (
        <PhoneInputWrapper
          onFocus={onFocus}
          error={error}
          name={name}
          required={required}
          {...rest}
          {...controllerProps}
        />
      )}
      rules={generateValidators()}
    />
  );
};

FormPhoneInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  name: PropTypes.string,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
};

FormPhoneInput.defaultProps = {
  error: null,
  name: '',
  required: false,
  onFocus: () => {},
};

export default FormPhoneInput;

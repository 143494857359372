const PrinterStatus = {
  Error: 'error',
  Initialising: 'initialising',
  NotReady: 'notReady',
  Ready: 'ready',
  Printed: 'printed',
  Printing: 'printing',
};

export default PrinterStatus;

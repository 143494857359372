/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import IconButton from '../../../components/IconButton';
import Label from '../../../components/Label';
import NewTable from '../../../components/NewTable';
import Tooltip from '../../../components/Tooltip';
import Breakpoint from '../../../enums/Breakpoint';
import OrganisationType from '../../../enums/OrganisationType';
import UserRole from '../../../enums/UserRole';
import useWindowSize from '../../../hooks/useWindowSize';
import { useUser } from '../../../providers/UserProvider';
import MemberRating from '../../organisations/MemberRating';
import MemberStatus from '../../organisations/MemberStatus';
import MemberBadge from '../MemberBadge';

const MembersTable = (props) => {
  const { isCustomer, organisationType, ...rest } = props;

  const { t } = useTranslation();
  const location = useLocation();
  const { isSubcarrierUser } = useUser();

  const { width } = useWindowSize();
  const isSmallScreen = width < Breakpoint.XXL;

  const columns = useMemo(() => {
    const allColumns = [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: t('Name'),
        size: 250,
        cell: (info) => (
          <span>
            {info.row.original.firstName} {info.row.original.lastName}{' '}
            {info.row.original.subcarrier && !isSubcarrierUser && (
              <Label
                size="xs"
                text={info.row.original?.subcarrier?.shortCode}
                variant="primary"
              />
            )}
          </span>
        ),
      },
      {
        accessorKey: 'role',
        header: t('Role'),
        cell: (info) => (
          <MemberBadge
            memberRole={info.getValue()}
            size="s"
            shortened={isSmallScreen}
          />
        ),
      },
      {
        accessorKey: 'hub',
        header: t('Hub'),
        cell: (info) => {
          const isAdmin = info.row.original?.role === UserRole.Admin;
          if (isAdmin) {
            return t('All');
          }
          return info.getValue()?.name || '/';
        },
      },
      {
        accessorKey: 'email',
        header: t('E-mail'),
        size: 300,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'phoneNumber',
        header: t('Phone Number'),
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'status',
        header: t('Status'),
        cell: (info) => <MemberStatus status={info.getValue()} />,
      },
      {
        accessorKey: 'rating',
        header: t('Rating'),
        cell: (info) => {
          if (info.getValue()) {
            return <MemberRating rating={info.getValue()} />;
          }

          return <span className="text-grey-500">N/A</span>;
        },
      },
      {
        header: t('Actions'),
        cell: (info) => (
          <Tooltip placement="top" size="sm" text={t('Member Details')}>
            <IconButton
              as={Link}
              icon="pageView"
              state={{
                backLink: location.pathname + location.search,
              }}
              to={{
                pathname: `/members/${info.row.original?.id}`,
              }}
              variant="ghostBlack"
            />
          </Tooltip>
        ),
        size: 60,
        enableResizing: false,
      },
    ];

    return allColumns.filter((column) => {
      if (isCustomer) {
        return (
          column.accessorKey !== 'rating' &&
          column.accessorKey !== 'role' &&
          column.accessorKey !== 'hub'
        );
      }

      if (organisationType === OrganisationType.Superadmin) {
        return column.accessorKey !== 'hub' && column.accessorKey !== 'rating';
      }

      if (organisationType !== OrganisationType.Superadmin) {
        return column.accessorKey !== 'phoneNumber';
      }

      return column;
    });
  }, [
    isCustomer,
    isSmallScreen,
    isSubcarrierUser,
    location.pathname,
    location.search,
    organisationType,
    t,
  ]);

  return <NewTable columns={columns} data-test="members-table" {...rest} />;
};

MembersTable.propTypes = {
  isCustomer: PropTypes.bool,
  organisationType: PropTypes.oneOf([
    OrganisationType.Carrier,
    OrganisationType.Superadmin,
  ]),
};

MembersTable.defaultProps = {
  isCustomer: false,
  organisationType: undefined,
};

export default MembersTable;

import useEvent from './useEvent';

const useClickOutside = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback(e);
    }
  };

  useEvent('click', handleClick);

  return ref;
};

export default useClickOutside;

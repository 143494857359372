import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Select from '../../../components/Select';
import Breakpoint from '../../../enums/Breakpoint';
import UserRole from '../../../enums/UserRole';
import useDateQuery from '../../../hooks/useDateQuery';
import useWindowSize from '../../../hooks/useWindowSize';
import { useIsRole, useUser } from '../../../providers/UserProvider';
import CarriersSelect from '../../shared/CarriersSelect';
import HubSelect from '../../shared/HubSelect';
import ToursSearch from './ToursSearch';
import ToursSearchBy from './ToursSearchBy';
import TourStatusSelect from './TourStatusSelect';

const GroceryToursFilters = (props) => {
  const { isResetEnabled } = props;
  const dateQuery = useDateQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchByQuery =
    searchParams.get('searchBy') || ToursSearchBy.TourNumber;
  const searchQuery = searchParams.get('search')?.trim() || '';
  const hubIdQuery = searchParams.get('hubId') || '';
  const tourStatusQuery = searchParams.get('tourStatus') || '';
  const subcarrierQuery = searchParams.get('subcarrierId') || '';
  const shiftsQuery = searchParams.get('shiftNumber') || '';
  const isSubcarrierFitlerVisible = useIsRole([
    UserRole.Admin,
    UserRole.HubManager,
    UserRole.OperationsManager,
  ]);
  const { user } = useUser();
  const organisation = user?.carrier;

  const { t } = useTranslation();

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const shiftOptions = [1, 2, 3].map((shift) => ({
    value: String(shift),
    label: `${t('Shift')} ${shift}`,
  }));

  return (
    <div className="flex w-full flex-col gap-4 lg:flex-row lg:flex-wrap lg:items-center">
      <div className="flex flex-col gap-4 lg:flex-1 lg:flex-row lg:items-center">
        {isSubcarrierFitlerVisible && (
          <CarriersSelect
            value={subcarrierQuery}
            onChange={(value) => {
              searchParams.set('page', '1');
              if (!value) {
                searchParams.delete('subcarrierId');
              } else if (value === organisation?.id) {
                searchParams.set('subcarrierId', 'null');
              } else {
                searchParams.set('subcarrierId', value);
              }
              setSearchParams(searchParams);
            }}
          />
        )}

        <Select
          className="lg:w-[160px]"
          placeholder={t('Shift')}
          options={shiftOptions}
          value={shiftsQuery}
          onChange={(value) => {
            searchParams.set('page', '1');
            if (!value) {
              searchParams.delete('shiftNumber');
            } else {
              searchParams.set('shiftNumber', value);
            }
            setSearchParams(searchParams);
          }}
        />

        <HubSelect
          value={hubIdQuery}
          onChange={(hubId) => {
            searchParams.set('page', '1');
            if (!hubId) {
              searchParams.delete('hubId');
            } else {
              searchParams.set('hubId', hubId);
            }
            setSearchParams(searchParams);
          }}
        />

        <TourStatusSelect
          value={tourStatusQuery}
          onChange={(tourStatus) => {
            searchParams.set('page', '1');
            if (!tourStatus) {
              searchParams.delete('tourStatus');
            } else {
              searchParams.set('tourStatus', tourStatus);
            }
            setSearchParams(searchParams);
          }}
        />
        <div className="lg:w-[128px]">
          <DatePicker
            dateFormat="dd.MM.yyyy"
            isClearable
            name="date"
            placeholderText={t('Date')}
            required
            value={dateQuery}
            onChange={(date) => {
              searchParams.set('page', '1');
              if (!date) {
                searchParams.delete('date');
              } else {
                const formattedDate = moment(date).format('YYYY-MM-DD');
                searchParams.set('date', formattedDate);
              }
              setSearchParams(searchParams);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 lg:flex-row lg:items-center">
        <ToursSearch
          searchBy={searchByQuery}
          searchValue={searchQuery}
          onSearch={(newSearchValue) => {
            if (newSearchValue === searchQuery || !searchByQuery) {
              return;
            }
            searchParams.set('page', '1');
            if (!newSearchValue) {
              searchParams.delete('search');
            } else {
              searchParams.set('search', newSearchValue?.trim());
              searchParams.set('searchBy', searchByQuery);
            }
            setSearchParams(searchParams);
          }}
          onSearchByChange={(newSearchBy) => {
            searchParams.set('page', '1');
            searchParams.set('searchBy', newSearchBy);
            setSearchParams(searchParams);
          }}
        />
        <Button
          disabled={!isResetEnabled}
          text={t('Reset')}
          variant={isDesktop ? 'ghostBlack' : 'outlineBlack'}
          onClick={() => {
            setSearchParams({ page: '1' });
          }}
        />
      </div>
    </div>
  );
};

GroceryToursFilters.propTypes = {
  isResetEnabled: PropTypes.bool,
};

GroceryToursFilters.defaultProps = {
  isResetEnabled: false,
};

export default GroceryToursFilters;

import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import UserRole from '../../enums/UserRole';
import Routings from '../../features/routings';
import useFetch from '../../lib/api/hooks/useFetch';
import { useUser } from '../../providers/UserProvider';

const OrganisationRoutings = () => {
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const { user } = useUser();
  const { id } = useParams();
  const { state } = useLocation();

  const isSuperAdmin = user.role === UserRole.Superadmin;

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isLoading,
  } = useQuery([`/carriers/${id}`], async () => {
    const response = await fetch(`/carriers/${id}`);
    return response.json();
  });

  const {
    data: fetchedRoutings,
    isError: isRoutingsError,
    isLoading: isRoutingsLoading,
  } = useQuery([`/carriers/${id}/routing-configurations`], async () => {
    const response = await fetch(`/carriers/${id}/routing-configurations`);
    return response.json();
  });

  const organisation = fetchedOrganisation?.data || null;

  const backLink = useMemo(() => {
    if (state?.backLink) {
      return state?.backLink;
    }
    return `/organisations/${organisation?.id}`;
  }, [organisation?.id, state?.backLink]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        backLink={backLink}
        textPrimary={t('Routing Configuration')}
        textSecondary={organisation?.name}
        isLoading={isLoading}
      />
      <Page.Content variant="grey">
        <Routings
          isLoading={isRoutingsLoading}
          routings={fetchedRoutings?.data}
          isFetchError={isRoutingsError}
          organisationId={isSuperAdmin ? id : user.carrier.id}
        />
      </Page.Content>
    </Page>
  );
};

export default OrganisationRoutings;

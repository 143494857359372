import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormInput from '../../../components/FormInput';
import PostcodesInput from './PostcodesInput';

const ImportDeliveryFields = ({ isGroceries }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-4 text-sm">
        <div className="font-medium">{t('Import')}</div>
        <div className="text-grey-700">
          {isGroceries
            ? t('Tours will be imported via e-mail address')
            : t('Tours will be imported via postcodes')}
        </div>
      </div>
      {isGroceries && (
        <FormInput
          id="email"
          label={t('E-mail')}
          name="email"
          required
          type="email"
        />
      )}

      {!isGroceries && (
        <PostcodesInput
          id="postCodes"
          label={t('Postcodes')}
          name="postCodes"
          required
        />
      )}
    </div>
  );
};

ImportDeliveryFields.propTypes = {
  isGroceries: PropTypes.bool,
};

ImportDeliveryFields.defaultProps = {
  isGroceries: false,
};

export default ImportDeliveryFields;

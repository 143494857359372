const getLargeLabelTemplate = (templateData, isCentral) => `
^FX ${isCentral ? 'central' : 'local'} template
  
^FX print speed 5
^PR5,5

^XA~TA000~JSN^LT0^MNW^MTD^PON^PMN^LH0,0^JMA~SD8^JUS^LRN
^FX enables UTF-8 
^CI28
^MMT
^PW812
^LL1218
^LS0
^FX urbify logo
^FO453,0^GFA,05632,05632,00044,:Z64:
eJztl79u2zAQxqmoAoF0UDMIHrN36Zit0iM4gLn3ERxANUer7Yt4ztBXsB7BQ7wLnYpOAboISSD27qg/lCLJl6mLL5BskV++/EIfeWchznGOc5zj/8TFG7SLN2jjnK9VB7bUNyVbK7Vmaz8Zk3G1SqmEKb0whg3sa61TpjYE3yrjaSPFBzYYBUvqaYw1SxuSb8XSEq5SLG1s2MAWlwfsW1vzwtAGR2V/GNoa15jdaa3WfODGdiTVsiGuamI1lHpLId65A2Hr++qT80tYpMIZkC0v7DnvSogrsvyAUynMJqO+BpzA2+Q4WtlbSE8OL1wrzAp8gM2HTsHK3iI18M0ksF6TL1xE2PjGvQ2OZ4PUG7FAXgkXEcrU3voHR9+3xytLse35zvDC+qrltG+PV+xEP6tneEX9wOEVYuAbqCNcD8B7xFwG3rzhFTX8HO/H3eewev9bXN5fmj/f7+F1mvfmbiPTBSRDCpMw1MCP8cZ/q7CKIRkqmPwBry8t76v1jW5XwQqHVzDpKRGtpnnJF4atr8nicoZXb+RXrRPi9bRYrGd4DRqagny/Ob5jvApBVdLyLmd4zc/Q/DKP8OHB5wa+jzO8G3yj15i/CT7P8b7AqIjL2jePixneJW21JdwgH1TSJsUYb0lbrfbdFttihnctJAy0vHrc1/KS73XtGxftGTGSv9GBeA/BA/JGucq5vOCb9XjTIW/a8i7u2jMCff2e74D3+rH1xfX15tY3ulV93xLLBvrC+7zPGz4b4fB6UCuI18f39fre1Lyyx5vTv2t9c5/y1cmH8Lk98ANsHaLE8nqQtJSvcFc1b9AVkrCuxLXv07723TX5K7tCArxf8NXWCyobyKvvmvyVXS/U+FK9EFSOyJf2G/r6XaUGXtpOtoOgMke8tN+Q1+vO4NAWTN/6AvUT+VaOb1tQpe3NPOLFVV4Tb6rpHANeryvUoS2Y0voSPflu8dxBX6/zDWzngF0E2uOd8hcOMZu/3tHlxTyKra/Ywx8h35CSAtfINMcD1ePEdhFiPOz6N76l7SImtF3NCGhJg7H+oY6uZjRt1GTn57QQ3ql+Rzs1rvUtxrVuSY6aficZ17qdZtuYjEuFO9EAT3XscNa5vzjRntXTbqMZzeL2W4i6ocwmtPud8yBncfvhz+IOibD7zXnamNn9YsiZ5B2Gz/56IehUWJ5W2dhzvw4JYc9eZoR8XKoX7OiOgNOxSPjaMONrz8GPf7CeB1A=:B046

^FX customer logo
${templateData.logoZplCode}

^FT37,330^A0N,28,28^FH\\^FD${templateData.name}^FS
^FT37,364^A0N,28,28^FH\\^FD${templateData.addressNote}^FS
^FT37,398^A0N,28,28^FH\\^FD${templateData.street}^FS
^FT37,432^A0N,28,28^FH\\^FD${templateData.postcode} ${templateData.city}^FS
^FO37,150^GB738,0,2^FS
^FO37,252^GB738,0,2^FS
^BY${templateData.narrowBarWidth},3,104^FT${
  templateData.barcodeHorizontalPosition
},1120^BCN,,Y,N
^FD>:${templateData.externalId}^FS
^FT37,215^A0N,28,28^FH\\^FDOrder: ${templateData.orderId}^FS
^FT772,215,1^A0N,28,28^FH\\^FDDate: ${templateData.date}^FS

^FT772,874,1^A0N,39,38^FH\\^FDAttempt: ${templateData.attempt}^FS

^FT772,792,1^A0N,39,38^FH\\^FDParcel: ${templateData.parcelNumber}/${
  templateData.totalParcelsInDelivery
}^FS
^FT37,809^A0N,73,72^FH\\^FD${
  isCentral ? templateData.hubShortCode : templateData.tourIdentifier
}^FS

^FO37,489^GB738,0,2^FS
^FO37,722^GB738,0,2^FS

^FT37,695
^A0N,28,28
^FH\\
^TBN,738,170
^FD${templateData.note}^FS
${
  !isCentral &&
  `
^FT37,894^A0N,70,70^FH\\^FD${templateData.tourStop}/${templateData.tourStopsCount}^FS

^FT772,957,1^A0N,39,38^FH\\^FDShift: ${templateData.shift}^FS
`
}

^PQ1,0,1,Y^XZ
  `;

export default getLargeLabelTemplate;

import PropTypes from 'prop-types';

import CardListContainer from '../../../components/CardListContainer';
import UserRole from '../../../enums/UserRole';
import { useIsRole } from '../../../providers/UserProvider';
import SubcarriersCard from '../SubcarrierCard';

const SubcarriersCardList = (props) => {
  const { data, isInitialLoading, isLoading } = props;

  const showActions = useIsRole([UserRole.Admin]);

  return (
    <CardListContainer
      isLoading={isLoading}
      isInitialLoading={isInitialLoading}
      isEmpty={data.length === 0}
    >
      {data.map((subcarrier) => (
        <SubcarriersCard
          id={subcarrier?.id}
          key={subcarrier?.id}
          name={subcarrier?.name}
          shortCode={subcarrier?.shortCode}
          showActions={showActions}
          userCount={subcarrier?.userCount}
        />
      ))}
    </CardListContainer>
  );
};

SubcarriersCardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
};

SubcarriersCardList.defaultProps = {
  data: [],
  isLoading: false,
  isInitialLoading: false,
};

export default SubcarriersCardList;

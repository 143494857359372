import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Label from '../../../components/Label';
import UserRole from '../../../enums/UserRole';

const MemberBadge = (props) => {
  const { memberRole, shortened, size } = props;

  const { t } = useTranslation();

  const badgeText = useMemo(() => {
    const labels = {
      [UserRole.Superadmin]: t('Superadmin'),
      [UserRole.Admin]: t('Admin'),
      [UserRole.Driver]: t('Driver'),
      [UserRole.Customer]: t('Client'),
      [UserRole.OperationsManager]: shortened
        ? t('OM')
        : t('Operations Manager'),
      [UserRole.HubManager]: shortened ? t('HM') : t('Hub Manager'),
      [UserRole.Crew]: t('Crew'),
    };

    return labels[memberRole] || '';
  }, [t, shortened, memberRole]);

  const badgeVariant = useMemo(() => {
    const variants = {
      [UserRole.Superadmin]: 'greyOutline',
      [UserRole.Admin]: 'watermelon',
      [UserRole.Driver]: 'green',
      [UserRole.Customer]: 'blue',
      [UserRole.OperationsManager]: 'blue',
      [UserRole.HubManager]: 'lightBlue',
      [UserRole.Crew]: 'purple',
    };

    return variants[memberRole] || '';
  }, [memberRole]);

  return <Label size={size} text={badgeText} variant={badgeVariant} />;
};

MemberBadge.propTypes = {
  memberRole: PropTypes.string.isRequired,
  shortened: PropTypes.bool,
  size: PropTypes.string,
};

MemberBadge.defaultProps = {
  shortened: false,
  size: 'xs',
};

export default MemberBadge;

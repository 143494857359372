export const smallInateckBcst60ScannerConfigTemplate1 = `
^XA

^FX demo barcode
^BY2,3,104^FT110,200^BCN,,Y,N
^FD>:0123456789-abcdyz_ABCDYZ^FS

^FT330,250^A0N,28,28^FH\\^FDDemo barcode^FS

^XZ
`;

export const smallInateckBcst60ScannerConfigTemplate2 = `
^XA
^MMT
^PW799
^LL0400
^LS0

^BY2,3,104^FT30,150^BCN,,N,N
^FD/*SetFun86*/^FS
^FT0,180^A0,28,28^FB400,1,0,C
^FDUS Keyboard\\&^FS

^BY2,3,104^FT430,150^BCN,,N,N
^FD/*SetFun88*/^FS
^FT400,180^A0,28,28^FB400,1,0,C
^FDDE Keyboard\\&^FS

^BY2,3,104^FT30,330^BCN,,N,N
^FD/*SetFun14*/^FS
^FT0,360^A0,28,28^FB400,1,0,C
^FDEnable Manual Scanning Mode\\&^FS

^BY2,3,104^FT430,330^BCN,,N,N
^FD/*SetFun19*/^FS
^FT400,360^A0,28,28^FB400,1,0,C
^FDEnable Auto Induction Mode\\&^FS

^PQ1,0,1,Y
^XZ
`;

export const smallInateckBcst60ScannerConfigTemplate3 = `
^XA
^MMT
^PW799
^LL0400
^LS0

^BY2,3,104^FT30,150^BCN,,N,N
^FD/*EnterSet*/^FS
^FT0,180^A0,28,28^FB400,1,0,C
^FDEnter Setup\\&^FS

^BY2,3,104^FT430,150^BCN,,N,N
^FD/*ExitSave*/^FS
^FT400,180^A0,28,28^FB400,1,0,C
^FDExit with Save\\&^FS

^BY2,3,104^FT30,330^BCN,,N,N
^FD/*ExitQuit*/^FS
^FT0,360^A0,28,28^FB400,1,0,C
^FDExit without Save\\&^FS

^BY2,3,104^FT430,330^BCN,,N,N
^FD/*SetFun00*/^FS
^FT400,360^A0,28,28^FB400,1,0,C
^FDRestore Factory Setting\\&^FS

^PQ1,0,1,Y
^XZ
`;

import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';

const ListSelectionContext = React.createContext(undefined);

const ListSelectionProvider = (props) => {
  const {
    children,
    defaultDisabledState,
    defaultVisibleState,
    disabledCheckboxTooltipMessage,
    getCheckboxItemData,
    maxSelectedItems,
  } = props;

  const [isSelectionVisible, setIsSelectionVisible] =
    useState(defaultVisibleState);
  const [isSelectionDisabled, setIsSelectionDisabled] =
    useState(defaultDisabledState);
  const [selectedItems, setSelectedItems] = useState([]);

  const enableSelection = useCallback(() => {
    setIsSelectionVisible(true);
    setSelectedItems([]);
  }, []);

  const disableSelection = useCallback(() => {
    setIsSelectionVisible(false);
    setSelectedItems([]);
  }, []);

  const selectItem = useCallback(
    (record) => {
      if (selectedItems.find((item) => item.id === record.id)) {
        return;
      }
      setSelectedItems((prevState) => [...prevState, record]);
    },
    [selectedItems],
  );

  const unselectItem = useCallback((id) => {
    setSelectedItems((prevState) => prevState.filter((item) => item.id !== id));
  }, []);

  const value = useMemo(
    () => ({
      disableSelection,
      enableSelection,
      isSelectionVisible,
      selectItem,
      selectedItems,
      setSelectedItems,
      unselectItem,
      maxSelectedItems,
      isSelectionDisabled,
      setIsSelectionDisabled,
      disabledCheckboxTooltipMessage,
      getCheckboxItemData,
    }),
    [
      disableSelection,
      disabledCheckboxTooltipMessage,
      enableSelection,
      getCheckboxItemData,
      isSelectionDisabled,
      isSelectionVisible,
      maxSelectedItems,
      selectItem,
      selectedItems,
      unselectItem,
    ],
  );

  return (
    <ListSelectionContext.Provider value={value}>
      {children}
    </ListSelectionContext.Provider>
  );
};

ListSelectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultVisibleState: PropTypes.bool,
  defaultDisabledState: PropTypes.bool,
  maxSelectedItems: PropTypes.number,
  getCheckboxItemData: PropTypes.func,
  disabledCheckboxTooltipMessage: PropTypes.string,
};

ListSelectionProvider.defaultProps = {
  defaultVisibleState: false,
  defaultDisabledState: false,
  maxSelectedItems: 100,
  getCheckboxItemData: undefined,
  disabledCheckboxTooltipMessage: undefined,
};

export default ListSelectionProvider;

export const useListSelection = ({ ignoreMissingContext } = {}) => {
  const context = useContext(ListSelectionContext);
  // used for scenarios when context is not available and the provider is not used,
  // but we still need to use the hook because of some conditional case
  if (ignoreMissingContext) {
    return {};
  }
  if (context === undefined) {
    throw new Error(
      'useListSelection must be used within a ListSelectionProvider',
    );
  }
  return context;
};

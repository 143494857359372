import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useParams } from 'react-router-dom';

import logo from '../../assets/images/logo-full.svg';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import PageLoader from '../../components/PageLoader';
import useApiErrorTranslation from '../../hooks/useApiErrorTranslation';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';

const UserResetPassword = () => {
  const [redirect, setRedirect] = useState(null);

  const { t } = useTranslation();
  const { translateError } = useApiErrorTranslation();
  const { token } = useParams();

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const { toastError } = useCustomToast();

  const methods = useForm({
    defaultValues: {
      confirmPassword: '',
      email: '',
      newPassword: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });
  const { handleSubmit, setValue, watch } = methods;
  const watchEmail = watch('email');

  const {
    data,
    error: userQueryError,
    isLoading: userQueryIsLoading,
  } = useQuery(
    [`/users/reset-password-email/${token}`],
    async () => {
      const response = await fetch(`/users/reset-password-email/${token}`, {
        method: 'GET',
      });
      return response.json();
    },
    {
      onError: () => {
        toastError(t('Registration unsuccessful.'));
      },
      retry: 0,
    },
  );

  const userData = data?.data;

  useEffect(() => {
    setValue('email', userData?.email);
  }, [userData?.email, setValue]);

  const errorMessage = useMemo(
    () => userQueryError?.data?.errorCode,
    [userQueryError?.data?.errorCode],
  );

  const resetPasswordMutation = useMutation(
    (values) =>
      fetch(`/users/reset-password`, {
        body: {
          confirmPassword: values.confirmPassword,
          password: values.newPassword,
          resetPasswordCode: token,
        },
        method: 'POST',
      }),
    {
      onError: (error) => toastFetchError(error),
      onSuccess: () => {
        setRedirect(true);
      },
    },
  );

  if (redirect) {
    return <Navigate replace to="/reset-password-success" />;
  }

  if (resetPasswordMutation.isLoading || userQueryIsLoading) {
    return <PageLoader />;
  }

  return !errorMessage ? (
    <div className="flex flex-1 flex-col items-center justify-center pt-18">
      <img
        alt="Urbify logo"
        className="h-[96px] w-[128px]"
        height="96"
        src={logo}
      />
      <div className="my-[60px] flex w-full justify-center px-4">
        <div className="w-full max-w-[480px] rounded-md bg-white px-4 py-5 shadow-elevation-200 sm:p-8">
          <FormProvider {...methods}>
            <form
              noValidate
              onSubmit={handleSubmit(resetPasswordMutation.mutate)}
            >
              <div className="flex flex-col gap-5">
                <p className="text-base font-semibold text-primary-dark">
                  {t('Set a new password')}
                </p>
                <div className="flex flex-col gap-4">
                  <FormInput
                    disabled={!!watchEmail}
                    id="email"
                    label={t('Your Account e-mail Address')}
                    name="email"
                    required
                    type="email"
                  />

                  <FormInput
                    id="password"
                    label={t('New Password')}
                    minLength={{
                      message: t('Password has to be longer than 8 characters'),
                      value: 8,
                    }}
                    name="newPassword"
                    placeholder={t('Enter password')}
                    required
                    type="password"
                  />

                  <FormInput
                    id="confirm-password"
                    label={t('Repeat New Password')}
                    minLength={{
                      message: t('Password has to be longer than 8 characters'),
                      value: 8,
                    }}
                    name="confirmPassword"
                    placeholder={t('Repeat Password')}
                    required
                    type="password"
                    validate={(confirmPassword) => {
                      const newPassword = watch('newPassword');
                      if (confirmPassword !== newPassword) {
                        return t('Passwords must match');
                      }
                      return undefined;
                    }}
                  />
                </div>
                <div className="border-b-2 border-grey-200" />
                <Button
                  isFullWidth
                  text={t('Finish Setup')}
                  type="submit"
                  variant="solidBlack"
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  ) : (
    <div className="absolute top-1/3 w-full">
      <div className="container mx-auto">
        <div className="flex flex-col justify-center">
          <div className="mb-10 flex justify-center">
            <Link to="/">
              <span>
                <img alt="" height="96" src={logo} />
              </span>
            </Link>
          </div>
          <div className="px-9 pb-8 text-center text-base font-semibold text-primary-dark">
            <div>{translateError(userQueryError)}</div>
            {t(`Please contact your Urbify administrator.`)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserResetPassword;

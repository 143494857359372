import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import GroceriesTourStatus from '../../enums/GroceriesTourStatus';
import UserRole from '../../enums/UserRole';
import useGroceriesTourStatusTranslations from '../../hooks/useGroceriesTourStatusTranslations';
import { useUser } from '../../providers/UserProvider';
import GroceriesTourStatusIcon from './GroceriesTourStatusIcon';

const GroceryTourStatusInfo = ({ tourStatus }) => {
  const { t } = useTranslation();

  const tourStatusTranslations = useGroceriesTourStatusTranslations();
  const tourStatusName = tourStatusTranslations[tourStatus];
  const { isGroceries, user } = useUser();
  const userRole = user.role;
  const showCustomLabel =
    userRole === UserRole.Admin ||
    userRole === UserRole.OperationsManager ||
    userRole === UserRole.HubManager;

  const statusText = useMemo(() => {
    if (
      showCustomLabel &&
      tourStatus === GroceriesTourStatus.NotAssignedDriver
    ) {
      return t('Assign Driver');
    }

    if (tourStatus === GroceriesTourStatus.Active && !isGroceries) {
      return undefined;
    }

    return tourStatusName;
  }, [isGroceries, showCustomLabel, t, tourStatus, tourStatusName]);

  return (
    <div className="inline-flex h-[28px] w-full content-start items-center gap-2 text-sm text-grey-900">
      <div className="flex h-4 w-4 content-start items-center">
        <GroceriesTourStatusIcon status={tourStatus} />
      </div>

      {statusText && <div data-test="status-text">{statusText}</div>}
    </div>
  );
};

GroceryTourStatusInfo.propTypes = {
  tourStatus: PropTypes.string.isRequired,
};

export default GroceryTourStatusInfo;

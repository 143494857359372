import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import useOnScan from '../../../hooks/useOnScan';
import BarcodeInput from '../BarcodeInput';
import BarcodeInputStatus from '../BarcodeInput/BarcodeInputStatus';

const expectedBarcodeValue = '0123456789-abcdyz_ABCDYZ';

const DemoCodeScan = () => {
  const { t } = useTranslation();

  const [value, setValue] = useState(undefined);

  const onScan = useCallback(
    (scannedEvent) => {
      const code = scannedEvent?.detail?.scanCode;
      setValue(code);
    },
    [setValue],
  );

  useOnScan(onScan);

  const status = useMemo(() => {
    if (value === expectedBarcodeValue) {
      return 'ready';
    }

    if (value === undefined) {
      return 'pending';
    }

    return 'error';
  }, [value]);

  const alertProps = {
    'data-test': 'scanner-ready-state',
    'data-test-status': status,
  };

  const inputRef = useRef(null);

  return (
    <div
      className="flex flex-col gap-4 rounded-md border border-grey-300 p-4"
      data-test="demo-code-scan"
    >
      <div>
        <span className="block pb-3 text-sm font-semibold uppercase text-grey-900">
          {t('Scan code')}
        </span>
        <BarcodeInput
          autoFocus={false}
          status={
            status === 'error'
              ? BarcodeInputStatus.ScanError
              : BarcodeInputStatus.Pending
          }
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if ((e.key === 'Enter' || e.code === 'Enter') && value.length > 0) {
              inputRef.current.blur();
            }
          }}
          ref={inputRef}
        />
      </div>

      <hr className="border-grey-200" />

      <div className="flex justify-between text-sm">
        <span>{t('Expected result:')}</span>
        <span className="inline-block font-mono font-medium">
          {expectedBarcodeValue}
        </span>
      </div>
      {status === 'ready' && (
        <Alert
          {...alertProps}
          message={t('Scanning device is ready')}
          variant="success"
        />
      )}
      {status === 'error' && (
        <Alert
          {...alertProps}
          message={t('Scanning device not ready')}
          variant="error"
        />
      )}
      {status === 'pending' && (
        <Alert
          {...alertProps}
          message={t('Waiting demo barcode scan')}
          variant="default"
        />
      )}
    </div>
  );
};

export default DemoCodeScan;

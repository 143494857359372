import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import Button from '../../components/Button';
import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import OrganisationType from '../../enums/OrganisationType';
import MembersFilters from '../../features/organisations/MembersFilters';
import MembersCardList from '../../features/shared/MembersCardList';
import MembersTable from '../../features/shared/MembersTable';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import Resource from '../../lib/api/Resource';

const OrganisationMembers = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { fetch } = useFetch();
  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;
  const [searchParams] = useSearchParams();

  const searchQuery = searchParams.get('search');
  const hubIdQuery = searchParams.get('hubId');
  const roleQuery = searchParams.get('role');
  const subcarrierIdQuery = searchParams.get('subcarrierId');

  const hasFiltersApplied = !!(
    searchQuery ||
    hubIdQuery ||
    roleQuery ||
    subcarrierIdQuery
  );

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isLoading,
  } = useQuery([`/carriers/${id}`], async () => {
    const response = await fetch(`/carriers/${id}`);
    return response.json();
  });

  const organisation = fetchedOrganisation?.data || null;
  const params = {
    name: searchQuery,
    carrierId: organisation?.id,
    role: roleQuery,
    subcarrierId: subcarrierIdQuery,
    hubId: hubIdQuery,
  };

  if (isError) {
    return <ErrorPage error={error} />;
  }

  const isSuperadminOrganisation =
    organisation?.type === OrganisationType.Superadmin;

  if (!organisation) {
    return null;
  }

  return (
    <Page>
      <Titlebar
        backLink={
          isSuperadminOrganisation
            ? '/organisations'
            : `/organisations/${organisation?.id}`
        }
        isActive={hasFiltersApplied}
        menu={
          <MembersFilters
            organisation={organisation}
            searchOnly={isSuperadminOrganisation}
          />
        }
        textPrimary={`${t('Members')} (${organisation?.userCount})`}
        textSecondary={organisation?.name}
        isLoading={isLoading}
        titleRightContent={
          <Button
            as={Link}
            className="w-full lg:w-fit"
            icon="userAdd"
            text={t('New Member')}
            to="new"
            variant="solidBlue"
          />
        }
      />
      <Page.Content variant={isDesktop ? 'white' : 'grey'}>
        <Resource params={params} resourceUrl="/users">
          {isDesktop ? (
            <MembersTable organisationType={organisation?.type} />
          ) : (
            <MembersCardList />
          )}
        </Resource>
      </Page.Content>
    </Page>
  );
};

export default OrganisationMembers;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CSSTransitionStage from '../../../enums/CSSTransitionStage';
import TourStatus from '../../../enums/TourStatus';
import useCSSTransition from '../../../hooks/useCSSTransition';

const getPercentage = (total, completed) => {
  if (total === 0) {
    return 0;
  }

  if (total !== undefined && completed !== undefined) {
    return (completed / total) * 100;
  }

  return 0;
};

const FixedTourProgress = ({ tour }) => {
  const start =
    tour?.status === TourStatus.Active || tour?.status === TourStatus.Completed;
  const end = tour?.status === TourStatus.Completed;
  const total = tour?.totalStops || 0;
  const completed = tour?.completedStops || 0;

  const { shouldMount, stage } = useCSSTransition(true, 500);

  const currentPercentage = getPercentage(total, completed);

  const { t } = useTranslation();

  const labelProgress =
    completed !== undefined && total !== undefined
      ? `${completed} / ${total}`
      : '0 / 0';

  return (
    <div className="flex flex-col gap-4 rounded-md bg-white p-4 shadow-elevation-100 print:hidden">
      <div className="flex flex-wrap items-start justify-between">
        <div className="text-sm uppercase text-grey-900">{t('Progress')}</div>
        <div className="flex items-center justify-center gap-1 text-primary-dark">
          <span className="text-base font-semibold">{labelProgress}</span>{' '}
          <span className="text-sm font-normal">{t('stops')}</span>
        </div>
      </div>

      <div className="w-full">
        <div className="flex">
          <div className="flex flex-1 flex-col items-center">
            <div
              className="h-2 w-full rounded-lg bg-grey-200"
              data-is-active={start}
              data-test="tour-progress-start"
            >
              <div
                className={cn(
                  'h-full rounded-lg bg-ui-green-dark',
                  start ? 'w-full' : 'w-0',
                )}
              />
            </div>
            <span className="mt-2 text-xs font-normal text-grey-700">
              {t('Start')}
            </span>
          </div>

          <div className="ml-2 mr-2 flex flex-[4] flex-col items-center text-center">
            <div className="h-2 w-full rounded-lg bg-grey-200">
              <div
                className="h-full max-w-full"
                data-percentage={currentPercentage}
                data-test="tour-progress-percentage"
                style={{ width: `${currentPercentage}%` }}
              >
                {shouldMount && (
                  <div
                    className={cn(
                      'h-full rounded-lg transition-[width] duration-500',
                      currentPercentage === 100
                        ? 'bg-ui-green-dark'
                        : 'bg-grey-700',
                      stage === CSSTransitionStage.To && 'h-full w-full',
                      stage === CSSTransitionStage.From && 'w-0',
                    )}
                  />
                )}
              </div>
            </div>
            <span className="mt-2 text-xs font-normal text-grey-700">
              {t('Delivery')}
            </span>
          </div>

          <div className="flex flex-1 flex-col items-center">
            <div className="h-2 w-full rounded-lg bg-grey-200">
              <div
                className={cn(
                  'h-full rounded-lg bg-ui-green-dark',
                  end ? 'w-full' : 'w-0',
                )}
                data-is-active={end}
                data-test="tour-progress-end"
              />
            </div>
            <span className="mt-2 text-xs font-normal text-grey-700">
              {t('End')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

FixedTourProgress.propTypes = {
  tour: PropTypes.shape({
    totalStops: PropTypes.number,
    completedStops: PropTypes.number,
    status: PropTypes.string,
  }).isRequired,
};

export default FixedTourProgress;

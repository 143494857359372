import { useTranslation } from 'react-i18next';

import FailureReason from '../enums/FailureReason';

const useFailureReasonTranslations = () => {
  const { t } = useTranslation();

  return {
    [FailureReason.NoOneAtTheAddress]: t('No one at the address'),
    [FailureReason.AddressNotFound]: t('Address not found'),
    [FailureReason.StoreClosed]: t('Store closed'),
    [FailureReason.NameNotOnTheDoorbell]: t('Name not on the doorbell'),
    [FailureReason.ForceMajeure]: t('Force majeure'),
    [FailureReason.RefusedByCustomer]: t('Refused by customer'),
    [FailureReason.Other]: t('Other'),
    [FailureReason.LackOfTime]: t('Exceeded execution time'),
    [FailureReason.Damaged]: t('Damaged'),
  };
};

export default useFailureReasonTranslations;

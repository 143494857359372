import cn from 'classnames';
import PropTypes from 'prop-types';

export const notificationCountBadgeVariantsMap = {
  warning: 'bg-ui-notification-yellow text-primary-dark',
  default: 'bg-grey-200 text-grey-500',
  alert: 'bg-ui-red text-white',
};

const NotificationCountBadge = (props) => {
  const { active, count, variant, ...rest } = props;

  return (
    <span
      className={cn(
        'flex h-5 items-center justify-center rounded px-1.5 text-xs font-medium transition-all duration-200 absolute right-3 lg:right-[-4px] lg:top-1',
        notificationCountBadgeVariantsMap[variant],
      )}
      {...rest}
    >
      {count}
    </span>
  );
};

NotificationCountBadge.propTypes = {
  active: PropTypes.bool,
  count: PropTypes.number,
  variant: PropTypes.oneOf(Object.keys(notificationCountBadgeVariantsMap)),
};

NotificationCountBadge.defaultProps = {
  active: false,
  count: 0,
  variant: 'default',
};

export default NotificationCountBadge;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Checkbox from '../Checkbox';

const CheckboxGroup = forwardRef((props, ref) => {
  const {
    checkboxClassName,
    disabled,
    name,
    onChange,
    options,
    selectedValues,
  } = props;

  return (
    <fieldset className="flex flex-col">
      {options.map((option) => (
        <div className="flex items-center gap-4" key={option.value}>
          <Checkbox
            checked={selectedValues?.includes(option.value)}
            disabled={disabled || option.disabled}
            label={option.label}
            name={name}
            ref={ref}
            value={option.value}
            onChange={onChange}
            className={checkboxClassName}
          />
          {option.description && (
            <p
              className={cn(
                'text-sm',
                option.disabled ? 'text-grey-700' : 'text-inherit',
              )}
            >
              {option.description}
            </p>
          )}
        </div>
      ))}
    </fieldset>
  );
});

CheckboxGroup.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool,
      description: PropTypes.string,
    }),
  ),
  selectedValues: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  checkboxClassName: PropTypes.string,
};

CheckboxGroup.defaultProps = {
  disabled: false,
  name: undefined,
  onChange: () => {},
  options: [],
  selectedValues: [],
  checkboxClassName: undefined,
};

export default CheckboxGroup;

const InvalidAddressReason = {
  DriverMarkedAsInvalidAddress: 'driverMarkedAsInvalidAddress',
  InvalidCity: 'invalidCity',
  InvalidStreetName: 'invalidStreetName',
  InvalidStreetNumber: 'invalidStreetNumber',
  NoGooglePostcodeAvailable: 'noGooglePostcodeAvailable',
  NoHubToAssign: 'noHubToAssign',
  NotConfidentEnough: 'notConfidentEnough',
};

export default InvalidAddressReason;

import { useTranslation } from 'react-i18next';

import logo from '../../assets/images/logo-full.svg';

const ForgotPasswordSuccess = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col items-center justify-center pt-18">
      <img
        alt="Urbify logo"
        className="h-[96px] w-[128px]"
        height="96"
        src={logo}
      />
      <div className="my-[60px] flex w-full justify-center px-4">
        <div className="flex w-full max-w-[480px] flex-col gap-5 rounded-md bg-white p-8 shadow-elevation-200">
          <p className="font-semibold">{t('Reset Password')}</p>
          <span className="text-sm">
            {t(
              'An e-mail has been sent. Check your mailbox and follow the instructions.',
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;

/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Dropdown from '../../../components/Dropdown/Dropdown';
import IconButton from '../../../components/IconButton';
import Label from '../../../components/Label';
import NewTable from '../../../components/NewTable';
import Tooltip from '../../../components/Tooltip';
import UserRole from '../../../enums/UserRole';
import { useIsRole } from '../../../providers/UserProvider';
import DeleteSubcarrierConfirmationModal from '../DeleteSubcarrierConfirmationModal';

const Actions = (props) => {
  const { subcarrierId } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const menuItems = useMemo(
    () => [
      {
        icon: 'edit',
        iconClassName: 'text-grey-700',
        onClick: () => {
          navigate(`/my-organisation/subcarriers/${subcarrierId}/edit`);
        },
        text: t('Edit Subcarrier'),
      },
      {
        icon: 'delete',
        iconClassName: 'text-ui-red',
        onClick: () => {
          setIsModalOpen(true);
        },
        text: t('Delete Subcarrier'),
        textClassName: 'text-ui-red',
      },
    ],
    [navigate, subcarrierId, t],
  );

  return (
    <>
      <DeleteSubcarrierConfirmationModal
        id={subcarrierId}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <Dropdown menu={menuItems}>
        <div className="flex">
          <Tooltip placement="top" size="sm" text={t('Subcarrier Options')}>
            <IconButton
              icon="moreHoriz"
              iconSize="s"
              size="s"
              variant="ghostBlack"
            />
          </Tooltip>
        </div>
      </Dropdown>
    </>
  );
};

Actions.propTypes = {
  subcarrierId: PropTypes.string,
};

Actions.defaultProps = {
  subcarrierId: undefined,
};

const SubcarriersTable = (props) => {
  const { t } = useTranslation();
  const isAdmin = useIsRole(UserRole.Admin);

  const columns = useMemo(() => {
    const config = [
      {
        accessorKey: 'name',
        header: t('Name'),
        size: 350,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'shortCode',
        header: t('Shortcode'),
        size: 50,
        cell: (info) => <Label size="xs" text={info.getValue()} />,
      },
      {
        header: t('Type'),
        size: 150,
        cell: () => <Label size="s" text={t('Subcarrier')} variant="green" />,
      },
      {
        accessorKey: 'userCount',
        header: t('Members'),
        size: 60,
        cell: (info) => info.getValue(),
      },
    ];

    if (isAdmin) {
      config.push({
        accessorKey: 'id',
        header: t('Actions'),
        cell: (info) => <Actions subcarrierId={info.getValue()} />,
        size: 60,
      });
    }

    return config;
  }, [isAdmin, t]);

  return (
    <NewTable
      columns={columns}
      noRecordsText={t('No Subcarriers linked to this Organisation')}
      {...props}
    />
  );
};

SubcarriersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
};

SubcarriersTable.defaultProps = {
  data: [],
  isLoading: false,
};

export default SubcarriersTable;

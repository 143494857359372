import cn from 'classnames';
import PropTypes from 'prop-types';

import Breakpoint from '../../enums/Breakpoint';
import CSSTransitionStage from '../../enums/CSSTransitionStage';
import useCSSTransition from '../../hooks/useCSSTransition';
import useWindowSize from '../../hooks/useWindowSize';
import { useTitlebarHeight } from '../../providers/TitlebarHeightProvider';
import LoadingSpinner from '../LoadingSpinner';

const StickyLoader = (props) => {
  const { isLoading } = props;
  const { subContentElementHeight, titlebarElementHeight } =
    useTitlebarHeight();
  const marginTop = subContentElementHeight + titlebarElementHeight;

  const { height, width } = useWindowSize();
  const isMobile = width < Breakpoint.LG;

  const { shouldMount, stage } = useCSSTransition(isLoading, 200);

  if (!shouldMount) {
    return null;
  }

  return (
    <div className="absolute bottom-0 top-0 w-full">
      <div
        className="sticky bottom-0 left-0 top-0 z-10 h-full w-full"
        style={{
          maxHeight: isMobile
            ? // -32px to accommodate Page.Content padding
              `calc(${height}px - ${marginTop}px - var(--topbar-height) - 32px)`
            : `calc(100vh - ${marginTop}px)`,
          top: isMobile
            ? `calc(${marginTop}px + var(--topbar-height))`
            : `${marginTop}px`,
        }}
      >
        <div
          className={cn(
            'absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 transition duration-200',
            stage === CSSTransitionStage.To && 'opacity-100',
            stage === CSSTransitionStage.From && 'opacity-0',
          )}
        >
          <LoadingSpinner className="text-grey-700" />
        </div>
      </div>
    </div>
  );
};

StickyLoader.propTypes = {
  isLoading: PropTypes.bool,
};

StickyLoader.defaultProps = {
  isLoading: false,
};

export default StickyLoader;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Alert from '../../components/Alert';
import GroceriesTourStatus from '../../enums/GroceriesTourStatus';
import GroceriesTourHelper from '../../helpers/GroceriesTourHelper';

const GroceriesTourAlert = (props) => {
  const { tour } = props;

  const { t } = useTranslation();
  const isUnresolved = GroceriesTourHelper.isUnresolved(tour);

  if (isUnresolved) {
    return (
      <div className="mb-4">
        <Alert
          data-test="tour-alert"
          message={t('This Tour has unresolved status')}
          variant="error"
        />
      </div>
    );
  }

  if (tour.status === GroceriesTourStatus.CompletedAdmin) {
    return (
      <div className="mb-4">
        <Alert
          data-test="tour-alert"
          message={`${t('Warning')}: ${t('Tour completed by Admin')}`}
          variant="warningOrange"
        />
      </div>
    );
  }

  if (tour.status === GroceriesTourStatus.CanceledAdmin) {
    return (
      <div className="mb-4">
        <Alert
          data-test="tour-alert"
          message={`${t('Warning')}: ${t('Tour cancelled by Admin')}`}
          variant="warningOrange"
        />
      </div>
    );
  }

  return null;
};

GroceriesTourAlert.propTypes = {
  tour: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default GroceriesTourAlert;

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const Meta = ({ title }) => <Helmet title={title && `${title} | Urbify`} />;

Meta.propTypes = {
  title: PropTypes.string,
};

Meta.defaultProps = {
  title: '',
};

export default Meta;

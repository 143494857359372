import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import useFailureReasonTranslations from '../../../hooks/useFailureReasonTranslations';
import CollapsedInfo from './CollapsedInfo';
import ExpandableStatusItem from './ExpandableStatusItem';
import FailureReasons from './FailureReasons';

const RetryStatusItem = (props) => {
  const {
    currentUnitStatus,
    isCurrent,
    isTourActiveOrCompleted,
    isUnitRouted,
    item,
  } = props;
  const { watch } = useFormContext();
  const failureReason = watch('failureReason');
  const failureReasonTranslations = useFailureReasonTranslations();
  const text = failureReasonTranslations[failureReason];

  return (
    <ExpandableStatusItem
      isCurrent={isCurrent}
      item={item}
      isUnitRouted={isUnitRouted}
      currentUnitStatus={currentUnitStatus}
      isTourActiveOrCompleted={isTourActiveOrCompleted}
      collapsedChildren={<FailureReasons />}
    >
      {text && <CollapsedInfo text={text} />}
    </ExpandableStatusItem>
  );
};

RetryStatusItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  isUnitRouted: PropTypes.bool,
  isCurrent: PropTypes.bool,
  currentUnitStatus: PropTypes.string,
  isTourActiveOrCompleted: PropTypes.bool,
};

RetryStatusItem.defaultProps = {
  item: undefined,
  isCurrent: false,
  isUnitRouted: false,
  currentUnitStatus: undefined,
  isTourActiveOrCompleted: false,
};

export default RetryStatusItem;

/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Dropdown from '../../../components/Dropdown/Dropdown';
import IconButton from '../../../components/IconButton';
import Label from '../../../components/Label';
import NewTable from '../../../components/NewTable';
import Tooltip from '../../../components/Tooltip';
import OrganisationType from '../../../enums/OrganisationType';
import UserRole from '../../../enums/UserRole';
import { useIsRole } from '../../../providers/UserProvider';
import DeleteShiftModal from '../DeleteShiftModal';

const Actions = ({ isHubShift, shiftId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const menuItems = useMemo(
    () => [
      {
        icon: 'edit',
        iconClassName: 'text-grey-700',
        onClick: () => {
          navigate(`/my-organisation/shifts/${shiftId}/edit`);
        },
        text: t('Edit Shift'),
      },
      {
        icon: 'delete',
        iconClassName: 'text-ui-red',
        onClick: () => {
          setIsModalOpen(true);
        },
        text: t('Delete Shift'),
        textClassName: 'text-ui-red',
      },
    ],
    [navigate, shiftId, t],
  );

  return (
    <>
      <DeleteShiftModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        shiftId={shiftId}
        isHubShift={isHubShift}
      />
      <Dropdown menu={menuItems}>
        <div className="flex">
          <Tooltip placement="top" size="sm" text={t('Shift Options')}>
            <IconButton
              icon="moreHoriz"
              iconSize="s"
              size="s"
              variant="ghostBlack"
            />
          </Tooltip>
        </div>
      </Dropdown>
    </>
  );
};

Actions.propTypes = {
  shiftId: PropTypes.string.isRequired,
  isHubShift: PropTypes.bool,
};

Actions.defaultProps = {
  isHubShift: false,
};

const ShiftsTable = (props) => {
  const { children, className, isCustomer, organisationType, ...rest } = props;
  const isAdmin = useIsRole(UserRole.Admin);

  const { t } = useTranslation();

  const getUnitLabel = useCallback(
    (number) => {
      if (number === 1) {
        return t('Morning');
      }
      if (number === 2) {
        return t('Afternoon');
      }
      return t('Evening');
    },
    [t],
  );

  const columns = useMemo(() => {
    const allColumns = [
      {
        header: t('Type'),
        cell: (info) => (
          <span>{info.row.original?.hub ? t('Hub') : t('Carrier')}</span>
        ),
      },
      {
        header: t('Unit'),
        cell: (info) => (
          <span>
            {info.row.original.number} ({getUnitLabel(info.row.original.number)}
            )
          </span>
        ),
      },
      {
        header: t('Duration'),
        cell: (info) => (
          <span>
            {info.row.original.startTime} - {info.row.original.endTime}
          </span>
        ),
      },
      {
        cell: (info) => (
          <span>
            {info.row.original.deliverySlotStart} -{' '}
            {info.row.original.deliverySlotEnd}
          </span>
        ),
        header: t('Delivery Slot'),
      },
      {
        accessorKey: 'routingTime',
        header: t('Routing Time'),
        cell: (info) => info.getValue(),
      },
      {
        header: t('Label'),
        size: 50,
        cell: (info) => (
          <Label
            size="s"
            text={`S${info.row.original.number}`}
            variant="lightBlue"
          />
        ),
      },
      {
        accessorKey: 'hub',
        header: t('Hub'),
        size: 250,
        cell: (info) => (
          <span>
            {info.row.original?.hub
              ? info.row.original?.hub?.name
              : t('All (except Custom assigned)')}
          </span>
        ),
      },
      {
        header: t('Actions'),
        size: 60,
        enableResizing: false,
        cell: (info) => (
          <Actions
            shiftId={info.row.original.id}
            isHubShift={!!info.row.original.hub}
          />
        ),
      },
    ];

    return allColumns.filter((column) => {
      if (!isAdmin) {
        return column.key !== 'actions';
      }

      return column;
    });
  }, [t, getUnitLabel, isAdmin]);

  return (
    <NewTable
      columns={columns}
      data-test="members-table"
      noRecordsText={t('No Shifts added to this Organisation')}
      {...rest}
    />
  );
};

ShiftsTable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isCustomer: PropTypes.bool,
  organisationType: PropTypes.oneOf([
    OrganisationType.Carrier,
    OrganisationType.Superadmin,
  ]),
};

ShiftsTable.defaultProps = {
  children: undefined,
  className: '',
  isCustomer: false,
  organisationType: undefined,
};

export default ShiftsTable;

import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const buttonSizesMap = {
  m: 'w-10 h-10',
  s: 'w-8 h-8',
  xs: 'w-6 h-6',
};

const iconSizesMap = {
  m: 'w-5 h-5',
  s: 'w-4 h-4',
  xs: 'w-3 h-3',
};

const variantsMap = {
  ghostBlack: {
    className:
      'border-transparent bg-transparent text-primary-dark hover:bg-grey-200 active:bg-grey-200',
  },
  ghostGrey: {
    className:
      'border-transparent bg-transparent text-grey-500 hover:bg-grey-200 active:bg-grey-200',
  },
  ghostBlue: {
    className:
      'border-transparent bg-transparent text-ui-blue hover:bg-grey-200 active:bg-grey-200',
  },
  ghostRed: {
    className:
      'border-transparent bg-transparent text-ui-red hover:bg-grey-200 active:bg-grey-200',
  },
  outlineBlack: {
    className:
      'border-grey-500 bg-white text-primary-dark hover:bg-grey-200 active:bg-grey-200',
  },
  outlineBlue: {
    className:
      'border-grey-500 bg-white text-ui-blue hover:bg-grey-200 active:bg-grey-200',
  },
  outlineRed: {
    className:
      'border-grey-500 bg-white text-ui-red hover:bg-grey-200 active:bg-grey-200',
  },
  solidBlack: {
    className:
      'border-transparent bg-primary-dark text-white hover:bg-[rgba(29,29,27,0.8)] active:bg-[rgba(29,29,27,0.8)]',
  },
  solidBlue: {
    className:
      'border-transparent bg-ui-blue text-white hover:bg-[rgba(56,103,214,0.8)] active:bg-[rgba(56,103,214,0.8)]',
  },
  solidRed: {
    className:
      'border-transparent bg-ui-red text-white hover:bg-[rgba(245,59,87,0.8)] active:bg-[rgba(245,59,87,0.8)]',
  },
};

const IconButton = (props) => {
  const {
    active,
    as: Component,
    className,
    'data-test': dataTest,
    disabled,
    icon,
    iconSize,
    isFullWidth,
    onClick,
    size,
    variant,
    ...rest
  } = props;

  return (
    <Component
      className={cn(
        'inline-flex flex-shrink-0 cursor-pointer appearance-none items-center justify-center rounded-md border transition-colors focus:border-primary-yellow active:border-primary-yellow disabled:pointer-events-none disabled:opacity-40',
        active && 'border-primary-yellow',
        isFullWidth && '!w-full',
        buttonSizesMap[size],
        variantsMap[variant]?.className,
        className,
      )}
      data-test={dataTest}
      disabled={disabled}
      type="button"
      onClick={(e) => {
        if (disabled) {
          return;
        }

        onClick(e);
      }}
      {...rest}
    >
      {icon && <Icon className={iconSizesMap[iconSize]} icon={icon} />}
    </Component>
  );
};

IconButton.propTypes = {
  active: PropTypes.bool,
  as: PropTypes.elementType,
  className: PropTypes.string,
  'data-test': PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.oneOf(Object.keys(iconSizesMap)),
  isFullWidth: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(buttonSizesMap)),
  variant: PropTypes.oneOf(Object.keys(variantsMap)),
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  active: false,
  as: 'button',
  className: '',
  'data-test': undefined,
  disabled: false,
  iconSize: 'm',
  isFullWidth: false,
  size: 'm',
  variant: 'solidBlack',
  onClick: () => {},
};

export default IconButton;

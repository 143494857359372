import PropTypes from 'prop-types';
import { useCallback } from 'react';

import parseSearchParams from '../../helpers/parseSearchParams';
import useBlocker from '../../hooks/useBlocker';

const Block = ({ children, ignoreIdenticalPathname, requiredSearchParams }) => {
  const blockerFn = useCallback(
    ({ currentLocation, nextLocation }) => {
      if (requiredSearchParams?.length > 0) {
        const nextParams = parseSearchParams(
          new URLSearchParams(nextLocation.search),
        );

        const isValid = requiredSearchParams.every(
          (param) => nextParams[param] !== undefined,
        );

        if (!isValid) {
          return true;
        }
      }

      if (ignoreIdenticalPathname) {
        return currentLocation.pathname !== nextLocation.pathname;
      }
      return true;
    },
    [ignoreIdenticalPathname, requiredSearchParams],
  );

  const blocker = useBlocker(blockerFn, true);
  return children(blocker);
};

Block.propTypes = {
  children: PropTypes.func.isRequired,
  ignoreIdenticalPathname: PropTypes.bool.isRequired,
  requiredSearchParams: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const BlockNavigation = (props) => {
  const {
    children,
    ignoreIdenticalPathname,
    requiredSearchParams,
    shouldBlock,
  } = props;

  if (!shouldBlock) {
    return null;
  }

  return (
    <Block
      requiredSearchParams={requiredSearchParams}
      ignoreIdenticalPathname={ignoreIdenticalPathname}
    >
      {(blocker) => children(blocker)}
    </Block>
  );
};

BlockNavigation.propTypes = {
  children: PropTypes.func,
  shouldBlock: PropTypes.bool,
  ignoreIdenticalPathname: PropTypes.bool,
  requiredSearchParams: PropTypes.arrayOf(PropTypes.string),
};

BlockNavigation.defaultProps = {
  children: () => {},
  shouldBlock: false,
  ignoreIdenticalPathname: false,
  requiredSearchParams: [],
};

export default BlockNavigation;

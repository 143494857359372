const UnitProgress = {
  RoutingPending: 'routingPending',
  RoutingDone: 'routingDone',
  Ready: 'ready',
  OnTour: 'onTour',
  FinalStatus: 'finalStatus',
  ShipToClient: 'shipToClient',
  Blocked: 'blocked',
};

export default UnitProgress;

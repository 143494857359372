import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Tooltip from '../../../components/Tooltip';
import TourStopStatus from '../../../enums/TourStopStatus';
import UserRole from '../../../enums/UserRole';
import TourHelper from '../../../helpers/TourHelper';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import Allow from '../../../lib/rbac/Allow';

const TourAlert = (props) => {
  const { tour } = props;

  const { t } = useTranslation();
  const isNotCheckedOut = TourHelper.isNotCheckedOut(tour);
  const [isVisible, setIsVisible] = useState(true);
  const { toastSuccess } = useCustomToast();
  const { toastFetchError } = useToastFetchError();
  const { fetch } = useFetch();

  const { data, isLoading } = useQuery(
    [`/tours/${tour.id}/stops`],
    async () => {
      const response = await fetch(`/tours/${tour.id}/stops`, {
        method: 'GET',
      });
      return response.json();
    },
    {
      onError: (error) => toastFetchError(error),
    },
  );

  const tourStops = data?.data;

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      fetch(`/tours/${tour.id}/manual-checkout`, {
        method: 'PUT',
      }),
    {
      onError: (error) => toastFetchError(error),
      onSuccess: () => {
        setIsVisible(false);
        toastSuccess(t('Tour checked-out.'));
        queryClient.invalidateQueries({
          queryKey: [`/tours/${tour.id}`],
        });
      },
    },
  );

  const isManualCheckoutEnabled = useMemo(() => {
    if (!tourStops) {
      return false;
    }

    return tourStops.every((stop) => stop.status !== TourStopStatus.Pending);
  }, [tourStops]);

  if (!isVisible) {
    return null;
  }

  if (isNotCheckedOut) {
    return (
      <Allow
        roles={[
          UserRole.Admin,
          UserRole.OperationsManager,
          UserRole.HubManager,
        ]}
      >
        <div className="mb-4 flex flex-col gap-3 lg:flex-row lg:gap-4">
          <Alert
            data-test="tour-alert"
            message={t("Driver didn't check-out this Tour.")}
            variant="error"
          />
          <Tooltip
            text={t('All Unit statuses have to be updated')}
            hidden={isManualCheckoutEnabled}
          >
            <Button
              isFullWidth
              data-test="check-out-tour"
              disabled={
                mutation.isLoading || isLoading || !isManualCheckoutEnabled
              }
              text={t('Check-out Tour')}
              variant="outlineBlack"
              onClick={mutation.mutate}
            />
          </Tooltip>
        </div>
      </Allow>
    );
  }

  return null;
};

TourAlert.propTypes = {
  tour: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default TourAlert;

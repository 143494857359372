import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import BrowserPrintStatus from '../../../lib/browser-print/enums/BrowserPrintStatus';
import InfoBox from '../InfoBox';

const BrowserPrintLoader = (props) => {
  const { status } = props;
  const { t } = useTranslation();

  if (status === BrowserPrintStatus.Pending) {
    return (
      <InfoBox
        icon={
          <Icon
            className="h-12 w-12 animate-spin text-primary-yellow"
            icon="loading"
          />
        }
        text={t('Establishing connection with default printing device...')}
        title={t('Initializing scanning feed')}
      />
    );
  }

  if (status === BrowserPrintStatus.ConnectionError) {
    return (
      <InfoBox
        dataTest="zebra-connection-error"
        icon={<Icon className="h-12 w-12 text-ui-red" icon="report" />}
        text={t(
          'An error occurred while attempting to connect to your Zebra Printer. You may not have Zebra Browser Print installed, or it may not be running. Install Zebra Browser Print, or start the Zebra Browser Print Service, and refresh this page.',
        )}
        title={t('Zebra Browser Print Error')}
      >
        <Button
          icon="reload"
          text={t('Reload')}
          variant="outlineBlack"
          onClick={() => window.location.reload()}
        />
      </InfoBox>
    );
  }

  return null;
};

BrowserPrintLoader.propTypes = {
  status: PropTypes.oneOf(Object.values(BrowserPrintStatus)).isRequired,
};

BrowserPrintLoader.defaultProps = {};

export default BrowserPrintLoader;

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const Portal = (props) => {
  const { children } = props;
  return ReactDOM.createPortal(children, document.body);
};

Portal.propTypes = {
  children: PropTypes.node,
};

Portal.defaultProps = {
  children: undefined,
};

export default Portal;

import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DebouncedSearch from '../../../components/DebouncedSearch';
import Select from '../../../components/Select';
import ToursSearchBy from './ToursSearchBy';

const ToursSearch = (props) => {
  const { onSearch, onSearchByChange, searchBy, searchValue } = props;

  const { t } = useTranslation();

  const options = useMemo(
    () => [
      {
        label: t('Tour Code'),
        value: ToursSearchBy.TourNumber,
      },
      {
        dropdownLabel: t('Driver name'),
        label: t('Driver'),
        value: ToursSearchBy.DriverName,
      },
    ],
    [t],
  );

  return (
    <div className="flex  flex-1">
      <Select
        className="lg:w-[115px] lg:basis-[content]"
        dropdownClassname="lg:w-[160px]"
        flatRight
        id="tour-id-select"
        isSearchable={false}
        options={options}
        required
        value={searchBy}
        onChange={onSearchByChange}
      />
      <DebouncedSearch
        minLength={3}
        className="rounded-l-none lg:w-[240px]"
        isFullWidth
        placeholder={`${t('Type here')} (min. 3)...`}
        searchValue={searchValue}
        onSearch={onSearch}
        data-test="tours-search-input"
      />
    </div>
  );
};

ToursSearch.propTypes = {
  onSearch: PropTypes.func,
  onSearchByChange: PropTypes.func,
  searchBy: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
};

ToursSearch.defaultProps = {
  onSearch: () => {},
  onSearchByChange: () => {},
  searchValue: undefined,
};

export default ToursSearch;

import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputLayout from '../../../components/InputLayout';
import Textarea from '../../../components/Textarea';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import useFormInputValidationRules from '../../../hooks/useFormInputValidationRules';
import StatusItem from './StatusItem';

const MissortedStatusItem = (props) => {
  const {
    currentUnitStatus,
    isCurrent,
    isTourActiveOrCompleted,
    isUnitRouted,
    item,
  } = props;
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const status = watch('status');

  const rules = useFormInputValidationRules({
    required: true,
  });

  const errorInput =
    errors.postponeReasonNote && errors.postponeReasonNote.message;

  return (
    <>
      <StatusItem
        item={item}
        isCurrent={isCurrent}
        isUnitRouted={isUnitRouted}
        currentUnitStatus={currentUnitStatus}
        isTourActiveOrCompleted={isTourActiveOrCompleted}
      />
      {status === DeliveryTaskUnitStatus.Missorted && (
        <div className="px-3">
          <Controller
            name="missortedHub"
            control={control}
            rules={rules()}
            render={({ field }) => (
              <InputLayout label={t('Hub')} required error={errorInput}>
                <Textarea
                  hasError={!!errorInput}
                  readOnly={
                    currentUnitStatus === DeliveryTaskUnitStatus.Missorted
                  }
                  autoFocus
                  className="resize-none"
                  placeholder={t(
                    'Enter the name of the Hub where the Unit is currently located',
                  )}
                  {...field}
                />
              </InputLayout>
            )}
          />
        </div>
      )}
    </>
  );
};

MissortedStatusItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  isUnitRouted: PropTypes.bool,
  isCurrent: PropTypes.bool,
  currentUnitStatus: PropTypes.string,
  isTourActiveOrCompleted: PropTypes.bool,
};

MissortedStatusItem.defaultProps = {
  item: undefined,
  isCurrent: false,
  isUnitRouted: false,
  currentUnitStatus: undefined,
  isTourActiveOrCompleted: false,
};

export default MissortedStatusItem;

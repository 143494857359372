import { useEffect } from 'react';

const ScrollbarCalculator = () => {
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      `${window.innerWidth - document.body.clientWidth}px`,
    );
  }, []);
};

export default ScrollbarCalculator;

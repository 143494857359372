import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/Button';
import CancelPrompt from '../../components/CancelPrompt';
import { Card } from '../../components/Card';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import ShiftForm from '../../features/shifts/ShiftForm';
import ShiftTimeConflictModal from '../../features/shifts/ShiftTimeConflictModal';
import useBlocker from '../../hooks/useBlocker';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';
import { useUser } from '../../providers/UserProvider';

const MyOrganisationEditShift = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toastFetchError } = useToastFetchError();
  const { toastSuccess } = useCustomToast();
  const { fetch } = useFetch();
  const { user } = useUser();
  const { id } = useParams();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isShiftTimeConflictModalOpen, setIsShiftTimeConflictModalOpen] =
    useState(false);

  const { data: organisationShifts } = useQuery(
    [`/carriers/${user.carrier.id}/shifts`],
    async () => {
      const response = await fetch(`/carriers/${user.carrier.id}/shifts`, {
        method: 'GET',
      });
      return response.json();
    },
    {
      onError: (error) => toastFetchError(error),
    },
  );

  const shift = useMemo(() => {
    if (organisationShifts?.data && organisationShifts?.data.length > 0) {
      return organisationShifts.data.find((item) => item.id === id);
    }

    return undefined;
  }, [id, organisationShifts?.data]);

  const mutation = useMutation(
    (body) =>
      fetch(`/shifts/${id}`, {
        body,
        method: 'PATCH',
      }),
    {
      onError: toastFetchError,
      onSuccess: () => {
        setIsFormDirty(false);
        toastSuccess(t('Shift edited.'));
      },
    },
  );

  const shouldBlock = useMemo(() => {
    if (mutation.isSuccess) {
      return false;
    }
    return isFormDirty;
  }, [isFormDirty, mutation.isSuccess]);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate('/my-organisation/shifts', { replace: true });
    }
  }, [mutation.isSuccess, navigate]);

  const onSubmit = async (values) => {
    await mutation.mutateAsync(values);
  };

  if (!shift) {
    return null;
  }

  const title = `${shift?.hub ? t('Hub Shift') : t('Carrier Shift')} ${
    shift?.number
  }`;

  return (
    <>
      <CancelPrompt
        title={t('Cancel Editing Shift?')}
        isOpen={blocker.state === 'blocked'}
        onClose={() => blocker.reset()}
        onExitClick={() => blocker.proceed()}
      />
      <ShiftTimeConflictModal
        isOpen={isShiftTimeConflictModalOpen}
        onClose={() => setIsShiftTimeConflictModalOpen(false)}
      />
      <Page>
        <Titlebar
          icon={<Icon className="h-5 w-5 text-ui-blue" icon="edit" />}
          textPrimary={t('Edit Shift')}
          textSecondary={title}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              disabled={mutation.isLoading}
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => navigate('/my-organisation/shifts')}
            />
          }
        />

        <Page.Content centerContent variant="grey">
          <div className="w-full max-w-[480px]">
            <Card className="pt-6 sm:p-8">
              <ShiftForm
                isSubmitting={mutation.isLoading}
                onIsDirtyChange={setIsFormDirty}
                onCancel={() => navigate('/my-organisation/shifts')}
                onShiftSubmit={onSubmit}
                existingShifts={organisationShifts?.data}
                number={shift.number}
                startTime={shift.startTime}
                endTime={shift.endTime}
                routingTime={shift.routingTime}
                deliverySlotStart={shift.deliverySlotStart}
                deliverySlotEnd={shift.deliverySlotEnd}
                hubId={shift.hub?.id}
                shiftType={shift.hub ? 'hubShift' : 'carrierShift'}
              />
            </Card>
          </div>
        </Page.Content>
      </Page>
    </>
  );
};

export default MyOrganisationEditShift;

import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../../components/Icon';
import TourStatus from '../../../enums/TourStatus';

const statusesMap = {
  [TourStatus.Created]: {
    color: 'bg-primary-yellow',
  },
  [TourStatus.Active]: {
    color: 'bg-ui-green-dark',
  },
  [TourStatus.Completed]: {
    color: 'bg-ui-blue',
  },
  [TourStatus.Pending]: {
    color: 'bg-ui-green-dark',
  },
};

const TourStatusIcon = ({ isDelayed, isManualCheckout, status }) => {
  if (status === TourStatus.Completed && isManualCheckout) {
    return (
      <Icon
        className="w-3 h-3 text-ui-blue"
        data-test="tour-status-icon"
        icon="warningRounded"
      />
    );
  }

  if (
    status === TourStatus.NotAssignedDriver ||
    (status === TourStatus.Active && isDelayed)
  ) {
    return (
      <Icon
        className="w-4 h-4 text-ui-warning-orange"
        data-test="tour-status-icon"
        icon="warning"
      />
    );
  }

  return (
    <div className="flex items-center justify-center w-4 h-4">
      <div className={cn('w-2 h-2 rounded-full', statusesMap[status].color)} />
    </div>
  );
};

TourStatusIcon.propTypes = {
  status: PropTypes.oneOf(Object.values(TourStatus)),
  isDelayed: PropTypes.bool,
  isManualCheckout: PropTypes.bool,
};

TourStatusIcon.defaultProps = {
  status: TourStatus.Pending,
  isDelayed: false,
  isManualCheckout: false,
};

export default TourStatusIcon;

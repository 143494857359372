import cn from 'classnames';
import PropTypes from 'prop-types';

const variantsMap = {
  dev: 'bg-ui-red',
  local: 'bg-ui-warning-orange',
  stage: 'bg-ui-purple',
};

const EnvLabel = (props) => {
  const { env } = props;

  if (!env) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex items-center justify-center rounded-md px-2 py-1 text-2xs uppercase text-white lg:w-full',
        variantsMap[env],
      )}
    >
      <span>{env}</span>
    </div>
  );
};

EnvLabel.propTypes = {
  env: PropTypes.oneOf(Object.keys(variantsMap)),
};

EnvLabel.defaultProps = {
  env: undefined,
};

export default EnvLabel;

import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputLayout from '../../../components/InputLayout';
import Textarea from '../../../components/Textarea';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import useFormInputValidationRules from '../../../hooks/useFormInputValidationRules';
import ChangeParcelStatusContext from './ChangeUnitStatusContext';

const StatusNote = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const changeParcelStatusContext = useContext(ChangeParcelStatusContext);

  const { defaultValues = {} } = changeParcelStatusContext;
  const { status: originalStatus } = defaultValues;
  const status = watch('status');
  const unitSpecialCase = watch('unitSpecialCase');

  const isOriginalStatusIdenticalToCurrentStatus = originalStatus === status;

  const errorInput = errors.statusNote && errors.statusNote.message;

  const rules = useFormInputValidationRules({
    required: status === DeliveryTaskUnitStatus.InClarification,
  });

  const isDisabled =
    (isOriginalStatusIdenticalToCurrentStatus || status === '') &&
    !unitSpecialCase;

  return (
    <Controller
      name="statusNote"
      control={control}
      rules={rules()}
      render={({ field: { value, ...controllerProps } }) => (
        <InputLayout
          error={errorInput}
          id="statusNote"
          label={t('Change Note (internal)')}
          required={status === DeliveryTaskUnitStatus.InClarification}
          inputCounter={
            (!isDisabled && `${value?.length || 0}/1000`) || undefined
          }
        >
          <Textarea
            hasError={!!errorInput}
            showCounter
            data-test="status-note"
            disabled={isDisabled}
            placeholder={t('Type here...')}
            value={value}
            {...controllerProps}
          />
        </InputLayout>
      )}
    />
  );
};

export default StatusNote;

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Select from '../../../components/Select';
import LocalStorage from '../../../enums/LocalStorage';
import LocalStorageHelper from '../../../helpers/LocalStorageHelper';
import useCustomToast from '../../../hooks/useCustomToast';

const options = [
  {
    label: 'Deutsch',
    value: 'de',
  },
  {
    label: 'English',
    value: 'en',
  },
];

const LanguagePicker = () => {
  const { toastInfo } = useCustomToast();
  const { i18n, t } = useTranslation();

  return (
    <Select
      isSearchable={false}
      options={options}
      required
      value={i18n.language}
      onChange={(value) => {
        i18n.changeLanguage(value);
        moment.locale(value);
        LocalStorageHelper.setItem(LocalStorage.Language, value);
        toastInfo(t('Application language updated.'));
      }}
    />
  );
};

LanguagePicker.propTypes = {};

LanguagePicker.defaultProps = {};

export default LanguagePicker;

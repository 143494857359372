const DelayReason = {
  AggressiveCustomer: 'aggressiveCustomer',
  DeliveryWindowTooSmall: 'deliveryWindowTooSmall',
  IncompleteTour: 'incompleteTour',
  IncorrectNavigation: 'incorrectNavigation',
  MissingBoxes: 'missingBoxes',
  OccupiedLoadingRamps: 'occupiedLoadingRamps',
  Other: 'other',
  TooLongStopAtCustomer: 'tooLongStopAtCustomer',
  TooManyBoxes: 'tooManyBoxes',
  TourNotReadyOnTime: 'tourNotReadyOnTime',
  WrongAddress: 'wrongAddress',
};

export default DelayReason;

import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import ListChoice from '../../../components/ListChoice';
import useDeliveryUnitStatusTranslations from '../../../hooks/useDeliveryUnitStatusTranslations';
import UnitProgressIcon from '../UnitProgressIcon/UnitProgressIcon';

const StatusItem = (props) => {
  const {
    currentUnitStatus,
    isCurrent,
    isTourActiveOrCompleted,
    isUnitRouted,
    item,
  } = props;
  const { control, reset, setValue } = useFormContext();
  const deliveryUnitStatusTranslations = useDeliveryUnitStatusTranslations();

  return (
    <Controller
      name="status"
      control={control}
      render={({ field: { onChange, value } }) => {
        const isSelected = value === item.value;
        return (
          <ListChoice
            isSelected={isSelected}
            onClick={() => {
              if (isCurrent) {
                reset();
                return;
              }

              if (item.value === value) {
                return;
              }

              setValue('deliveryOption', null);
              setValue('deliveryOptionNote', '');
              setValue('notifyCustomer', false);
              setValue('statusNote', '');
              setValue('unitSpecialCase', '');
              setValue('postponeReason', '');
              setValue('returnReason', '');
              setValue('failureReason', '');
              setValue('postponeReasonNote', '');
              onChange(item.value);
            }}
          >
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-1 text-sm">
                <div className="font-medium text-primary-dark flex items-center gap-2">
                  <UnitProgressIcon
                    isUnitRouted={isUnitRouted}
                    unitStatus={item.value}
                    currentUnitStatus={currentUnitStatus}
                    isTourActiveOrCompleted={isTourActiveOrCompleted}
                  />
                  <span>{deliveryUnitStatusTranslations[item.value]}</span>
                </div>
                {item.description && (
                  <div className="text-grey-700">{item.description}</div>
                )}
              </div>
            </div>
          </ListChoice>
        );
      }}
    />
  );
};

StatusItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  isUnitRouted: PropTypes.bool,
  isCurrent: PropTypes.bool,
  currentUnitStatus: PropTypes.string,
  isTourActiveOrCompleted: PropTypes.bool,
};

StatusItem.defaultProps = {
  item: undefined,
  isCurrent: false,
  isUnitRouted: false,
  currentUnitStatus: undefined,
  isTourActiveOrCompleted: false,
};

export default StatusItem;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Select from '../Select';

const PageSizePicker = (props) => {
  const { onChange, pageSize, pageSizeOptions } = props;

  const { t } = useTranslation();
  const pageText = t('page');

  const options = pageSizeOptions.map((option) => ({
    label: `${option} / ${pageText}`,
    value: option,
  }));

  return (
    <Select
      className="w-[108px]"
      dropdownMinWidth={160}
      id="page-size-select"
      isSearchable={false}
      options={options}
      placement="bottom"
      required
      size="s"
      value={pageSize}
      onChange={onChange}
    />
  );
};

PageSizePicker.propTypes = {
  onChange: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
};

PageSizePicker.defaultProps = {
  onChange: () => {},
  pageSize: undefined,
  pageSizeOptions: [15, 25, 50],
};

export default PageSizePicker;

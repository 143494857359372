import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import PrinterStatus from '../../../enums/PrinterStatus';

const PrinterInfo = (props) => {
  const { status } = props;

  const { t } = useTranslation();

  const testProps = {
    'data-test': 'printer-info',
    'data-test-status': status,
  };

  if (status === PrinterStatus.Ready) {
    return (
      <Alert
        {...testProps}
        message={t('Connected - standing by...')}
        variant="default"
      />
    );
  }

  if (status === PrinterStatus.Initialising) {
    return (
      <Alert
        {...testProps}
        message={t('Connecting to Printer')}
        variant="loading"
      />
    );
  }

  if (status === PrinterStatus.Printing) {
    return (
      <Alert
        {...testProps}
        message={`${t('Printing label')}...`}
        variant="default"
      />
    );
  }

  if (status === PrinterStatus.Printed) {
    return (
      <Alert {...testProps} message={t('Label Printed')} variant="success" />
    );
  }

  if (status === PrinterStatus.NotReady) {
    return (
      <Alert
        {...testProps}
        message={t('Error Connecting to Printer')}
        variant="error"
      />
    );
  }

  return (
    <Alert {...testProps} message={t('Printing Failed')} variant="error" />
  );
};

PrinterInfo.propTypes = {
  status: PropTypes.oneOf(Object.values(PrinterStatus)),
};

PrinterInfo.defaultProps = {
  status: undefined,
};

export default PrinterInfo;

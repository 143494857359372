import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useRef, useState } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import BrowserClosePrompt from '../app/BrowserClosePrompt/BrowserClosePrompt';
import GlobalHelmet from '../app/GlobalHelmet/GlobalHelmet';
import Notifications from '../app/Notifications/Notifications';
import ScrollbarCalculator from '../app/ScrollbarCalculator';
import ServiceWorkerUpdateChecker from '../app/ServiceWorkerUpdateChecker';
import VersionToastHandler from '../app/VersionToastHandler';
import MaintenancePage from '../components/MaintenancePage';
import { FetchProvider } from '../lib/api/hooks/useFetch';
import { WebsocketProvider } from '../lib/api/hooks/useSocket';
import PosthogProvider from '../providers/PosthogProvider';
import SocketIdProvider from '../providers/SocketIdProvider';
import TitlebarHeightProvider from '../providers/TitlebarHeightProvider';
import UserProvider from '../providers/UserProvider';

const Root = () => {
  const [isMaintenanceEnabled, setIsMaintenanceEnabled] = useState(false);

  const queryClientRef = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false,
        },
      },
    }),
  );

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Notifications />
      <ScrollRestoration />
      <GlobalHelmet />
      <ScrollbarCalculator />
      {'serviceWorker' in navigator && <ServiceWorkerUpdateChecker />}
      {!window.Cypress && <VersionToastHandler />}
      <SkeletonTheme baseColor="#F6F6F6" highlightColor="#EEEEEE">
        <UserProvider>
          <PosthogProvider>
            <SocketIdProvider>
              <FetchProvider
                onError={(response) => {
                  if (response.status === 503 && response?.data?.maintenance) {
                    setIsMaintenanceEnabled(true);
                  }
                }}
                url={`${import.meta.env.VITE_API_URL}/api/v1`}
              >
                <WebsocketProvider enabled={!isMaintenanceEnabled}>
                  <TitlebarHeightProvider>
                    <BrowserClosePrompt />
                    {isMaintenanceEnabled ? <MaintenancePage /> : <Outlet />}
                  </TitlebarHeightProvider>
                </WebsocketProvider>
              </FetchProvider>
            </SocketIdProvider>
          </PosthogProvider>
        </UserProvider>
      </SkeletonTheme>
      {!window.Cypress && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};

export default Root;

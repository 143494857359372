import cn from 'classnames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MemberStatusType from '../../../enums/MemberStatusType';

const MemberStatus = ({ status: propStatus }) => {
  const { t } = useTranslation();

  const renderUserStatus = useCallback(
    (status) => {
      if (status === MemberStatusType.Active) {
        return t('Active');
      }
      if (status === MemberStatusType.Invited) {
        return t('Invited');
      }
      if (status === MemberStatusType.Deactivated) {
        return t('Inactive');
      }
      return '';
    },
    [t],
  );

  return (
    <span
      className={cn(
        'capitalize',
        propStatus === MemberStatusType.Active && 'text-ui-green-dark',
        propStatus === MemberStatusType.Invited && 'text-ui-warning-orange',
        propStatus === MemberStatusType.Deactivated && 'text-grey-700',
      )}
    >
      {renderUserStatus(propStatus)}
    </span>
  );
};

MemberStatus.propTypes = {
  status: PropTypes.string,
};

MemberStatus.defaultProps = {
  status: undefined,
};

export default MemberStatus;

import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useParams } from 'react-router-dom';

import Meta from '../../app/Meta';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import FadingOverlay from '../../components/FadingOverlay';
import Page from '../../components/Page';
import TabsCard from '../../components/TabsCard';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import UserRole from '../../enums/UserRole';
import CustomerGroceryTourDetails from '../../features/groceries/CustomerGroceryTourDetails';
import GroceriesResolveTourStatusModal from '../../features/groceries/GroceriesResolveTourStatusModal';
import GroceriesTourActivity from '../../features/groceries/GroceriesTourActivity';
import GroceriesTourAlert from '../../features/groceries/GroceriesTourAlert';
import GroceriesTourCarrier from '../../features/groceries/GroceriesTourCarrier';
import GroceriesTourStatusCard from '../../features/groceries/GroceriesTourStatusCard';
import ShiftLabel from '../../features/shared/ShiftLabel';
import TourPerformance from '../../features/shared/TourPerformance';
import CardDetailsRow from '../../features/tours/CardDetailsRow';
import getTourDuration from '../../helpers/getTourDuration';
import metersToKilometers from '../../helpers/metersToKilometers';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import { SocketHandler } from '../../lib/api/hooks/useSocket';
import Allow from '../../lib/rbac/Allow';
import { useIsRole, useUser } from '../../providers/UserProvider';

const PageSkeleton = () => (
  <Page.Content variant="grey">
    <div className="grid gap-4 lg:grid-cols-2">
      <div className="flex flex-col gap-4">
        <Card>
          <Skeleton count={1} height={58} />
        </Card>
        <Card>
          <Skeleton count={1} height={72} />
        </Card>
        <Card>
          <FadingOverlay>
            <div className="flex flex-col gap-4">
              <Skeleton height={120} />
              <Skeleton height={120} />
              <Skeleton height={120} />
            </div>
          </FadingOverlay>
        </Card>
      </div>

      <div className="flex flex-col gap-4">
        <Card>
          <Skeleton count={1} height={140} />
        </Card>
        <Card>
          <Skeleton count={1} height={132} />
        </Card>
        <Card>
          <FadingOverlay>
            <div className="flex flex-col gap-4">
              <Skeleton height={44} />
              <Skeleton height={44} />
              <Skeleton height={44} />
            </div>
          </FadingOverlay>
        </Card>
      </div>
    </div>
  </Page.Content>
);

const GroceryTourDetails = () => {
  const [isResolveStatusModalOpen, setIsResolveStatusModalOpen] =
    useState(false);
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const { id } = useParams();
  const { state } = useLocation();

  const { user } = useUser();
  const userRole = user.role;

  const {
    data: tourQueryData,
    error,
    isError,
    isLoading,
  } = useQuery([`/grocery-tours/${id}`], async () => {
    const response = await fetch(`/grocery-tours/${id}`, {
      method: 'GET',
    });
    return response.json();
  });

  const tour = tourQueryData?.data;

  const backLink = useMemo(() => {
    if (state?.backLink) {
      return state?.backLink;
    }
    return '/grocery-tours';
  }, [state?.backLink]);

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const isAdmin = useIsRole(UserRole.Admin);
  const queryClient = useQueryClient();

  const onTourSocketEvent = useCallback(() => {
    queryClient.invalidateQueries([`/grocery-tours/${tour?.id}`]);
  }, [queryClient, tour?.id]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <GroceriesResolveTourStatusModal
        isOpen={isResolveStatusModalOpen}
        tourId={tour?.id}
        tourNumber={tour?.tourNumber}
        onClose={() => setIsResolveStatusModalOpen(false)}
      />
      <Page>
        <Meta title={tour?.tourNumber} />
        <Titlebar
          backLink={backLink}
          generateMeta={false}
          isLoading={isLoading}
          textPrimary={tour?.tourNumber}
        />

        {isLoading ? (
          <PageSkeleton />
        ) : (
          <Page.Content variant={isDesktop ? 'white' : 'grey'}>
            {tour && <GroceriesTourAlert tour={tour} />}

            <div className="flex-1 rounded-md bg-grey-100 lg:p-4">
              <Allow roles={[UserRole.Customer]}>
                <CustomerGroceryTourDetails tour={tour} />
              </Allow>
              <Allow
                roles={[
                  UserRole.Admin,
                  UserRole.OperationsManager,
                  UserRole.HubManager,
                ]}
              >
                <SocketHandler
                  entity="groceryTour"
                  id={tour.id}
                  onEvent={onTourSocketEvent}
                />
                <div className="grid gap-4 xl:grid-cols-2">
                  <div className="flex flex-col gap-4">
                    <GroceriesTourStatusCard
                      isAdmin={isAdmin}
                      tour={tour}
                      onResolveTourStatus={() => {
                        setIsResolveStatusModalOpen(true);
                      }}
                    />

                    <TabsCard
                      tabs={[
                        {
                          content: (
                            <div className="divide-y divide-grey-200 px-4">
                              <CardDetailsRow
                                icon="date"
                                label={t('Date')}
                                value={moment(tour.date).format('DD.MM.YYYY')}
                              />

                              <CardDetailsRow
                                icon="startTime"
                                label={t('Start Time')}
                                value={`${moment(tour.startLoading).format(
                                  'HH:mm',
                                )}`}
                              />
                              <CardDetailsRow
                                icon="warehouse"
                                label={t('Hub')}
                                value={
                                  <>
                                    {tour.hub?.name || 'N/A'}
                                    <br />
                                    <span className="text-grey-700 font-normal">
                                      {tour.hub?.address}
                                    </span>
                                  </>
                                }
                              />
                              <CardDetailsRow
                                icon="warehouseRamp"
                                label={t('Wa Ramp')}
                                value={tour.waRamp || 'N/A'}
                              />
                              <CardDetailsRow
                                icon="warehouseSpur"
                                label={t('Wa Spur')}
                                value={tour.waSpur || 'N/A'}
                              />
                              <CardDetailsRow
                                icon="parcel"
                                label={t('Total Package Weight')}
                                value={`${tour.weight} kg`}
                              />
                              <CardDetailsRow
                                icon="tourStop"
                                label={t('Stops')}
                                value={tour.amount || 'N/A'}
                              />
                              <CardDetailsRow
                                icon="distance"
                                label={t('Drive Distance')}
                                value={
                                  tour.distance
                                    ? `${metersToKilometers(tour.distance)} km`
                                    : 'N/A'
                                }
                              />
                              <CardDetailsRow
                                icon="car"
                                label={t('Drive Duration')}
                                value={getTourDuration(tour)}
                              />
                            </div>
                          ),
                          dataTest: 'tour-details',
                          label: t('Details'),
                        },
                      ]}
                    />
                  </div>

                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-4 rounded-md bg-white p-4 shadow-elevation-100">
                      <div className="text-sm uppercase text-grey-900">
                        {t('Shift')}
                      </div>
                      <div className="flex items-center gap-2">
                        <ShiftLabel shiftNumber={tour.shift} />
                        <span>
                          {moment(tour.startLoading).format('HH:mm')} -{' '}
                          {moment(tour.tourEnd).format('HH:mm')}
                        </span>
                      </div>
                    </div>
                    <TourPerformance rating={tour.rating} score={tour.score} />

                    <div className="flex flex-col gap-4 rounded-md bg-white p-4 shadow-elevation-100">
                      <div className="text-sm uppercase text-grey-900">
                        {t('Carrier')}
                      </div>
                      <GroceriesTourCarrier userRole={userRole} tour={tour} />
                    </div>

                    <div
                      className="flex flex-col rounded-md bg-white shadow-elevation-100 pt-4"
                      data-test="tour-activity-card"
                    >
                      <div className="text-sm uppercase text-grey-900 px-4">
                        {t('Activity Feed')}
                      </div>
                      <GroceriesTourActivity tourId={tour.id} />
                    </div>
                  </div>
                </div>
              </Allow>
            </div>
          </Page.Content>
        )}
      </Page>
    </>
  );
};

export default GroceryTourDetails;

import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import CancelPrompt from '../../components/CancelPrompt';
import { Card } from '../../components/Card';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import OrganisationForm from '../../features/shared/OrganisationForm';
import useBlocker from '../../hooks/useBlocker';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';

const NewOrganisation = () => {
  const { toastSuccess } = useCustomToast();
  const navigate = useNavigate();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { fetch } = useFetch();
  const { t } = useTranslation();
  const { toastFetchError } = useToastFetchError();

  const mutation = useMutation(
    async (values) => {
      const body = {
        address: values.address,
        bundling: false,
        name: values.name,
        publicName: values.publicName,
        shortCode: values.shortCode,
      };

      const response = await fetch(`/carriers`, {
        body,
        method: 'POST',
      });
      return response.json();
    },
    {
      onError: (error) => toastFetchError(error),
      onSuccess: () => {
        setIsFormDirty(false);
        toastSuccess(t('Organisation created successfully.'));
      },
    },
  );

  const shouldBlock = useMemo(() => {
    if (mutation.isSuccess === true) {
      return false;
    }
    return isFormDirty;
  }, [mutation.isSuccess, isFormDirty]);

  const blocker = useBlocker(shouldBlock);

  const id = mutation.data?.data?.id;

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate(`/organisations/${id}`, {
        replace: true,
      });
    }
  }, [id, mutation.isSuccess, navigate]);

  return (
    <>
      <CancelPrompt
        title={t('Cancel Creating Organisation?')}
        isOpen={blocker.state === 'blocked'}
        onClose={() => blocker.reset()}
        onExitClick={() => blocker.proceed()}
      />
      <Page>
        <Titlebar
          icon={<Icon className="h-5 w-5 text-ui-blue" icon="domainAdd" />}
          textPrimary={t('New Organisation')}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              disabled={mutation.isLoading}
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => navigate('/organisations')}
            />
          }
        />
        <Page.Content variant="grey">
          <Card className="m-auto mt-4 w-full max-w-[480px] px-4 py-5 sm:p-8">
            <OrganisationForm
              errorCode={mutation?.error?.data?.errorCode}
              errors={mutation?.error?.data?.errors}
              isSubmitting={mutation.isLoading}
              onCancel={() => navigate('/organisations')}
              onIsDirtyChange={setIsFormDirty}
              onSubmit={mutation.mutate}
            />
          </Card>
        </Page.Content>
      </Page>
    </>
  );
};

export default NewOrganisation;

import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CheckboxGroup from '../CheckboxGroup';
import InputLayout from '../InputLayout';

const FormCheckboxGroup = (props) => {
  const { disabled, error, id, label, name, required, validate, ...rest } =
    props;

  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const value = watch(name);

  const rules = useCallback(() => {
    const validators = {};

    if (required && !validate) {
      validators.validate = (val) =>
        val.length > 0 || t('This field is required.');
    }

    if (validate) {
      validators.validate = validate;
    }

    return validators;
  }, [required, t, validate]);

  const errorInput = error || (errors[name] && errors[name].message);

  const onChange = useCallback(
    (event) => {
      const index = value.indexOf(event.target.value);
      const tempValue = [...value];
      if (index === -1) {
        tempValue.push(event.target.value);
      } else {
        tempValue.splice(index, 1);
      }
      setValue(name, tempValue, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [name, setValue, value],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref } }) => (
        <InputLayout
          error={errorInput}
          id={id}
          label={label}
          required={required}
        >
          <CheckboxGroup
            disabled={disabled}
            name={name}
            ref={ref}
            selectedValues={value}
            onChange={onChange}
            {...rest}
          />
        </InputLayout>
      )}
      rules={rules()}
    />
  );
};

FormCheckboxGroup.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
};

FormCheckboxGroup.defaultProps = {
  disabled: false,
  error: undefined,
  id: '',
  label: '',
  name: '',
  required: false,
  validate: null,
};

export default FormCheckboxGroup;

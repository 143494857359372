import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import NetworkRequestFailedError from '../errors/NetworkRequestFailedError';
import useApiErrorTranslation from './useApiErrorTranslation';
import useCustomToast from './useCustomToast';

const useToastFetchError = () => {
  const { toastError } = useCustomToast();
  const { translateError } = useApiErrorTranslation();
  const { t } = useTranslation();

  const toastFetchError = useCallback(
    (err) => {
      if (err instanceof NetworkRequestFailedError) {
        // the network request did not succeed
        toastError(t('Network request failed.'), {
          // toast id is used to prevent duplicate toasts
          toastId: 'networkRequestFailed',
        });
        return;
      }

      if (err?.status === 503 && err?.data?.maintenance === true) {
        // this will be handled by maintenance mode in Root.jsx
        return;
      }

      const errorMessage = translateError(err);
      toastError(errorMessage);
    },
    [t, toastError, translateError],
  );

  return { toastFetchError };
};

export default useToastFetchError;

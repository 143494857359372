import { useEffect, useRef, useState } from 'react';

import useEvent from '../../hooks/useEvent';

const useCalculateTableScroll = (totalWidth = 0) => {
  const ref = useRef(null);
  const [isTableStickedLeft, setIsTableStickedLeft] = useState(true);
  const [isTableStickedRight, setIsTableStickedRight] = useState(true);

  const onScroll = () => {
    if (ref.current.scrollLeft === 0) {
      setIsTableStickedLeft(true);
    } else {
      setIsTableStickedLeft(false);
      if (
        Math.round(ref.current.clientWidth + ref.current.scrollLeft) ===
        Math.round(ref.current.scrollWidth)
      ) {
        setIsTableStickedRight(true);
      } else {
        setIsTableStickedRight(false);
      }
    }
  };

  useEvent('scroll', onScroll, ref.current);

  useEffect(() => {
    if (totalWidth <= 0) {
      setIsTableStickedLeft(true);
      setIsTableStickedRight(true);
      return;
    }

    if (
      Math.round(ref.current.scrollWidth) > Math.round(ref.current.clientWidth)
    ) {
      setIsTableStickedRight(false);
    } else {
      setIsTableStickedRight(true);
    }
  }, [totalWidth]);

  return {
    tableRef: ref,
    isTableStickedLeft,
    isTableStickedRight,
  };
};

export default useCalculateTableScroll;

import eventType from '../eventType';
import NoteField from './NoteField';
import UserField from './UserField';

const NoteEvent = (props) => {
  const { event } = props;
  return (
    <>
      {event.note && <NoteField note={event.note} />}
      <UserField event={event} />
    </>
  );
};

NoteEvent.propTypes = {
  event: eventType.isRequired,
};

export default NoteEvent;

import { useTranslation } from 'react-i18next';

import Icon from '../../../components/Icon';
import FeedSettingsButton from '../FeedSettingsButton';

const PrinterErrorTroubleshootingInfo = () => {
  const { t } = useTranslation();

  return (
    <div
      className="flex w-full gap-4 rounded-md bg-white p-4 shadow-elevation-200"
      data-test="printer-error-troubleshooting"
    >
      <div className="flex flex-col justify-center">
        <Icon className="h-5 w-5 text-ui-info-blue" icon="infoFilled" />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex">
          <span className="text-sm font-medium">
            {t(
              `An error occurred while printing. Please try Manual Print. If the problem persists, try setting the printer device again in the Feed Settings or check printer hardware.`,
            )}
          </span>
        </div>
        <div>
          <FeedSettingsButton />
        </div>
      </div>
    </div>
  );
};

export default PrinterErrorTroubleshootingInfo;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import MemberStatusType from '../../../enums/MemberStatusType';
import UserRole from '../../../enums/UserRole';
import Allow from '../../../lib/rbac/Allow';
import { useUser } from '../../../providers/UserProvider';

const MemberDetailsTitlebarContent = (props) => {
  const { memberId, memberRole, memberStatus, onChangeStatus } = props;

  const { t } = useTranslation();

  const { isSubcarrierUser, user } = useUser();

  const isCurrentUser = user?.id === memberId;

  const renderEditButton = () => {
    if (isSubcarrierUser) {
      return null;
    }

    return (
      <Button
        as={Link}
        className="w-full lg:w-fit"
        data-test="edit-account"
        icon="edit"
        text={t('Edit Member')}
        to={{
          pathname: `/members/${memberId}/edit`,
        }}
        variant="outlineBlue"
      />
    );
  };

  const renderDeactivateButton = () => {
    if (
      isCurrentUser ||
      memberRole === UserRole.Superadmin ||
      memberStatus === MemberStatusType.Invited
    ) {
      return null;
    }

    return (
      <Button
        className="w-full lg:w-fit"
        data-test="delete-account"
        icon="shutdown"
        text={
          memberStatus === MemberStatusType.Active
            ? t('Deactivate Member')
            : t('Activate Member')
        }
        variant="outlineBlack"
        onClick={onChangeStatus}
      />
    );
  };

  return (
    <>
      <Allow roles={[UserRole.Superadmin]}>
        {(memberRole === UserRole.Superadmin ||
          memberRole === UserRole.Admin) && (
          <>
            {renderEditButton()}
            {renderDeactivateButton()}
          </>
        )}
      </Allow>
      <Allow roles={[UserRole.Admin]}>
        {(memberRole === UserRole.Admin ||
          memberRole === UserRole.OperationsManager ||
          memberRole === UserRole.HubManager ||
          memberRole === UserRole.Crew ||
          memberRole === UserRole.Customer ||
          memberRole === UserRole.Driver) && (
          <>
            {renderEditButton()}
            {renderDeactivateButton()}
          </>
        )}
      </Allow>
      <Allow roles={[UserRole.OperationsManager]}>
        {(memberRole === UserRole.OperationsManager ||
          memberRole === UserRole.HubManager ||
          memberRole === UserRole.Crew ||
          memberRole === UserRole.Driver) &&
          renderEditButton()}
      </Allow>
      <Allow roles={[UserRole.HubManager]}>
        {(memberRole === UserRole.HubManager ||
          memberRole === UserRole.Crew ||
          memberRole === UserRole.Driver) &&
          renderEditButton()}
      </Allow>
      <Allow roles={[UserRole.HubManager, UserRole.OperationsManager]}>
        {(memberRole === UserRole.Driver || memberRole === UserRole.Crew) &&
          renderDeactivateButton()}
      </Allow>
    </>
  );
};

MemberDetailsTitlebarContent.propTypes = {
  memberId: PropTypes.string,
  memberRole: PropTypes.string,
  onChangeStatus: PropTypes.func,
  memberStatus: PropTypes.oneOf(Object.values(MemberStatusType)),
};

MemberDetailsTitlebarContent.defaultProps = {
  memberId: undefined,
  memberRole: undefined,
  onChangeStatus: undefined,
  memberStatus: undefined,
};

export default MemberDetailsTitlebarContent;

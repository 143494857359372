const UnitsSearchBy = {
  Code: 'code',
  TaskCode: 'taskCode',
  Recipient: 'recipient',
  Address: 'address',
  Email: 'email',
  PhoneNumber: 'phoneNumber',
  TourNumber: 'tourNumber',
};

export default UnitsSearchBy;

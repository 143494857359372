import PropTypes from 'prop-types';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const TitlebarHeightContext = createContext();

export const useTitlebarHeight = () => {
  const context = useContext(TitlebarHeightContext);
  if (context === undefined) {
    throw new Error(
      'useTitlebarHeight must be used within a TitlebarHeightProvider',
    );
  }
  return context;
};

const TitlebarHeightProvider = (props) => {
  const { children } = props;

  const [titlebarElement, setTitlebarElement] = useState(null);
  const [titlebarElementHeight, setTitlebarElementHeight] = useState(0);

  const [subContentElement, setSubContentElement] = useState(null);
  const [subContentElementHeight, setSubContentElementHeight] = useState(0);

  const titlebarElementObserver = useRef(
    new ResizeObserver((entries) => {
      const borderBoxSize = entries?.[0].borderBoxSize?.[0];

      if (borderBoxSize) {
        setTitlebarElementHeight(borderBoxSize?.blockSize);
      } else {
        setTitlebarElementHeight(0);
      }
    }),
  );

  const subContentElementObserver = useRef(
    new ResizeObserver((entries) => {
      const borderBoxSize = entries?.[0].borderBoxSize?.[0];

      if (borderBoxSize) {
        setSubContentElementHeight(borderBoxSize?.blockSize);
      }
    }),
  );

  useEffect(() => {
    if (!subContentElement) {
      setSubContentElementHeight(0);
      return undefined;
    }
    const obs = subContentElementObserver.current;
    if (subContentElement) {
      obs.observe(subContentElement);
    }

    return () => {
      obs.disconnect();
    };
  }, [subContentElement]);

  useEffect(() => {
    if (!titlebarElement) {
      setTitlebarElementHeight(0);
      return undefined;
    }
    const obs = titlebarElementObserver.current;
    if (titlebarElement) {
      obs.observe(titlebarElement);
    }

    return () => {
      obs.disconnect();
    };
  }, [titlebarElement]);

  const totalTitlebarHeight = subContentElementHeight + titlebarElementHeight;

  const value = useMemo(
    () => ({
      setSubContentElement,
      setTitlebarElement,
      subContentElementHeight,
      titlebarElementHeight,
      totalTitlebarHeight,
    }),
    [subContentElementHeight, titlebarElementHeight, totalTitlebarHeight],
  );

  return (
    <TitlebarHeightContext.Provider value={value}>
      {children}
    </TitlebarHeightContext.Provider>
  );
};

TitlebarHeightProvider.propTypes = {
  children: PropTypes.node,
};

TitlebarHeightProvider.defaultProps = {
  children: null,
};

export default TitlebarHeightProvider;

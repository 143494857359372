import { useCallback, useRef, useState } from 'react';

import useClickOutside from './useClickOutside';

const useDropdown = ({ closeOnOutsideClick = true, onClose, onOpen } = {}) => {
  const ref = useRef();

  const [isOpen, setIsOpen] = useState();

  const close = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  }, [onClose]);

  const open = useCallback(() => {
    if (onOpen) {
      onOpen();
    }
    setIsOpen(true);
  }, [onOpen]);

  const toggle = useCallback(() => {
    if (isOpen && onClose) {
      onClose();
    }
    if (!isOpen && onOpen) {
      onOpen();
    }
    setIsOpen((prevState) => !prevState);
  }, [isOpen, onClose, onOpen]);

  const onClickOutside = useCallback(() => {
    if (!closeOnOutsideClick) {
      return;
    }
    if (!isOpen) {
      return;
    }
    close();
  }, [close, closeOnOutsideClick, isOpen]);

  useClickOutside(ref, onClickOutside);

  const getRootProps = () => ({ ref });

  return { close, getRootProps, isOpen, open, ref, toggle };
};

export default useDropdown;

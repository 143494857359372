import { useSearchParams } from 'react-router-dom';

const validPageSizes = [15, 25, 50, 100];

const isValidPageSize = (pageSize) => validPageSizes.includes(pageSize);

const usePageSizeLocationQuery = () => {
  const [searchParams] = useSearchParams();
  const pageSizeParam = Number(searchParams.get('pageSize'));

  if (isValidPageSize(pageSizeParam)) {
    return pageSizeParam;
  }

  return undefined;
};

export default usePageSizeLocationQuery;

import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Collapse from '../../../components/Collapse';
import LinkButton from '../../../components/LinkButton';
import imagePropType from '../../../prop-types/imagePropType';

const ProofBlock = (props) => {
  const { executionLocation, images, isUnsuccessful, signature } = props;
  const { t } = useTranslation();
  const hasImages = images?.length > 0;
  const [isExecutionProofOpened, setIsExecutionProofOpened] = useState(false);

  const text = useMemo(() => {
    if (isUnsuccessful) {
      if (isExecutionProofOpened) {
        return t('Hide Failure Proofs');
      }

      return t('Show Failure Proofs');
    }
    if (isExecutionProofOpened) {
      return t('Hide Execution Proofs');
    }

    return t('Show Execution Proofs');
  }, [isExecutionProofOpened, isUnsuccessful, t]);

  if (!hasImages && !executionLocation && !signature) {
    return null;
  }

  return (
    <div>
      <Collapse isOpen={isExecutionProofOpened}>
        <div className="flex flex-col gap-4 pb-4">
          {executionLocation && (
            <>
              <section className="flex flex-col gap-2 last:pb-0">
                <h3 className="text-sm text-grey-700 font-medium">
                  {t('Location Proof')}
                </h3>
                <div>
                  <a
                    className="text-sm text-ui-blue underline"
                    href={`https://maps.google.com/?q=${executionLocation?.coordinates[1]},${executionLocation?.coordinates[0]}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('Open proof location in Google Maps')}
                  </a>
                </div>
              </section>

              <div className="border-b border-grey-200" />
            </>
          )}

          {hasImages && (
            <section className="flex flex-col gap-2 last:pb-0 ">
              <h3 className="text-sm text-grey-700 font-medium">
                {t('Photo Proof')} ({images?.length})
              </h3>
              <div className="grid grid-cols-3 gap-2.5">
                {images.map((image) => (
                  <div className="relative pb-[100%]" key={image.originalName}>
                    <a
                      href={image.signedUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="absolute w-full h-full"
                    >
                      {/* only load the image if the proof block is opened */}
                      {isExecutionProofOpened && (
                        <img
                          alt={image.originalname}
                          className="w-full h-full rounded-md object-cover bg-grey-100"
                          src={image.signedUrl}
                          title={image.originalname}
                        />
                      )}
                    </a>
                  </div>
                ))}
              </div>
            </section>
          )}

          {hasImages && signature && (
            <div className="border-b border-grey-200" />
          )}

          {signature && (
            <section className="flex flex-col gap-2">
              <h3 className="text-sm text-grey-700 font-medium">
                {t('Signature')}
              </h3>
              <a
                href={signature.signedUrl}
                rel="noopener noreferrer"
                target="_blank"
                className="h-[200px]"
              >
                {/* only load the image if the proof block is opened */}
                {isExecutionProofOpened && (
                  <img
                    alt={signature.originalname}
                    className="w-full rounded-md bg-grey-100 object-contain p-4 h-full"
                    src={signature.signedUrl}
                    title={signature.originalname}
                  />
                )}
              </a>
            </section>
          )}
        </div>
      </Collapse>

      <LinkButton
        text={text}
        onClick={() => setIsExecutionProofOpened(!isExecutionProofOpened)}
      />
    </div>
  );
};

ProofBlock.propTypes = {
  images: PropTypes.arrayOf(imagePropType),
  signature: imagePropType,
  executionLocation: PropTypes.shape({
    coordinates: PropTypes.arrayOf(PropTypes.number),
  }),
  isUnsuccessful: PropTypes.bool,
};

ProofBlock.defaultProps = {
  images: [],
  signature: undefined,
  executionLocation: undefined,
  isUnsuccessful: false,
};

export default ProofBlock;

import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PrinterStatus from '../../../enums/PrinterStatus';
import useAsyncFn, { AsyncStatus } from '../../../hooks/useAsyncFn';
import createZebraPrinter from '../createZebraPrinter';
import BrowserPrintStatus from '../enums/BrowserPrintStatus';
import PrinterErrorType from '../enums/PrinterErrorType';
import PrinterError from './PrinterError';
import useBrowserPrintScripts from './useBrowserPrintScripts';

export const BrowserPrintContext = createContext(undefined);
const resetTimeoutMs = 3000;
export const BrowserPrintProvider = (props) => {
  const { children } = props;

  const { BrowserPrint } = window;

  const browserPrintLoading = useBrowserPrintScripts();

  const [printers, setPrinters] = useState([]);
  const [defaultPrinter, setDefaultPrinter] = useState(undefined);
  const [currentPrinter, setCurrentPrinter] = useState(undefined);
  const [currentPrinterStatus, setCurrentPrinterStatus] = useState(undefined);
  const [status, setStatus] = useState(BrowserPrintStatus.Pending);
  const printerResetTimeoutRef = useRef(null);

  useEffect(() => {
    if (browserPrintLoading) {
      return;
    }

    BrowserPrint.getDefaultDevice('printer', (printer) => {
      setDefaultPrinter(printer);
    });

    BrowserPrint.getLocalDevices(
      (deviceList) => {
        setPrinters(deviceList);
        setStatus(BrowserPrintStatus.Ready);
      },
      () => {
        setStatus(BrowserPrintStatus.ConnectionError);
      },
      'printer',
    );
  }, [BrowserPrint, browserPrintLoading]);

  // auto assign default printer if it's available
  useEffect(() => {
    if (printers.find((printer) => printer?.uid === defaultPrinter?.uid)) {
      setCurrentPrinter(createZebraPrinter(defaultPrinter));
    }
  }, [defaultPrinter, printers]);

  /**
   *
   * @param zebraPrinterStatus {Zebra.Printer.Status}
   */
  const getPrinterError = (zebraPrinterStatus) => {
    if (!zebraPrinterStatus) {
      return PrinterErrorType.ConnectionError;
    }

    if (zebraPrinterStatus.headOpen) {
      return PrinterErrorType.HeadOpen;
    }
    if (zebraPrinterStatus.ribbonOut) {
      return PrinterErrorType.RibbonOut;
    }
    if (zebraPrinterStatus.paperOut) {
      return PrinterErrorType.PaperOut;
    }
    if (zebraPrinterStatus.paused) {
      return PrinterErrorType.Paused;
    }
    if (zebraPrinterStatus.offline) {
      return PrinterErrorType.Offline;
    }
    return PrinterErrorType.ConnectionError;
  };

  const sendData = useCallback(
    async (data) => {
      if (!data) {
        return undefined;
      }

      // printer readiness checks before and after to verify:
      // before - that printer is ready to print
      // after - that the printing job finished successfully
      try {
        const statusBefore = await currentPrinter.getStatus();
        const isReadyBefore = statusBefore && statusBefore.isPrinterReady();
        if (!isReadyBefore) {
          throw new PrinterError(getPrinterError(statusBefore));
        }
        const response = await currentPrinter.sendAsync(data);
        if (!response) {
          throw new PrinterError(PrinterErrorType.ConnectionError);
        }

        const statusAfter = await currentPrinter.getStatus();
        const isReadyAfter = statusAfter && statusAfter.isPrinterReady();

        if (!isReadyAfter) {
          throw new PrinterError(getPrinterError(statusAfter));
        }
      } catch (error) {
        if (error instanceof PrinterError) {
          throw error;
        }
        throw new PrinterError(PrinterErrorType.ConnectionError);
      }

      return true;
    },
    [currentPrinter],
  );

  const {
    error: printError,
    reset: resetCurrentPrinterState,
    runAsyncFn: print,
    status: printActionStatus,
  } = useAsyncFn(sendData);

  // printer change handler
  useEffect(() => {
    if (currentPrinter) {
      setCurrentPrinterStatus(PrinterStatus.Initialising);
      currentPrinter
        .isPrinterReady()
        .then(() => {
          setCurrentPrinterStatus(PrinterStatus.Ready);
        })
        .catch(() => {
          setCurrentPrinterStatus(PrinterStatus.NotReady);
        });
    } else {
      setCurrentPrinterStatus(undefined);
    }
  }, [currentPrinter, print]);

  useEffect(() => {
    // ignore if no printer is selected or printer is initializing or not ready
    if (
      !currentPrinter ||
      currentPrinterStatus === undefined ||
      currentPrinterStatus === PrinterStatus.Initialising ||
      currentPrinterStatus === PrinterStatus.NotReady
    ) {
      return;
    }

    if (printActionStatus === AsyncStatus.Loading) {
      setCurrentPrinterStatus(PrinterStatus.Printing);
    }

    if (printActionStatus === AsyncStatus.Success) {
      setCurrentPrinterStatus(PrinterStatus.Printed);
    }

    if (printActionStatus === AsyncStatus.Error) {
      setCurrentPrinterStatus(PrinterStatus.Error);
    }

    if (printActionStatus === AsyncStatus.Idle) {
      setCurrentPrinterStatus(PrinterStatus.Ready);
    }
  }, [currentPrinter, currentPrinterStatus, printActionStatus]);

  useEffect(() => {
    clearTimeout(printerResetTimeoutRef?.current);

    if (currentPrinterStatus === PrinterStatus.Printed) {
      printerResetTimeoutRef.current = setTimeout(() => {
        resetCurrentPrinterState();
      }, resetTimeoutMs);
    }

    return () => {
      clearTimeout(printerResetTimeoutRef?.current);
    };
  }, [currentPrinterStatus, resetCurrentPrinterState]);

  const value = useMemo(
    () => ({
      currentPrinter,
      currentPrinterStatus,
      defaultPrinter,
      printers,
      setCurrentPrinter,
      status,
      resetCurrentPrinterState,
      print,
      printError,
    }),
    [
      currentPrinter,
      currentPrinterStatus,
      defaultPrinter,
      printers,
      resetCurrentPrinterState,
      status,
      print,
      printError,
    ],
  );

  return (
    <BrowserPrintContext.Provider value={value}>
      {children}
    </BrowserPrintContext.Provider>
  );
};

BrowserPrintProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useBrowserPrint = () => useContext(BrowserPrintContext);

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Breakpoint from '../../../enums/Breakpoint';
import UserRole from '../../../enums/UserRole';
import useWindowSize from '../../../hooks/useWindowSize';
import { useIsRole } from '../../../providers/UserProvider';
import OrganisationDetailsSectionCard from '../../shared/OrganisationDetailsSectionCard';

const AddButton = ({ isMedium }) => {
  const { t } = useTranslation();
  const isAdmin = useIsRole(UserRole.Admin);

  if (!isAdmin) {
    return null;
  }

  return (
    <Button
      as={Link}
      className="w-full sm:w-fit"
      icon="plus"
      iconSize="m"
      text={isMedium ? t('Add') : t('Add Hub')}
      to="hubs/new"
      variant="outlineBlack"
    />
  );
};

AddButton.propTypes = {
  isMedium: PropTypes.bool,
};

AddButton.defaultProps = {
  isMedium: false,
};

const OrganisationDetailsHubsCard = (props) => {
  const { hubCount } = props;
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const isMedium = width < Breakpoint.XXL && width >= Breakpoint.XL;

  return (
    <OrganisationDetailsSectionCard
      title={
        <div className="flex items-center gap-2 2xl:gap-4">
          <Icon className="h-6 w-6" icon="warehouse" />
          <div>
            {t('Hubs')} {(hubCount || hubCount === 0) && `(${hubCount})`}
          </div>
        </div>
      }
      topRightContent={
        hubCount > 0 ? (
          <Button
            as={Link}
            className="w-full sm:w-fit"
            iconRight="chevronRight"
            iconSize="s"
            text={isMedium ? t('View') : t('View Hubs')}
            to="hubs"
            variant="outlineBlue"
          />
        ) : (
          <AddButton isMedium={isMedium} />
        )
      }
    />
  );
};

OrganisationDetailsHubsCard.propTypes = {
  hubCount: PropTypes.number,
};

OrganisationDetailsHubsCard.defaultProps = {
  hubCount: undefined,
};

export default OrganisationDetailsHubsCard;

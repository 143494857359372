import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import postcodeGroupPropType from '../../../prop-types/postcodeGroupPropType';

const DeletePostcodeGroupModal = ({
  hubId,
  isOpen,
  onClose,
  postcodeGroup,
}) => {
  const { toastFetchError } = useToastFetchError();
  const { toastSuccess } = useCustomToast();
  const { fetch } = useFetch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const deletePostcodeGroupMutation = useMutation(
    () =>
      fetch(`/postcode-groups/${postcodeGroup?.id}`, {
        method: 'DELETE',
      }),
    {
      onError: toastFetchError,
      onSuccess: () => {
        toastSuccess(t('Postcode group successfully deleted.'));
        queryClient.invalidateQueries({
          queryKey: [`/hubs/${hubId}/postcode-groups`],
        });
        onClose();
      },
    },
  );

  return (
    <Modal
      body={
        <p>
          {t(
            'Note that this action is permanent. Postcodes from this group will be moved back to the non-group category.',
          )}
        </p>
      }
      cancelButtonText={t('Cancel')}
      footer={
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            text={t('Delete')}
            variant="solidRed"
            onClick={deletePostcodeGroupMutation.mutate}
            disabled={deletePostcodeGroupMutation.isLoading}
          />
        </div>
      }
      header={
        <div className="flex items-center gap-2">
          <Icon className="h-6 w-6 text-ui-red" icon="delete" />
          <span>
            {t('Delete {{postcodeGroupName}} Postcode Group?', {
              postcodeGroupName: postcodeGroup?.name,
              interpolation: { escapeValue: false },
            })}
          </span>
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

DeletePostcodeGroupModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  postcodeGroup: postcodeGroupPropType,
  hubId: PropTypes.string,
};

DeletePostcodeGroupModal.defaultProps = {
  isOpen: false,
  postcodeGroup: undefined,
  hubId: undefined,
};

export default DeletePostcodeGroupModal;

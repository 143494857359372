import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import usePostponeReasonTranslations from '../../../hooks/usePostponeReasonTranslations';
import CollapsedInfo from './CollapsedInfo';
import ExpandableStatusItem from './ExpandableStatusItem';
import PostponedReasons from './PostponedReasons';

const PostponedStatusItem = (props) => {
  const {
    currentUnitStatus,
    isCurrent,
    isTourActiveOrCompleted,
    isUnitRouted,
    item,
  } = props;

  const { watch } = useFormContext();
  const postponedReason = watch('postponeReason');
  const postponeReasonNote = watch('postponeReasonNote');
  const nameTranslations = usePostponeReasonTranslations();
  const text = nameTranslations[postponedReason];

  return (
    <ExpandableStatusItem
      isCurrent={isCurrent}
      item={item}
      isUnitRouted={isUnitRouted}
      currentUnitStatus={currentUnitStatus}
      isTourActiveOrCompleted={isTourActiveOrCompleted}
      collapsedChildren={<PostponedReasons />}
    >
      <CollapsedInfo text={text} note={postponeReasonNote} />
    </ExpandableStatusItem>
  );
};

PostponedStatusItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  isUnitRouted: PropTypes.bool,
  isCurrent: PropTypes.bool,
  currentUnitStatus: PropTypes.string,
  isTourActiveOrCompleted: PropTypes.bool,
};

PostponedStatusItem.defaultProps = {
  item: undefined,
  isUnitRouted: false,
  isCurrent: false,
  currentUnitStatus: undefined,
  isTourActiveOrCompleted: false,
};

export default PostponedStatusItem;

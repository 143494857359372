import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import Checkbox from '../../../components/Checkbox';
import Icon from '../../../components/Icon';
import RadioButton from '../../../components/RadioButton';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useUser } from '../../../providers/UserProvider';

const ShiftsFields = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { fetch } = useFetch();
  const { id } = useParams();

  const { data: fetchedShifts, isLoading } = useQuery(
    [`/carriers/${user.carrier.id}/shifts`],
    async () => {
      const response = await fetch(`/carriers/${user.carrier.id}/shifts`);
      return response.json();
    },
  );

  const shifts = fetchedShifts?.data || null;

  const hubShifts = useMemo(() => {
    if (!shifts || !id) {
      return [];
    }

    return shifts.filter((shift) => !!shift.hub && shift.hub.id === id);
  }, [id, shifts]);

  const carrierShifts = useMemo(() => {
    if (!shifts || hubShifts?.length > 0) {
      return [];
    }

    return shifts.filter((shift) => !shift.hub);
  }, [shifts, hubShifts]);

  if (isLoading) {
    return (
      <div className="py-8 flex flex-col gap-4">
        <Skeleton height={112} />
        <Skeleton height={112} />
      </div>
    );
  }

  return (
    <div className="p-5 rounded-md border border-grey-300 flex flex-col gap-5">
      <div>{t('Shifts')}</div>
      <div className="flex flex-col gap-4 pointer-events-none">
        <div>
          <RadioButton
            checked={hubShifts?.length === 0}
            label={t('Carrier')}
            disabled={carrierShifts.length === 0}
          />
          {carrierShifts.length === 0 && (
            <div className="flex items-start gap-2 text-sm text-grey-700 mt-2">
              <Icon
                className="h-4 w-4 text-ui-info-blue self-center"
                icon="infoFilled"
              />
              <span>
                {t(
                  'The Hub operates within designated Hub Shift. To switch back to Carrier Shifts, go to Organisation > Shifts, locate the Hub Shift assigned to this Hub and delete it.',
                )}
              </span>
            </div>
          )}
        </div>
        {carrierShifts.map((shift) => (
          <div key={shift.id} className="bg-grey-100 rounded-md px-4">
            <Checkbox
              checked
              label={`S${shift.number} (${shift.startTime}-${shift.endTime})`}
              disabled
            />
          </div>
        ))}
      </div>
      <hr className="text-grey-200" />
      <div className="flex flex-col gap-4 pointer-events-none">
        <div>
          <RadioButton
            checked={carrierShifts?.length === 0}
            label={t('Hub')}
            disabled={hubShifts.length === 0}
          />
          {hubShifts.length === 0 && (
            <div className="flex items-start gap-2 text-sm text-grey-700 mt-2">
              <Icon
                className="h-4 w-4 text-ui-info-blue self-center"
                icon="infoFilled"
              />
              <span>
                {t(
                  "The Hub operates within Carrier Shifts by default. To set a custom time slot for this Hub, go to Organisation > Shifts > New Shift, select shift type 'Hub,' and assign the Hub to it.",
                )}
              </span>
            </div>
          )}
        </div>
        {hubShifts.map((shift) => (
          <div key={shift.id} className="bg-grey-100 rounded-md px-4">
            <Checkbox
              checked
              label={`S${shift.number} (${shift.startTime}-${shift.endTime})`}
              disabled
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShiftsFields;

import cn from 'classnames';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputLayout from '../../../components/InputLayout';
import ListChoice from '../../../components/ListChoice';
import Textarea from '../../../components/Textarea';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import useDeliveryOptionsTranslations from '../../../hooks/useDeliveryOptionsTranslations';
import useFormInputValidationRules from '../../../hooks/useFormInputValidationRules';
import ChangeUnitStatusContext from './ChangeUnitStatusContext';

const DeliveryOptions = () => {
  const { t } = useTranslation();
  const { nameTranslations } = useDeliveryOptionsTranslations();
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useFormContext();

  const status = watch('status');
  const deliveryOption = watch('deliveryOption');
  const deliveryOptionNote = watch('deliveryOptionNote');
  const changeUnitStatusContext = useContext(ChangeUnitStatusContext);
  const {
    availableDeliveryInstructions,
    availableDeliveryOptions = [],
    defaultValues = {},
  } = changeUnitStatusContext;
  const { deliveryOption: originalDeliveryOption, status: originalStatus } =
    defaultValues;

  const isOriginalStatusDelivered =
    originalStatus === DeliveryTaskUnitStatus.Delivered;

  const errorInput =
    errors.deliveryOptionNote && errors.deliveryOptionNote.message;

  const rules = useFormInputValidationRules({
    required: true,
  });

  return (
    <div className="flex">
      <div className="ml-5 mr-3 border-l-[1px] border-grey-300" />
      <div className="flex-1">
        <Controller
          name="deliveryOption"
          render={({ field: { onChange: onOptionChange } }) =>
            availableDeliveryOptions.map((option) => {
              const instruction = availableDeliveryInstructions.find(
                (i) =>
                  option !== null &&
                  i.deliveryOption === option.deliveryOption.code,
              )?.instruction;

              const isSelected =
                status === DeliveryTaskUnitStatus.Delivered &&
                ((deliveryOption === null && option === null) ||
                  (option && option.deliveryOption.code === deliveryOption));

              const isOriginalDeliveryOption =
                (originalDeliveryOption === null && option === null) ||
                (option &&
                  option.deliveryOption.code === originalDeliveryOption);

              return (
                <div
                  className={cn(
                    isOriginalStatusDelivered &&
                      !isOriginalDeliveryOption &&
                      'opacity-40 pointer-events-none',
                  )}
                  key={option === null ? 'direct' : option.deliveryOption.code}
                >
                  <ListChoice
                    isSelected={isSelected}
                    onClick={() => {
                      if (
                        isOriginalDeliveryOption &&
                        isOriginalStatusDelivered
                      ) {
                        reset();
                        return;
                      }

                      if (isSelected) {
                        return;
                      }

                      if (option === null) {
                        onOptionChange(null);
                      } else {
                        onOptionChange(option.deliveryOption.code);
                      }
                      setValue('deliveryOptionNote', '');
                      setValue('notifyCustomer', false);
                      setValue('failureReason', '');
                      setValue('postponeReason', '');
                      setValue('postponeReasonNote', '');
                      setValue('statusNote', '');
                      setValue('unitSpecialCase', '');
                      setValue('returnReason', '');
                      setValue('status', DeliveryTaskUnitStatus.Delivered, {
                        shouldDirty: true,
                      });
                    }}
                  >
                    <div>
                      <div className="text-sm text-primary-dark">
                        {option === null
                          ? t('Customer (direct)')
                          : nameTranslations[option.deliveryOption.code] ||
                            option.deliveryOption.code}
                      </div>
                      {instruction && (
                        <div className="mt-1 text-sm break-all font-normal text-grey-700">
                          {instruction}
                        </div>
                      )}
                    </div>
                  </ListChoice>
                  {deliveryOption === option?.deliveryOption?.code &&
                    (option?.requiredDriverNote ||
                      // if its original and has note
                      (isOriginalStatusDelivered && deliveryOptionNote)) && (
                      <div className="mt-2">
                        <Controller
                          name="deliveryOptionNote"
                          control={control}
                          rules={rules()}
                          render={({ field }) => (
                            <InputLayout
                              label={t('Details')}
                              required
                              error={errorInput}
                            >
                              <Textarea
                                hasError={!!errorInput}
                                readOnly={
                                  !isSelected || isOriginalDeliveryOption
                                }
                                autoFocus
                                className="resize-none"
                                data-test="deliveryOptionNote"
                                placeholder={t(
                                  'Neighbour’s Name/Address/Apartment',
                                )}
                                {...field}
                              />
                            </InputLayout>
                          )}
                        />
                      </div>
                    )}
                </div>
              );
            })
          }
        />
      </div>
    </div>
  );
};

export default DeliveryOptions;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import CheckoutType from '../../../enums/CheckoutType';
import useTourStatusTranslations from '../../../hooks/useTourStatusTranslations';
import TourStatusIcon from '../TourStatusIcon';

const TourStatusCard = ({ tour }) => {
  const { t } = useTranslation();
  const tourStatusTranslations = useTourStatusTranslations();
  const tourStatusName = tourStatusTranslations[tour.status];

  return (
    <div className="flex flex-col gap-4 rounded-md bg-white p-4 shadow-elevation-100 print:hidden">
      <div className="text-sm uppercase text-grey-900">{t('Status')}</div>
      <div className="flex items-center gap-2">
        <TourStatusIcon
          status={tour.status}
          isDelayed={tour.delays.length > 0}
          isManualCheckout={tour.checkOutType === CheckoutType.Manual}
        />
        <span className="text-sm text-grey-900" data-test="tour-status-name">
          {tourStatusName}
        </span>
      </div>
      {tour.checkOutType === CheckoutType.Auto && (
        <Alert
          data-test="tour-checkout-auto"
          message={t('Tour checked out automatically.')}
          variant="info"
        />
      )}
      {tour.checkOutType === CheckoutType.Manual && (
        <Alert
          data-test="tour-checkout-manual"
          message={t('Tour checked out by supervisor.')}
          variant="info"
        />
      )}
    </div>
  );
};

TourStatusCard.propTypes = {
  tour: PropTypes.shape({
    checkOutType: PropTypes.oneOf(Object.values(CheckoutType)),
    date: PropTypes.string,
    status: PropTypes.string,
    delays: PropTypes.arrayOf(PropTypes.shape({})),
    stopsRemovedBeforeCheckIn: PropTypes.bool,
  }).isRequired,
};

export default TourStatusCard;

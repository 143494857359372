import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSearchParams } from 'react-router-dom';

import { Card } from '../../components/Card';
import CollapsableCard from '../../components/CollapsableCard';
import ErrorPage from '../../components/ErrorPage';
import RoutingConfigHelper from '../../helpers/RoutingConfigHelper';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';
import routingConfigurationPropType from '../../prop-types/routingConfigurationPropType';
import CollapsableCardsProvider from '../../providers/CollapsableCardsProvider';
import {
  CollapsableRoutingCard,
  InitialRoutingCard,
  RoutingCard,
} from '../shared/RoutingCard';
import DeleteRoutingConfirmationModal from './DeleteRoutingConfirmationModal';
import RoutingConfigurationForm from './RoutingConfigurationForm';

const sortedRoutings = (routingsToSort) =>
  routingsToSort.sort((a, b) => {
    if (a.days === null && b.days !== null) {
      return -1; // a comes before b
    }
    if (a.days !== null && b.days === null) {
      return 1; // b comes before a
    }
    return 0; // no change in order
  });

const groupRoutingsByHub = (routings) =>
  routings.reduce((acc, routing) => {
    const { hub } = routing;
    const key = hub.id;

    if (!acc[key]) {
      acc[key] = [];
    }

    if (!routing.postcodeGroup && !routing.days) {
      acc[key].unshift(routing);
    } else {
      acc[key].push(routing);
    }

    return acc;
  }, {});

const Routings = ({ isFetchError, isLoading, organisationId, routings }) => {
  const { t } = useTranslation();
  const [deleteModalId, setDeleteModalId] = useState(undefined);
  const [searchParams] = useSearchParams();
  const { toastSuccess } = useCustomToast();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const queryClient = useQueryClient();

  const deleteRoutingMutation = useMutation(
    async (id) => {
      const response = await fetch(`/routing-configurations/${id}`, {
        method: 'DELETE',
      });
      return response.json();
    },
    {
      onError: (error) => toastFetchError(error),
      onSuccess: (response) => {
        if (response?.success) {
          toastSuccess(t('Routing Configuration deleted.'));
          queryClient.invalidateQueries([
            `/carriers/${organisationId}/routing-configurations`,
          ]);
        }
      },
    },
  );

  const [carrierRoutings, hubRoutings] = useMemo(() => {
    if (routings.length > 0) {
      const sorted = sortedRoutings(routings);
      const sortedCarrierRoutings = sorted.filter((routing) => !routing.hub);
      const sortedHubRoutings = sorted.filter((routing) => routing.hub);
      const groupedHubRoutings =
        sortedHubRoutings.length > 0
          ? groupRoutingsByHub(sortedHubRoutings)
          : undefined;

      return [sortedCarrierRoutings, groupedHubRoutings];
    }

    return [[], []];
  }, [routings]);

  const [isConfigFormOpen, setIsConfigFormOpen] = useState(undefined);
  const [editingRoutingConfig, setEditingRoutingConfig] = useState(undefined);

  const deleteRoutingModalContent = useMemo(() => {
    if (!deleteModalId) {
      return undefined;
    }

    const routingToDelete = routings.find(
      (routing) => routing.id === deleteModalId,
    );
    const routingType =
      RoutingConfigHelper.getRoutingConfigType(routingToDelete);

    if (
      routingType === RoutingConfigHelper.routingConfigType.CustomCarrierConfig
    ) {
      return {
        title: t('Delete Custom Carrier Configuration?'),
        message: t(
          'Note that this action is permanent. Configuration for these days will reset to Main.',
        ),
      };
    }
    if (routingType === RoutingConfigHelper.routingConfigType.MainHubConfig) {
      return {
        title: t('Delete Main {{hubName}} Configuration?', {
          hubName: routingToDelete.hub.name,
        }),
        message: t(
          'Note that this action is permanent. Main Configuration for this Hub will reset to Carrier Configuration.',
        ),
      };
    }

    return {
      title: t('Delete Custom {{hubName}} Configuration?', {
        hubName: routingToDelete.hub.name,
      }),
      message: t(
        'Note that this action is permanent. Configuration for these days will reset to the Carrier Routing Configuration or, if applicable, the Main Hub Configuration.',
      ),
    };
  }, [deleteModalId, routings, t]);

  if (isFetchError) {
    return <ErrorPage />;
  }

  if (isLoading) {
    return (
      <div className="grid gap-4">
        <Skeleton wrapper={Card} height={156} />
        <Skeleton wrapper={Card} height={156} />
      </div>
    );
  }

  return (
    <>
      <DeleteRoutingConfirmationModal
        handleDelete={() => deleteRoutingMutation.mutate(deleteModalId)}
        isOpen={!!deleteModalId}
        onClose={() => setDeleteModalId(undefined)}
        title={deleteRoutingModalContent?.title}
        message={deleteRoutingModalContent?.message}
      />
      <div className="flex flex-col gap-4">
        <Card>
          <div className="flex flex-col gap-4 divide-y divide-grey-300 mb-4">
            <div className="font-semibold">{t('Carrier Configuration')}</div>
            <div className="text-sm text-grey-700 pt-4">
              {t(
                'Carrier Configuration applies to all Hubs that do not have their own configuration set up, but you can also add customisations on Carrier level that override it for designated days.',
              )}
            </div>
          </div>

          {routings.length === 0 && (
            <InitialRoutingCard
              onAddConfiguration={() => {
                setIsConfigFormOpen(true);
                setEditingRoutingConfig(undefined);
              }}
            />
          )}

          <div className="flex flex-col gap-4">
            <CollapsableCardsProvider isMultipleExpandable>
              {carrierRoutings.map((routing) => {
                const isMain = !routing.days;

                if (isMain) {
                  return (
                    <RoutingCard
                      isCarrierCard
                      key={routing.id}
                      onEditClick={() => {
                        setIsConfigFormOpen(true);
                        setEditingRoutingConfig(routing);
                      }}
                      routing={routing}
                    />
                  );
                }

                return (
                  <CollapsableRoutingCard
                    key={routing.id}
                    onDeleteClick={() => setDeleteModalId(routing.id)}
                    onEditClick={() => {
                      setIsConfigFormOpen(true);
                      setEditingRoutingConfig(routing);
                    }}
                    routing={routing}
                  />
                );
              })}
            </CollapsableCardsProvider>
          </div>
        </Card>

        <Card>
          <div className="flex flex-col gap-4 divide-y divide-grey-300 mb-4">
            <div className="font-semibold">{t('Hub Configuration')}</div>
            <div className="text-sm text-grey-700 pt-4">
              {t(
                'This configuration overrides the Carrier Configuration for a specific Hub.',
              )}
            </div>
          </div>

          {hubRoutings && (
            <div className="flex flex-col gap-4">
              <CollapsableCardsProvider
                defaultOpenIds={[searchParams.get('hubId')]}
                id="hubRoutings"
                isMultipleExpandable
              >
                {Object.keys(hubRoutings).map((key) => {
                  const hubGroup = hubRoutings[key];
                  return (
                    <CollapsableCard
                      key={key}
                      id={key}
                      className="shadow-none border border-grey-300"
                      expandedContent={
                        <div className="flex flex-col gap-4">
                          <hr className="text-grey-300" />
                          {hubGroup.map((routing, index) => (
                            <RoutingCard
                              shouldScrollTo={
                                searchParams.get('hubId') === routing.hub.id &&
                                index === 0
                              }
                              key={routing.id}
                              onDeleteClick={() => setDeleteModalId(routing.id)}
                              onEditClick={() => {
                                setIsConfigFormOpen(true);
                                setEditingRoutingConfig(routing);
                              }}
                              routing={routing}
                            />
                          ))}
                        </div>
                      }
                    >
                      <div>{`${hubGroup[0].hub.name} (${hubGroup[0].hub.shortCode})`}</div>
                    </CollapsableCard>
                  );
                })}
              </CollapsableCardsProvider>
            </div>
          )}
        </Card>
      </div>

      <RoutingConfigurationForm
        isOpen={isConfigFormOpen}
        editingRoutingConfig={editingRoutingConfig}
        onClose={() => {
          setIsConfigFormOpen(false);
          setEditingRoutingConfig(undefined);
        }}
        organisationId={organisationId}
        allRoutingConfigs={routings}
      />
    </>
  );
};

export default Routings;

Routings.propTypes = {
  isLoading: PropTypes.bool,
  isFetchError: PropTypes.bool,
  organisationId: PropTypes.string.isRequired,
  routings: PropTypes.arrayOf(routingConfigurationPropType),
};

Routings.defaultProps = {
  isLoading: true,
  isFetchError: false,
  routings: [],
};

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Popover from '../../../components/Popover';
import UserCardContainer from './UserCardContainer';

const UserPopover = (props) => {
  const { children, placement, title, user } = props;
  const { t } = useTranslation();
  return (
    <Popover
      className="w-[calc(100vw_-_32px)] max-w-[360px]"
      content={<UserCardContainer user={user} />}
      placement={placement}
      title={title || t('User Info')}
    >
      {children}
    </Popover>
  );
};

UserPopover.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  placement: PropTypes.string,
};

UserPopover.defaultProps = {
  title: undefined,
  placement: 'bottom',
};

export default UserPopover;

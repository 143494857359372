import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import useDeliveryOptionsTranslations from '../../../hooks/useDeliveryOptionsTranslations';

const DeliveryOption = ({
  deliveryOption,
  label,
  onButtonClick,
  showEnabledState,
}) => {
  const { t } = useTranslation();
  const { nameTranslations } = useDeliveryOptionsTranslations();
  const deliveryOptionName = nameTranslations[deliveryOption?.code];

  function getEnabledInfo() {
    return (
      <div
        className={cn(
          'text-sm',
          deliveryOption?.enabled
            ? 'text-ui-green-dark font-semibold'
            : 'text-grey-700',
        )}
      >
        {deliveryOption?.enabled &&
          deliveryOption?.requiredConsent &&
          t('Enabled with consent required')}
        {deliveryOption?.enabled &&
          !deliveryOption?.requiredConsent &&
          t('Enabled')}
        {!deliveryOption?.enabled && t('Disabled')}
      </div>
    );
  }

  return (
    <div
      data-test="delivery-option"
      className="flex flex-col gap-4 divide-y divide-grey-300 rounded-md bg-grey-100 p-4 sm:flex-row sm:gap-8 sm:divide-y-0 sm:py-5"
    >
      <div className="flex flex-1 flex-col gap-2 sm:flex-row sm:items-center sm:justify-between">
        <div className="flex items-center justify-between sm:flex-1">
          <div className="self-start font-medium">
            {label || deliveryOptionName || deliveryOption?.code}
          </div>
          {showEnabledState && (
            <div className="hidden sm:block">{getEnabledInfo()}</div>
          )}
        </div>
      </div>

      {showEnabledState && (
        <div className="block sm:hidden pt-4">{getEnabledInfo()}</div>
      )}

      <div className="pt-4 sm:pt-0">
        <Button
          data-test="open-delivery-option-drawer"
          className="w-full sm:w-auto"
          size="s"
          variant="outlineBlue"
          text={t('Configure')}
          onClick={() => onButtonClick(deliveryOption.id)}
        />
      </div>
    </div>
  );
};

DeliveryOption.propTypes = {
  deliveryOption: PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    requiredConsent: PropTypes.bool,
    requiredDriverNote: PropTypes.bool,
    enabled: PropTypes.bool,
  }).isRequired,
  onButtonClick: PropTypes.func.isRequired,
  showEnabledState: PropTypes.bool,
  label: PropTypes.string,
};

DeliveryOption.defaultProps = {
  showEnabledState: true,
  label: '',
};

export default DeliveryOption;

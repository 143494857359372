import { useCallback, useState } from 'react';

export const AsyncStatus = {
  Idle: 'idle',
  Loading: 'loading',
  Success: 'success',
  Error: 'error',
};

const useAsyncFn = (fn) => {
  const [status, setStatus] = useState(AsyncStatus.Idle);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  const runAsyncFn = useCallback(
    async (...args) => {
      setStatus(AsyncStatus.Loading);
      try {
        const value = await fn(...args);
        setStatus(AsyncStatus.Success);
        setData(value);
        setError(undefined);
        return value;
      } catch (err) {
        setStatus(AsyncStatus.Error);
        setData(undefined);
        setError(err);
        return Promise.reject(err);
      }
    },
    [fn],
  );

  const reset = useCallback(() => {
    setStatus(AsyncStatus.Idle);
    setData(undefined);
    setError(undefined);
  }, []);

  return {
    status,
    data,
    error,
    runAsyncFn,
    reset,
  };
};

export default useAsyncFn;

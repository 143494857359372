import PropTypes from 'prop-types';

import RatingImage from '../../../components/RatingImage';
import Tooltip from '../../../components/Tooltip';
import useRatingTranslations from '../../../hooks/useRatingTranslations';

const MemberRating = ({ rating }) => {
  const getRatingTranslation = useRatingTranslations();

  return (
    <div className="flex">
      <Tooltip
        className="block"
        placement="bottom"
        text={getRatingTranslation(rating)}
      >
        <span data-test="rating">
          <RatingImage rating={rating} size="s" />
        </span>
      </Tooltip>
    </div>
  );
};

MemberRating.propTypes = {
  rating: PropTypes.string,
};

MemberRating.defaultProps = {
  rating: undefined,
};

export default MemberRating;

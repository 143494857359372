import cn from 'classnames';
import PropTypes from 'prop-types';

import TourStatus from '../../../enums/TourStatus';
import useTourStatusTranslations from '../../../hooks/useTourStatusTranslations';
import TourStatusIcon from '../TourStatusIcon';

const getPercentage = (total, completed) => {
  if (total === 0) {
    return 0;
  }

  if (total !== undefined && completed !== undefined) {
    return (completed / total) * 100;
  }

  return 0;
};

const TourStatusInfo = ({
  completedStops,
  isDeleyed,
  isManualCheckout,
  totalStops,
  tourStatus,
}) => {
  const tourStatusTranslations = useTourStatusTranslations();
  const tourStatusName = tourStatusTranslations[tourStatus];

  const currentPercentage = getPercentage(totalStops, completedStops);

  return (
    <div className="inline-flex h-[28px] w-full content-start items-center gap-2 text-sm text-grey-900">
      <div className="flex h-4 w-4 content-start items-center">
        <TourStatusIcon
          status={tourStatus}
          isDelayed={isDeleyed}
          isManualCheckout={isManualCheckout}
        />
      </div>

      {tourStatus === TourStatus.Active ? (
        <div className="flex flex-1 content-center items-center">
          <div className="mr-2 min-w-[48px] text-center text-xs font-semibold">
            {completedStops} / {totalStops}
          </div>

          <div className="flex flex-1">
            <div className="h-2 w-full rounded-lg bg-grey-200">
              <div
                className="h-full transition"
                style={{ width: `${currentPercentage}%` }}
              >
                <div
                  className={cn(
                    'h-full rounded-lg',
                    isDeleyed && 'bg-ui-warning-orange',
                    !isDeleyed && 'bg-ui-green-dark',
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div data-test="status-text">{tourStatusName}</div>
      )}
    </div>
  );
};

TourStatusInfo.propTypes = {
  completedStops: PropTypes.number,
  totalStops: PropTypes.number,
  tourStatus: PropTypes.string.isRequired,
  isDeleyed: PropTypes.bool,
  isManualCheckout: PropTypes.bool,
};

TourStatusInfo.defaultProps = {
  completedStops: undefined,
  totalStops: undefined,
  isDeleyed: false,
  isManualCheckout: false,
};

export default TourStatusInfo;

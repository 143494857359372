import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const ListChoice = (props) => {
  const {
    children,
    'data-test': dataTest,
    isSelected,
    onClick,
    ...rest
  } = props;
  return (
    <div
      className={cn(
        'cursor-pointer rounded-md border px-3 py-2.5 hover:bg-grey-100',
        isSelected ? 'border-grey-500' : 'border-transparent',
      )}
      data-test={dataTest}
      onClick={onClick}
      {...rest}
    >
      <div className="flex items-center justify-between">
        {children}
        {isSelected ? (
          <Icon
            className="h-4 w-4 text-ui-blue flex-shrink-0"
            icon="checkmark"
          />
        ) : (
          <div className="h-4 w-4 flex-shrink-0" />
        )}
      </div>
    </div>
  );
};

ListChoice.propTypes = {
  children: PropTypes.node,
  'data-test': PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

ListChoice.defaultProps = {
  children: undefined,
  'data-test': undefined,
  isSelected: false,
  onClick: () => {},
};

export default ListChoice;

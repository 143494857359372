import PropTypes from 'prop-types';

import Event from './Event';
import eventType from './eventType';

const UnitEventHistory = (props) => {
  const { events } = props;

  return (
    <div className="flex flex-col gap-4">
      {events.map((event, index) => {
        const previousEvents = events.slice(index + 1);
        const previousEventWithStatus = previousEvents.find((e) => e.status);
        const previousEventWithExecutionDate = previousEvents.find(
          (e) => e.timeFrom && e.timeTo,
        );

        return (
          <Event
            key={event.id}
            event={event}
            previousEventWithExecutionDate={previousEventWithExecutionDate}
            previousEventWithStatus={previousEventWithStatus}
          />
        );
      })}
    </div>
  );
};

UnitEventHistory.propTypes = {
  events: PropTypes.arrayOf(eventType),
};

UnitEventHistory.defaultProps = {
  events: [],
};

export default UnitEventHistory;

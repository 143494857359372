import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/Button';
import CancelPrompt from '../../components/CancelPrompt';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import SubcarrierForm from '../../features/subcarriers/SubcarriersForm';
import useBlocker from '../../hooks/useBlocker';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';

const MyOrganisationEditSubcarrier = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const { toastSuccess } = useCustomToast();
  const { id } = useParams();

  const {
    data: fetchedSubcarrier,
    error,
    isError,
    isLoading,
  } = useQuery([`/subcarriers/${id}`], async () => {
    const response = await fetch(`/subcarriers/${id}`);
    return response.json();
  });

  const mutation = useMutation(
    (values) =>
      fetch(`/subcarriers/${id}`, {
        body: values,
        method: 'PATCH',
      }),
    {
      onError: toastFetchError,
      onSuccess: () => {
        setIsFormDirty(false);
        toastSuccess(t('Subcarrier data updated.'));
      },
    },
  );

  const subcarrier = fetchedSubcarrier?.data;

  const shouldBlock = useMemo(() => {
    if (mutation.isSuccess) {
      return false;
    }
    return isFormDirty;
  }, [isFormDirty, mutation.isSuccess]);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate(`/my-organisation/subcarriers`, {
        replace: true,
      });
    }
  }, [mutation.isSuccess, navigate]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <CancelPrompt
        isOpen={blocker.state === 'blocked'}
        title={t('Cancel Editing Subcarrier?')}
        onClose={() => blocker.reset()}
        onExitClick={() => blocker.proceed()}
      />
      <Page>
        <Titlebar
          icon={<Icon className="h-5 w-5 text-ui-blue" icon="userAdd" />}
          textPrimary={t('Edit Subcarrier')}
          textSecondary={subcarrier?.name}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => navigate('/my-organisation/subcarriers')}
            />
          }
          isLoading={isLoading}
        />
        <Page.Content variant="grey">
          <Card className="m-auto mt-4 w-full max-w-[480px] px-4 py-5 sm:p-8">
            {isLoading ? (
              <div className="grid gap-8">
                <Skeleton height={110} />
                <Skeleton height={110} />
                <Skeleton height={110} />
              </div>
            ) : (
              <SubcarrierForm
                isEditMode
                isSubmitting={mutation.isLoading}
                name={subcarrier?.name}
                shortCode={subcarrier?.shortCode}
                onCancel={() => {
                  navigate('/my-organisation/subcarriers');
                }}
                onIsDirtyChange={setIsFormDirty}
                onSubmit={mutation.mutate}
              />
            )}
          </Card>
        </Page.Content>
      </Page>
    </>
  );
};

export default MyOrganisationEditSubcarrier;

import PropTypes from 'prop-types';
import { useEffect, useId, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import Skeleton from 'react-loading-skeleton';
import { useDebounce, usePrevious, useWindowSize } from 'react-use';

import FadingOverlay from '../../../components/FadingOverlay';
import Icon from '../../../components/Icon';
import Input from '../../../components/Input';
import Breakpoint from '../../../enums/Breakpoint';
import useInfiniteScrollQuery from '../../../hooks/useInfiniteScrollQuery';
import { useUser } from '../../../providers/UserProvider';
import ListCount from './ListCount';
import SubcarrierItem from './SubcarrierItem';

const AssignSubcarrierTab = (props) => {
  const { onSubcarrierSelect, selectedSubcarrierId } = props;
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { user } = useUser();
  const subcarrierSearchInput = useRef(null);

  const listId = useId();
  const listDomId = `subcarriers-list-${listId}`;
  const root = document.getElementById(listDomId);
  const { inView, ref: bottomRef } = useInView({
    rootMargin: '0px 0px 50px 0px',
    root,
  });
  const previousInView = usePrevious(inView);

  const [subcarrierSearchValue, setSubcarrierSearchValue] = useState('');
  const [debouncedSubcarrierSearchValue, setDebouncedSubcarrierSearchValue] =
    useState('');

  const params = useMemo(
    () => ({ name: debouncedSubcarrierSearchValue }),
    [debouncedSubcarrierSearchValue],
  );

  const [, cancel] = useDebounce(
    () => {
      setDebouncedSubcarrierSearchValue(subcarrierSearchValue.trim());
    },
    300,
    [subcarrierSearchValue],
  );

  useEffect(
    () => () => {
      if (cancel) {
        cancel();
      }
    },
    [cancel],
  );

  const {
    count,
    flattenedData: subcarriers,
    isFetchingNextPage,
    isLoading,
    onBottomReached,
  } = useInfiniteScrollQuery({
    params,
    url: `/carriers/${user.carrier.id}/subcarriers`,
  });

  useEffect(() => {
    if (inView && !previousInView) {
      onBottomReached();
    }
  }, [inView, previousInView, onBottomReached]);

  return (
    <div>
      <div className="mb-4">
        <Input
          autoFocus={width >= Breakpoint.LG}
          className="lg:w-full"
          ref={subcarrierSearchInput}
          clearable
          icon="search"
          isFullWidth
          placeholder={t('Find Subcarrier...')}
          value={subcarrierSearchValue}
          onChange={(e) => {
            setSubcarrierSearchValue(e?.target?.value);
          }}
        />
      </div>
      <div className="h-[400px] overflow-y-scroll">
        {!isLoading && (
          <ListCount>{`${count} ${t('Organisations available')}`}</ListCount>
        )}
        {isLoading ? (
          <FadingOverlay>
            <div className="flex flex-col gap-2">
              <Skeleton height={32} />
              <Skeleton height={32} />
            </div>
          </FadingOverlay>
        ) : (
          subcarriers.map((subcarrier) => (
            <SubcarrierItem
              isSelected={subcarrier.id === selectedSubcarrierId}
              key={subcarrier.id}
              subcarrier={subcarrier}
              onItemClick={(record) =>
                onSubcarrierSelect(
                  record.id === selectedSubcarrierId ? undefined : record.id,
                )
              }
            />
          ))
        )}
        <div ref={bottomRef}>
          {isFetchingNextPage && !isLoading && (
            <div className="flex h-11 w-full items-center justify-center">
              <Icon
                className="h-6 w-6 animate-spin text-primary-dark"
                icon="loading"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AssignSubcarrierTab.propTypes = {
  onSubcarrierSelect: PropTypes.func,
  selectedSubcarrierId: PropTypes.string,
};

AssignSubcarrierTab.defaultProps = {
  onSubcarrierSelect: () => {},
  selectedSubcarrierId: undefined,
};

export default AssignSubcarrierTab;

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from '../../../components/Icon';
import PrinterStatus from '../../../enums/PrinterStatus';
import { useBrowserPrint } from '../../../lib/browser-print/hooks/useBrowserPrint';
import FeedSettingsButton from '../FeedSettingsButton';
import InfoBox from '../InfoBox';

const PrinterLoader = () => {
  const { currentPrinter, currentPrinterStatus, defaultPrinter } =
    useBrowserPrint();

  const { t } = useTranslation();

  const defaultPrinterErrorMessage = t('Default Printer Not Ready');
  const defaultPrinterPendingMessage = t('Initializing Default Printer');
  const currentPrinterErrorMessage = t('Current Printer Not Ready');
  const currentPrinterPendingMessage = t('Initializing Current Printer');

  const isDefaultPrinter = currentPrinter?.uid === defaultPrinter?.uid;

  const pendingTitle = isDefaultPrinter
    ? defaultPrinterPendingMessage
    : currentPrinterPendingMessage;

  const errorTitle = isDefaultPrinter
    ? defaultPrinterErrorMessage
    : currentPrinterErrorMessage;

  if (currentPrinterStatus === PrinterStatus.Initialising) {
    return (
      <InfoBox
        dataTest="initializing-printer-info"
        icon={
          <Icon
            className={cn('h-12 w-12 animate-spin text-primary-yellow')}
            icon="loading"
          />
        }
        text={`${t('Please wait')}...`}
        title={pendingTitle}
      />
    );
  }

  if (currentPrinterStatus === PrinterStatus.NotReady) {
    return (
      <InfoBox
        dataTest="current-printer-not-ready"
        icon={<Icon className="h-12 w-12 text-ui-red" icon="report" />}
        text={t(
          'Please select a different printer in the Feed Settings or set new default device in Zebra Browser Print Service and refresh this page.',
        )}
        title={errorTitle}
      >
        <FeedSettingsButton />
      </InfoBox>
    );
  }

  return null;
};

export default PrinterLoader;

import PropTypes from 'prop-types';

import CardListContainer from '../../components/CardListContainer';
import GroceryTourCard from './GroceryTourCard';

const GroceryTourCardList = (props) => {
  const { data, isInitialLoading, isLoading } = props;

  return (
    <CardListContainer
      isLoading={isLoading}
      isInitialLoading={isInitialLoading}
      isEmpty={data.length === 0}
    >
      {data.map((tour) => (
        <GroceryTourCard key={tour.id} tour={tour} />
      ))}
    </CardListContainer>
  );
};

GroceryTourCardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
};

GroceryTourCardList.defaultProps = {
  data: [],
  isLoading: false,
  isInitialLoading: false,
};

export default GroceryTourCardList;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

import DebouncedSearch from '../../../components/DebouncedSearch';
import Select from '../../../components/Select';
import Breakpoint from '../../../enums/Breakpoint';
import UnitsSearchBy from '../../../enums/UnitsSearchBy';

const UnitsSearch = (props) => {
  const { debounceMs, onSearch, onSearchByChange, searchBy, searchValue } =
    props;

  const { t } = useTranslation();
  const { width } = useWindowSize();

  const isMobile = width < Breakpoint.LG;

  const options = [
    {
      label: t('Unit code'),
      value: UnitsSearchBy.Code,
    },
    {
      label: t('Task code'),
      value: UnitsSearchBy.TaskCode,
    },
    {
      label: t('Tour code'),
      value: UnitsSearchBy.TourNumber,
    },
    {
      label: t('Customer'),
      value: UnitsSearchBy.Recipient,
    },
    {
      label: t('Address'),
      value: UnitsSearchBy.Address,
    },
    {
      label: t('E-mail'),
      value: UnitsSearchBy.Email,
    },
    {
      label: t('Phone number'),
      value: UnitsSearchBy.PhoneNumber,
    },
  ];

  return (
    <div className="flex items-center">
      <Select
        placement={isMobile ? 'top' : undefined}
        className="w-[115px]"
        dropdownClassname="lg:w-[160px]"
        flatRight
        isSearchable={false}
        options={options}
        required
        value={searchBy}
        onChange={onSearchByChange}
      />
      <div className="flex-1">
        <DebouncedSearch
          minLength={3}
          autoFocus={!isMobile}
          className="rounded-l-none lg:w-[240px]"
          isFullWidth
          placeholder={`${t('Type here')} (min. 3)...`}
          searchValue={searchValue}
          onSearch={onSearch}
          debounceMs={debounceMs}
        />
      </div>
    </div>
  );
};

UnitsSearch.propTypes = {
  onSearch: PropTypes.func,
  onSearchByChange: PropTypes.func,
  searchBy: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
  debounceMs: PropTypes.number,
};

UnitsSearch.defaultProps = {
  onSearch: () => {},
  onSearchByChange: () => {},
  searchValue: undefined,
  debounceMs: undefined,
};

export default UnitsSearch;

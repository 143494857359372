import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import deepCopy from '../../../helpers/deepCopy';
import { SocketHandler } from '../../../lib/api/hooks/useSocket';

const TaskUnitSocketHandler = (props) => {
  const { id } = props;
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();

  const topQueryKey = '/tasks';
  const liveQueriesOnScreen = queryCache.findAll({
    active: true,
    queryKey: [topQueryKey],
  });

  const activeQueryKeys = liveQueriesOnScreen.map((query) => query.queryKey);

  const onSocketUpdate = useCallback(
    (event) => {
      if (event.type !== 'updated') {
        return;
      }

      const updatedUnit = event.data;
      const updatedUnitTaskId = updatedUnit?.task?.id;
      const changedProperties = event.changedProperties || [];

      if (updatedUnitTaskId) {
        activeQueryKeys.forEach((queryKey) => {
          queryClient.setQueriesData(
            {
              queryKey,
            },
            (oldData) => {
              const tasks = oldData?.data || [];
              const newTasks = tasks.map((task) => {
                if (task.id === updatedUnitTaskId) {
                  const newTask = deepCopy(task);
                  newTask.units = newTask.units.map((unit) => {
                    if (unit.id === id) {
                      const newUnit = { ...unit };
                      changedProperties.forEach((propertyName) => {
                        newUnit[propertyName] = updatedUnit[propertyName];
                      });
                      return newUnit;
                    }
                    return unit;
                  });

                  return newTask;
                }
                return task;
              });

              return {
                ...oldData,
                data: newTasks,
              };
            },
          );
        });
      }
    },
    [activeQueryKeys, id, queryClient],
  );

  if (!id) {
    return null;
  }

  return <SocketHandler entity="unit" id={id} onEvent={onSocketUpdate} />;
};

TaskUnitSocketHandler.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TaskUnitSocketHandler;

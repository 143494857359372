import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const GlobalHelmet = () => {
  const { i18n } = useTranslation();

  return <Helmet htmlAttributes={{ lang: i18n?.language }} title="Urbify" />;
};

export default GlobalHelmet;

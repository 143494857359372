import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMount } from 'react-use';

import Button from '../../components/Button';
import VersionToastMessage from '../../enums/VersionToastMessage';
import useCustomToast from '../../hooks/useCustomToast';

const toastId = 'version-toast';

const VersionToastHandler = () => {
  const { toastInfo } = useCustomToast();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [pendingUpdateToast, setPendingUpdateToast] = useState(null);

  useMount(() => {
    // notify the service worker registration handler that the toast handler is ready
    window.dispatchEvent(new CustomEvent('version-toast-handler-ready'));
  });

  const getMessage = useCallback(
    (type) => {
      switch (type) {
        case VersionToastMessage.NewVersionAvailableUpdateNow: {
          return t(
            "A new version of the dashboard is available. Click 'Update' to install the latest version. All open dashboard tabs will be automatically refreshed.",
          );
        }

        default:
          throw new Error(`Unknown message type: ${type}`);
      }
    },
    [t],
  );

  const renderToastContent = useCallback(
    (type) => (
      <div>
        <p>{getMessage(type)}</p>
        <div className="mt-4 flex justify-end gap-3">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              // only dismiss toast, dont apply update.
              // pending toast will be shown again on next route change because the update is not yet applied
              toast.dismiss(toastId);
            }}
            variant="outlineBlue"
            text={t('Dismiss')}
            size="s"
          />

          <Button
            onClick={(e) => {
              e.stopPropagation();

              // apply the update and dismiss the toast
              pendingUpdateToast?.detail.onClick();
              toast.dismiss(toastId);
              // toast is not pending anymore. move it from the pending state
              setPendingUpdateToast(null);
            }}
            variant="solidBlue"
            text={t('Update')}
            size="s"
          />
        </div>
      </div>
    ),
    [getMessage, pendingUpdateToast?.detail, t],
  );

  const showPendingUpdateToast = useCallback(() => {
    const isVisible = toast.isActive(toastId);
    if (isVisible) {
      return;
    }

    toastInfo(
      renderToastContent(VersionToastMessage.NewVersionAvailableUpdateNow),
      {
        position: 'bottom-right',
        // prevent any auto close or close handling. we'll do this manually based on update progress
        autoClose: false,
        hideIcon: true,
        hideCloseButton: true,
        hideProgressBar: true,
        closeOnClick: false,
        draggable: false,
        toastId,
        size: 'lg',
      },
    );
  }, [renderToastContent, toastInfo]);

  // Show pending toast on route change.
  // this is a case if the user only dismisses the toast, without "applying" the update
  useEffect(() => {
    if (pathname && pendingUpdateToast) {
      showPendingUpdateToast();
    }
  }, [pathname, pendingUpdateToast, showPendingUpdateToast]);

  useEffect(() => {
    if (pendingUpdateToast) {
      showPendingUpdateToast();
    }
  }, [pendingUpdateToast, showPendingUpdateToast]);

  // Listener for toast events
  useEffect(() => {
    const handleToast = (event) => {
      setPendingUpdateToast(event);
    };

    window.addEventListener('show-new-version-toast', handleToast);

    return () => {
      window.removeEventListener('show-new-version-toast', handleToast);
    };
  }, [getMessage, toastInfo]);
};

VersionToastHandler.propTypes = {
  children: PropTypes.node,
};

VersionToastHandler.defaultProps = {
  children: undefined,
};

export default VersionToastHandler;

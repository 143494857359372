import { useTranslation } from 'react-i18next';

import OrganisationType from '../../../enums/OrganisationType';
import UserRole from '../../../enums/UserRole';
import { useUser } from '../../../providers/UserProvider';

const useGetRoleOptions = (organisationType, isCustomer) => {
  const { t } = useTranslation();
  const { isSubcarrierUser, user } = useUser();

  if (isCustomer) {
    return [{ label: t('Client'), value: UserRole.Customer }];
  }

  if (isSubcarrierUser) {
    return [
      { label: t('Driver'), value: UserRole.Driver },
      {
        label: t('Operations Manager'),
        value: UserRole.OperationsManager,
      },
      {
        label: t('Hub Manager'),
        value: UserRole.HubManager,
      },
      { label: t('Crew'), value: UserRole.Crew },
    ];
  }

  if (organisationType === OrganisationType.Carrier) {
    const options = [{ label: t('Driver'), value: UserRole.Driver }];

    if (
      user.role === UserRole.OperationsManager ||
      user.role === UserRole.Admin
    ) {
      options.push({
        label: t('Operations Manager'),
        value: UserRole.OperationsManager,
      });
    }

    if (
      user.role === UserRole.HubManager ||
      user.role === UserRole.OperationsManager ||
      user.role === UserRole.Admin
    ) {
      options.push(
        {
          label: t('Hub Manager'),
          value: UserRole.HubManager,
        },
        { label: t('Crew'), value: UserRole.Crew },
      );
    }

    if (user.role === UserRole.Superadmin) {
      return [
        {
          label: t('Admin'),
          value: UserRole.Admin,
        },
      ];
    }

    return options;
  }

  if (organisationType === OrganisationType.Superadmin) {
    return [
      {
        label: t('Superadmin'),
        value: UserRole.Superadmin,
      },
    ];
  }

  return [];
};

export default useGetRoleOptions;

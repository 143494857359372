import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import Breakpoint from '../../enums/Breakpoint';
import TasksPageDesktop from '../../features/tasks/TasksPage/TasksPageDesktop';
import TasksPageMobile from '../../features/tasks/TasksPage/TasksPageMobile';
import TasksUnitsHelper from '../../helpers/TasksUnitsHelper';
import usePageLocationQuery from '../../hooks/usePageLocationQuery';
import ListSelectionProvider from '../../providers/ListSelectionProvider';

const Tasks = () => {
  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.LG;
  const page = usePageLocationQuery();
  const { t } = useTranslation();

  if (page === undefined) {
    return <Navigate replace to="/tasks?page=1" />;
  }

  return (
    <ListSelectionProvider
      disabledCheckboxTooltipMessage={t(
        'Hub filter must be active to make selection',
      )}
      getCheckboxItemData={(task) => ({
        disabled: !TasksUnitsHelper.isTaskExecutionDateActionEnabled(task),
        disabledTooltip: t(
          'Update date is available only for tasks without a Tour and with an execution date no earlier than today',
        ),
      })}
    >
      {isMobile ? <TasksPageMobile /> : <TasksPageDesktop />}
    </ListSelectionProvider>
  );
};

export default Tasks;

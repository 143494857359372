import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Button from '../../../components/Button';
import CollapsableCard from '../../../components/CollapsableCard';
import CheckoutType from '../../../enums/CheckoutType';
import TourHelper from '../../../helpers/TourHelper';
import ShiftLabel from '../../shared/ShiftLabel';
import TourAssignment from '../../shared/TourAssignment';
import TourDetails from '../../shared/TourDetails';
import TourStatusInfo from '../../tours/TourStatusInfo';

const FixedTourCard = (props) => {
  const { tour } = props;
  const location = useLocation();

  const { t } = useTranslation();

  return (
    <CollapsableCard
      id={tour.id}
      data-test={`fixed-tours-mobile-card-${tour.id}`}
      expandedContent={
        <div className="flex flex-col gap-1">
          <div className="flex flex-col border-t border-grey-200 py-3">
            <TourAssignment size="m" tour={tour} />
          </div>

          <div className="flex flex-1 flex-col border-t border-grey-200 py-3">
            <TourDetails className="justify-between" tour={tour} />
          </div>

          <div className="flex items-center justify-between border-t border-grey-200 py-3 text-sm">
            <span data-test="tour-mobile-card-date">
              {moment(tour.date).format('DD.MM.YYYY')}
            </span>
            <span>
              <ShiftLabel shiftNumber={tour.shift} />{' '}
              {moment(tour.shiftStart).format('HH:mm')}
            </span>
          </div>
          <div className="flex flex-col border-t border-grey-200 pt-4">
            <Button
              as={Link}
              data-test="fixed-tours-mobile-view-details"
              icon="pageView"
              isFullWidth
              state={{
                backLink: location.pathname + location.search,
              }}
              text={t('View Tour Details')}
              to={{
                pathname: `/tours/${tour.id}`,
              }}
              variant="outlineBlack"
            />
          </div>
        </div>
      }
      key={tour.id}
    >
      <div className="flex flex-1 flex-col gap-2">
        <span className="break-all text-base font-medium">
          {TourHelper.getTourCode(tour)}
        </span>
        <div>
          <TourStatusInfo
            isManualCheckout={tour.checkOutType === CheckoutType.Manual}
            isDeleyed={tour.delayCount > 0}
            completedStops={tour.completedStops}
            totalStops={tour.totalStops}
            tourStatus={tour.status}
          />
        </div>
      </div>
    </CollapsableCard>
  );
};

FixedTourCard.propTypes = {
  tour: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default FixedTourCard;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import GroceriesTourStatus from '../enums/GroceriesTourStatus';

const useGroceriesTourStatusTranslations = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      [GroceriesTourStatus.Active]: t('In Delivery'),
      [GroceriesTourStatus.Completed]: t('Completed'),
      [GroceriesTourStatus.NotAssignedDriver]: t('Not assigned'),
      [GroceriesTourStatus.Pending]: t('Pickup Pending'),
      [GroceriesTourStatus.CanceledAdmin]: t('Cancelled by Admin'),
      [GroceriesTourStatus.CompletedAdmin]: t('Completed by Admin'),
      [GroceriesTourStatus.Created]: t('Created'),
    }),
    [t],
  );
};

export default useGroceriesTourStatusTranslations;

import cn from 'classnames';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import ListChoice from '../../../components/ListChoice';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import useReturnReasonTranslations from '../../../hooks/useReturnReasonTranslations';
import ChangeUnitStatusContext from './ChangeUnitStatusContext';

const ReturnReasons = () => {
  const returnResonsTranslations = useReturnReasonTranslations();
  const { reset, setValue, watch } = useFormContext();
  const status = watch('status');
  const failureReason = watch('returnReason');
  const changeUnitStatusContext = useContext(ChangeUnitStatusContext);

  const { availableReturnReasons = [], defaultValues = {} } =
    changeUnitStatusContext;
  const { returnReason: originalReturnReason, status: originalStatus } =
    defaultValues;
  const isOriginalStatusRetry = originalStatus === DeliveryTaskUnitStatus.Retry;

  const options = [
    ...new Set([originalReturnReason, ...availableReturnReasons]),
  ].filter((option) => option);

  return (
    <div className="flex">
      <div className="ml-5 mr-3 border-l-[1px] border-grey-300" />
      <div className="flex-1">
        <Controller
          name="returnReason"
          render={({ field: { onChange: onFailureReasonChange } }) =>
            options.map((availableReturnReason) => {
              const isSelected =
                status === DeliveryTaskUnitStatus.ReturnToClient &&
                failureReason === availableReturnReason;

              const isOriginalFailureReason =
                originalReturnReason === availableReturnReason;
              const isOriginal =
                isOriginalStatusRetry && isOriginalFailureReason;

              return (
                <div
                  className={cn(
                    isOriginalStatusRetry &&
                      !isOriginalFailureReason &&
                      'opacity-40 pointer-events-none',
                  )}
                  key={availableReturnReason}
                >
                  <ListChoice
                    isSelected={isSelected}
                    onClick={() => {
                      if (isOriginal) {
                        reset();
                        return;
                      }

                      if (isSelected) {
                        return;
                      }

                      onFailureReasonChange(availableReturnReason);
                      setValue('deliveryOptionNote', '');
                      setValue('deliveryOption', '');
                      setValue('postponeReason', '');
                      setValue('failureReason', '');
                      setValue('postponeReasonNote', '');
                      setValue('notifyCustomer', false);
                      setValue('statusNote', '');
                      setValue('unitSpecialCase', '');
                      setValue(
                        'status',
                        DeliveryTaskUnitStatus.ReturnToClient,
                        {
                          shouldDirty: true,
                        },
                      );
                    }}
                  >
                    <span className="text-sm text-primary-dark">
                      {returnResonsTranslations[availableReturnReason]}
                    </span>
                  </ListChoice>
                </div>
              );
            })
          }
        />
      </div>
    </div>
  );
};

export default ReturnReasons;

import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardContent,
  CardHeader,
  CardIcon,
  CardItem,
} from '../../components/Card';
import UserRole from '../../enums/UserRole';
import { useIsRole } from '../../providers/UserProvider';
import GroceriesTourStatusCard from './GroceriesTourStatusCard';

const CustomerGroceryTourDetails = ({ tour }) => {
  const { t } = useTranslation();

  const isAdmin = useIsRole(UserRole.Admin);

  return (
    <div className="flex flex-col gap-4">
      <div className="grid gap-4 lg:grid-cols-3">
        <GroceriesTourStatusCard isAdmin={isAdmin} tour={tour} />
        <Card>
          <CardHeader title={t('Date')} />
          <CardContent>
            <div className="flex gap-2">
              <CardIcon className="text-grey-500" icon="date" />
              <span>{moment(tour.date).format('DD.MM.YYYY')}</span>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title={t('Start Time')} />
          <CardContent>
            <div className="flex gap-2">
              <CardIcon className="text-grey-500" icon="startTime" />
              <span>{`${moment(tour.startLoading).format('HH:mm')}`}</span>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader title={t('Pickup Information')} />
        <CardContent className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
          <CardItem title={t('Hub Name')}>
            <div className="flex gap-2">
              <CardIcon className="text-primary-yellow" icon="warehouse" />
              {tour.hub?.name}
            </div>
          </CardItem>

          <CardItem title={t('WA-Ramp')}>
            <div className="flex gap-2">
              <CardIcon className="text-primary-yellow" icon="warehouseRamp" />
              {tour.waRamp ? tour.waRamp : t('N/A')}
            </div>
          </CardItem>

          <CardItem title={t('WA-Spur')}>
            <div className="flex gap-2">
              <CardIcon className="text-primary-yellow" icon="warehouseSpur" />
              {tour.waSpur ? tour.waSpur : t('N/A')}
            </div>
          </CardItem>

          <CardItem title={t('Hub Location')}>
            <div className="flex gap-2">
              <CardIcon className="text-primary-yellow" icon="locationOn" />
              <span className="break-words">{tour.hub?.address}</span>
            </div>
          </CardItem>
        </CardContent>
      </Card>
    </div>
  );
};

CustomerGroceryTourDetails.propTypes = {
  tour: PropTypes.shape({
    date: PropTypes.string,
    startLoading: PropTypes.string,
    hub: PropTypes.shape({
      address: PropTypes.string,
      name: PropTypes.string,
    }),
    waRamp: PropTypes.string,
    waSpur: PropTypes.string,
    duration: PropTypes.number,
    shift: PropTypes.number,
  }).isRequired,
};
export default CustomerGroceryTourDetails;

import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

import logo from '../../assets/images/logo-big.svg';
import useSentry from '../../hooks/useSentry';

const ErrorBoundary = () => {
  const { t } = useTranslation();

  const { showReportDialog } = useSentry();
  const routeError = useRouteError();

  useEffect(() => {
    const eventId = Sentry.captureException(routeError);
    showReportDialog(eventId);
  }, [routeError, showReportDialog]);

  return (
    <div
      className="relative flex flex-1 flex-col px-8"
      data-test="error-boundary-fallback"
    >
      <div className="flex flex-1 flex-col items-center sm:justify-center lg:mb-4">
        <div className="flex h-screen flex-col justify-center gap-10">
          <img alt="logo" className="h-10" src={logo} />
          <h4 className="text-center text-ui-red">
            {t('Something went wrong.')} {t('Please try refreshing the page.')}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundary;

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import eventType from '../eventType';
import StatusField from './StatusField';
import UserField from './UserField';

const RefusedEvent = (props) => {
  const { event } = props;
  const { t } = useTranslation();
  return (
    <>
      <StatusField event={event} />
      <div>
        <span>{t('Attempted')}: </span>
        <span className="font-medium">
          {moment(event.timestamp).format('DD.MM.YYYY - HH:mm')}
        </span>
      </div>

      <UserField event={event} />
    </>
  );
};

RefusedEvent.propTypes = {
  event: eventType.isRequired,
};

export default RefusedEvent;

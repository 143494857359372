/* eslint-disable react/no-unstable-nested-components */
import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Icon from '../../../components/Icon';
import LinkButton from '../../../components/LinkButton';
import NewTable, { CellPadding } from '../../../components/NewTable';
import Tooltip from '../../../components/Tooltip';
import LocalStorage from '../../../enums/LocalStorage';
import TaskStatus from '../../../enums/TaskStatus';
import TaskType from '../../../enums/TaskType';
import LocalStorageHelper from '../../../helpers/LocalStorageHelper';
import useTaskStatusTranslations from '../../../hooks/useTaskStatusTranslations';
import useTaskTypeTranslations from '../../../hooks/useTaskTypeTranslations';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import { useUser } from '../../../providers/UserProvider';
import EndCustomerPopover from '../../shared/EndCustomerPopover';
import ShiftLabel from '../../shared/ShiftLabel';
import UnitDetailsDrawer from '../../shared/UnitDetailsDrawer';
import UnitStatusUpdate from '../../shared/UnitStatusUpdate';
import ManualDataImport from '../ManualDataImport';
import TaskUnitList from '../TaskUnitList';
import UpdateAddressDrawer from '../UpdateAddressDrawer';
import UpdateDateDrawer from '../UpdateDateDrawer';
import TasksTableActionsCell from './TasksTableActionCell';
import TasksTableFilters from './TasksTableFilters';
import TasksTableHeaderActions from './TasksTableHeaderActions';

export const tasksTableId = 'tasks-table';

const TasksTable = (props) => {
  const { data, total, ...rest } = props;
  const { t } = useTranslation();
  const { user } = useUser();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isManualDataImportDrawerOpen, setIsManualDataImportDrawerOpen] =
    useState(false);
  const [unitForStatusUpdate, setUnitForStatusUpdate] = useState(null);
  const [unitForDetailsView, setUnitForDetailsView] = useState(null);
  const [taskForAddressUpdate, setTaskForAddressUpdate] = useState(null);
  const [taskForDateUpdate, setTaskForDateUpdate] = useState(null);
  const { isSelectionVisible } = useListSelection();
  const typeTranslation = useTaskTypeTranslations();
  const statusTranslation = useTaskStatusTranslations();

  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (location) {
      // Close drawers when location changes
      setIsDrawerOpen(false);
      setIsManualDataImportDrawerOpen(false);
    }
  }, [location]);

  const columnVisibilityState = JSON.parse(
    LocalStorageHelper.getItem(LocalStorage.TasksColumnsVisibilitySettings),
  );

  const onColumnVisibilityChange = useCallback(
    (newColumnVisibilityState) => {
      LocalStorageHelper.setItem(
        LocalStorage.TasksColumnsVisibilitySettings,
        JSON.stringify({
          [user.id]: newColumnVisibilityState,
        }),
      );
    },
    [user.id],
  );

  const columns = useMemo(() => {
    const tempColumns = [
      {
        accessorKey: 'code',
        id: 'code',
        header: t('Task Code'),
        enableHiding: false,
        size: 212,
      },
      {
        id: 'type',
        header: t('Task type'),
        enableHiding: false,
        size: 120,
        cell: ({ row: { original: record } }) => (
          <span>{`${typeTranslation[record.type]}`}</span>
        ),
      },
      {
        id: 'status',
        header: t('Task Status'),
        enableHiding: false,
        size: i18n.language === 'en' ? 120 : 130,
        cell: ({ row: { original: record } }) => (
          <span>{`${statusTranslation[record.status]}`}</span>
        ),
      },
      {
        id: 'address',
        header: () => (
          <Tooltip text={t('Always shows the latest address')}>
            <div className="flex items-center gap-2">
              <span>{t('Address')}</span>
              <Icon className="h-4 w-4 text-grey-500" icon="infoFilled" />
            </div>
          </Tooltip>
        ),
        meta: {
          headerLabel: t('Address'),
        },
        enableHiding: false,
        cell: ({ row: { original: record } }) => (
          <span
            className={cn(
              'break-normal',
              record.invalidAddress && 'text-ui-red',
            )}
          >
            {record.address}
          </span>
        ),
        size: 352,
      },
      {
        id: 'date',
        accessorKey: 'date',
        header: () => (
          <Tooltip text={t('Always shows the latest execution date')}>
            <div className="flex items-center gap-2">
              <span>{t('Execution Date')}</span>
              <Icon className="h-4 w-4 text-grey-500" icon="infoFilled" />
            </div>
          </Tooltip>
        ),
        meta: {
          headerLabel: t('Execution Date'),
        },
        enableHiding: false,
        size: i18n.language === 'en' ? 150 : 180,
        cell: ({ getValue }) => (
          <span>
            {getValue() ? moment(getValue()).format('DD.MM.YYYY.') : 'N/A'}
          </span>
        ),
      },
      {
        id: 'executionSlot',
        header: () => (
          <Tooltip text={t('Always shows the latest execution slot')}>
            <div className="flex items-center gap-2">
              <span>{t('Execution Slot')}</span>
              <Icon className="h-4 w-4 text-grey-500" icon="infoFilled" />
            </div>
          </Tooltip>
        ),
        meta: {
          headerLabel: t('Execution Slot'),
        },
        enableHiding: false,
        size: i18n.language === 'en' ? 150 : 170,
        cell: ({ row: { original: record } }) => (
          <span>{`${moment(record.timeFrom).format('HH:mm')}-${moment(
            record.timeTo,
          ).format('HH:mm')}`}</span>
        ),
      },
      {
        id: 'shiftNumber',
        accessorKey: 'shiftNumber',
        header: t('Shift'),
        size: i18n.language === 'en' ? 64 : 86,
        cell: ({ getValue }) =>
          getValue() ? (
            <ShiftLabel shiftNumber={getValue()} />
          ) : (
            <span>N/A</span>
          ),
      },
      {
        id: 'executionWindow',
        header: () => (
          <Tooltip text={t('Always shows the latest execution window')}>
            <div className="flex items-center gap-2">
              <span>{t('Execution Window')}</span>
              <Icon className="h-4 w-4 text-grey-500" icon="infoFilled" />
            </div>
          </Tooltip>
        ),
        meta: {
          headerLabel: t('Execution Window'),
        },
        size: i18n.language === 'en' ? 166 : 190,
        cell: ({ row: { original: record } }) => (
          <span>
            {record.latestTour?.tourStop?.windowFrom &&
            record.latestTour?.tourStop?.windowTo
              ? `${moment(record.latestTour.tourStop.windowFrom).format(
                  'HH:mm',
                )}-${moment(record.latestTour.tourStop.windowTo).format('HH:mm')}`
              : 'N/A'}
          </span>
        ),
      },
      {
        accessorKey: 'createdAt',
        id: 'openedDate',
        header: t('Date Opened'),
        size: i18n.language === 'en' ? 120 : 130,
        cell: ({ getValue }) => (
          <span>{moment(getValue()).format('DD.MM.YYYY.')}</span>
        ),
      },
      {
        id: 'tourStop',
        header: () => (
          <Tooltip text={t('Always shows the latest Tour info')}>
            <div className="flex items-center gap-2">
              <span>{t('Tour Stop')}</span>
              <Icon className="h-4 w-4 text-grey-500" icon="infoFilled" />
            </div>
          </Tooltip>
        ),
        meta: {
          headerLabel: t('Tour Stop'),
        },
        size: 188,
        cell: ({ row: { original: record } }) => {
          if (record.latestTour) {
            return (
              <LinkButton
                className="text-sm"
                as={Link}
                text={`${record?.latestTour?.vehicle} ${record?.latestTour?.tourStop?.sequence}/${record?.latestTour?.totalStops}`}
                to={{
                  pathname: `/tours/${record?.latestTour?.id}`,
                  search: `?stopId=${record?.latestTour?.tourStop.id}`,
                }}
                state={{
                  backLink: -1,
                }}
              />
            );
          }
          return <span>N/A</span>;
        },
      },
      {
        id: 'hub',
        header: t('Hub'),
        size: 72,
        cell: ({ row: { original: record } }) => (
          <span>{record?.hub?.shortCode || 'N/A'}</span>
        ),
      },
      {
        id: 'unitCount',
        header: t('Unit Count'),
        size: i18n.language === 'en' ? 112 : 130,
        cell: ({ row: { original: record } }) => (
          <span>{`${record.units.length}`}</span>
        ),
      },
      {
        id: 'client',
        header: t('Client'),
        size: 158,
        cell: ({ row: { original: record } }) => (
          <span>{`${record.client?.name || 'N/A'}`}</span>
        ),
      },
      {
        id: 'endCustomer',
        header: t('Customer'),
        size: 212,
        cell: ({ row: { original: record } }) => (
          <EndCustomerPopover
            title={record.endCustomerName}
            phoneNumber={record.phoneNumber}
            email={record.email}
            key={record.id}
          >
            <LinkButton className="text-left" text={record.endCustomerName} />
          </EndCustomerPopover>
        ),
      },
      {
        id: 'actions',
        size: 50,
        cell: (cellProps) => (
          <TasksTableActionsCell
            onUpdateAddressClick={setTaskForAddressUpdate}
            onUpdateDateClick={setTaskForDateUpdate}
            {...cellProps}
          />
        ),
        enableHiding: false,
        header: () => (
          <TasksTableHeaderActions
            onShowHideColumnsClick={() => setIsDrawerOpen(true)}
            onDataImportClick={() => setIsManualDataImportDrawerOpen(true)}
          />
        ),
        meta: {
          padding: CellPadding.Sm,
          showInVisibilityToggle: false,
        },
      },
    ];

    if (isSelectionVisible) {
      return tempColumns.filter((column) => column.id !== 'actions');
    }

    return tempColumns;
  }, [
    i18n.language,
    isSelectionVisible,
    statusTranslation,
    t,
    typeTranslation,
  ]);

  const getHiddenColumnsByDefault = useMemo(() => {
    const defaultColumns = {};
    columns.forEach((column) => {
      if (column.enableHiding === undefined) {
        defaultColumns[column.id] = false;
      }
    });
    return defaultColumns;
  }, [columns]);

  const isLocalStorageEmpty = useMemo(() => {
    if (
      !columnVisibilityState ||
      Object.keys(columnVisibilityState[user.id] || {}).length === 0
    ) {
      return true;
    }

    return false;
  }, [columnVisibilityState, user.id]);

  const renderSubComponent = useCallback((row) => {
    const task = row?.original;
    return (
      row?.original?.units?.length > 0 && (
        <TaskUnitList
          units={task.units}
          onUpdateUnitStatus={setUnitForStatusUpdate}
          onUnitDetailsClick={setUnitForDetailsView}
        />
      )
    );
  }, []);

  return (
    <>
      <TasksTableFilters count={total} />
      <ManualDataImport
        onClose={() => {
          setIsManualDataImportDrawerOpen(false);
        }}
        isOpen={isManualDataImportDrawerOpen}
      />
      <UnitStatusUpdate
        isOpen={!!unitForStatusUpdate}
        units={[unitForStatusUpdate]}
        onClose={() => setUnitForStatusUpdate(null)}
      />
      <UnitDetailsDrawer
        isOpen={!!unitForDetailsView}
        unitId={unitForDetailsView?.id}
        onClose={() => setUnitForDetailsView(null)}
      />
      <UpdateAddressDrawer
        isOpen={!!taskForAddressUpdate}
        task={taskForAddressUpdate}
        onClose={() => setTaskForAddressUpdate(null)}
      />
      <UpdateDateDrawer
        isOpen={!!taskForDateUpdate}
        tasks={[taskForDateUpdate]}
        onClose={() => setTaskForDateUpdate(null)}
      />
      {/* id is mandatory for scroll and expansion  */}
      <NewTable
        id={tasksTableId}
        restoreScroll
        inlineScroll
        preserveExpandedStateInLocation
        minColSize={50}
        roundedTop={false}
        data={data}
        columns={columns}
        enableSelection={isSelectionVisible}
        columnVisibilityState={
          isLocalStorageEmpty
            ? getHiddenColumnsByDefault
            : columnVisibilityState[user.id]
        }
        isDrawerOpen={isDrawerOpen}
        onDrawerClose={() => setIsDrawerOpen(false)}
        onColumnVisibilityChange={onColumnVisibilityChange}
        renderSubComponent={renderSubComponent}
        {...rest}
      />
    </>
  );
};

TasksTable.propTypes = {
  total: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      type: PropTypes.oneOf(Object.values(TaskType)),
      status: PropTypes.oneOf(Object.values(TaskStatus)),
      address: PropTypes.string,
      invalidAddress: PropTypes.bool,
      date: PropTypes.string,
      timeFrom: PropTypes.string,
      timeTo: PropTypes.string,
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
      shiftNumber: PropTypes.number,
      latestTour: PropTypes.shape({
        id: PropTypes.string,
        vehicle: PropTypes.string,
        totalStops: PropTypes.number,
        tourStop: PropTypes.shape({
          sequence: PropTypes.number,
          windowTo: PropTypes.string,
          windowFrom: PropTypes.string,
        }),
      }),
      endCustomerName: PropTypes.string,
      hub: PropTypes.shape({
        id: PropTypes.string,
        shortCode: PropTypes.string,
      }),
      createdAt: PropTypes.string,
      client: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ),
};

TasksTable.defaultProps = {
  data: [],
  total: undefined,
};

export default TasksTable;

import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Icon from '../../../components/Icon';
import InfiniteScrollFormSelect from '../../../components/InfiniteScrollFormSelect';
import RadioButton from '../../../components/RadioButton';
import { useUser } from '../../../providers/UserProvider';

const ShiftTypeSection = ({ isEdit }) => {
  const { control, watch } = useFormContext();
  const { t } = useTranslation();
  const { user } = useUser();

  const selectedShiftType = watch('shiftType');
  const transformOptionFn = useCallback(
    (option) => ({
      label: `${option.shortCode} (${option.name})`,
      value: option.id,
    }),
    [],
  );

  return (
    <div className="flex flex-col gap-4">
      {(!isEdit || selectedShiftType === 'carrierShift') && (
        <div>
          {isEdit ? (
            <div className="mb-4">{t('Carrier Shift (CS)')}</div>
          ) : (
            <Controller
              control={control}
              name="shiftType"
              render={({ field: { name, onChange, value, ...rest } }) => (
                <RadioButton
                  name="shiftType"
                  value="carrierShift"
                  checked={value === 'carrierShift'}
                  label={t('Carrier Shift (CS)')}
                  onChange={onChange}
                  {...rest}
                />
              )}
            />
          )}
          <div className="flex items-center gap-2 text-sm text-grey-700">
            <Icon className="h-4 w-4 text-ui-info-blue" icon="infoFilled" />
            <span>
              {t(
                'Carrier Shift is a shift defined at the Carrier Organisation level and is automatically assigned to all Hubs that do not have a Hub shift applied.',
              )}
            </span>
          </div>
        </div>
      )}
      {!isEdit && (
        <div className="border-bottom h-0.5 w-full border border-grey-200" />
      )}
      {(!isEdit || selectedShiftType === 'hubShift') && (
        <div>
          {isEdit ? (
            <div className="mb-4">{t('Hub Shift (WS)')}</div>
          ) : (
            <Controller
              control={control}
              name="shiftType"
              render={({ field: { name, onChange, value, ...rest } }) => (
                <RadioButton
                  data-test="hub-shift"
                  name="shiftType"
                  checked={value === 'hubShift'}
                  value="hubShift"
                  label={t('Hub Shift (WS)')}
                  onChange={onChange}
                  {...rest}
                />
              )}
            />
          )}
          <div className="flex items-center gap-2 text-sm text-grey-700">
            <Icon className="h-4 w-4 text-ui-info-blue" icon="infoFilled" />
            <span>
              {t(
                'Hub Shift applies only to selected Hubs and overrides the Carrier Shift specifically for those Hubs.',
              )}
            </span>
          </div>
        </div>
      )}
      {selectedShiftType === 'hubShift' && !isEdit && (
        <div data-test="hub-id-select">
          <InfiniteScrollFormSelect
            disabled={isEdit}
            singleItemUrl="/hubs/"
            url={`/carriers/${user?.carrier?.id}/hubs`}
            id="hub-select"
            label={t('Hub')}
            name="hubId"
            placeholder={t('Select Hub')}
            required
            transformOptionFn={transformOptionFn}
          />
        </div>
      )}
    </div>
  );
};

export default ShiftTypeSection;

ShiftTypeSection.propTypes = {
  isEdit: PropTypes.bool,
};

ShiftTypeSection.defaultProps = {
  isEdit: false,
};

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';

const ShiftTimeConflictModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      body={
        <p>
          {t(
            'Two or more shifts cannot operate within the same time slot. Please adjust the duration of the shift accordingly.',
          )}
        </p>
      }
      footer={
        <Button
          data-test="modal-button-cancel"
          text="OK"
          variant="solidBlue"
          onClick={onClose}
        />
      }
      header={
        <div className="flex items-center gap-2">
          <Icon className="h-6 w-6 text-grey-700" icon="warning" />
          <span>{t('Shift Time Conflict')}</span>
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

ShiftTimeConflictModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShiftTimeConflictModal;

import cn from 'classnames';
import PropTypes from 'prop-types';

import { Card } from '../../../components/Card';

const OrganisationDetailsSectionCard = (props) => {
  const { children, noWrap, title, topRightContent } = props;

  return (
    <Card className="flex flex-col justify-center">
      <div
        className={cn(
          'flex items-center justify-between gap-2 sm:flex-row',
          noWrap && 'flex-row',
          !noWrap && 'flex-col',
        )}
      >
        <div className="w-full font-medium text-primary-dark">{title}</div>
        {topRightContent}
      </div>
      {children && children}
    </Card>
  );
};

OrganisationDetailsSectionCard.propTypes = {
  children: PropTypes.node,
  noWrap: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  topRightContent: PropTypes.oneOfType([PropTypes.object]),
};

OrganisationDetailsSectionCard.defaultProps = {
  children: undefined,
  noWrap: false,
  title: undefined,
  topRightContent: undefined,
};

export default OrganisationDetailsSectionCard;

import eventType from '../eventType';
import StatusField from './StatusField';
import UserField from './UserField';

const DriverScanEvent = (props) => {
  const { event } = props;
  return (
    <>
      <StatusField event={event} />
      <UserField event={event} />
    </>
  );
};

DriverScanEvent.propTypes = {
  event: eventType.isRequired,
};

export default DriverScanEvent;

import cn from 'classnames';
import PropTypes from 'prop-types';

const ListItem = (props) => {
  const {
    'data-test': dataTest,
    isDisabled,
    isSelected,
    onClick,
    value,
  } = props;
  return (
    <li
      data-test={dataTest}
      data-test-value={value}
      data-test-is-selected={isSelected}
    >
      {/* button used for aria compliance, li is non-interactive element */}
      <button
        disabled={isDisabled}
        type="button"
        className={cn(
          'flex h-6 w-12 cursor-pointer items-center justify-center rounded text-sm',
          isDisabled ? 'text-grey-500' : 'text-primary-dark',
          isSelected ? 'bg-ui-blue text-white' : 'hover:bg-grey-200',
        )}
        onClick={() => {
          if (!isDisabled) {
            onClick(value);
          }
        }}
      >
        <span>{value}</span>
      </button>
    </li>
  );
};

ListItem.propTypes = {
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  'data-test': PropTypes.string,
};

ListItem.defaultProps = {
  value: undefined,
  isDisabled: false,
  isSelected: false,
  onClick: () => {},
  'data-test': undefined,
};

export default ListItem;

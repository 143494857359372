import cn from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import CollapsableCard from '../../../components/CollapsableCard';
import UserRole from '../../../enums/UserRole';
import useRoutingDaysTranslations from '../../../hooks/useRoutingDaysTranslations';
import routingConfigurationPropType from '../../../prop-types/routingConfigurationPropType';
import { useIsRole } from '../../../providers/UserProvider';

export const InitialRoutingCard = ({ onAddConfiguration }) => {
  const { t } = useTranslation();
  const isAdmin = useIsRole(UserRole.Admin);

  return (
    <div className="p-4 rounded-md flex flex-col gap-4 bg-grey-100">
      <div className="flex flex-col gap-2">
        <div className="font-medium">{t('Main')}</div>
        <div className="text-sm text-grey-700">
          {t(
            "This applies by default to each day that doesn't have a custom setup assigned.",
          )}
        </div>
      </div>
      <hr className="text-grey-300" />
      <div className="flex flex-col gap-4">
        <div className="text-sm text-grey-700">
          {t('Carrier can’t perform routing without Main configuration setup.')}
        </div>
        {isAdmin && (
          <Button
            onClick={onAddConfiguration}
            className="w-full lg:w-fit"
            icon="plus"
            text={t('Add Main Configuration')}
            to="new"
            variant="outlineBlack"
          />
        )}
      </div>
    </div>
  );
};

InitialRoutingCard.propTypes = {
  onAddConfiguration: PropTypes.func.isRequired,
};

const RoutingContent = ({ isMain, onDeleteClick, onEditClick, routing }) => {
  const { t } = useTranslation();
  const isAdmin = useIsRole(UserRole.Admin);
  const routingDaysTranslations = useRoutingDaysTranslations();

  const isHubRouting = !!routing.hub;

  return (
    <div className="flex flex-col gap-4">
      <hr className="text-grey-300" />
      <div className="py-3 px-4 font-semibold rounded-md border border-grey-300">
        {isMain ? (
          <span className="text-grey-700">{t('All days')}</span>
        ) : (
          <span className="text-ui-blue">
            {routing.days
              .map((day) => routingDaysTranslations[day.substring(0, 3)])
              .join(', ')}
          </span>
        )}
        {routing.postcodeGroup && (
          <div className="border-t border-grey-300 flex flex-col gap-2 mt-2 pt-2">
            <span className="text-grey-700 font-normal text-sm">
              {t('Postcode group')}
            </span>
            <span className="font-medium">{routing.postcodeGroup.name}</span>
          </div>
        )}
      </div>

      <div className="flex flex-col lg:flex-row gap-4">
        <div
          className={cn(
            'flex flex-col gap-2 rounded-md py-3 px-4 flex-1',
            !isMain && 'bg-grey-100',
            isMain && 'bg-white',
          )}
        >
          <div className="text-sm text-grey-700">{t('Max km / Tour')}</div>
          <div>
            {routing.maxDistancePerTourKm
              ? `${routing.maxDistancePerTourKm} km`
              : t('No limit')}
          </div>
        </div>
        <div
          className={cn(
            'flex flex-col gap-2 rounded-md py-3 px-4 flex-1',
            !isMain && 'bg-grey-100',
            isMain && 'bg-white',
          )}
        >
          <div className="text-sm text-grey-700">
            {t('Max number of stops / Tour')}
          </div>
          <div>
            {routing.maxDeliveriesPerTour
              ? routing.maxDeliveriesPerTour
              : t('No limit')}
          </div>
        </div>
        <div
          className={cn(
            'flex flex-col gap-2 rounded-md py-3 px-4 flex-1',
            !isMain && 'bg-grey-100',
            isMain && 'bg-white',
          )}
        >
          <div className="text-sm text-grey-700">
            {t('Service time duration')}
          </div>
          <div>{routing.serviceTime} sec</div>
        </div>
        <div
          className={cn(
            'flex flex-col gap-2 rounded-md py-3 px-4 flex-1',
            !isMain && 'bg-grey-100',
            isMain && 'bg-white',
          )}
        >
          <div className="text-sm text-grey-700">
            {t('Minimise number of Tours')}
          </div>
          <div>{routing.minimizeNumberOfTours ? 'ON' : 'OFF'}</div>
        </div>
      </div>

      {isAdmin && (
        <div className="flex flex-col lg:flex-row gap-4">
          <Button
            size="s"
            onClick={onEditClick}
            className="w-full lg:w-fit"
            icon="edit"
            text={t('Edit Configuration')}
            to="new"
            variant="outlineBlack"
          />
          {(!isMain || isHubRouting) && (
            <Button
              size="s"
              onClick={onDeleteClick}
              className="w-full lg:w-fit"
              icon="delete"
              text={t('Delete Configuration')}
              variant="outlineRed"
            />
          )}
        </div>
      )}
    </div>
  );
};

RoutingContent.propTypes = {
  isMain: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  routing: routingConfigurationPropType.isRequired,
};

RoutingContent.defaultProps = {
  isMain: false,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export const RoutingCard = ({
  isCarrierCard,
  onDeleteClick,
  onEditClick,
  routing,
  shouldScrollTo,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const routingDaysTranslations = useRoutingDaysTranslations();

  const isMain = !routing.days;
  const isPostcodeGroup = routing.postcodeGroup;

  useEffect(() => {
    if (shouldScrollTo && ref?.current) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [shouldScrollTo]);

  return (
    <div
      ref={ref}
      className={cn(
        'p-4 rounded-md flex flex-col gap-4',
        isMain && 'bg-grey-100',
        !isMain && 'bg-white border border-grey-300',
      )}
    >
      <div className="flex flex-col gap-2">
        <div className="font-medium">
          {isCarrierCard ? (
            <span>
              {isMain ? (
                t('Main')
              ) : (
                <span>
                  {t('Custom')} (
                  {routing.days
                    .map((day) => routingDaysTranslations[day.substring(0, 3)])
                    .join(', ')}
                  )
                </span>
              )}
            </span>
          ) : (
            <span>
              {isMain && !isPostcodeGroup && t('Hub Main')}
              {isMain && isPostcodeGroup && t('Postcode Group Main')}
              {!isMain && !isPostcodeGroup && t('Hub Custom')}
              {!isMain && isPostcodeGroup && t('Postcode Group Custom')}
            </span>
          )}
        </div>
        {isMain && !isPostcodeGroup && (
          <div className="text-sm text-grey-700">
            {t(
              "This applies by default to each day that doesn't have a custom setup assigned.",
            )}
          </div>
        )}
      </div>
      <RoutingContent
        isMain={isMain}
        routing={routing}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
      />
    </div>
  );
};

RoutingCard.propTypes = {
  routing: routingConfigurationPropType.isRequired,
  isCarrierCard: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  shouldScrollTo: PropTypes.bool,
};

RoutingCard.defaultProps = {
  isCarrierCard: false,
  shouldScrollTo: false,
  onEditClick: () => {},
  onDeleteClick: () => {},
};

export const CollapsableRoutingCard = ({
  onDeleteClick,
  onEditClick,
  routing,
}) => {
  const { t } = useTranslation();
  const routingDaysTranslations = useRoutingDaysTranslations();

  const isMain = !routing.days;
  return (
    <CollapsableCard
      id={routing.id}
      className="shadow-none border border-grey-300"
      expandedContent={
        <RoutingContent
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
          routing={routing}
        />
      }
    >
      <div className="flex flex-col gap-2">
        <div className="font-medium">
          {isMain ? (
            t('Main')
          ) : (
            <span>
              {t('Custom')} (
              {routing.days
                .map((day) => routingDaysTranslations[day.substring(0, 3)])
                .join(', ')}
              )
            </span>
          )}
        </div>
        {isMain && (
          <div className="text-sm text-grey-700">
            {t(
              "This applies by default to each day that doesn't have a custom setup assigned.",
            )}
          </div>
        )}
      </div>
    </CollapsableCard>
  );
};

CollapsableRoutingCard.propTypes = {
  routing: routingConfigurationPropType.isRequired,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

CollapsableRoutingCard.defaultProps = {
  onEditClick: () => {},
  onDeleteClick: () => {},
};

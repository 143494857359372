import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../../../components/Select';
import HubType from '../../../enums/HubType';
import { usePackageScanningSettings } from '../../../providers/PackageScanningSettingsProvider';
import { useUser } from '../../../providers/UserProvider';

const ScanningHubTypeSelect = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const userHubType = useMemo(() => user?.hub?.type || [], [user?.hub?.type]);
  const { hubType, setHubType } = usePackageScanningSettings();

  const options = useMemo(() => {
    const tempOptions = [];
    if (userHubType.includes(HubType.Central)) {
      tempOptions.push({
        label: t('Central Hub'),
        value: HubType.Central,
      });
    }

    if (userHubType.includes(HubType.Local)) {
      tempOptions.push({
        label: t('Local Hub'),
        value: HubType.Local,
      });
    }

    return tempOptions;
  }, [t, userHubType]);

  const hubTypeValue = useMemo(() => {
    if (options.length === 0) {
      return '';
    }

    const storedWarehouseOption = options.find(
      (option) => option.value === hubType,
    );

    return storedWarehouseOption
      ? storedWarehouseOption.value
      : options[0].value;
  }, [options, hubType]);

  if (options.length === 0) {
    return null;
  }

  return (
    <Select
      className="js-scanning-hub-type-select max-w-[200px]"
      options={options}
      required
      value={hubTypeValue}
      onChange={(value) => setHubType(value)}
    />
  );
};

export default ScanningHubTypeSelect;

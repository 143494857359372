import { useCallback, useState } from 'react';

import useEvent from './useEvent';

const useIsStuck = (elementRef) => {
  const [isStuck, setIsStuck] = useState(false);

  const onScroll = useCallback(() => {
    if (elementRef.current) {
      const elementCSSTop = parseInt(
        window
          .getComputedStyle(elementRef.current, null)
          .getPropertyValue('top'),
        10,
      );
      setIsStuck(
        elementRef.current.getBoundingClientRect().top <= elementCSSTop,
      );
    }
  }, [elementRef]);

  useEvent('scroll', onScroll);

  return isStuck;
};

export default useIsStuck;

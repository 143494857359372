import cn from 'classnames';
import PropTypes from 'prop-types';

const LoadingSpinner = (props) => {
  const { className } = props;

  return (
    <div
      className={cn(
        'h-full max-h-[40px] w-full max-w-[40px] text-grey-500',
        className,
      )}
    >
      <svg
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin"
      >
        <path
          d="M28.2727 5.58719C28.9275 4.44637 28.5376 2.97534 27.3239 2.4683C25.188 1.57604 22.9007 1.07895 20.5737 1.00866C17.4505 0.914323 14.3523 1.59167 11.5535 2.98071C8.75463 4.36976 6.34155 6.42761 4.52799 8.972C2.71442 11.5164 1.55635 14.4688 1.15634 17.5676C0.756333 20.6665 1.12674 23.8162 2.23476 26.7377C3.34278 29.6592 5.1542 32.2624 7.50858 34.3166C9.86295 36.3708 12.6876 37.8127 15.7323 38.5145C18.0009 39.0374 20.3395 39.1375 22.632 38.8168C23.9347 38.6346 24.6853 37.3108 24.3417 36.0411C23.998 34.7714 22.6884 34.0422 21.3792 34.1696C19.8527 34.3182 18.3069 34.2196 16.8023 33.8728C14.5209 33.3469 12.4044 32.2665 10.6403 30.7273C8.87616 29.1881 7.51888 27.2376 6.68865 25.0485C5.85842 22.8594 5.58087 20.4994 5.8806 18.1774C6.18032 15.8555 7.04805 13.6433 8.40694 11.7368C9.76583 9.83032 11.5739 8.28838 13.6711 7.24759C15.7682 6.20679 18.0897 5.69925 20.4298 5.76994C21.9733 5.81656 23.4934 6.11376 24.9321 6.64508C26.166 7.10078 27.6179 6.72802 28.2727 5.58719Z"
          fill="#EEEEEE"
        />
        <path
          d="M8.24911 31.7509C7.31898 32.681 7.31102 34.2028 8.35014 35.0094C10.1787 36.4286 12.2567 37.5061 14.4846 38.1819C17.4746 39.0889 20.6422 39.2445 23.7067 38.6349C26.7712 38.0253 29.6381 36.6694 32.0535 34.6872C34.4688 32.705 36.358 30.1577 37.5537 27.271C38.7494 24.3843 39.2148 21.2472 38.9085 18.1377C38.6022 15.0282 37.5338 12.0422 35.7979 9.44417C34.062 6.84618 31.7122 4.71641 28.9565 3.2435C26.9033 2.14604 24.6721 1.43849 22.3755 1.14908C21.0705 0.984622 20 2.06633 20 3.38173C20 4.69712 21.0736 5.74318 22.3707 5.96222C23.8829 6.21761 25.3493 6.71659 26.7111 7.44449C28.7758 8.54813 30.5365 10.1439 31.8373 12.0906C33.138 14.0372 33.9385 16.2746 34.168 18.6046C34.3975 20.9345 34.0488 23.2851 33.1529 25.4481C32.2569 27.6111 30.8414 29.5198 29.0316 31.005C27.2218 32.4903 25.0736 33.5062 22.7774 33.963C20.4812 34.4197 18.1077 34.3031 15.8673 33.6235C14.3897 33.1753 13 32.4913 11.7501 31.6025C10.6781 30.8403 9.17923 30.8208 8.24911 31.7509Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  className: '',
};

export default LoadingSpinner;

const ActivityType = {
  CheckIn: 'checkIn',
  CheckOut: 'checkOut',
  LateCheckIn: 'lateCheckIn',
  ReportDelay: 'reportDelay',
  TourCanceledAdmin: 'tourCanceledAdmin',
  TourCompletedAdmin: 'tourCompletedAdmin',
  TourReport: 'tourReport',
  TourRequestManualScanning: 'tourRequestManualScanning',
  AutoCheckOut: 'autoCheckOut',
  ManualCheckOut: 'manualCheckOut',
};

export default ActivityType;

import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Tooltip from '../../../components/Tooltip';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import useCustomToast from '../../../hooks/useCustomToast';
import useDeliveryUnitStatusTranslations from '../../../hooks/useDeliveryUnitStatusTranslations';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import ShiftLabel from '../../shared/ShiftLabel';
import BarcodeInputStatus from '../BarcodeInput/BarcodeInputStatus';
import StatusChangeConfirmation from './StatusChangeConfirmation';

const MetaEntry = ({ children, title }) => (
  <div>
    <h1 className="mb-1 text-xs text-grey-700">{title}</h1>
    <div className="text-grey-900">{children}</div>
  </div>
);

MetaEntry.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

const Metadata = ({ barcodeInputStatus, isMissorted, unit }) => {
  const { t } = useTranslation();
  const deliveryUnitStatusTranslations = useDeliveryUnitStatusTranslations();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [updatedUnit, setUpdatedUnit] = useState(null);
  const { toastSuccess } = useCustomToast();
  const { toastFetchError } = useToastFetchError();
  const { fetch } = useFetch();

  const mutation = useMutation(
    async () => {
      const response = await fetch(`/units/${unit?.id}/mark-as-damaged`, {
        method: 'POST',
      });
      return response.json();
    },
    {
      onError: (error) => {
        toastFetchError(error);
      },
      onSuccess: ({ data }) => {
        setUpdatedUnit({
          status: data?.status,
          statusChangeTime: data?.statusChangeTime,
        });
        setShowConfirmation(false);
        toastSuccess(t('Unit Status Updated.'));
      },
    },
  );

  useEffect(() => {
    if (unit) {
      setUpdatedUnit(null);
    }
  }, [unit]);

  return (
    <>
      <StatusChangeConfirmation
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => mutation.mutate()}
      />
      <div className="pt-5">
        <h2 className="mb-4 text-sm font-medium uppercase text-grey-900">
          {t('Meta data')}
        </h2>
        {barcodeInputStatus === BarcodeInputStatus.Success ||
        barcodeInputStatus === BarcodeInputStatus.AlreadyScanned ? (
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-4" data-test="meta-entries">
              <div className="grid w-full grid-cols-3 gap-6">
                <MetaEntry title={t('Hub')}>
                  <span data-test="meta-hub">
                    {unit?.hub?.shortCode || '/'}
                  </span>
                </MetaEntry>

                <MetaEntry title={t('Execution Date')}>
                  {moment(unit?.date).format('DD.MM.YYYY.') || '/'}
                </MetaEntry>

                <MetaEntry title={t('Shift')}>
                  <span data-test="meta-shift">
                    {unit?.shiftNumber ? (
                      <ShiftLabel shiftNumber={unit?.shiftNumber} />
                    ) : (
                      '/'
                    )}
                  </span>
                </MetaEntry>
              </div>
            </div>
            <div className="rounded-md p-4 border border-grey-200 flex flex-col gap-4">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-2">
                  <Icon
                    className="h-4 w-4 text-grey-700"
                    icon="parcelWarning"
                  />
                  <span className="text-sm">{t('Unit Status')}</span>
                </div>
                <div className="text-sm">
                  {
                    deliveryUnitStatusTranslations[
                      updatedUnit ? updatedUnit?.status : unit?.status
                    ]
                  }
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-sm">{t('Status Update')}</div>
                <div className="bg-grey-100 p-4 rounded-md text-sm">
                  <span className="text-grey-700">
                    {moment(
                      updatedUnit
                        ? updatedUnit?.statusChangeTime
                        : unit?.statusChangeTime,
                    ).format('HH:mm - DD.MM.YYYY.')}{' '}
                  </span>
                  <span className="font-medium">
                    •{' '}
                    {
                      deliveryUnitStatusTranslations[
                        updatedUnit ? updatedUnit?.status : unit?.status
                      ]
                    }
                  </span>
                </div>
              </div>
              {!isMissorted && (
                <div>
                  <Tooltip
                    text={t(
                      'For any further actions please contact your supervisor',
                    )}
                    hidden={
                      updatedUnit
                        ? false
                        : unit?.status !== DeliveryTaskUnitStatus.Damaged
                    }
                  >
                    <Button
                      text={t('Mark Unit As Damaged')}
                      onClick={() => setShowConfirmation(true)}
                      variant="outlineBlack"
                      disabled={
                        !!updatedUnit ||
                        unit?.status === DeliveryTaskUnitStatus.Damaged
                      }
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        ) : (
          <span className="text-sm text-grey-700" data-test="meta-waiting-scan">
            {t('Waiting code scan')}...
          </span>
        )}
      </div>
    </>
  );
};

Metadata.propTypes = {
  barcodeInputStatus: PropTypes.string,
  isMissorted: PropTypes.bool,
  unit: PropTypes.shape({
    id: PropTypes.string,
    date: PropTypes.string,
    statusChangeTime: PropTypes.string,
    shiftNumber: PropTypes.number,
    status: PropTypes.oneOf(Object.values(DeliveryTaskUnitStatus)),
    hub: PropTypes.shape({
      id: PropTypes.string,
      shortCode: PropTypes.string,
    }),
  }),
};

Metadata.defaultProps = {
  isMissorted: false,
  barcodeInputStatus: undefined,
  unit: undefined,
};

export default Metadata;

import PropTypes from 'prop-types';
import { useMemo } from 'react';

import useInfiniteSelect from '../../hooks/useInfiniteSelect';
import Select from '../Select';
import { optionPropType } from '../Select/Option';
import SelectVariant from '../Select/SelectVariant';

const InfiniteScrollSelect = (props) => {
  const {
    className,
    'data-test': dataTest,
    disabled,
    dropdownClassname,
    flatRight,
    groupLabel,
    id,
    isLoading,
    isMountedValueQueryEnabled,
    itemsPerPage,
    onChange,
    placeholder,
    prependOptions,
    required,
    searchKey,
    singleItemUrl,
    transformOptionFn,
    url,
    value,
    variant,
  } = props;

  const {
    isFetching,
    mountedValueIsFetching,
    onBottomReached,
    options: infiniteOptions,
    setSearchValue,
  } = useInfiniteSelect({
    isMountedValueQueryEnabled,
    itemsPerPage,
    searchKey,
    singleItemUrl,
    transformOptionFn,
    url,
    value,
  });

  const options = useMemo(() => {
    if (groupLabel) {
      return [
        {
          groupLabel,
          options: infiniteOptions,
        },
      ];
    }
    return infiniteOptions;
  }, [groupLabel, infiniteOptions]);

  const mergedOptions = useMemo(
    () => [...prependOptions, ...options],
    [options, prependOptions],
  );

  return (
    <Select
      variant={variant}
      data-test={dataTest}
      id={id}
      options={mergedOptions}
      isLoading={isFetching || isLoading || mountedValueIsFetching}
      disabled={disabled}
      className={className}
      useLocalSearch={false}
      onSearch={setSearchValue}
      onBottomReached={onBottomReached}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      dropdownClassname={dropdownClassname}
      flatRight={flatRight}
      required={required}
      mode="vertical"
    />
  );
};

InfiniteScrollSelect.propTypes = {
  url: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  transformOptionFn: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  'data-test': PropTypes.string,
  isLoading: PropTypes.bool,
  searchKey: PropTypes.string,
  prependOptions: PropTypes.arrayOf(optionPropType),
  singleItemUrl: PropTypes.string,
  isMountedValueQueryEnabled: PropTypes.bool,
  flatRight: PropTypes.bool,
  dropdownClassname: PropTypes.string,
  groupLabel: PropTypes.string,
  required: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(SelectVariant)),
};

InfiniteScrollSelect.defaultProps = {
  itemsPerPage: 50,
  className: undefined,
  value: undefined,
  onChange: undefined,
  placeholder: undefined,
  transformOptionFn: (options) =>
    options.map((option) => ({ value: option.id, label: option.id })),
  disabled: false,
  id: undefined,
  'data-test': undefined,
  isLoading: false,
  searchKey: 'name',
  prependOptions: [],
  singleItemUrl: undefined,
  isMountedValueQueryEnabled: true,
  flatRight: undefined,
  dropdownClassname: undefined,
  groupLabel: undefined,
  required: false,
  variant: undefined,
};
export default InfiniteScrollSelect;

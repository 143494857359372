import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import Button from '../../../components/Button';
import DebouncedSearch from '../../../components/DebouncedSearch';
import Breakpoint from '../../../enums/Breakpoint';

const SubcarrierFilters = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const nameQuery = searchParams.get('name') || '';

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const hasFiltersApplied = !!nameQuery;

  return (
    <div className="flex w-full flex-col gap-4 lg:flex-row lg:flex-wrap lg:items-center lg:justify-between">
      <div className="lg:w-[250px]">
        <DebouncedSearch
          placeholder={`${t('Find Subcarrier')}...`}
          searchValue={nameQuery}
          onSearch={(newSearchValue) => {
            if (newSearchValue === nameQuery) {
              return;
            }
            searchParams.set('page', '1');
            if (!newSearchValue) {
              searchParams.delete('name');
            } else {
              searchParams.set('name', newSearchValue?.trim());
            }
            setSearchParams(searchParams);
          }}
        />
      </div>
      <Button
        disabled={!hasFiltersApplied}
        text={t('Reset')}
        variant={isDesktop ? 'ghostBlack' : 'outlineBlack'}
        onClick={() => setSearchParams(new URLSearchParams())}
      />
    </div>
  );
};

export default SubcarrierFilters;

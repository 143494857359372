import cn from 'classnames';
import PropTypes from 'prop-types';

const sizesMap = {
  m: 'w-6 h-6 text-xs',
  s: 'w-5 h-5 text-2xs',
};

const variantsMap = {
  purple: 'bg-ui-purple text-white',
  yellow: 'bg-primary-yellow text-primary-dark',
};

const Avatar = (props) => {
  const { disabled, size, text, variant } = props;

  return (
    <div
      className={cn(
        'flex shrink-0 items-center justify-center rounded p-0.5 font-semibold uppercase',
        sizesMap[size],
        variantsMap[variant],
        disabled && 'opacity-40',
      )}
    >
      {text}
    </div>
  );
};

Avatar.propTypes = {
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(sizesMap)),
  text: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(variantsMap)),
};

Avatar.defaultProps = {
  disabled: false,
  size: 'm',
  text: undefined,
  variant: 'purple',
};

export default Avatar;

import moment from 'moment';

import UnitProgress from '../enums/UnitProgress';

const isTaskUpdatable = (task) => {
  const latestTourDate = task.latestTour
    ? new Date(task.latestTour.date)
    : null;

  const isTourDateBeforeToday =
    latestTourDate && moment(latestTourDate).isBefore(moment(), 'day');

  const hasUnitsInPendingOrBlockedState = task.units.some(
    (unit) =>
      unit.progress === UnitProgress.RoutingPending ||
      unit.progress === UnitProgress.Blocked,
  );

  return hasUnitsInPendingOrBlockedState && !isTourDateBeforeToday;
};

class TasksUnitsHelper {
  static reverseUnitProgressToStatusMapping = (unitProgressMap) => {
    const reverseMapping = {};

    Object.values(unitProgressMap).forEach((unitProgress) => {
      unitProgress.statuses.forEach((status) => {
        reverseMapping[status] = Object.keys(unitProgressMap).filter(
          (progress) => unitProgressMap[progress].statuses.includes(status),
        );
      });
    });
    return reverseMapping;
  };

  static isTaskAddressActionEnabled = (task) => isTaskUpdatable(task);

  static isTaskExecutionDateActionEnabled = (task) => {
    if (!isTaskUpdatable(task)) {
      return false;
    }

    if (!task.latestTour) {
      return !moment(task.date).isBefore(moment(), 'day');
    }

    return false;
  };

  static getUniqueUnitStatuses = (units = []) => {
    const unitStatuses = units.map((unit) => `${unit.progress}-${unit.status}`);
    return [...new Set(unitStatuses)];
  };
}

export default TasksUnitsHelper;

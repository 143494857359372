const TourStatusFilter = {
  Active: 'active',
  Canceled: 'canceled',
  Completed: 'completed',
  Created: 'created',
  NotAssignedDriver: 'notAssignedDriver',
  Pending: 'pending',
};

export default TourStatusFilter;

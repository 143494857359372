import cn from 'classnames';
import PropTypes from 'prop-types';

const DrawerCard = (props) => {
  const { border, children, className, rightContent, title } = props;
  return (
    <div
      className={cn(
        'p-3 pt-0 rounded-lg flex flex-col',
        border && 'border border-grey-200',
        className,
      )}
    >
      <div className="py-1.5 border-b border-grey-200 flex justify-between items-center min-h-[48px]">
        <div className="text-grey-900 font-medium text-sm">{title}</div>
        {rightContent && rightContent}
      </div>
      <div>{children}</div>
    </div>
  );
};

DrawerCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  rightContent: PropTypes.node,
  className: PropTypes.string,
  border: PropTypes.bool,
};

DrawerCard.defaultProps = {
  children: undefined,
  rightContent: undefined,
  className: undefined,
  border: true,
};

export default DrawerCard;

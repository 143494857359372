import PropTypes from 'prop-types';

import clientPropType from './clientPropType';
import tourStopUnitPropType from './tourStopUnitPropType';

const taskPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  client: clientPropType.isRequired,
  tourStopUnits: PropTypes.arrayOf(tourStopUnitPropType).isRequired,
});

export default taskPropType;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import RatingValue from '../enums/RatingValue';

const useRatingTranslations = () => {
  const { t } = useTranslation();

  const translations = useMemo(
    () => ({
      [RatingValue.Excellent]: t('Excellent'),
      [RatingValue.VeryGood]: t('Very good'),
      [RatingValue.Good]: t('Good'),
      [RatingValue.NotSoGood]: t('Not so good'),
      [RatingValue.Bad]: t('Bad'),
    }),
    [t],
  );

  return (rating) => translations[rating];
};

export default useRatingTranslations;

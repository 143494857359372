const getTourDuration = (tour) => {
  const hours = tour.duration / 60;
  const roundedHours = Math.floor(hours);
  const minutes = (hours - roundedHours) * 60;
  const roundedMinutes = Math.round(minutes);

  return `${tour.duration >= 60 ? `${roundedHours}h` : ''}  ${roundedMinutes}m`;
};

export default getTourDuration;

const UserRole = {
  Admin: 'admin',
  Crew: 'crew',
  Customer: 'customer',
  Driver: 'driver',
  OperationsManager: 'operationsManager',
  Superadmin: 'superadmin',
  HubManager: 'hubManager',
};

export default UserRole;

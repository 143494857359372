import PropTypes from 'prop-types';

import UserRole from '../../enums/UserRole';
import { useUser } from '../../providers/UserProvider';

const Forbid = ({ children, roles }) => {
  const { user } = useUser();
  const isForbidden = roles.some((role) => user?.role === role);

  if (isForbidden) {
    return null;
  }

  return children;
};

Forbid.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(
    PropTypes.oneOf([
      UserRole.Superadmin,
      UserRole.Admin,
      UserRole.OperationsManager,
      UserRole.Driver,
      UserRole.Customer,
      UserRole.HubManager,
      UserRole.Crew,
    ]),
  ),
};

Forbid.defaultProps = {
  children: undefined,
  roles: [],
};

export default Forbid;

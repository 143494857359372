/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import IconButton from '../../../components/IconButton';
import Label from '../../../components/Label';
import NewTable from '../../../components/NewTable';
import Tooltip from '../../../components/Tooltip';

const ClientsTable = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Name'),
        size: 250,
        cell: (info) => info.getValue(),
      },
      {
        header: t('Shortcode'),
        accessorKey: 'shortCode',
        size: 60,
        cell: (info) => {
          if (!info.getValue()) {
            return null;
          }
          return (
            <Label size="xs" text={info.getValue()} variant="greyOutline" />
          );
        },
      },
      {
        header: t('Type'),
        size: 120,
        cell: () => <Label size="s" text={t('Client')} variant="purple" />,
      },
      {
        accessorKey: 'userCount',
        header: t('Members'),
        size: 60,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'address',
        header: t('Address'),
        size: 350,
        cell: (info) => info.getValue(),
      },
      {
        header: t('Actions'),
        size: 60,
        cell: (info) => (
          <div className="flex justify-end">
            <Tooltip
              className="flex"
              placement="top"
              size="sm"
              text={t('Client Details')}
            >
              <IconButton
                as={Link}
                icon="pageView"
                iconSize="s"
                size="s"
                to={`/clients/${info.row.original.id}`}
                variant="ghostBlack"
                state={{ backLink: location.pathname + location.search }}
              />
            </Tooltip>
          </div>
        ),
      },
    ],
    [location.pathname, location.search, t],
  );

  return (
    <NewTable
      columns={columns}
      noRecordsText={t('No Clients linked to this Organisation')}
      {...props}
    />
  );
};

ClientsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      shortCode: PropTypes.string,
      userCount: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool,
};

ClientsTable.defaultProps = {
  data: [],
  isLoading: false,
};

export default ClientsTable;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card } from '../../../components/Card';
import UserRole from '../../../enums/UserRole';
import MemberBadge from '../../shared/MemberBadge';
import MemberOrganisationBadge from '../../shared/MemberOrganisationBadge';

const MemberInfo = ({ user }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card>
        <h3 className="mb-6 mt-2 font-semibold">{t('Basic Information')}</h3>
        <div className="flex flex-col gap-4">
          <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
            <div className="flex-1">
              <div className="mb-1.5 text-xs text-grey-700">
                {t('First Name')}
              </div>
              <div className="text-sm">{user?.firstName}</div>
            </div>
            <div className="flex-1">
              <div className="mb-1.5 text-xs text-grey-700">
                {t('Last Name')}
              </div>
              <div className="text-sm">{user?.lastName}</div>
            </div>
          </div>
          <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
            <div className="flex-1">
              <div className="mb-1.5 text-xs text-grey-700">{t('E-mail')}</div>
              <div className="text-sm">{user?.email}</div>
            </div>
            <div className="flex-1">
              <div className="mb-1.5 text-xs text-grey-700">
                {t('Phone Number')}
              </div>
              <div className="text-sm">{user?.phoneNumber}</div>
            </div>
          </div>
        </div>
      </Card>
      {user !== UserRole.Superadmin && (
        <Card>
          <h3 className="mb-6 mt-2 font-semibold">{t('Organisation')}</h3>
          <div className="flex flex-col gap-4">
            <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
              <div className="flex-1">
                <div className="mb-1.5 text-xs text-grey-700">{t('Name')}</div>
                <div className="text-sm">
                  {user?.subcarrier ? (
                    <span>
                      {user.subcarrier.name} ({user.subcarrier.shortCode})
                    </span>
                  ) : (
                    <span>
                      {user?.carrier?.name} ({user?.carrier?.shortCode})
                    </span>
                  )}
                </div>
              </div>
              <div className="flex-1">
                <div className="mb-1.5 text-xs text-grey-700">{t('Type')}</div>
                <div className="text-sm">
                  <MemberOrganisationBadge user={user} />
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
      {!user?.client && (
        <Card>
          <h3 className="mb-6 mt-2 font-semibold">{t('Setup')}</h3>
          <div className="flex flex-col gap-4">
            <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
              <div className="flex-1">
                <div className="mb-1.5 text-xs text-grey-700">{t('Role')}</div>
                <MemberBadge memberRole={user.role} />
              </div>
              {user.role !== UserRole.Superadmin &&
                user.role !== UserRole.Admin && (
                  <div className="flex-1">
                    <div className="mb-1.5 text-xs text-grey-700">
                      {t('Hub')}
                    </div>
                    <div className="text-sm">
                      {user?.hub?.name ? user?.hub?.name : '/'}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

MemberInfo.propTypes = {
  user: PropTypes.shape({
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    carrier: PropTypes.shape({
      name: PropTypes.string,
      shortCode: PropTypes.string,
      type: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    role: PropTypes.string,
    subcarrier: PropTypes.shape({
      name: PropTypes.string,
      shortCode: PropTypes.string,
    }),
    hub: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

MemberInfo.defaultProps = {
  user: undefined,
};

export default MemberInfo;

import PropTypes from 'prop-types';

const AffectedUnitsList = ({ info, unitsCount }) => (
  <div className="font-medium flex gap-1 text-sm">
    <span>{info}</span>
    {unitsCount && <span className="text-grey-700">({unitsCount})</span>}
  </div>
);

AffectedUnitsList.propTypes = {
  info: PropTypes.string,
  unitsCount: PropTypes.number,
};

AffectedUnitsList.defaultProps = {
  info: '',
  unitsCount: null,
};

export default AffectedUnitsList;

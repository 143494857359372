import { useLayoutEffect, useMemo, useState } from 'react';

const defaultSize = {
  inlineSize: 0,
  blockSize: 0,
};

function useBorderBoxSize() {
  const [element, ref] = useState(null);

  const [boxSize, setBoxSize] = useState(defaultSize);

  const observer = useMemo(
    () =>
      new window.ResizeObserver((entries) => {
        if (entries[0]) {
          // borderBoxSize is an array!
          // reference: https://stackoverflow.com/a/70737748
          const size = entries[0].borderBoxSize[0];
          setBoxSize(size || defaultSize);
        }
      }),
    [],
  );

  useLayoutEffect(() => {
    if (!element) return undefined;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element, observer]);

  return [ref, boxSize];
}

export default useBorderBoxSize;

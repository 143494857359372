import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import Button from '../../components/Button';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import OrganisationType from '../../enums/OrganisationType';
import UserRole from '../../enums/UserRole';
import MembersFilters from '../../features/organisations/MembersFilters';
import MembersCardList from '../../features/shared/MembersCardList';
import MembersTable from '../../features/shared/MembersTable';
import useWindowSize from '../../hooks/useWindowSize';
import Resource from '../../lib/api/Resource';

const Superadmins = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;
  const [searchParams] = useSearchParams();

  // useQuery called again to fetch user count
  const { data: users } = useQuery([
    '/users',
    {
      role: UserRole.Superadmin,
      skip: 0,
      take: 15,
    },
  ]);

  const searchQuery = searchParams.get('search');

  const hasFiltersApplied = !!searchQuery;

  const params = {
    name: searchQuery,
    role: UserRole.Superadmin,
  };

  return (
    <Page>
      <Titlebar
        backLink="/organisations"
        isActive={hasFiltersApplied}
        menu={<MembersFilters searchOnly />}
        textPrimary={`${t('Superadmins')} (${users?.count || 0})`}
        titleRightContent={
          <Button
            as={Link}
            className="w-full lg:w-fit"
            icon="userAdd"
            text={t('New Member')}
            to="new"
            variant="solidBlue"
          />
        }
      />
      <Page.Content variant={isDesktop ? 'white' : 'grey'}>
        <Resource params={params} resourceUrl="/users">
          {isDesktop ? (
            <MembersTable organisationType={OrganisationType.Superadmin} />
          ) : (
            <MembersCardList />
          )}
        </Resource>
      </Page.Content>
    </Page>
  );
};

export default Superadmins;

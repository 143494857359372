import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import IconButton from '../IconButton';
import Tooltip from '../Tooltip';

const CopyToClipboardButton = (props) => {
  const { info, tooltipText } = props;
  const copyToClipboard = useCopyToClipboard();
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const timeoutRef = useRef(null);

  const onMouseLeave = () => {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 300);
  };

  return (
    <Tooltip
      onMouseLeave={onMouseLeave}
      hidden={tooltipText === '' && isCopied === false}
      text={isCopied ? t('Copied!') : tooltipText}
      className="flex"
    >
      <IconButton
        icon="copy"
        variant="ghostGrey"
        size="xs"
        iconSize="s"
        onClick={async () => {
          try {
            await copyToClipboard(info);
            setIsCopied(true);
            return undefined;
          } catch (e) {
            return undefined;
          }
        }}
      />
    </Tooltip>
  );
};

CopyToClipboardButton.propTypes = {
  info: PropTypes.string,
  tooltipText: PropTypes.string,
};

CopyToClipboardButton.defaultProps = {
  info: undefined,
  tooltipText: '',
};

export default CopyToClipboardButton;

import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import { useBrowserPrint } from '../../../lib/browser-print/hooks/useBrowserPrint';
import { usePackageScanningSettings } from '../../../providers/PackageScanningSettingsProvider';

const PrinterSetupButton = () => {
  const { t } = useTranslation();

  const { setIsPrinterSetupModalOpen } = usePackageScanningSettings();
  const { currentPrinter } = useBrowserPrint();

  return (
    <div className="flex h-10 overflow-hidden">
      {currentPrinter && (
        <div className="ml-auto flex h-full items-center gap-2 rounded-l-md border-[1px] border-r-0 border-grey-500 bg-grey-200 px-3 text-sm">
          <Icon className="h-4 w-4 text-primary-dark" icon="printer" />
          <span className="font-medium">{currentPrinter?.name}</span>
        </div>
      )}
      <Button
        data-test="printer-settings"
        text={t('Printer Setup')}
        variant="outlineBlack"
        className={currentPrinter && 'rounded-l-none'}
        onClick={() => setIsPrinterSetupModalOpen(true)}
      />
    </div>
  );
};

export default PrinterSetupButton;

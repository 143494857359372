import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import IconButton from '../IconButton';

const PopoverInner = forwardRef((props, ref) => {
  const { className, content, onCloseClick, style, title, ...rest } = props;
  return (
    <div
      ref={ref}
      style={style}
      className={cn(
        'relative min-h-[100px] min-w-[100px] rounded-md bg-white shadow-elevation-400 z-30',
        className,
      )}
      {...rest}
    >
      <div className="flex flex-row items-center justify-between gap-2 border-b border-grey-200 p-4">
        {title && <div className="font-medium text-primary-dark">{title}</div>}

        <IconButton
          icon="close"
          iconSize="xs"
          size="s"
          variant="ghostGrey"
          onClick={onCloseClick}
        />
      </div>
      <div className="p-4">{content}</div>
    </div>
  );
});

PopoverInner.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node,
  onCloseClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  title: PropTypes.node,
};

PopoverInner.displayName = 'PopoverInner';

PopoverInner.defaultProps = {
  className: undefined,
  content: undefined,
  onCloseClick: undefined,
  style: undefined,
  title: undefined,
};

export default PopoverInner;

import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../components/Icon';
import GroceriesTourStatus from '../../enums/GroceriesTourStatus';

const statusesMap = {
  [GroceriesTourStatus.CompletedAdmin]: {
    color: 'text-ui-green-dark',
    icon: 'checkmarkSemicircle',
  },
  [GroceriesTourStatus.Created]: {
    color: 'text-[#8E44AD]',
    icon: 'assignmentCreated',
  },
  [GroceriesTourStatus.Active]: {
    color: 'text-grey-700',
    icon: 'steeringWheel',
  },
  [GroceriesTourStatus.Completed]: {
    color: 'text-ui-green-dark',
    icon: 'checkmarkCircleOutlined',
  },
  [GroceriesTourStatus.NotAssignedDriver]: {
    color: 'text-ui-red',
    icon: 'hourglassEmpty',
  },
  [GroceriesTourStatus.CanceledAdmin]: {
    color: 'text-ui-red',
    icon: 'xCircleOutlined',
  },
  [GroceriesTourStatus.Pending]: {
    color: 'text-primary-yellow',
    icon: 'clock',
  },
};

const GroceriesTourStatusIcon = (props) => {
  const { status } = props;

  const { color, icon } = statusesMap[status];

  return (
    <Icon
      className={cn('w-4 h-4', color)}
      data-test="tour-status-icon"
      icon={icon}
    />
  );
};

GroceriesTourStatusIcon.propTypes = {
  status: PropTypes.oneOf(Object.keys(statusesMap)),
};

GroceriesTourStatusIcon.defaultProps = {
  status: GroceriesTourStatus.Pending,
};

export default GroceriesTourStatusIcon;

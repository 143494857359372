import { useCallback, useEffect, useId } from 'react';
import {
  useBeforeUnload,
  useBlocker as useBlockerOriginal,
} from 'react-router-dom';

import LocalStorage from '../enums/LocalStorage';
import LocalStorageHelper from '../helpers/LocalStorageHelper';

const useBlocker = (shouldBlock, shouldBlockUnload = false) => {
  const id = useId();
  let shouldBlockBeforeUnload;
  if (typeof shouldBlock === 'boolean') {
    shouldBlockBeforeUnload = shouldBlock;
  } else {
    shouldBlockBeforeUnload = shouldBlockUnload;
  }

  useEffect(() => {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.log('[useBlocker] registered - blocker id:', id);
    }

    return () => {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log('[useBlocker] unregistered - blocker id:', id);
      }
    };
  }, [id]);

  useEffect(() => {
    try {
      // initial setup for this variable. always set SkipBeforeUnload to false on load for useBlocker to work properly
      LocalStorageHelper.setItem(LocalStorage.SkipBeforeUnload, false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, []);

  // used for applying app updates (serviceWorkerRegistration.js),
  // where the user explicitly allows the refresh to happen
  const getShouldIgnoreBeforeUnload = useCallback(() => {
    try {
      return (
        JSON.parse(
          LocalStorageHelper.getItem(LocalStorage.SkipBeforeUnload),
        ) === true
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  }, []);

  const beforeUnload = useCallback(
    (event) => {
      if (shouldBlockBeforeUnload && getShouldIgnoreBeforeUnload() === false) {
        event.preventDefault();
        // needed for beforeUnload to work https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#browser_compatibility
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    },
    [getShouldIgnoreBeforeUnload, shouldBlockBeforeUnload],
  );
  useBeforeUnload(beforeUnload, { capture: true });

  return useBlockerOriginal(shouldBlock);
};

export default useBlocker;

import cn from 'classnames';
import PropTypes from 'prop-types';

const Card = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div
      className={cn(
        'relative rounded-md bg-white p-4 shadow-elevation-100',
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Card.defaultProps = {
  children: undefined,
  className: undefined,
};

export default Card;

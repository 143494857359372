import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import DeliveryTaskUnitStatus from '../../../../enums/DeliveryTaskUnitStatus';
import eventType from '../eventType';
import FailureReasonField from './FailureReasonField';
import StatusField from './StatusField';
import UserField from './UserField';

const FailedAttemptEvent = (props) => {
  const { event } = props;
  const { t } = useTranslation();
  return (
    <>
      {event.status !== DeliveryTaskUnitStatus.ReturnToClient && (
        <StatusField event={event} />
      )}
      {event.failureReason && <FailureReasonField event={event} />}

      <div>
        <span>{t('Attempted')}: </span>
        <span className="font-medium">
          {moment(event.timestamp).format('DD.MM.YYYY - HH:mm')}
        </span>
      </div>

      <UserField event={event} />
    </>
  );
};

FailedAttemptEvent.propTypes = {
  event: eventType.isRequired,
};

export default FailedAttemptEvent;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Breakpoint from '../../enums/Breakpoint';
import useWindowSize from '../../hooks/useWindowSize';
import Button from '../Button';
import IconButton from '../IconButton';
import PageSizePicker from './PageSizePicker';

function getFirstAndLastVisiblePage(current, numberOfPages, maxVisiblePages) {
  let pagesBefore = Math.min(current - 1, maxVisiblePages - 1);
  let pagesAfter = Math.min(numberOfPages - current, maxVisiblePages - 1);

  while (pagesBefore + pagesAfter + 1 > maxVisiblePages) {
    if (pagesBefore > pagesAfter) {
      pagesBefore -= 1;
    } else {
      pagesAfter -= 1;
    }
  }

  return {
    firstPage: current - pagesBefore,
    lastPage: current + pagesAfter,
  };
}

const getNumberOfPages = (total, pageSize) =>
  Math.max(Math.ceil(total / pageSize), 1);

const Pagination = forwardRef((props, ref) => {
  const {
    current,
    onChange,
    onPageSizeChange,
    pageSize,
    showPageSizePicker,
    total,
  } = props;

  const { t } = useTranslation();
  const displayFrom = current === 1 ? 1 : (current - 1) * pageSize + 1;
  const displayTo = Math.min(displayFrom + pageSize - 1, total);

  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.LG;

  const numberOfPages = getNumberOfPages(total, pageSize);
  const maxVisiblePages = useMemo(() => {
    if (isMobile) {
      return 2;
    }
    return 5;
  }, [isMobile]);

  const { firstPage, lastPage } = getFirstAndLastVisiblePage(
    Math.min(current, numberOfPages),
    numberOfPages,
    maxVisiblePages,
  );

  const getPageSizePickerOptions = useMemo(() => {
    const options = [15];

    if (current === 1) {
      options.push(25, 50);
      return options;
    }

    if (current <= getNumberOfPages(total, 25)) {
      options.push(25);
    }

    if (current <= getNumberOfPages(total, 50)) {
      options.push(50);
    }

    return options;
  }, [current, total]);

  const visiblePagesCount = lastPage - firstPage + 1;
  const notFoundPage = current > numberOfPages;

  const pages = Array(visiblePagesCount)
    .fill()
    .map((_, index) => firstPage + index);

  const isPreviousEnabled = current > 1;
  const isNextEnabled = current < numberOfPages;

  const buttonSize = isMobile ? 'm' : 's';

  return (
    <div
      ref={ref}
      className="flex flex-col items-center gap-3 px-4 py-3 lg:flex-row lg:justify-between lg:rounded-b-md lg:border lg:border-t-0 lg:border-grey-200"
    >
      {!notFoundPage && (
        <span className="text-xs text-grey-900">
          {`${displayFrom}-${displayTo} ${t('out of')} ${total} ${t(
            'records',
          )}`}
        </span>
      )}
      <div className="flex flex-col items-center gap-3 lg:ml-auto lg:flex-row lg:gap-4">
        <div className="flex items-center gap-1">
          <IconButton
            disabled={!isPreviousEnabled}
            icon="firstPage"
            iconSize="xs"
            size={buttonSize}
            variant="ghostBlack"
            onClick={() => onChange(1)}
          />
          <IconButton
            data-test="pagination-previous"
            disabled={!isPreviousEnabled}
            icon="chevronLeft"
            iconSize="xs"
            size={buttonSize}
            variant="ghostBlack"
            onClick={() => onChange(current - 1)}
          />
          <div className="flex items-center gap-1 text-grey-900">
            {pages.map((pageNumber) => {
              const isCurrent = pageNumber === current;
              return (
                <Button
                  className={cn(
                    isCurrent &&
                      'pointer-events-none focus:border-transparent active:border-transparent',
                  )}
                  data-test={isCurrent ? 'pagination-current' : undefined}
                  data-test-pagination-number={isCurrent ? current : undefined}
                  key={pageNumber}
                  size={buttonSize}
                  text={String(pageNumber)}
                  variant={isCurrent ? 'solidBlack' : 'ghostBlack'}
                  onClick={() =>
                    isCurrent ? () => false : onChange(pageNumber)
                  }
                />
              );
            })}
          </div>
          <IconButton
            data-test="pagination-next"
            disabled={!isNextEnabled}
            icon="chevronRight"
            iconSize="xs"
            size={buttonSize}
            variant="ghostBlack"
            onClick={() => onChange(current + 1)}
          />
          <IconButton
            disabled={!isNextEnabled}
            icon="lastPage"
            iconSize="xs"
            size={buttonSize}
            variant="ghostBlack"
            onClick={() => onChange(numberOfPages)}
          />
        </div>
        {showPageSizePicker && (
          <PageSizePicker
            pageSize={pageSize}
            pageSizeOptions={getPageSizePickerOptions}
            onChange={onPageSizeChange}
          />
        )}
      </div>
    </div>
  );
});

Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  showPageSizePicker: PropTypes.bool,
};

Pagination.defaultProps = {
  onPageSizeChange: () => {},
  showPageSizePicker: true,
};

export default Pagination;

import Breakpoint from '../enums/Breakpoint';
import useWindowSize from '../hooks/useWindowSize';
import HorizontalLayout from './HorizontalLayout';
import VerticalLayout from './VerticalLayout';

const ResponsiveLayout = () => {
  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.LG;

  return isMobile ? <VerticalLayout /> : <HorizontalLayout />;
};

export default ResponsiveLayout;

import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useCustomToast from '../../hooks/useCustomToast';
import { useListSelection } from '../../providers/ListSelectionProvider';
import Checkbox from '../Checkbox';
import Tooltip from '../Tooltip';

const ListSelectionCheckbox = (props) => {
  const { onClick, record } = props;
  const {
    disabledCheckboxTooltipMessage,
    getCheckboxItemData,
    isSelectionDisabled,
    isSelectionVisible,
    maxSelectedItems,
    selectItem,
    selectedItems,
    unselectItem,
  } = useListSelection();
  const { toastError } = useCustomToast();
  const { t } = useTranslation();

  const checkboxData = useMemo(() => {
    if (isSelectionVisible && getCheckboxItemData) {
      return getCheckboxItemData(record);
    }

    return undefined;
  }, [getCheckboxItemData, isSelectionVisible, record]);

  const getTooltipText = useMemo(() => {
    if (isSelectionDisabled) {
      return disabledCheckboxTooltipMessage;
    }

    if (checkboxData?.disabled) {
      return checkboxData.disabledTooltip;
    }

    return '';
  }, [
    checkboxData?.disabled,
    checkboxData?.disabledTooltip,
    disabledCheckboxTooltipMessage,
    isSelectionDisabled,
  ]);

  if (!isSelectionVisible) {
    return null;
  }

  const isRowSelectable = selectedItems.length < maxSelectedItems;

  const checked = selectedItems.some((item) => item.id === record.id);

  return (
    <Tooltip
      hidden={!isSelectionDisabled && !checkboxData?.disabled}
      text={getTooltipText}
    >
      <Checkbox
        checked={checked}
        data-test={`row-checkbox-${record.id}`}
        disabled={isSelectionDisabled || checkboxData?.disabled}
        onChange={(e) => {
          if (e.target.checked) {
            if (!isRowSelectable) {
              toastError(
                t(
                  'The maximum number of selected items is {{maxSelectedItems}}.',
                  { maxSelectedItems },
                ),
              );
              return;
            }

            selectItem(record);
            return;
          }

          unselectItem(record.id);
        }}
        onClick={onClick}
      />
    </Tooltip>
  );
};

ListSelectionCheckbox.propTypes = {
  onClick: PropTypes.func,
  record: PropTypes.oneOfType([PropTypes.object]),
};

ListSelectionCheckbox.defaultProps = {
  onClick: undefined,
  record: undefined,
};

export default ListSelectionCheckbox;

import cn from 'classnames';
import PropTypes from 'prop-types';

import { useCollapsableCardsContext } from '../../providers/CollapsableCardsProvider';
import Collapse from '../Collapse';
import Icon from '../Icon';

const CollapsableCard = (props) => {
  const {
    children,
    className,
    'data-test': dataTest,
    expandedContent,
    headerClassName,
    id,
  } = props;
  const { isMultipleExpandable, openedCardIds, setOpenedCardIds } =
    useCollapsableCardsContext();
  const isOpen = openedCardIds.includes(id);

  const onTriggerClick = () => {
    if (isMultipleExpandable) {
      if (isOpen) {
        setOpenedCardIds(openedCardIds.filter((cardId) => cardId !== id));
      } else {
        setOpenedCardIds([...openedCardIds, id]);
      }
    } else {
      setOpenedCardIds(isOpen ? [] : [id]);
    }
  };

  return (
    <div
      className={cn(
        'relative rounded-md bg-white shadow-elevation-100',
        className,
      )}
      data-test={dataTest}
    >
      <div className="flex flex-1 flex-col">
        <div
          className={cn('flex rounded-t-md p-4', headerClassName)}
          onClick={onTriggerClick}
        >
          <div className="flex flex-1">{children}</div>
          <div className="ml-4 flex items-center">
            <Icon
              className={cn('h-3 w-3', isOpen ? '-rotate-90' : 'rotate-90')}
              icon="chevronRight"
            />
          </div>
        </div>
        <Collapse isOpen={isOpen}>
          <div className="px-4 pb-5">{expandedContent}</div>
        </Collapse>
      </div>
    </div>
  );
};

CollapsableCard.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  'data-test': PropTypes.string,
  expandedContent: PropTypes.node,
  headerClassName: PropTypes.string,
  className: PropTypes.string,
};

CollapsableCard.defaultProps = {
  id: undefined,
  children: undefined,
  'data-test': undefined,
  expandedContent: undefined,
  headerClassName: undefined,
  className: undefined,
};

export default CollapsableCard;

import { useTranslation } from 'react-i18next';

import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import OrganisationPlatformFeaturesList from '../../features/shared/OrganisationPlatformFeaturesList';
import { useUser } from '../../providers/UserProvider';

const MyOrganisationPlatformFeatures = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  const { carrier } = user;

  return (
    <Page>
      <Titlebar
        backLink="/my-organisation"
        textPrimary={t('Platform Features')}
        textSecondary={carrier.name}
      />
      <Page.Content variant="grey">
        <OrganisationPlatformFeaturesList
          workflow={carrier.workflow}
          organisationId={carrier.id}
        />
      </Page.Content>
    </Page>
  );
};

export default MyOrganisationPlatformFeatures;

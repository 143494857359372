import { useTranslation } from 'react-i18next';

import DeliveryTaskUnitStatus from '../enums/DeliveryTaskUnitStatus';
import UnitProgress from '../enums/UnitProgress';

const useUnitProgress = () => {
  const { t } = useTranslation();

  return {
    [UnitProgress.RoutingPending]: {
      value: UnitProgress.RoutingPending,
      labelIcon: 'pending',
      labelIconClassname: 'text-grey-700',
      label: t('Routing pending'),
      statuses: [
        DeliveryTaskUnitStatus.Created,
        DeliveryTaskUnitStatus.Arrived,
        DeliveryTaskUnitStatus.ArrivedLocal,
        DeliveryTaskUnitStatus.Retry,
        DeliveryTaskUnitStatus.Postponed,
      ],
    },
    [UnitProgress.RoutingDone]: {
      value: UnitProgress.RoutingDone,
      labelIcon: 'timelapseThird',
      labelIconClassname: 'text-primary-yellow',
      label: t('Routing done'),
      statuses: [
        DeliveryTaskUnitStatus.Created,
        DeliveryTaskUnitStatus.Arrived,
        DeliveryTaskUnitStatus.ArrivedLocal,
        DeliveryTaskUnitStatus.Retry,
        DeliveryTaskUnitStatus.Postponed,
      ],
    },
    [UnitProgress.Ready]: {
      value: UnitProgress.Ready,
      labelIcon: 'timelapseHalf',
      labelIconClassname: 'text-ui-green-dark',
      label: t('Ready'),
      statuses: [DeliveryTaskUnitStatus.Prepared],
    },
    [UnitProgress.OnTour]: {
      value: UnitProgress.OnTour,
      labelIcon: 'timelapseFinal',
      labelIconClassname: 'text-ui-green-dark',
      label: t('In Execution'),
      statuses: [DeliveryTaskUnitStatus.InDelivery],
    },
    [UnitProgress.Blocked]: {
      value: UnitProgress.Blocked,
      labelIcon: 'xCircleFilled',
      labelIconClassname: 'text-grey-500',
      label: t('Blocked'),
      statuses: [
        DeliveryTaskUnitStatus.NotArrived,
        DeliveryTaskUnitStatus.Missorted,
        DeliveryTaskUnitStatus.Misplaced,
        DeliveryTaskUnitStatus.Damaged,
        DeliveryTaskUnitStatus.InClarification,
      ],
    },
    [UnitProgress.ShipToClient]: {
      value: UnitProgress.ShipToClient,
      labelIcon: 'arrowLeftAlt',
      labelIconClassname: 'text-grey-700',
      label: t('Ship to client'),
      statuses: [
        DeliveryTaskUnitStatus.ReturnToClient,
        DeliveryTaskUnitStatus.Stored,
        DeliveryTaskUnitStatus.StoredLocal,
      ],
    },
    [UnitProgress.FinalStatus]: {
      value: UnitProgress.FinalStatus,
      labelIcon: 'lockCircleFilled',
      labelIconClassname: 'text-ui-blue',
      label: t('Final status'),
      statuses: [
        DeliveryTaskUnitStatus.Cancelled,
        DeliveryTaskUnitStatus.Lost,
        DeliveryTaskUnitStatus.Delivered,
        DeliveryTaskUnitStatus.ShippedToClient,
      ],
    },
  };
};

export default useUnitProgress;

import { useTranslation } from 'react-i18next';

import PostponeReason from '../enums/PostponeReason';

const usePostponeReasonTranslations = () => {
  const { t } = useTranslation();

  return {
    [PostponeReason.ForceMajeure]: t('Force majeure'),
    [PostponeReason.LackOfTime]: t('Exceeded execution time'),
  };
};

export default usePostponeReasonTranslations;

import PropTypes from 'prop-types';

const routingConfigurationPropType = PropTypes.shape({
  id: PropTypes.string,
  serviceTime: PropTypes.number,
  minimizeNumberOfTours: PropTypes.bool,
  maxDistancePerTourKm: PropTypes.number,
  maxDeliveriesPerTour: PropTypes.number,
  hub: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  days: PropTypes.arrayOf(PropTypes.string),
  postcodeGroup: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    postcodes: PropTypes.arrayOf(PropTypes.string),
  }),
});

export default routingConfigurationPropType;

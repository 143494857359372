import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Icon from '../../../components/Icon';
import HubType from '../../../enums/HubType';
import LocalStorage from '../../../enums/LocalStorage';
import LocalStorageHelper from '../../../helpers/LocalStorageHelper';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import { usePackageScanningSettings } from '../../../providers/PackageScanningSettingsProvider';
import CompleteScanSessionModal from './CompleteScanSessionModal';

const ScanHeader = (props) => {
  const { onScanningDateChange, scanned, scanningDate, total } = props;
  const [isCompleteScanSessionModalOpen, setIsCompleteScanSessionModalOpen] =
    useState(false);
  const { t } = useTranslation();

  const sessionDate = useMemo(() => moment(), []);
  const { isCentral } = usePackageScanningSettings();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();

  const [sessionEnd, setSessionEnd] = useState(() => {
    const localStorageSessionEnd = LocalStorageHelper.getItem(
      LocalStorage.ScanSessionEnd,
    );
    if (!localStorageSessionEnd) {
      return undefined;
    }
    return JSON.parse(localStorageSessionEnd);
  });

  const isScanningComplete = useMemo(() => {
    if (!sessionEnd) {
      return false;
    }

    if (isCentral) {
      return sessionEnd.central
        ? moment(sessionEnd.central).isSame(moment(), 'day')
        : false;
    }

    return sessionEnd.local
      ? moment(sessionEnd.local).isSame(moment(), 'day')
      : false;
  }, [isCentral, sessionEnd]);

  const finishScanMutation = useMutation(
    () =>
      fetch(`/units/hub-scan/finish`, {
        body: {
          date: moment(scanningDate).format('YYYY-MM-DD'),
          type: isCentral ? HubType.Central : HubType.Local,
        },
        method: 'POST',
      }),
    {
      onError: (error) => toastFetchError(error),
      onSuccess: () => {
        const newTimestamp = moment().toISOString(true);

        const newSessionEnd = {
          ...sessionEnd,
        };

        if (isCentral) {
          newSessionEnd.central = newTimestamp;
        } else {
          newSessionEnd.local = newTimestamp;
        }

        LocalStorageHelper.setItem(
          LocalStorage.ScanSessionEnd,
          JSON.stringify(newSessionEnd),
        );

        setSessionEnd(newSessionEnd);
      },
    },
  );

  const isStandardMode =
    scanningDate === null || moment().isSame(moment(scanningDate), 'day');

  return (
    <>
      <CompleteScanSessionModal
        isOpen={isCompleteScanSessionModalOpen}
        onActionClick={finishScanMutation.mutate}
        onClose={() => setIsCompleteScanSessionModalOpen(false)}
      />
      <div className="mb-4 w-full rounded-md bg-white px-5 py-4 shadow-elevation-200">
        <div className="mb-4 flex items-center justify-between border-b border-grey-300 pb-4">
          <div>
            <span className="mb-1 block font-medium leading-none text-grey-900">
              {t('Scan for day')}:
            </span>
            <span className="text-sm text-grey-700">
              {t('Mode')}: {isStandardMode ? t('Standard') : t('Custom')}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <div>
              <DatePicker
                dateFormat="dd.MM.yyyy."
                value={scanningDate}
                onChange={(date) => {
                  onScanningDateChange(date);
                }}
              />
            </div>
            {!isStandardMode && (
              <Button
                text={t('Reset')}
                variant="ghostBlue"
                onClick={() => {
                  onScanningDateChange(new Date());
                }}
              />
            )}
          </div>
        </div>

        <div>
          {!isStandardMode && (
            <div className="mb-4">
              <Alert message={t('Custom scan mode active')} variant="info" />
            </div>
          )}
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <div className="mb-1 font-medium">
                <span className="text-ui-blue">{scanned}</span> / {total}{' '}
                {t('Scanned parcels')}
              </div>
              <div className="text-sm text-grey-700">
                {t('Scan session date')}:{' '}
                {sessionDate.format('DD.MM.YYYY - HH:mm')}
              </div>
            </div>

            <div>
              {isScanningComplete ? (
                <div className="flex items-center gap-2">
                  <Icon
                    className="h-5 w-5 text-ui-green-dark"
                    icon="checkmark"
                  />
                  <span className="text-sm font-medium">
                    {t('Session marked as completed')}
                  </span>
                </div>
              ) : (
                <Button
                  disabled={finishScanMutation.isLoading}
                  text={t('Complete Scan Session')}
                  variant="outlineBlack"
                  onClick={() => setIsCompleteScanSessionModalOpen(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ScanHeader.propTypes = {
  onScanningDateChange: PropTypes.func,
  scanned: PropTypes.number,
  scanningDate: PropTypes.instanceOf(Date).isRequired,
  total: PropTypes.number,
};

ScanHeader.defaultProps = {
  onScanningDateChange: () => {},
  scanned: 0,
  total: 0,
};

export default ScanHeader;

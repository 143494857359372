import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import Breakpoint from '../../enums/Breakpoint';
import UnitsPageDesktop from '../../features/units/UnitsPage/UnitsPageDesktop';
import UnitsPageMobile from '../../features/units/UnitsPage/UnitsPageMobile';
import usePageLocationQuery from '../../hooks/usePageLocationQuery';
import useWindowSize from '../../hooks/useWindowSize';
import ListSelectionProvider from '../../providers/ListSelectionProvider';

const Units = () => {
  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.LG;
  const { t } = useTranslation();

  const page = usePageLocationQuery();

  if (page === undefined) {
    return <Navigate replace to="/units?page=1" />;
  }

  return (
    <ListSelectionProvider
      disabledCheckboxTooltipMessage={t(
        'Date and status filters must be active to make selection',
      )}
    >
      {isMobile ? <UnitsPageMobile /> : <UnitsPageDesktop />}
    </ListSelectionProvider>
  );
};

export default Units;

import PropTypes from 'prop-types';

import Label from '../../../components/Label';

const ShiftLabel = (props) => {
  const { shiftNumber } = props;
  return (
    <Label
      className="whitespace-nowrap"
      size="s"
      text={`S${shiftNumber}`}
      variant="grey"
    />
  );
};

ShiftLabel.propTypes = {
  shiftNumber: PropTypes.number.isRequired,
};

export default ShiftLabel;

import moment from 'moment';

export const stringToTimePickerObject = (timeToTransform) => {
  if (!timeToTransform) {
    return {
      hours: undefined,
      minutes: undefined,
    };
  }

  const time = moment(timeToTransform, 'HH:mm');

  return {
    hours: time.hours(),
    minutes: time.minutes(),
  };
};

export const timePickerObjectToString = (timeToTransform) => {
  if (timeToTransform.hours === undefined) {
    return '';
  }

  const time = moment({
    hour: timeToTransform.hours || 0,
    minute: timeToTransform.minutes || 0,
  });

  return time.format('HH:mm');
};

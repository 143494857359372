import cn from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import TasksSearchBy from '../../../enums/TasksSearchBy';
import useTasksPageSearchParams from '../../../hooks/useTasksPageSearchParams';
import Resource from '../../../lib/api/Resource';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import RoutingCycleInfo from '../../shared/RoutingCycleInfo';
import TasksViewToggle from '../../shared/TasksViewToggle';
import TasksBulkEdit from '../TaskBulkEdit';
import TasksSearch from '../TasksSearch';
import TasksTable, { tasksTableId } from '../TasksTable';

const TasksPageDesktop = () => {
  const { t } = useTranslation();
  const { allParams, hubIdQuery, searchByQuery, searchParams, searchQuery } =
    useTasksPageSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { isSelectionVisible, setIsSelectionDisabled } = useListSelection();

  const isBulkFiltersApplied = Boolean(hubIdQuery);

  useEffect(() => {
    if (isBulkFiltersApplied) {
      setIsSelectionDisabled(false);
    } else {
      setIsSelectionDisabled(true);
    }
  }, [isBulkFiltersApplied, setIsSelectionDisabled]);

  return (
    <Page className="max-h-screen">
      <Titlebar
        variant="small"
        menu={
          <div className={cn(isSelectionVisible && 'flex gap-4')}>
            <div className="flex flex-col lg:flex-row gap-6">
              <TasksSearch
                searchBy={searchByQuery}
                searchValue={searchQuery}
                onSearch={(newSearchValue) => {
                  if (newSearchValue === searchQuery) {
                    return;
                  }
                  searchParams.set('page', '1');
                  if (!newSearchValue) {
                    searchParams.delete('search');
                    searchParams.delete('expandAll');
                  } else {
                    if (searchByQuery === TasksSearchBy.UnitCode) {
                      searchParams.set('expandAll', 'true');
                    }
                    searchParams.set('search', newSearchValue?.trim());
                    searchParams.set('searchBy', searchByQuery);
                  }

                  const { search, ...rest } = location;
                  const newLocation = {
                    ...rest,
                    search: `?${searchParams.toString()}`,
                  };

                  navigate(newLocation, {
                    replace: true,
                    ...(!(searchParams.get('expandAll') === 'true') && {
                      state: {
                        [tasksTableId]: {
                          expanded: {},
                        },
                      },
                    }),
                  });
                }}
                onSearchByChange={(newSearchBy) => {
                  searchParams.set('page', '1');
                  searchParams.set('searchBy', newSearchBy);

                  if (newSearchBy === TasksSearchBy.UnitCode && searchQuery) {
                    searchParams.set('expandAll', 'true');
                  } else {
                    searchParams.delete('expandAll');
                  }

                  const { search, ...rest } = location;
                  const newLocation = {
                    ...rest,
                    search: `?${searchParams.toString()}`,
                  };

                  navigate(newLocation, {
                    replace: true,
                    state: {
                      [tasksTableId]: {
                        expanded: {},
                      },
                    },
                  });
                }}
              />
              {!isSelectionVisible && <RoutingCycleInfo />}
            </div>

            <div className="flex-1">
              {isSelectionVisible && (
                <TasksBulkEdit isBulkFiltersApplied={isBulkFiltersApplied} />
              )}
            </div>
          </div>
        }
        textPrimary={t('Tasks view')}
        titleRightContent={<TasksViewToggle />}
      />

      <Page.Content variant="wide">
        <div className="flex flex-col min-h-0">
          <Resource
            paginationStickyBottom
            itemsPerPage={100}
            showPageSizePicker={false}
            params={allParams}
            resourceUrl="/tasks"
            socketEntityName="task"
          >
            <TasksTable />
          </Resource>
        </div>
      </Page.Content>
    </Page>
  );
};

export default TasksPageDesktop;

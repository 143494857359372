import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { useUser } from '../../providers/UserProvider';

const NotFound = () => {
  const { t } = useTranslation();

  const { user } = useUser();

  // disallow guest user access to not found routes
  if (!user) {
    return <Navigate replace to="login" />;
  }

  return (
    <div className="flex h-[calc(100vh-80px)] flex-col items-center justify-center">
      <div className="text-center">
        <h1>404</h1>
        <h4 className="mt-2 uppercase">{t('Page not found')}</h4>
      </div>
    </div>
  );
};

export default NotFound;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import FormSelect from '../../components/FormSelect';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import GroceriesTourStatus from '../../enums/GroceriesTourStatus';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';

const GroceriesResolveTourStatusModal = ({
  isOpen,
  onClose,
  tourId,
  tourNumber,
}) => {
  const { toastSuccess } = useCustomToast();
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: {
      resolveStatusType: '',
    },
  });

  const { handleSubmit, watch } = methods;

  const resolveStatusType = watch('resolveStatusType');

  const options = useMemo(
    () => [
      {
        label: t('Tour Completed by Admin'),
        labelIcon: 'checkmarkSemicircle',
        labelIconClassname: 'text-ui-green-dark',
        value: GroceriesTourStatus.CompletedAdmin,
      },
      {
        label: t('Tour Cancelled by Admin'),
        labelIcon: 'xCircleOutlined',
        labelIconClassname: 'text-ui-red',
        value: GroceriesTourStatus.CanceledAdmin,
      },
    ],
    [t],
  );

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const queryClient = useQueryClient();
  const resolveTourStatusMutation = useMutation(
    async () => {
      const url = `/grocery-tours/${tourId}/status`;
      const response = await fetch(url, {
        body: {
          status: resolveStatusType,
        },
        method: 'PATCH',
      });
      return response.json();
    },
    {
      onError: (error) => toastFetchError(error),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`/grocery-tours/${tourId}`],
        });
        queryClient.invalidateQueries({
          queryKey: [`/activity/grocery-tours/${tourId}`],
        });

        toastSuccess(t('Tour status resolved.'));
        onClose();
      },
    },
  );

  return (
    <Modal
      body={
        <FormProvider {...methods}>
          <div className="flex flex-col gap-5">
            <div className="text-sm">
              <span className="text-grey-700">{t('Tour ID')}:</span>{' '}
              {tourNumber}
            </div>
            <div className="flex flex-col gap-2">
              <FormSelect
                className="js-resolve-tour-status-select"
                id="resolve-status-type"
                label={t('Resolution Status')}
                name="resolveStatusType"
                options={options}
                placeholder={t('Select')}
              />
            </div>
          </div>
        </FormProvider>
      }
      footer={
        <div
          className="grid grid-cols-1 gap-4 sm:grid-cols-2"
          data-test="resolve-tour-status-modal"
        >
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            disabled={resolveTourStatusMutation.isLoading}
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onClose}
          />
          <Button
            data-test="modal-button-action"
            disabled={!resolveStatusType || resolveTourStatusMutation.isLoading}
            text={t('Resolve')}
            variant="solidBlue"
            onClick={handleSubmit(resolveTourStatusMutation.mutate)}
          />
        </div>
      }
      header={
        <div className="flex items-center gap-2">
          <Icon className="h-6 w-6 text-ui-red" icon="construction" />
          <span>{t('Resolve Tour Status')}</span>
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

GroceriesResolveTourStatusModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tourId: PropTypes.string,
  tourNumber: PropTypes.string,
};

GroceriesResolveTourStatusModal.defaultProps = {
  tourId: '',
  tourNumber: '',
};

export default GroceriesResolveTourStatusModal;

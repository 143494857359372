import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Button from '../../components/Button';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import UserRole from '../../enums/UserRole';
import DataExportModal from '../../features/fixed-tours/DataExportModal';
import GroceryTourCardList from '../../features/groceries/GroceryTourCardList';
import GroceryToursFilters from '../../features/groceries/GroceryToursFilters';
import GroceryToursTable from '../../features/groceries/GroceryToursTable';
import useDateQuery from '../../hooks/useDateQuery';
import useWindowSize from '../../hooks/useWindowSize';
import Resource from '../../lib/api/Resource';
import Allow from '../../lib/rbac/Allow';

const GroceryTours = () => {
  const [searchParams] = useSearchParams();
  const searchByQuery = searchParams.get('searchBy');
  const searchQuery = searchParams.get('search');
  const hubIdQuery = searchParams.get('hubId');
  const tourStatusQuery = searchParams.get('tourStatus');
  const subcarrierQuery = searchParams.get('subcarrierId');
  const shiftsQuery = searchParams.get('shiftNumber');

  const dateQuery = useDateQuery();
  const hasFiltersApplied = !!(
    searchQuery ||
    hubIdQuery ||
    tourStatusQuery ||
    subcarrierQuery ||
    shiftsQuery ||
    dateQuery
  );

  const [isDataExportModalOpen, setIsDataExportModalOpen] = useState(false);

  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const params = useMemo(() => {
    const date = dateQuery ? moment(dateQuery).format('YYYY-MM-DD') : undefined;
    let fields = {
      date,
      status: tourStatusQuery,
      hubId: hubIdQuery,
      subcarrierId: subcarrierQuery,
      shiftNumber: shiftsQuery,
    };

    if (searchQuery) {
      fields = {
        ...fields,
        search: searchQuery,
        searchBy: searchByQuery,
      };
    }

    return fields;
  }, [
    dateQuery,
    searchByQuery,
    searchQuery,
    subcarrierQuery,
    tourStatusQuery,
    shiftsQuery,
    hubIdQuery,
  ]);

  return (
    <>
      <DataExportModal
        isOpen={isDataExportModalOpen}
        onClose={() => setIsDataExportModalOpen(false)}
        isGroceries
      />
      <Page>
        <Titlebar
          isActive={hasFiltersApplied}
          menu={<GroceryToursFilters isResetEnabled={hasFiltersApplied} />}
          textPrimary={t('Tours')}
          titleRightContent={
            <Allow roles={[UserRole.Admin]}>
              <div className="flex flex-col gap-4 lg:flex-row">
                <Button
                  icon="dataExport"
                  text={t('Data Export')}
                  variant="outlineBlack"
                  onClick={() => setIsDataExportModalOpen(true)}
                />
              </div>
            </Allow>
          }
        />
        <Page.Content variant={isDesktop ? 'white' : 'grey'}>
          <Resource
            params={params}
            resourceUrl="/grocery-tours"
            socketEntityName="groceryTour"
          >
            {isDesktop ? <GroceryToursTable /> : <GroceryTourCardList />}
          </Resource>
        </Page.Content>
      </Page>
    </>
  );
};

export default GroceryTours;

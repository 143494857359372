import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../components/Icon';
import getTourDuration from '../../helpers/getTourDuration';

const GroceriesTourDetails = (props) => {
  const { className, tour } = props;

  return (
    <div className={cn('flex flex-wrap items-center gap-2', className)}>
      <div className="flex items-center gap-1">
        <Icon className="h-5 w-5 text-grey-700" icon="tourStop" />
        <span className="text-sm text-primary-dark">{tour.amount}</span>
      </div>
      <div className="flex items-center gap-1">
        <Icon className="h-5 w-5 text-grey-700" icon="distance" />
        <span className="text-sm text-primary-dark">
          {tour.distance ? `${Math.round(tour.distance / 1000)}km` : 'N/A'}
        </span>
      </div>
      <div className="flex items-center gap-1">
        <Icon className="h-5 w-5 text-grey-700" icon="history" />
        <span className="text-sm text-primary-dark">
          {getTourDuration(tour)}
        </span>
      </div>
    </div>
  );
};

GroceriesTourDetails.propTypes = {
  className: PropTypes.string,
  tour: PropTypes.shape({
    amount: PropTypes.number,
    distance: PropTypes.number,
    duration: PropTypes.number,
  }),
};

GroceriesTourDetails.defaultProps = {
  className: '',
  tour: undefined,
};

export default GroceriesTourDetails;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import TourStatus from '../enums/TourStatus';

const useTourStatusTranslations = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      [TourStatus.Active]: t('Active'),
      [TourStatus.Completed]: t('Completed'),
      [TourStatus.NotAssignedDriver]: t('Driver not assigned'),
      [TourStatus.Pending]: t('Pending activation'),
      [TourStatus.Created]: t('Created'),
    }),
    [t],
  );
};

export default useTourStatusTranslations;

import moment from 'moment';

import TourStatus from '../enums/TourStatus';

export default class TourHelper {
  static isUnresolved(tour) {
    const hasPotentiallyUnresolvedStatus =
      tour.status === TourStatus.Created ||
      tour.status === TourStatus.Pending ||
      tour.status === TourStatus.NotAssignedDriver;

    const today = moment();
    const hasPastDate = today.isAfter(tour.date, 'day');

    return hasPotentiallyUnresolvedStatus && hasPastDate;
  }

  static isNotCheckedOut(tour) {
    const today = moment();
    const hasPastDate = today.isAfter(tour.date, 'day');

    return tour.status !== TourStatus.Completed && hasPastDate;
  }

  static isAssignmentEnabled(tour) {
    const isStatusUnresolved = this.isUnresolved(tour);
    return (
      isStatusUnresolved ||
      (tour.status !== TourStatus.Completed &&
        tour.status !== TourStatus.Active)
    );
  }

  static getTourCode(tour) {
    return `${tour?.vehicle}#${moment(tour?.date).format('YYYYMMDD')}#${
      tour?.shift
    }`;
  }
}

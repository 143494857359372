import { useMemo } from 'react';

function useMaxOptionsDepth(options) {
  return useMemo(() => {
    const findMaxDepth = (_options, currentDepth) => {
      let max = currentDepth;
      _options.forEach((item) => {
        if (item.options && Array.isArray(item.options)) {
          const depth = findMaxDepth(item.options, currentDepth + 1);
          if (depth > max) {
            max = depth;
          }
        }
      });

      return max;
    };

    return findMaxDepth(options, 1);
  }, [options]);
}

export default useMaxOptionsDepth;

import { useTranslation } from 'react-i18next';

import DeliveryOption from '../enums/DeliveryOption';

const useDeliveryOptionsTranslations = () => {
  const { t } = useTranslation();

  const directDeliveryOptionName = t('deliveryOption:direct:name');
  const neighbourDeliveryOptionName = t('deliveryOption:neighbour:name');
  const apartmentDoorDeliveryOptionName = t(
    'deliveryOption:apartmentDoor:name',
  );
  const frontDoorDeliveryOptionName = t('deliveryOption:frontDoor:name');
  const stairwellDeliveryOptionName = t('deliveryOption:stairwell:name');
  const terraceDeliveryOptionName = t('deliveryOption:terrace:name');
  const garageDeliveryOptionName = t('deliveryOption:garage:name');
  const gardenGateDeliveryOptionName = t('deliveryOption:gardenGate:name');
  const customArrangementDeliveryOptionName = t(
    'deliveryOption:customArrangement:name',
  );
  const otherDeliveryOptionName = t('deliveryOption:other:name');
  const cellarDoorDeliveryOptionName = t('deliveryOption:cellarDoor:name');
  const mailboxDeliveryOptionName = t('deliveryOption:mailbox:name');
  const gardenShedDeliveryOptionName = t('deliveryOption:gardenShed:name');

  // descriptions
  const neighbourDeliveryOptionDescription = t(
    'deliveryOption:neighbour:description',
  );
  const apartmentDoorDeliveryOptionDescription = t(
    'deliveryOption:apartmentDoor:description',
  );
  const frontDoorDeliveryOptionDescription = t(
    'deliveryOption:frontDoor:description',
  );
  const stairwellDeliveryOptionDescription = t(
    'deliveryOption:stairwell:description',
  );
  const terraceDeliveryOptionDescription = t(
    'deliveryOption:terrace:description',
  );
  const garageDeliveryOptionDescription = t(
    'deliveryOption:garage:description',
  );
  const gardenGateDeliveryOptionDescription = t(
    'deliveryOption:gardenGate:description',
  );
  const customArrangementDeliveryOptionDescription = t(
    'deliveryOption:customArrangement:description',
  );
  const otherDeliveryOptionDescription = t('deliveryOption:other:description');

  const cellarDoorDeliveryOptionDescription = t(
    'deliveryOption:cellarDoor:description',
  );
  const mailboxDeliveryOptionDescription = t(
    'deliveryOption:mailbox:description',
  );
  const gardenShedDeliveryOptionDescription = t(
    'deliveryOption:gardenShed:description',
  );

  const nameTranslations = {
    [DeliveryOption.Direct]: directDeliveryOptionName,
    [DeliveryOption.Neighbour]: neighbourDeliveryOptionName,
    [DeliveryOption.ApartmentDoor]: apartmentDoorDeliveryOptionName,
    [DeliveryOption.FrontDoor]: frontDoorDeliveryOptionName,
    [DeliveryOption.Stairwell]: stairwellDeliveryOptionName,
    [DeliveryOption.Terrace]: terraceDeliveryOptionName,
    [DeliveryOption.Garage]: garageDeliveryOptionName,
    [DeliveryOption.GardenGate]: gardenGateDeliveryOptionName,
    [DeliveryOption.Other]: otherDeliveryOptionName,
    [DeliveryOption.CustomArrangement]: customArrangementDeliveryOptionName,
    [DeliveryOption.CellarDoor]: cellarDoorDeliveryOptionName,
    [DeliveryOption.Mailbox]: mailboxDeliveryOptionName,
    [DeliveryOption.GardenShed]: gardenShedDeliveryOptionName,
  };

  const descriptionTranslations = {
    // no description for "direct"
    [DeliveryOption.Direct]: '',
    [DeliveryOption.Neighbour]: neighbourDeliveryOptionDescription,
    [DeliveryOption.ApartmentDoor]: apartmentDoorDeliveryOptionDescription,
    [DeliveryOption.FrontDoor]: frontDoorDeliveryOptionDescription,
    [DeliveryOption.Stairwell]: stairwellDeliveryOptionDescription,
    [DeliveryOption.Terrace]: terraceDeliveryOptionDescription,
    [DeliveryOption.Garage]: garageDeliveryOptionDescription,
    [DeliveryOption.GardenGate]: gardenGateDeliveryOptionDescription,
    [DeliveryOption.Other]: otherDeliveryOptionDescription,
    [DeliveryOption.CustomArrangement]:
      customArrangementDeliveryOptionDescription,
    [DeliveryOption.CellarDoor]: cellarDoorDeliveryOptionDescription,
    [DeliveryOption.Mailbox]: mailboxDeliveryOptionDescription,
    [DeliveryOption.GardenShed]: gardenShedDeliveryOptionDescription,
  };

  return { nameTranslations, descriptionTranslations };
};

export default useDeliveryOptionsTranslations;

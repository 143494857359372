import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import Collapse from '../../../components/Collapse';
import LinkButton from '../../../components/LinkButton';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import UnitsStatusAndChangeTime from '../../shared/UnitsStatusAndChangeTime';
import TaskUnitSocketHandler from '../TaskUnitSocketHandler';

const NestedUnits = ({ onUnitDetailsClick, onUpdateUnitStatus, units }) => {
  const [seeAll, setSeeAll] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className={cn(seeAll && 'bg-ui-yellow-light pt-3 pb-5 -mx-4 px-4')}>
        <Collapse isOpen={seeAll}>
          <div className="flex flex-col gap-3 divide divide-y divide-grey-200">
            {units.map((unit) => {
              const isCanceled =
                unit.status === DeliveryTaskUnitStatus.Cancelled;
              return (
                <Fragment key={unit.id}>
                  <TaskUnitSocketHandler id={unit.id} />
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-2 px-4 py-3 bg-white">
                      <div className="text-xs text-grey-700">
                        {t('Unit Code')}
                      </div>
                      <div className="text-sm">{unit.code}</div>
                    </div>
                    <div className="flex flex-col gap-2 px-4 py-3 bg-white">
                      <div className="text-xs text-grey-700">
                        {t('Unit Number')}
                      </div>
                      <div className="text-sm">{unit.number}</div>
                    </div>
                    <div className="flex flex-col gap-2 px-4 py-3 bg-white">
                      <div className="text-xs text-grey-700">
                        {t('Unit Status and Change Time')}
                      </div>
                      <UnitsStatusAndChangeTime unit={unit} />
                    </div>
                    <div className="flex flex-col gap-2 px-4 py-3 bg-white">
                      <div className="text-xs text-grey-700">
                        {t('Tour Stop')}
                      </div>
                      <div className="text-sm">
                        {unit.latestTour ? (
                          <LinkButton
                            className="text-sm"
                            as={Link}
                            text={`${unit.latestTour.vehicle} ${unit.latestTour.tourStop.sequence}/${unit.latestTour.totalStops}`}
                            state={{ backLink: -1 }}
                            to={{
                              pathname: `/tours/${unit.latestTour.id}`,
                              search: `?stopId=${unit.latestTour.tourStop.id}`,
                            }}
                          />
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 px-4 py-3 bg-white">
                      <div className="text-xs text-grey-700">
                        {t('Execution Date')}
                      </div>
                      <div className="text-sm">
                        {moment(unit.date).format('DD.MM.YYYY.')}
                      </div>
                    </div>
                    <div className="flex flex-col gap-3 rounded-md p-4 bg-grey-100 mt-4">
                      <Button
                        color="secondary"
                        icon="parcelView"
                        isFullWidth
                        size="s"
                        text={t('View Unit Details')}
                        variant="outlineBlack"
                        onClick={() => onUnitDetailsClick(unit)}
                      />
                      <Button
                        color="secondary"
                        icon="updateUnitStatus"
                        isFullWidth
                        size="s"
                        disabled={isCanceled}
                        text={t('Update Unit status')}
                        variant="outlineBlack"
                        onClick={() => {
                          if (!isCanceled) {
                            onUpdateUnitStatus(unit);
                          }
                        }}
                      />
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </Collapse>
      </div>
      <div className={cn('pt-3 pb-2', !seeAll && 'border-t border-grey-200')}>
        <LinkButton
          text={`${seeAll ? t('Hide Units') : t('Show Units')} (${
            units.length
          })`}
          onClick={() => {
            if (seeAll) {
              setSeeAll(false);
              return;
            }
            setSeeAll(true);
          }}
        />
      </div>
    </>
  );
};

NestedUnits.propTypes = {
  units: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      number: PropTypes.number,
      latestTour: PropTypes.shape({
        id: PropTypes.string,
        totalStops: PropTypes.number,
        tourStop: PropTypes.shape({
          sequence: PropTypes.number,
        }),
      }),
      data: PropTypes.string,
    }),
  ),
  onUpdateUnitStatus: PropTypes.func.isRequired,
  onUnitDetailsClick: PropTypes.func.isRequired,
};

NestedUnits.defaultProps = {
  units: [],
};

export default NestedUnits;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

import Input from '../../../components/Input';
import Breakpoint from '../../../enums/Breakpoint';
import { useUser } from '../../../providers/UserProvider';
import SubcarrierSelect from '../../shared/SubcarrierSelect';

const DriverSearch = forwardRef((props, ref) => {
  const {
    onSearch,
    onSubcarrierChange,
    searchValue,
    showSubcarrierFilter,
    subcarrierId,
  } = props;

  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { user } = useUser();

  return (
    <div className="flex flex-1">
      {showSubcarrierFilter && (
        <SubcarrierSelect
          className="w-[115px] shrink-0"
          dropdownClassname="lg:w-[160px]"
          required
          value={subcarrierId || ''}
          flatRight
          onChange={onSubcarrierChange}
          carrierId={user.carrier.id}
          carrierName={user.carrier.name}
        />
      )}
      <Input
        autoFocus={width >= Breakpoint.LG}
        className={cn('lg:w-full', showSubcarrierFilter && 'rounded-l-none')}
        clearable
        icon="search"
        isFullWidth
        placeholder={`${t('Find Driver')}...`}
        ref={ref}
        value={searchValue}
        onChange={(e) => {
          onSearch(e?.target?.value);
        }}
      />
    </div>
  );
});

DriverSearch.propTypes = {
  onSearch: PropTypes.func,
  onSubcarrierChange: PropTypes.func,
  searchValue: PropTypes.string,
  showSubcarrierFilter: PropTypes.bool,
  subcarrierId: PropTypes.string,
};

DriverSearch.defaultProps = {
  onSearch: () => {},
  onSubcarrierChange: () => {},
  searchValue: undefined,
  showSubcarrierFilter: true,
  subcarrierId: '',
};

export default DriverSearch;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CopyToClipboardButton from '../../../components/CopyToClipboardButton';
import IconButton from '../../../components/IconButton';
import Popover from '../../../components/Popover';

const EndCustomerPopover = (props) => {
  const { children, email, phoneNumber, placement, title } = props;
  const { t } = useTranslation();

  return (
    <Popover
      className="w-[calc(100vw_-_32px)] max-w-[296px]"
      content={
        <div className="flex flex-col gap-3 text-sm">
          {phoneNumber && (
            <div className="flex gap-2 items-center justify-between">
              <div className="flex gap-2 items-center">
                <CopyToClipboardButton
                  tooltipText={t('Copy phone number')}
                  info={phoneNumber}
                />
                <span>{phoneNumber}</span>
              </div>
              <IconButton
                className="h-4 w-4 text-grey-900"
                variant="ghostBlack"
                icon="call"
                size="s"
                iconSize="s"
                onClick={() => {
                  window.open(`tel:${phoneNumber}`, '_self');
                }}
              />
            </div>
          )}
          {email && (
            <div className="flex gap-2 items-center break-all">
              <CopyToClipboardButton
                tooltipText={t('Copy e-mail')}
                info={email}
              />
              <span>{email}</span>
            </div>
          )}
        </div>
      }
      placement={placement}
      title={title}
    >
      {children}
    </Popover>
  );
};

EndCustomerPopover.propTypes = {
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
};

EndCustomerPopover.defaultProps = {
  placement: 'bottom',
  title: undefined,
  phoneNumber: undefined,
  email: undefined,
};

export default EndCustomerPopover;

const DeliveryOption = {
  CellarDoor: 'cellarDoor',
  Mailbox: 'mailbox',
  GardenShed: 'gardenShed',
  Direct: 'direct',
  Neighbour: 'neighbour',
  FrontDoor: 'frontDoor',
  ApartmentDoor: 'apartmentDoor',
  Garage: 'garage',
  CustomArrangement: 'customArrangement',
  Other: 'other',
  Stairwell: 'stairwell',
  Terrace: 'terrace',
  GardenGate: 'gardenGate',
};

export default DeliveryOption;

import cn from 'classnames';
import PropTypes from 'prop-types';

const CardHeader = (props) => {
  const { border, children, className, title } = props;

  return (
    <div
      className={cn(
        'w-full text-grey-700',
        border && 'border-b border-grey-200',
        className,
      )}
    >
      {title && (
        <h2 className="m-0 mb-3 mr-4 text-sm font-inherit uppercase text-inherit">
          {title}
        </h2>
      )}
      {children}
    </div>
  );
};

CardHeader.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};

CardHeader.defaultProps = {
  border: true,
  children: undefined,
  className: undefined,
  title: undefined,
};

export default CardHeader;

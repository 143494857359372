import { useMutation, useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';

import Button from '../../../components/Button';
import CancelPrompt from '../../../components/CancelPrompt';
import FormCheckbox from '../../../components/FormCheckbox';
import FormCheckboxGroup from '../../../components/FormCheckboxGroup';
import FormInput from '../../../components/FormInput';
import Icon from '../../../components/Icon';
import InfiniteScrollFormSelect from '../../../components/InfiniteScrollFormSelect';
import Modal from '../../../components/Modal';
import OnOffSwitch from '../../../components/OnOffSwitch';
import RadioGroup from '../../../components/RadioGroup';
import RoutingConfigHelper from '../../../helpers/RoutingConfigHelper';
import useBlocker from '../../../hooks/useBlocker';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import routingConfigurationPropType from '../../../prop-types/routingConfigurationPropType';
import NumberNoLimitFormInput from '../NumberNoLimitFormInput';

const FormType = {
  HubConfiguration: 'hubConfiguration',
  CustomCarrierConfiguration: 'customCarrierConfiguration',
};

const getDisabledDaysSetForConfig = (
  { hubId, postcodeGroupId, routingConfigId, type },
  labelledRoutingConfigs,
) => {
  const days = new Set();
  const configsWithoutCurrent = labelledRoutingConfigs.filter(
    (config) => config.id !== routingConfigId,
  );

  if (
    type === RoutingConfigHelper.routingConfigType.CustomPostcodeGroupConfig
  ) {
    const samePostcodeGroupConfigs = configsWithoutCurrent.filter(
      (config) =>
        config.postcodeGroup?.id === postcodeGroupId &&
        config.type ===
          RoutingConfigHelper.routingConfigType.CustomPostcodeGroupConfig,
    );
    samePostcodeGroupConfigs.forEach((config) => {
      config.days.forEach((day) => {
        days.add(day);
      });
    });
  }

  if (type === RoutingConfigHelper.routingConfigType.CustomHubConfig) {
    const sameHubConfigs = configsWithoutCurrent.filter(
      (config) =>
        config.hub?.id === hubId &&
        config.type === RoutingConfigHelper.routingConfigType.CustomHubConfig,
    );
    sameHubConfigs.forEach((config) => {
      config.days.forEach((day) => {
        days.add(day);
      });
    });
  }

  if (type === RoutingConfigHelper.routingConfigType.CustomCarrierConfig) {
    const sameCarrierConfigs = configsWithoutCurrent.filter(
      (config) =>
        config.type ===
        RoutingConfigHelper.routingConfigType.CustomCarrierConfig,
    );
    sameCarrierConfigs.forEach((config) => {
      config.days.forEach((day) => {
        days.add(day);
      });
    });
  }

  return days;
};

const getFormType = (routingConfig) => {
  if (
    routingConfig.type ===
    RoutingConfigHelper.routingConfigType.MainCarrierConfig
  ) {
    return null;
  }
  if (
    routingConfig.type ===
    RoutingConfigHelper.routingConfigType.CustomCarrierConfig
  ) {
    return FormType.CustomCarrierConfiguration;
  }

  return FormType.HubConfiguration;
};

const RoutingConfigurationForm = (props) => {
  const {
    allRoutingConfigs,
    editingRoutingConfig,
    isOpen,
    onClose: propsOnClose,
    organisationId,
  } = props;
  const { t } = useTranslation();
  const previousIsOpen = usePrevious(isOpen);

  const labelledRoutingConfigs = useMemo(
    () =>
      allRoutingConfigs.map((config) => {
        const type = RoutingConfigHelper.getRoutingConfigType(config);
        return {
          ...config,
          type,
        };
      }),
    [allRoutingConfigs],
  );

  const labelledEditingRoutingConfig = useMemo(
    () =>
      editingRoutingConfig
        ? {
            ...editingRoutingConfig,
            type: RoutingConfigHelper.getRoutingConfigType(
              editingRoutingConfig,
            ),
          }
        : undefined,
    [editingRoutingConfig],
  );

  const isEdit = !!labelledEditingRoutingConfig;

  const hasMainCarrierConfig = labelledRoutingConfigs.find(
    (config) =>
      config.type === RoutingConfigHelper.routingConfigType.MainCarrierConfig,
  );

  const defaultValues = useMemo(() => {
    if (labelledEditingRoutingConfig) {
      return {
        type: getFormType(labelledEditingRoutingConfig),
        serviceTime: String(labelledEditingRoutingConfig.serviceTime),
        minimizeNumberOfTours:
          labelledEditingRoutingConfig.minimizeNumberOfTours,
        maxDistancePerTourKm:
          labelledEditingRoutingConfig.maxDistancePerTourKm === 0
            ? ''
            : String(labelledEditingRoutingConfig.maxDistancePerTourKm),
        maxDistancePerTourKmNoLimit:
          labelledEditingRoutingConfig.maxDistancePerTourKm === 0,
        maxDeliveriesPerTour:
          labelledEditingRoutingConfig.maxDeliveriesPerTour === 0
            ? ''
            : String(labelledEditingRoutingConfig.maxDeliveriesPerTour),
        maxDeliveriesPerTourNoLimit:
          labelledEditingRoutingConfig.maxDeliveriesPerTour === 0,
        days: labelledEditingRoutingConfig.days,
        hubId: labelledEditingRoutingConfig.hub?.id || '',
        isMainHubConfig:
          labelledEditingRoutingConfig.hub?.id !== null &&
          labelledEditingRoutingConfig.days === null &&
          !labelledEditingRoutingConfig.postcodeGroup,
        isMainPostcodeGroupConfig:
          labelledEditingRoutingConfig.postcodeGroup?.id !== null &&
          labelledEditingRoutingConfig.days === null,
        applyToAllPostcodeGroups:
          labelledEditingRoutingConfig.postcodeGroup === null,
        postcodeGroupId: labelledEditingRoutingConfig.postcodeGroup?.id || '',
      };
    }

    return {
      serviceTime: '',
      minimizeNumberOfTours: false,
      maxDistancePerTourKm: '',
      maxDistancePerTourKmNoLimit: false,
      maxDeliveriesPerTour: '',
      maxDeliveriesPerTourNoLimit: false,
      days: [],
      isMainHubConfig: false,
      isMainPostcodeGroupConfig: false,
      type: hasMainCarrierConfig ? FormType.CustomCarrierConfiguration : null,
      hubId: '',
      applyToAllPostcodeGroups: true,
      postcodeGroupId: '',
    };
  }, [hasMainCarrierConfig, labelledEditingRoutingConfig]);

  const methods = useForm({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    control,
    formState: { isDirty },
    reset,
    resetField,
    watch,
  } = methods;

  useEffect(() => {
    if (isOpen && previousIsOpen !== isOpen) {
      reset(defaultValues);
    }
  }, [defaultValues, isOpen, previousIsOpen, reset]);

  const typeValue = watch('type');
  const daysValue = watch('days');
  const postcodeGroupIdValue = watch('postcodeGroupId');
  const hubIdValue = watch('hubId');
  const applyToAllPostcodeGroupsValue = watch('applyToAllPostcodeGroups');
  const minimizeNumberOfToursValue = watch('minimizeNumberOfTours');
  const isMainHubConfigValue = watch('isMainHubConfig');

  const currentRoutingConfigType = useMemo(() => {
    if (
      typeValue === FormType.HubConfiguration &&
      postcodeGroupIdValue &&
      daysValue !== null
    ) {
      return RoutingConfigHelper.routingConfigType.CustomPostcodeGroupConfig;
    }
    if (
      typeValue === FormType.HubConfiguration &&
      postcodeGroupIdValue &&
      daysValue === null
    ) {
      return RoutingConfigHelper.routingConfigType.MainPostcodeGroupConfig;
    }
    if (typeValue === FormType.HubConfiguration && daysValue !== null) {
      return RoutingConfigHelper.routingConfigType.CustomHubConfig;
    }
    if (typeValue === FormType.HubConfiguration && daysValue === null) {
      return RoutingConfigHelper.routingConfigType.MainHubConfig;
    }
    if (typeValue === FormType.CustomCarrierConfiguration) {
      return RoutingConfigHelper.routingConfigType.CustomCarrierConfig;
    }
    if (typeValue === null) {
      return RoutingConfigHelper.routingConfigType.MainCarrierConfig;
    }
    return undefined;
  }, [daysValue, postcodeGroupIdValue, typeValue]);

  const { toastSuccess } = useCustomToast();
  const onClose = () => {
    propsOnClose();
    reset();
  };

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (values) => {
      const body = {
        serviceTime: parseInt(values.serviceTime, 10),
        minimizeNumberOfTours: values.minimizeNumberOfTours,
        maxDistancePerTourKm: values.maxDistancePerTourKmNoLimit
          ? 0
          : parseInt(values.maxDistancePerTourKm, 10),
        maxDeliveriesPerTour: values.maxDeliveriesPerTourNoLimit
          ? 0
          : parseInt(values.maxDeliveriesPerTour, 10),
        days: values.days,
      };

      if (!values.postcodeGroupId && values.hubId) {
        body.hubId = values.hubId;
      }

      if (values.postcodeGroupId) {
        body.postcodeGroupId = values.postcodeGroupId;
      }

      const url = editingRoutingConfig?.id
        ? `/routing-configurations/${editingRoutingConfig?.id}`
        : '/routing-configurations';

      return fetch(url, {
        method: editingRoutingConfig?.id ? 'PATCH' : 'POST',
        body,
      });
    },
    {
      onError: (error) => toastFetchError(error),
      onSuccess: () => {
        onClose();

        if (
          currentRoutingConfigType ===
            RoutingConfigHelper.routingConfigType.CustomPostcodeGroupConfig ||
          currentRoutingConfigType ===
            RoutingConfigHelper.routingConfigType.MainPostcodeGroupConfig ||
          currentRoutingConfigType ===
            RoutingConfigHelper.routingConfigType.CustomHubConfig ||
          currentRoutingConfigType ===
            RoutingConfigHelper.routingConfigType.MainHubConfig
        ) {
          if (editingRoutingConfig?.id) {
            toastSuccess(t('Hub Routing Configuration updated.'));
          } else {
            toastSuccess(t('Hub Routing Configuration created.'));
          }
        } else if (editingRoutingConfig?.id) {
          toastSuccess(t('Carrier Routing Configuration updated.'));
        } else {
          toastSuccess(t('Carrier Routing Configuration created.'));
        }

        queryClient.invalidateQueries({
          queryKey: [`/carriers/${organisationId}/routing-configurations`],
        });
      },
    },
  );

  const shouldBlock = useMemo(() => {
    if (mutation.isSuccess) {
      return false;
    }
    return isDirty;
  }, [mutation.isSuccess, isDirty]);

  const blocker = useBlocker(shouldBlock);
  const [isPromptVisible, setIsPromptVisible] = useState(false);

  const modalOnClose = () => {
    if (isDirty) {
      setIsPromptVisible(true);
      return;
    }
    onClose();
  };

  const typeOptions = [
    {
      label: t('Custom Carrier Configuration'),
      value: FormType.CustomCarrierConfiguration,
      description: t(
        'Custom Carrier Configuration is a configuration defined at the Carrier Organisation level and is automatically assigned to all Hubs that do not have a Hub Configuration applied.',
      ),
    },
    {
      label: t('Hub Configuration'),
      value: FormType.HubConfiguration,
      description: t(
        'Hub Configuration applies only to selected Hubs and overrides the Main and Custom Carrier Configurations specifically for those Hubs.',
      ),
    },
  ];

  const disabledDaysSet = getDisabledDaysSetForConfig(
    {
      type: currentRoutingConfigType,
      postcodeGroupId: postcodeGroupIdValue,
      hubId: hubIdValue,
      routingConfigId: labelledEditingRoutingConfig?.id,
    },
    labelledRoutingConfigs,
  );

  const dayOptionDescription = useMemo(() => {
    if (
      currentRoutingConfigType ===
      RoutingConfigHelper.routingConfigType.CustomPostcodeGroupConfig
    ) {
      return t('Assigned to same postcode group');
    }
    if (
      currentRoutingConfigType ===
      RoutingConfigHelper.routingConfigType.CustomHubConfig
    ) {
      return t('Already assigned');
    }
    return t('Assigned to Custom Configuration');
  }, [currentRoutingConfigType, t]);

  const daysOptions = [
    {
      label: t('Monday'),
      value: 'Monday',
    },
    {
      label: t('Tuesday'),
      value: 'Tuesday',
    },
    {
      label: t('Wednesday'),
      value: 'Wednesday',
    },
    {
      label: t('Thursday'),
      value: 'Thursday',
    },
    {
      label: t('Friday'),
      value: 'Friday',
    },
    {
      label: t('Saturday'),
      value: 'Saturday',
    },
    {
      label: t('Sunday'),
      value: 'Sunday',
    },
  ].map(({ label, value }) => {
    const disabled = disabledDaysSet.has(value);
    return {
      label,
      value,
      disabled,
      description: disabled ? dayOptionDescription : '',
    };
  });

  const hasMainHubConfig = useMemo(
    () =>
      !!labelledRoutingConfigs.find(
        (config) => config.hub?.id === hubIdValue && config.days === null,
      ),
    [labelledRoutingConfigs, hubIdValue],
  );

  const hasMainPostcodeGroupConfig = useMemo(
    () =>
      !!labelledRoutingConfigs.find(
        (config) =>
          config.postcodeGroup?.id === postcodeGroupIdValue &&
          config.days === null,
      ),
    [labelledRoutingConfigs, postcodeGroupIdValue],
  );

  const modalTitle = useMemo(() => {
    if (
      !editingRoutingConfig &&
      currentRoutingConfigType ===
        RoutingConfigHelper.routingConfigType.MainCarrierConfig
    ) {
      return t('New Main Carrier Configuration');
    }
    if (!editingRoutingConfig) {
      return t('New Configuration');
    }

    if (
      currentRoutingConfigType ===
      RoutingConfigHelper.routingConfigType.CustomPostcodeGroupConfig
    ) {
      return t('Edit Postcode Group Custom Configuration');
    }
    if (
      currentRoutingConfigType ===
      RoutingConfigHelper.routingConfigType.MainPostcodeGroupConfig
    ) {
      return t('Edit Postcode Group Main Configuration');
    }
    if (
      currentRoutingConfigType ===
      RoutingConfigHelper.routingConfigType.CustomHubConfig
    ) {
      return t('Edit Hub Custom Configuration');
    }

    if (
      currentRoutingConfigType ===
      RoutingConfigHelper.routingConfigType.MainHubConfig
    ) {
      return t('Edit Hub Main Configuration');
    }

    if (
      currentRoutingConfigType ===
      RoutingConfigHelper.routingConfigType.CustomCarrierConfig
    ) {
      return t('Edit Custom Carrier Configuration');
    }

    return t('Edit Main Carrier Configuration');
  }, [currentRoutingConfigType, editingRoutingConfig, t]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        body={
          <FormProvider {...methods}>
            <div>
              {((typeValue !== null && editingRoutingConfig === undefined) ||
                (typeValue === FormType.HubConfiguration &&
                  editingRoutingConfig !== undefined)) && (
                <div
                  className={cn(
                    'flex flex-col mb-8',
                    !labelledEditingRoutingConfig && 'gap-4',
                  )}
                >
                  <div>
                    <h2 className="text-base font-semibold mb-5">
                      {t('Type')}
                    </h2>
                    {!labelledEditingRoutingConfig && (
                      <Controller
                        control={control}
                        name="type"
                        render={({ field: { onChange, ...rest } }) => (
                          <RadioGroup
                            divider
                            options={typeOptions}
                            onChange={(value) => {
                              reset({ ...defaultValues, type: value });
                            }}
                            {...rest}
                          />
                        )}
                      />
                    )}
                  </div>

                  {typeValue === FormType.HubConfiguration && (
                    <div className="flex flex-col gap-4">
                      <InfiniteScrollFormSelect
                        readOnly={!!labelledEditingRoutingConfig?.hub?.id}
                        url={`/carriers/${organisationId}/hubs`}
                        id="hub-select"
                        label={t('Hub')}
                        name="hubId"
                        placeholder={t('Select Hub')}
                        required
                        onChange={() => {
                          resetField('isMainHubConfig');
                          resetField(
                            'applyToAllPostcodeGroups',
                            defaultValues.applyToAllPostcodeGroups,
                          );
                          resetField('postcodeGroupId');
                          resetField('isMainPostcodeGroupConfig');
                        }}
                        transformOptionFn={(option) => ({
                          label: `${option.name} (${option.shortCode})`,
                          value: option.id,
                        })}
                      />

                      {hubIdValue &&
                        !postcodeGroupIdValue &&
                        (!hasMainHubConfig ||
                          labelledEditingRoutingConfig?.type ===
                            RoutingConfigHelper.routingConfigType
                              .MainHubConfig) && (
                          <div>
                            <FormCheckbox
                              label={t(
                                'Make this configuration Main for this Hub',
                              )}
                              name="isMainHubConfig"
                              readOnly={isEdit}
                              onChange={(checked) => {
                                if (checked) {
                                  methods.setValue('days', null, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                  });
                                  return;
                                }
                                resetField(
                                  'applyToAllPostcodeGroups',
                                  defaultValues.applyToAllPostcodeGroups,
                                );
                                resetField('postcodeGroupId');
                                resetField('isMainPostcodeGroupConfig');
                              }}
                            />

                            {!editingRoutingConfig && (
                              <div className="flex text-sm text-grey-700 items-center gap-2">
                                <Icon
                                  className="h-4 w-4 text-ui-info-blue"
                                  icon="infoFilled"
                                />
                                <p>
                                  {t(
                                    'Selecting this option makes this configuration the main one for the Hub. It will apply to all days and postcode groups but can be overridden with Hub Custom Configuration.',
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        )}

                      {hubIdValue && (
                        <>
                          <hr className="border-grey-200" />
                          <div>
                            <label
                              htmlFor="days"
                              className="mb-2 flex gap-1 text-sm font-normal text-primary-dark"
                            >
                              {t('Route by postcode groups')}
                            </label>

                            {!isMainHubConfigValue ? (
                              <>
                                {((isEdit &&
                                  currentRoutingConfigType ===
                                    RoutingConfigHelper.routingConfigType
                                      .MainHubConfig) ||
                                  currentRoutingConfigType ===
                                    RoutingConfigHelper.routingConfigType
                                      .CustomHubConfig ||
                                  !isEdit) && (
                                  <FormCheckbox
                                    readOnly={isEdit}
                                    name="applyToAllPostcodeGroups"
                                    onChange={() => {
                                      resetField('days');
                                      resetField('postcodeGroupId');
                                      resetField('isMainPostcodeGroupConfig');
                                    }}
                                    label={t('Apply to all groups')}
                                  />
                                )}

                                {((isEdit &&
                                  (currentRoutingConfigType ===
                                    RoutingConfigHelper.routingConfigType
                                      .MainPostcodeGroupConfig ||
                                    currentRoutingConfigType ===
                                      RoutingConfigHelper.routingConfigType
                                        .CustomPostcodeGroupConfig)) ||
                                  !isEdit) && (
                                  <div className="mt-2">
                                    <InfiniteScrollFormSelect
                                      disabled={applyToAllPostcodeGroupsValue}
                                      noOptionsMessage={t('No groups to show')}
                                      readOnly={isEdit}
                                      url={`/hubs/${hubIdValue}/postcode-groups`}
                                      id="postcode-groups"
                                      label={t('Postcode group')}
                                      name="postcodeGroupId"
                                      placeholder={t('Select group')}
                                      required={
                                        !applyToAllPostcodeGroupsValue
                                          ? t(
                                              'Select a group to which this configuration will apply or check “apply to all”.',
                                            )
                                          : false
                                      }
                                      onChange={() => {
                                        resetField('isMainPostcodeGroupConfig');
                                        resetField('days');
                                      }}
                                      transformOptionFn={(option) => ({
                                        label: option.name,
                                        value: option.id,
                                      })}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <span className="text-grey-700 text-sm">
                                {t('Automatically applied to all groups')}
                              </span>
                            )}
                          </div>
                          {((!hasMainPostcodeGroupConfig &&
                            !labelledEditingRoutingConfig &&
                            postcodeGroupIdValue) ||
                            (currentRoutingConfigType ===
                              RoutingConfigHelper.routingConfigType
                                .MainPostcodeGroupConfig &&
                              !!editingRoutingConfig)) && (
                            <div>
                              <FormCheckbox
                                label={t(
                                  'Make this configuration Main for this Postcode group',
                                )}
                                readOnly={isEdit}
                                name="isMainPostcodeGroupConfig"
                                onChange={(checked) => {
                                  if (checked) {
                                    methods.setValue('days', null, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                    return;
                                  }
                                  resetField('days');
                                }}
                              />

                              {!editingRoutingConfig && (
                                <div className="flex text-sm text-grey-700 items-center gap-2">
                                  <Icon
                                    className="h-4 w-4 text-ui-info-blue"
                                    icon="infoFilled"
                                  />
                                  <p>
                                    {t(
                                      'Selecting this option makes this configuration the main one for the Postcode group. It will apply to all days but can be overridden with Postcode Group Custom configuration.',
                                    )}
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
              <div>
                <h2 className="text-base font-semibold mb-5">{t('Setup')}</h2>
                <div className="flex flex-col gap-4">
                  <div>
                    <label
                      htmlFor="days"
                      className="mb-4 flex gap-1 text-sm font-normal text-primary-dark"
                    >
                      {t('Days active')}
                      {(currentRoutingConfigType ===
                        RoutingConfigHelper.routingConfigType
                          .CustomCarrierConfig ||
                        currentRoutingConfigType ===
                          RoutingConfigHelper.routingConfigType
                            .CustomHubConfig ||
                        currentRoutingConfigType ===
                          RoutingConfigHelper.routingConfigType
                            .CustomPostcodeGroupConfig) &&
                        '*'}
                    </label>
                    <p className="text-grey-700 text-sm mb-1">
                      {currentRoutingConfigType ===
                        RoutingConfigHelper.routingConfigType
                          .CustomCarrierConfig &&
                        t(
                          'Days that are not selected will continue to operate using Main Routing Configuration.',
                        )}
                      {currentRoutingConfigType ===
                        RoutingConfigHelper.routingConfigType.CustomHubConfig &&
                        t(
                          'Days that are not selected will continue to operate using the Main Carrier Routing Configuration or, if applicable, the Hub Main Configuration.',
                        )}
                      {currentRoutingConfigType ===
                        RoutingConfigHelper.routingConfigType
                          .CustomPostcodeGroupConfig &&
                        t(
                          'Days that are not selected will continue to operate using the Main Carrier Routing Configuration or, if applicable, the Hub Main or the Postcodes Group Main Configuration.',
                        )}
                    </p>
                    {currentRoutingConfigType ===
                      RoutingConfigHelper.routingConfigType
                        .CustomCarrierConfig ||
                    currentRoutingConfigType ===
                      RoutingConfigHelper.routingConfigType.CustomHubConfig ||
                    currentRoutingConfigType ===
                      RoutingConfigHelper.routingConfigType
                        .CustomPostcodeGroupConfig ? (
                      <FormCheckboxGroup
                        checkboxClassName="w-[160px] flex-shrink-1"
                        id="days"
                        name="days"
                        options={daysOptions}
                        required
                        validate={(val) =>
                          val?.length > 0 ||
                          t(
                            'Choose minimum one day to which this configuration will apply.',
                          )
                        }
                      />
                    ) : (
                      <span className="text-grey-700 text-sm">
                        {t('Automatically applied to all days')}
                      </span>
                    )}
                  </div>

                  <hr className="border-grey-200" />

                  <NumberNoLimitFormInput
                    label={t('Maximum kilometres per Tour')}
                    inlineLabel="km"
                    name="maxDistancePerTourKm"
                    id="maxDistancePerTourKm"
                    checkboxName="maxDistancePerTourKmNoLimit"
                    placeholder={t('Enter value')}
                    min={1}
                    max={999}
                  />

                  <hr className="border-grey-200" />

                  <NumberNoLimitFormInput
                    label={t('Maximum number of stops per Tour')}
                    inlineLabel={t('stops')}
                    name="maxDeliveriesPerTour"
                    id="maxDeliveriesPerTour"
                    checkboxName="maxDeliveriesPerTourNoLimit"
                    placeholder={t('Enter value')}
                    min={1}
                    max={999}
                  />

                  <hr className="border-grey-200" />

                  <div>
                    <label
                      htmlFor="serviceTime"
                      className="mb-2 flex gap-1 text-sm font-normal text-primary-dark"
                    >
                      {t('Service time duration')}*
                    </label>
                    <FormInput
                      inlineLabel={t('seconds')}
                      name="serviceTime"
                      id="serviceTime"
                      placeholder={t('Enter value')}
                      required
                      min={1}
                      max={7200}
                      type="number"
                    />
                  </div>

                  <hr className="border-grey-200" />

                  <div>
                    <div className="text-sm mb-4">
                      {t('Minimise number of Tours')}
                    </div>
                    <OnOffSwitch
                      checked={minimizeNumberOfToursValue}
                      onChange={(checked) => {
                        methods.setValue('minimizeNumberOfTours', checked, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormProvider>
        }
        onClose={modalOnClose}
        footer={
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Button
              className="order-last sm:order-none"
              data-test="modal-button-cancel"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={modalOnClose}
            />
            <Button
              data-test="modal-button-action"
              text={t('Save')}
              variant="solidBlue"
              onClick={methods.handleSubmit(mutation.mutate)}
              disabled={!methods.formState.isValid || mutation.isLoading}
            />
          </div>
        }
        header={
          <span className="flex items-center gap-2">
            <Icon
              className="h-6 w-6 text-grey-700"
              icon={editingRoutingConfig ? 'edit' : 'plus'}
            />
            <span>{modalTitle}</span>
          </span>
        }
      />
      <CancelPrompt
        title={
          editingRoutingConfig
            ? t('Cancel Editing Routing Configuration?')
            : t('Cancel Creating Routing Configuration?')
        }
        isOpen={isPromptVisible || blocker.state === 'blocked'}
        onClose={() => {
          setIsPromptVisible(false);
          if (blocker.state === 'blocked') {
            blocker.reset();
          }
        }}
        onExitClick={() => {
          setIsPromptVisible(false);
          onClose();
          if (blocker.state === 'blocked') {
            blocker.proceed();
          }
        }}
      />
    </>
  );
};

RoutingConfigurationForm.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  organisationId: PropTypes.string.isRequired,
  editingRoutingConfig: routingConfigurationPropType,
  allRoutingConfigs: PropTypes.arrayOf(routingConfigurationPropType),
};

RoutingConfigurationForm.defaultProps = {
  onClose: undefined,
  isOpen: false,
  editingRoutingConfig: undefined,
  allRoutingConfigs: [],
};

export default RoutingConfigurationForm;

import moment from 'moment/moment';

import UnitEventType from '../../../../enums/UnitEventType';
import EventLabel from '../EventLabel';
import eventType from '../eventType';
import AddressChangeEvent from './AddressChangeEvent';
import CanceledEvent from './CanceledEvent';
import DelayEvent from './DelayEvent';
import DeliveryConsentUpdateEvent from './DeliveryConsentUpdateEvent';
import DriverScanEvent from './DriverScanEvent';
import ExecutionSuccessEvent from './ExecutionSuccessEvent';
import FailedAttemptEvent from './FailedAttemptEvent';
import FixedEvent from './FixedEvent';
import FoundEvent from './FoundEvent';
import HubScanEvent from './HubScanEvent';
import ImportEvent from './ImportEvent';
import NoteEvent from './NoteEvent';
import PostponementEvent from './PostponementEvent';
import ReferenceUpdateEvent from './ReferenceUpdateEvent';
import RefusedEvent from './RefusedEvent';
import RoutingEvent from './RoutingEvent';
import StatusChangeEvent from './StatusChangeEvent';
import TimeSlotUpdateEvent from './TimeSlotUpdateEvent';
import WrongDriverScanEvent from './WrongDriverScanEvent';

const Event = (props) => {
  const { event, previousEventWithExecutionDate, previousEventWithStatus } =
    props;

  return (
    <div className="flex">
      <div className="flex flex-col w-full">
        <div className="flex flex-1 justify-between gap-4 items-center">
          <div className="flex items-center flex-1 overflow-hidden">
            <div className="w-[28px] flex-shrink-0">
              <div className="w-2.5 h-2.5 rounded-full bg-grey-900" />
            </div>
            <EventLabel event={event} />
          </div>
          <div className="text-grey-500 text-xs font-medium">
            {moment(event.timestamp).format('DD.MM.YYYY. - HH:mm')}
          </div>
        </div>
        <div className="flex mt-2">
          <div className="w-[28px]">
            <div className="relative w-[1px] left-1 -translate-x-1/2 h-full bg-grey-300" />
          </div>
          <div className="flex-1 flex flex-col gap-2 text-sm">
            {event.type === UnitEventType.Import && (
              <ImportEvent event={event} />
            )}
            {event.type === UnitEventType.StatusChange && (
              <StatusChangeEvent
                event={event}
                previousEventWithStatus={previousEventWithStatus}
              />
            )}
            {event.type === UnitEventType.Found && <FoundEvent event={event} />}
            {event.type === UnitEventType.Fixed && <FixedEvent event={event} />}
            {event.type === UnitEventType.Note && <NoteEvent event={event} />}
            {event.type === UnitEventType.ReferenceUpdate && (
              <ReferenceUpdateEvent event={event} />
            )}
            {(event.type === UnitEventType.TimeSlotChange ||
              event.type === UnitEventType.TrackingTimeSlotChange) && (
              <TimeSlotUpdateEvent
                event={event}
                previousEventWithExecutionDate={previousEventWithExecutionDate}
              />
            )}
            {event.type === UnitEventType.HubScan && (
              <HubScanEvent event={event} />
            )}
            {event.type === UnitEventType.Routing && (
              <RoutingEvent event={event} />
            )}
            {event.type === UnitEventType.DriverScan && (
              <DriverScanEvent event={event} />
            )}
            {event.type === UnitEventType.Delay && <DelayEvent event={event} />}
            {event.type === UnitEventType.Success && (
              <ExecutionSuccessEvent event={event} />
            )}
            {event.type === UnitEventType.Postponement && (
              <PostponementEvent event={event} />
            )}
            {event.type === UnitEventType.FailedAttempt && (
              <FailedAttemptEvent event={event} />
            )}
            {event.type === UnitEventType.Refused && (
              <RefusedEvent event={event} />
            )}
            {event.type === UnitEventType.Cancellation && (
              <CanceledEvent event={event} />
            )}
            {event.type === UnitEventType.WrongDriverScan && (
              <WrongDriverScanEvent event={event} />
            )}
            {event.type === UnitEventType.AddressChange && (
              <AddressChangeEvent event={event} />
            )}
            {event.type === UnitEventType.DeliveryConsentUpdate && (
              <DeliveryConsentUpdateEvent event={event} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Event.propTypes = {
  event: eventType,
  previousEventWithStatus: eventType,
  previousEventWithExecutionDate: eventType,
};

Event.defaultProps = {
  event: undefined,
  previousEventWithStatus: undefined,
  previousEventWithExecutionDate: undefined,
};

export default Event;

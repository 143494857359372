import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import useReturnReasonTranslations from '../../../hooks/useReturnReasonTranslations';
import CollapsedInfo from './CollapsedInfo';
import ExpandableStatusItem from './ExpandableStatusItem';
import ReturnReasons from './ReturnReasons';

const ReturnToClientStatusItem = (props) => {
  const { isCurrent, item } = props;
  const { watch } = useFormContext();
  const returnReason = watch('returnReason');
  const returnReasonTranslations = useReturnReasonTranslations();
  const text = returnReasonTranslations[returnReason];

  return (
    <ExpandableStatusItem
      isCurrent={isCurrent}
      item={item}
      collapsedChildren={<ReturnReasons />}
    >
      {text && <CollapsedInfo text={text} />}
    </ExpandableStatusItem>
  );
};

ReturnToClientStatusItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.string,
  }),
  isCurrent: PropTypes.bool,
};

ReturnToClientStatusItem.defaultProps = {
  item: undefined,
  isCurrent: false,
};

export default ReturnToClientStatusItem;

import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';

import HubType from '../enums/HubType';
import LocalStorage from '../enums/LocalStorage';
import ScanLabelSize from '../enums/ScanLabelSize';
import { useUser } from './UserProvider';

export const PackageScanningSettingsContext = createContext(undefined);

export const PackageScanningSettingsProvider = ({ children }) => {
  const { user } = useUser();
  const [isPrinterSetupModalOpen, setIsPrinterSetupModalOpen] = useState(false);
  const userHubType = useMemo(() => user?.hub?.type || [], [user?.hub?.type]);

  const defaultHubType = useMemo(() => {
    if (userHubType.includes(HubType.Central)) {
      return HubType.Central;
    }
    if (userHubType.includes(HubType.Local)) {
      return HubType.Local;
    }
    return undefined;
  }, [userHubType]);

  const [hubType, setHubType] = useLocalStorage(
    LocalStorage.ScanningHubType,
    defaultHubType,
  );

  const [isAutoPrintEnabled, setIsAutoPrintEnabled] = useLocalStorage(
    LocalStorage.ScanFeedAutoPrint,
    false,
  );

  const [labelSize, setLabelSize] = useLocalStorage(
    LocalStorage.ScanLabelSize,
    ScanLabelSize.Large,
  );

  const isCentral = hubType === HubType.Central;

  const value = useMemo(
    () => ({
      isAutoPrintEnabled,
      isCentral,
      labelSize,
      setIsAutoPrintEnabled,
      setLabelSize,
      setHubType,
      hubType,
      isPrinterSetupModalOpen,
      setIsPrinterSetupModalOpen,
    }),
    [
      isAutoPrintEnabled,
      isCentral,
      labelSize,
      setIsAutoPrintEnabled,
      setLabelSize,
      setHubType,
      hubType,
      isPrinterSetupModalOpen,
      setIsPrinterSetupModalOpen,
    ],
  );

  return (
    <PackageScanningSettingsContext.Provider value={value}>
      {children}
    </PackageScanningSettingsContext.Provider>
  );
};

PackageScanningSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const usePackageScanningSettings = () => {
  const context = useContext(PackageScanningSettingsContext);
  if (context === undefined) {
    throw new Error(
      'usePackageScanningSettings must be used within a PackageScanningSettingsProvider',
    );
  }
  return context;
};

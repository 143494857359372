import 'react-datepicker/dist/react-datepicker.css';
// import order is important. custom css must be loaded after react-datepicker styles
import './datePicker.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import de from 'date-fns/locale/de';
import moment from 'moment';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import IconButton from '../IconButton';
import DatePickerInput from './DatePickerInput';
import DatePickerVariant from './DatePickerVariant';

const CustomDatePicker = forwardRef((props, ref) => {
  const {
    dateFormat,
    disabled,
    endDate,
    error,
    isClearable,
    maxDate,
    minDate,
    minWidth,
    onCalendarClose,
    onChange,
    placeholderText,
    startDate,
    value,
    variant,
    ...rest
  } = props;

  const { i18n } = useTranslation();

  let localization;
  if (i18n.language === 'en') {
    localization = 'en';
  } else if (i18n.language === 'de') {
    localization = de;
  }

  // can't change value of input after startDate is selected to be for example 06.09.24 -> end time

  return (
    <div className={variant === DatePickerVariant.Filter ? 'w-fit' : 'w-full'}>
      <DatePicker
        disabled={disabled}
        enableTabLoop={false}
        ref={ref}
        customInput={
          <DatePickerInput
            active={!!value || (!!startDate && !!endDate)}
            variant={variant}
            error={error}
            isClearable={isClearable && !!(value || startDate || endDate)}
            minWidth={minWidth}
          />
        }
        dateFormat={dateFormat}
        disabledKeyboardNavigation
        endDate={endDate}
        locale={localization}
        minDate={minDate}
        maxDate={maxDate}
        calendarStartDay={1}
        placeholderText={placeholderText}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
          <div className="mb-0.5 flex items-center justify-between px-2 text-sm text-primary-dark">
            <IconButton
              aria-label="previous month"
              icon="chevronLeft"
              iconSize="xs"
              size="s"
              variant="ghostBlack"
              onClick={decreaseMonth}
            />
            <div className="font-medium">
              {moment(date).format('MMMM YYYY')}
            </div>
            <IconButton
              aria-label="next month"
              icon="chevronRight"
              iconSize="xs"
              size="s"
              variant="ghostBlack"
              onClick={increaseMonth}
            />
          </div>
        )}
        selected={value}
        startDate={startDate}
        strictParsing
        onCalendarClose={onCalendarClose}
        onChange={(val) => {
          onChange(val);
        }}
        {...rest}
      />
    </div>
  );
});

CustomDatePicker.propTypes = {
  dateFormat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  endDate: PropTypes.instanceOf(Date),
  error: PropTypes.bool,
  isClearable: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onCalendarClose: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
  selectsRange: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  variant: PropTypes.oneOf(Object.values(DatePickerVariant)),
  disabled: PropTypes.bool,
  minWidth: PropTypes.number,
};

CustomDatePicker.defaultProps = {
  dateFormat: '',
  endDate: undefined,
  error: undefined,
  isClearable: false,
  minDate: undefined,
  maxDate: undefined,
  onCalendarClose: () => {},
  placeholderText: '',
  selectsRange: false,
  startDate: undefined,
  value: undefined,
  variant: DatePickerVariant.Default,
  disabled: false,
  minWidth: undefined,
};

export default CustomDatePicker;

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Link } from 'react-router-dom';

import logo from '../../../assets/images/logo.svg';
import EnvLabel from '../../../components/EnvLabel';
import IconButton from '../../../components/IconButton';
import LogoutConfirmation from '../../../features/shared/LogoutConfirmation/LogoutConfirmation';
import useDropdown from '../../../hooks/useDropdown';
import useLogout from '../../../hooks/useLogout';
import { useUser } from '../../../providers/UserProvider';
import Menu from '../Menu';
import ProfileDropdown from './ProfileDropdown';

const variantMap = {
  white: 'bg-white border-b-[1px] border-grey-300',
  grey: 'bg-grey-100',
};

const Topbar = (props) => {
  const { user } = useUser();
  const { variant } = props;
  const logout = useLogout();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const { close, getRootProps, isOpen, toggle } = useDropdown();

  return (
    <>
      <LogoutConfirmation
        isOpen={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        onConfirm={() => logout()}
      />
      <div
        className={cn(
          'fixed left-0 right-0 top-0 z-20 flex flex-col',
          isOpen && 'shadow-elevation-300',
          variantMap[variant],
        )}
        id="topbar"
        {...getRootProps()}
      >
        <div className={cn('flex items-center justify-between gap-4 p-2')}>
          <IconButton
            icon={isOpen ? 'close' : 'menu'}
            variant="ghostBlack"
            onClick={toggle}
          />
          <Link className="flex items-center gap-2" to="/">
            <img alt="logo" height="32" src={logo} width="32" />
            <EnvLabel env={import.meta.env.VITE_ENV_LABEL} />
          </Link>
          <ProfileDropdown
            firstName={user?.firstName}
            lastName={user?.lastName}
            onLogout={() => setShowLogoutModal(true)}
          />
        </div>
        <AnimateHeight
          className="bg-inherit"
          duration={300}
          height={isOpen ? 'auto' : 0}
        >
          <div className="p-2">
            <Menu
              hideTextOnLg={false}
              collapsed={false}
              showUserItems={false}
              onItemClick={close}
            />
          </div>
        </AnimateHeight>
      </div>
    </>
  );
};

Topbar.propTypes = {
  variant: PropTypes.oneOf(['white', 'grey']),
};

Topbar.defaultProps = {
  variant: 'grey',
};

export default Topbar;

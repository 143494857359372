import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import TourStatus from '../../../enums/TourStatus';
import UserRole from '../../../enums/UserRole';
import DriverButton from '../../shared/DriverButton';
import UserPopover from '../../shared/UserPopover';
import TourAssignmentModal from '../TourAssignmentModal';

const TourCarrier = ({ tour, userRole }) => {
  const { t } = useTranslation();
  const [isTourAssignmentModalOpen, setIsTourAssignmentModalOpen] =
    useState(false);

  const { driver } = tour;

  const isEnabled =
    userRole === UserRole.Admin ||
    userRole === UserRole.OperationsManager ||
    userRole === UserRole.HubManager;

  const isAssignable =
    tour.status === TourStatus.Created ||
    tour.status === TourStatus.NotAssignedDriver;

  const isUpdatable = tour.status === TourStatus.Pending;

  const organisation = tour.subcarrier || tour.carrier;

  return (
    <>
      <TourAssignmentModal
        isOpen={isTourAssignmentModalOpen}
        tour={tour}
        onClose={() => setIsTourAssignmentModalOpen(false)}
      />
      <div className="flex flex-col gap-4">
        {organisation && (
          <div className="flex h-10 basis-auto items-center gap-2 rounded-md border border-grey-500 bg-grey-200 px-3">
            <Icon icon="domain" />
            <div
              className="text-sm font-medium text-grey-900 truncate"
              data-test="tour-carrier"
            >
              {organisation.shortCode}
              {organisation.name && <span> ({organisation.name})</span>}
            </div>
          </div>
        )}
        {driver && (
          <UserPopover title={t('Driver Contact')} user={driver}>
            <DriverButton driver={driver} size="m" />
          </UserPopover>
        )}
        {(isAssignable || isUpdatable) && (
          <Button
            className="w-fit"
            data-test="tour-carrier-assign-update"
            disabled={!isEnabled}
            icon={isAssignable ? 'plus' : 'replace'}
            text={isAssignable ? t('Assign') : t('Update')}
            variant="outlineRed"
            onClick={() => setIsTourAssignmentModalOpen(true)}
          />
        )}
      </div>
    </>
  );
};

TourCarrier.propTypes = {
  userRole: PropTypes.oneOf(Object.values(UserRole)).isRequired,
  tour: PropTypes.shape({
    status: PropTypes.oneOf(Object.values(TourStatus)),
    id: PropTypes.string,
    vehicle: PropTypes.string,
    date: PropTypes.string,
    shift: PropTypes.number,
    driver: PropTypes.shape({
      firstName: PropTypes.string,
      id: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
      subcarrier: PropTypes.shape({
        shortCode: PropTypes.string,
      }),
    }),
    carrier: PropTypes.shape({
      name: PropTypes.string,
      shortCode: PropTypes.string,
    }),
    subcarrier: PropTypes.shape({
      id: PropTypes.string,
      shortCode: PropTypes.string,
    }),
    hub: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default TourCarrier;

import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputLayout from '../InputLayout';
import MultiSelect from '../Select/MultiSelect';

const FormMultiSelect = (props) => {
  const {
    defaultValue,
    disabled,
    error,
    getLabelVariant,
    id,
    label,
    name,
    onChange: propOnChange,
    options,
    placeholder,
    readOnly,
    required,
    validate,
  } = props;

  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();

  const generateValidators = useCallback(() => {
    const validators = {};
    if (required) {
      validators.required = t('Please choose a value.');
    }
    if (validate) {
      validators.validate = validate;
    }
    return validators;
  }, [required, validate, t]);

  const errorInput = error || (errors[name] && errors[name].message);

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, ref, ...controllerProps } }) => (
        <InputLayout
          error={errorInput}
          id={id}
          label={label}
          required={required}
        >
          <MultiSelect
            disabled={disabled}
            error={errorInput}
            getLabelVariant={getLabelVariant}
            id={id}
            label={label}
            options={options}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required}
            onChange={(value) => {
              propOnChange(value);
              onChange(value);
            }}
            {...controllerProps}
          />
        </InputLayout>
      )}
      rules={generateValidators()}
    />
  );
};

FormMultiSelect.propTypes = {
  defaultValue: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  getLabelVariant: PropTypes.shape({}),
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  validate: PropTypes.func,
};

FormMultiSelect.defaultProps = {
  defaultValue: [],
  disabled: false,
  error: false,
  getLabelVariant: {},
  id: '',
  label: '',
  name: '',
  onChange: () => {},
  options: [],
  placeholder: '',
  readOnly: false,
  required: false,
  validate: undefined,
};

export default FormMultiSelect;

import { useTranslation } from 'react-i18next';

import ReturnReason from '../enums/ReturnReason';

const useReturnReasonTranslations = () => {
  const { t } = useTranslation();

  return {
    [ReturnReason.SecondAttemptFailed]: t('Second attempt failed'),
    [ReturnReason.Refused]: t('Refused by customer'),
    [ReturnReason.Cancelled]: t('Canceled'),
    [ReturnReason.Damaged]: t('Damaged'),
  };
};

export default useReturnReasonTranslations;

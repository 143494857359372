import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Button from '../../../components/Button';
import DebouncedSearch from '../../../components/DebouncedSearch';
import RoleSelect from '../../../components/RoleSelect';
import Breakpoint from '../../../enums/Breakpoint';
import UserRole from '../../../enums/UserRole';
import useWindowSize from '../../../hooks/useWindowSize';
import { useIsRole, useUser } from '../../../providers/UserProvider';
import HubSelect from '../../shared/HubSelect';
import SubcarrierSelect from '../../shared/SubcarrierSelect';

const MembersFilters = (props) => {
  const { organisation, searchOnly } = props;
  const isSuperadmin = useIsRole(UserRole.Superadmin);
  const { t } = useTranslation();
  const { user } = useUser();

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get('search')?.trim() || '';
  const hubIdQuery = searchParams.get('hubId') || '';
  const subcarrierIdQuery = searchParams.get('subcarrierId') || '';
  const roleQuery = searchParams.get('role') || '';

  const hasFiltersApplied = !!(
    searchQuery ||
    hubIdQuery ||
    roleQuery ||
    subcarrierIdQuery
  );

  return (
    <div className="flex w-full flex-col gap-4 lg:flex-row lg:flex-wrap lg:items-center">
      <div className="flex flex-1 flex-col gap-4 lg:flex-row lg:flex-wrap lg:items-center">
        {!searchOnly && (
          <>
            <SubcarrierSelect
              className="lg:w-[160px]"
              carrierName={organisation?.name}
              carrierId={isSuperadmin ? organisation?.id : user.carrier.id}
              value={subcarrierIdQuery}
              onChange={(subcarrierId) => {
                searchParams.set('page', '1');
                if (!subcarrierId) {
                  searchParams.delete('subcarrierId');
                } else {
                  searchParams.set('subcarrierId', subcarrierId);
                }
                setSearchParams(searchParams);
              }}
            />
            <HubSelect
              className="lg:w-[160px]"
              organisationId={isSuperadmin ? organisation?.id : undefined}
              placeholder={t('All Hubs')}
              value={hubIdQuery}
              onChange={(hubId) => {
                searchParams.set('page', '1');
                if (!hubId) {
                  searchParams.delete('hubId');
                } else {
                  searchParams.set('hubId', hubId);
                }
                setSearchParams(searchParams);
              }}
            />
            <RoleSelect
              className="lg:w-[160px]"
              value={roleQuery}
              onChange={(role) => {
                searchParams.set('page', '1');
                if (!role) {
                  searchParams.delete('role');
                } else {
                  searchParams.set('role', role);
                }
                setSearchParams(searchParams);
              }}
            />
          </>
        )}

        <DebouncedSearch
          placeholder={`${t('Find member')}...`}
          searchValue={searchQuery}
          onSearch={(newSearchValue) => {
            if (newSearchValue === searchQuery) {
              return;
            }
            searchParams.set('page', '1');
            if (!newSearchValue) {
              searchParams.delete('search');
            } else {
              searchParams.set('search', newSearchValue?.trim());
            }
            setSearchParams(searchParams);
          }}
        />
      </div>
      <Button
        disabled={!hasFiltersApplied}
        text={t('Reset')}
        variant={isDesktop ? 'ghostBlack' : 'outlineBlack'}
        onClick={() => setSearchParams(new URLSearchParams())}
      />
    </div>
  );
};

MembersFilters.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  searchOnly: PropTypes.bool,
};

MembersFilters.defaultProps = {
  organisation: undefined,
  searchOnly: false,
};

export default MembersFilters;

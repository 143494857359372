import PropTypes from 'prop-types';

const InfoBox = ({ children, dataTest, icon, text, title }) => (
  <div
    className="w-full rounded-md bg-white px-8 py-20 shadow-elevation-200"
    data-test={dataTest}
  >
    <div className="mb-6 flex justify-center">{icon}</div>
    <h1 className="mb-2 text-center font-medium text-grey-900">{title}</h1>
    <p className="text-center text-sm text-grey-700">{text}</p>
    {children && <div className="mt-8 flex justify-center">{children}</div>}
  </div>
);

InfoBox.propTypes = {
  children: PropTypes.node,
  dataTest: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object]),
  text: PropTypes.string,
  title: PropTypes.string,
};

InfoBox.defaultProps = {
  children: undefined,
  dataTest: undefined,
  icon: undefined,
  text: undefined,
  title: undefined,
};

export default InfoBox;

import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import {
  // eslint-disable-next-line camelcase
  UNSAFE_DataRouterContext,
  useBeforeUnload,
  useLocation,
} from 'react-router-dom';

const DIRECTION = {
  vertical: 'scrollTop',
  horizontal: 'scrollLeft',
};

// inspired by https://github.com/epicweb-dev/restore-scroll

const ElementScrollRestoration = ({ elementQuery, horizontal, vertical }) => {
  const memoizedDirections = useMemo(() => {
    const directions = [];
    if (horizontal) directions.push('vertical');
    if (vertical) directions.push('horizontal');
    return directions;
  }, [horizontal, vertical]);

  const scrollRestoreConfigs = useMemo(
    () =>
      memoizedDirections.map((direction) => {
        const STORAGE_KEY = `position:${elementQuery}:${direction}`;
        return {
          storageKey: STORAGE_KEY,
          scrollAttribute: DIRECTION[direction],
        };
      }),
    [memoizedDirections, elementQuery],
  );

  const location = useLocation();
  const routerCtx = useContext(UNSAFE_DataRouterContext);
  const unsubscribe = useRef(null);

  const removePositions = useCallback(
    (locationKey) => {
      scrollRestoreConfigs.forEach(({ storageKey }) => {
        try {
          let positions = {};
          const rawPositions = JSON.parse(
            sessionStorage.getItem(storageKey) || '{}',
          );
          if (typeof rawPositions === 'object' && rawPositions !== null) {
            positions = rawPositions;
          }
          const newPositions = {
            ...positions,
          };

          delete newPositions[locationKey];

          sessionStorage.setItem(storageKey, JSON.stringify(newPositions));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(
            `Error deleting scroll positions for ${storageKey} from sessionStorage:`,
            error,
          );
        }
      });
    },
    [scrollRestoreConfigs],
  );

  const updatePositions = useCallback(
    (locationKey) => {
      const element = document.querySelector(elementQuery);
      if (!element) return;

      scrollRestoreConfigs.forEach(({ scrollAttribute, storageKey }) => {
        let positions = {};

        try {
          const rawPositions = JSON.parse(
            sessionStorage.getItem(storageKey) || '{}',
          );
          if (typeof rawPositions === 'object' && rawPositions !== null) {
            positions = rawPositions;
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(
            `Error parsing scroll positions from sessionStorage:`,
            error,
          );
        }

        const newPositions = {
          ...positions,
          [locationKey]: element[scrollAttribute],
        };
        sessionStorage.setItem(storageKey, JSON.stringify(newPositions));
      });
    },
    [elementQuery, scrollRestoreConfigs],
  );

  useEffect(() => {
    if (unsubscribe.current) {
      unsubscribe.current();
      unsubscribe.current = null;
    }

    unsubscribe.current = routerCtx?.router.subscribe((state) => {
      // ** Essentially a onBeforeRouteChange event ***
      // we update positions based on the current route, which is saved in location.key
      // but, in the router subscribe callback, the route is not yet updated so we can safely save scroll position
      if (state.historyAction === 'REPLACE') {
        // if we replace the current entry, we need to update positions based on the upcoming location
        updatePositions(state.location.key);
        // remove old positions because we replace the entry, no need to keep them
        removePositions(location.key);
      } else {
        updatePositions(location.key);
      }
    });

    return () => {
      if (unsubscribe.current) {
        unsubscribe.current();
        unsubscribe.current = null;
      }
    };
  }, [location, removePositions, routerCtx?.router, updatePositions]);

  useBeforeUnload(() => {
    updatePositions(location.key);
  });

  function restoreScroll(
    storageKey,
    currentElementQuery,
    currentScrollAttribute,
  ) {
    const element = document.querySelector(currentElementQuery);
    if (!element) {
      // eslint-disable-next-line no-console
      console.warn(
        `Element not found: ${currentElementQuery}. Cannot restore scroll.`,
      );
      return;
    }
    if (!window.history.state || !window.history.state.key) {
      const key = Math.random().toString(32).slice(2);
      window.history.replaceState({ key }, '');
    }
    try {
      const positions = JSON.parse(sessionStorage.getItem(storageKey) || '{}');
      const stored = positions[window.history.state.key];
      if (typeof stored === 'number') {
        element[currentScrollAttribute] = stored;
      } else if (currentScrollAttribute === 'scrollTop') {
        element[currentScrollAttribute] = 0;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      sessionStorage.removeItem(storageKey);
    }
  }

  useEffect(() => {
    if (location.key) {
      scrollRestoreConfigs.forEach(({ scrollAttribute, storageKey }) => {
        restoreScroll(storageKey, elementQuery, scrollAttribute);
      });
    }
  }, [elementQuery, location, location.key, scrollRestoreConfigs]);
};

ElementScrollRestoration.propTypes = {
  elementQuery: PropTypes.string,
  horizontal: PropTypes.bool,
  vertical: PropTypes.bool,
};

ElementScrollRestoration.defaultProps = {};

export default ElementScrollRestoration;

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { usePrevious } from 'react-use';

import UserRole from '../enums/UserRole';
import Workflow from '../enums/Workflow';
import useCurrentPath from '../hooks/useCurrentPath';
import { usePosthog } from '../providers/PosthogProvider';
import { useUser } from '../providers/UserProvider';

const PrivateRoute = (props) => {
  const { allowSubcarrier, allowedWorkflows, children, roles } = props;
  const { isSubcarrierUser, user } = useUser();

  const currentPath = useCurrentPath();
  const previousPath = usePrevious(currentPath);

  const isRoleAllowed = roles.some((role) => user.role === role);
  const isUserAllowed = isSubcarrierUser
    ? isRoleAllowed && allowSubcarrier
    : isRoleAllowed;
  const isAllowedWorkflow = allowedWorkflows.includes(user.carrier?.workflow);
  const isSuperadmin = user.role === UserRole.Superadmin;
  const isAllowedWorkflowOrSuperadmin = isAllowedWorkflow || isSuperadmin;

  const { capture } = usePosthog();

  useEffect(() => {
    if (
      isUserAllowed &&
      isAllowedWorkflowOrSuperadmin &&
      currentPath !== previousPath
    ) {
      capture('$pageview', {
        path: currentPath,
      });
    }
  }, [
    currentPath,
    isAllowedWorkflowOrSuperadmin,
    isUserAllowed,
    capture,
    previousPath,
  ]);

  if (!isUserAllowed || !isAllowedWorkflowOrSuperadmin) {
    return <Navigate replace to="/" />;
  }

  return children || <Outlet />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(UserRole))),
  allowSubcarrier: PropTypes.bool,
  allowedWorkflows: PropTypes.arrayOf(PropTypes.oneOf(Object.values(Workflow))),
};

PrivateRoute.defaultProps = {
  children: undefined,
  roles: [],
  allowSubcarrier: true,
  allowedWorkflows: [],
};

export default PrivateRoute;

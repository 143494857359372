import { useTranslation } from 'react-i18next';

const useRoutingDaysTranslations = () => {
  const { t } = useTranslation();

  return {
    Mon: t('mondayShort'),
    Tue: t('tuesdayShort'),
    Wed: t('wednesdayShort'),
    Thu: t('thursdayShort'),
    Fri: t('fridayShort'),
    Sat: t('saturdayShort'),
    Sun: t('sundayShort'),
  };
};

export default useRoutingDaysTranslations;

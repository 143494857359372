import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '../Checkbox';
import InputLayout from '../InputLayout';

const FormCheckbox = (props) => {
  const {
    disabled,
    error,
    id,
    label,
    name,
    onChange,
    readOnly,
    required,
    tooltip,
    validate,
    ...rest
  } = props;

  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const rules = useCallback(() => {
    const validators = {};

    if (required) {
      validators.required = t('Please choose a value.');
    }

    return validators;
  }, [required, t]);

  const errorInput = error || (errors[name] && errors[name].message);

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: {
          // intentionally ignoring to prevent render error
          disabled: ignoredDisabled,
          onChange: controllerOnChange,
          ref,
          value,
          ...controllerProps
        },
      }) => (
        <InputLayout error={errorInput} id={id} required={required}>
          <Checkbox
            tooltip={tooltip}
            checked={value}
            label={label}
            name={name}
            ref={ref}
            onChange={(e) => {
              controllerOnChange(e.target.checked);
              if (onChange) {
                onChange(e.target.checked);
              }
            }}
            readOnly={readOnly}
            disabled={disabled === true || undefined}
            {...controllerProps}
            {...rest}
          />
        </InputLayout>
      )}
      rules={rules()}
    />
  );
};

FormCheckbox.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

FormCheckbox.defaultProps = {
  disabled: false,
  readOnly: false,
  error: undefined,
  id: '',
  label: '',
  name: '',
  required: false,
  validate: null,
  onChange: null,
  tooltip: '',
};

export default FormCheckbox;

import PropTypes from 'prop-types';

const NoteField = ({ note }) => (
  <div className="border py-2.5 px-3 rounded-md bg-grey-100 border-grey-300 text-grey-900">
    {note}
  </div>
);

NoteField.propTypes = {
  note: PropTypes.string.isRequired,
};

export default NoteField;

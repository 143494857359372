/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import IconButton from '../../../components/IconButton';
import NewTable from '../../../components/NewTable';
import Tooltip from '../../../components/Tooltip';
import OrganisationBadge from '../../shared/OrganisationBadge';

const OrganisationsTable = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const columns = useMemo(
    () => [
      {
        size: 250,
        header: t('Name'),
        accessorKey: 'name',
        cell: ({ getValue }) => getValue(),
      },
      {
        size: 150,
        header: t('Type'),
        accessorKey: 'type',
        cell: ({ getValue }) => (
          <OrganisationBadge organisationType={getValue()} />
        ),
      },
      {
        size: 60,
        header: t('Members'),
        accessorKey: 'userCount',
        cell: (info) => info.getValue(),
      },
      {
        size: 350,
        header: t('Address'),
        accessorKey: 'address',
        cell: (info) => info.getValue(),
      },
      {
        header: t('Actions'),
        cell: (info) => (
          <Tooltip placement="top" size="sm" text={t('Member Details')}>
            <IconButton
              as={Link}
              icon="pageView"
              state={{ backLink: location.pathname + location.search }}
              to={`/organisations/${info.row.original.id}`}
              variant="ghostBlack"
            />
          </Tooltip>
        ),
        size: 80,
        enableResizing: false,
      },
    ],
    [location.pathname, location.search, t],
  );

  return <NewTable columns={columns} {...props} />;
};

OrganisationsTable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

OrganisationsTable.defaultProps = {
  children: undefined,
  className: '',
};

export default OrganisationsTable;

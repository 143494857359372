import { useTranslation } from 'react-i18next';

import useReturnReasonTranslations from '../../../../hooks/useReturnReasonTranslations';
import eventType from '../eventType';

const ReturnReasonField = (props) => {
  const { event } = props;
  const { t } = useTranslation();

  const returnReasonTranslations = useReturnReasonTranslations();

  return (
    <div>
      <span>{t('Return reason')}: </span>
      <span className="font-medium">
        {returnReasonTranslations[event.returnReason]}{' '}
        {event.note && `(${event.note})`}
      </span>
    </div>
  );
};

ReturnReasonField.propTypes = {
  event: eventType.isRequired,
};

export default ReturnReasonField;

import PropTypes from 'prop-types';
import { useEffect } from 'react';

const Overlay = (props) => {
  const { onClick } = props;
  useEffect(() => {
    // no need for padding right because we dont have scrollbars on mobile devices
    document.body.style.overflow = 'hidden';
    return () => document.body.removeAttribute('style');
  }, []);

  return (
    <div
      className="visible fixed inset-0 bottom-0 left-0 right-0 top-0 z-[-1] bg-primary-dark opacity-40"
      onClick={onClick}
    />
  );
};

Overlay.propTypes = {
  onClick: PropTypes.func,
};

Overlay.defaultProps = {
  onClick: () => {},
};

export default Overlay;

const DeliveryTaskUnitStatus = {
  Created: 'created',
  NotArrived: 'notArrived',
  InClarification: 'inClarification',
  Cancelled: 'cancelled',
  Arrived: 'arrived',
  Damaged: 'damaged',
  Lost: 'lost',
  Missorted: 'missorted',
  Misplaced: 'misplaced',
  ArrivedLocal: 'arrivedLocal',
  Prepared: 'prepared',
  InDelivery: 'inDelivery',
  Postponed: 'postponed',
  Retry: 'retry',
  Delivered: 'delivered',
  Stored: 'stored',
  StoredLocal: 'storedLocal',
  ReturnToClient: 'returnToClient',
  ShippedToClient: 'shippedToClient',
};

export default DeliveryTaskUnitStatus;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';

const ManualScanning = ({ isEnabled, tourId }) => {
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const mutation = useMutation(
    async () => {
      const response = await fetch(`/tours/${tourId}/enable-manual-scanning`, {
        method: 'PATCH',
        body: {
          manualScanning: true,
        },
      });
      return response.json();
    },
    {
      onError: (error) => toastFetchError(error),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`/tours/${tourId}`],
        });
        setIsModalOpen(false);
      },
    },
  );

  if (isEnabled) {
    return (
      <Alert
        data-test="manual-parcel-scanning-alert"
        message={t('Manual parcel scanning was enabled.')}
        variant="warningOrange"
      />
    );
  }

  return (
    <>
      <Modal
        body={
          <p>
            {t(
              'Enabling this will allow Driver to manually change parcels to scanned status. This action cannot be reversed.',
            )}
          </p>
        }
        footer={
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Button
              className="order-last sm:order-none"
              data-test="modal-button-cancel"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => setIsModalOpen(false)}
            />
            <Button
              data-test="modal-button-action"
              disabled={mutation.isLoading}
              text={t('Enable')}
              variant="solidBlue"
              onClick={mutation.mutate}
            />
          </div>
        }
        header={
          <div className="flex items-center gap-2">
            <Icon className="h-6 w-6 text-grey-700" icon="codeScan" />
            <span>{t('Enable Manual Parcel Scanning?')}</span>
          </div>
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <div className="flex flex-row items-center justify-between">
        <div className="text-sm font-medium">{t('Manual parcel scanning')}</div>
        <Button
          data-test="enable-parcel-scanning"
          text={t('Enable')}
          variant="solidBlue"
          onClick={() => setIsModalOpen(true)}
        />
      </div>
    </>
  );
};

ManualScanning.propTypes = {
  isEnabled: PropTypes.bool,
  tourId: PropTypes.string.isRequired,
};

ManualScanning.defaultProps = {
  isEnabled: false,
};

export default ManualScanning;

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CancelPrompt from '../../../components/CancelPrompt';
import UserRole from '../../../enums/UserRole';
import ObjectHelper from '../../../helpers/ObjectHelper';
import useBlocker from '../../../hooks/useBlocker';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useUser } from '../../../providers/UserProvider';
import MembersForm from '../../members/MembersForm';

const CreateClientMemberForm = (props) => {
  const { onCancel, onSuccess, organisationId, organisationName } = props;

  const { toastSuccess } = useCustomToast();
  const { fetch } = useFetch();
  const { t } = useTranslation();
  const { user: loggedInUser } = useUser();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { toastFetchError } = useToastFetchError();

  const mutation = useMutation(
    (values) => {
      const body = {
        clientId: values.organisationId,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: `+${values.phoneNumber}`,
        role: UserRole.Customer,
      };
      return fetch('/users/invite', {
        body: ObjectHelper.removeNullableFields(body),
        method: 'POST',
      });
    },
    {
      onError: (error) => {
        toastFetchError(error);
      },
      onSuccess: () => {
        setIsFormDirty(false);
        toastSuccess(t('Invitation sent.'));
        onSuccess();
      },
    },
  );

  const shouldBlock = useMemo(() => {
    if (mutation.data?.success === true) {
      return false;
    }
    return isFormDirty;
  }, [mutation.data?.success, isFormDirty]);

  const blocker = useBlocker(shouldBlock);

  return (
    <>
      <CancelPrompt
        title={t('Cancel Creating Member?')}
        isOpen={blocker.state === 'blocked'}
        onClose={() => {
          blocker.reset();
        }}
        onExitClick={() => {
          blocker.proceed();
        }}
      />
      <MembersForm
        errors={mutation.error?.data?.errors}
        isCustomer
        isSubmitting={mutation.isLoading}
        loggedInUserRole={loggedInUser.role}
        organisationId={organisationId}
        organisationName={organisationName}
        onCancel={onCancel}
        onIsDirtyChange={setIsFormDirty}
        onSubmit={mutation.mutate}
      />
    </>
  );
};

CreateClientMemberForm.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  organisationId: PropTypes.string,
  organisationName: PropTypes.string,
};

CreateClientMemberForm.defaultProps = {
  onCancel: () => {},
  onSuccess: () => {},
  organisationId: '',
  organisationName: '',
};

export default CreateClientMemberForm;

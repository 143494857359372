import { useEffect, useRef, useState } from 'react';

import CSSTransitionStage from '../enums/CSSTransitionStage';

// used for updating the stage so the element that is rendered can start transition to other state
const stageUpdateDelayTimeMs = 30;

/**
 *
 * @param isTransitionActive -> boolean indicating whether the transition should occur. If false, the stage is initial, which is used for default UI state, before the transition to final visual state.
 * @param timeout -> duration of the CSS transition that you are tracking, in milliseconds. e.g. for component duration-300 use value 300
 */
const useCSSTransition = (isTransitionActive, timeout) => {
  const [stage, setStage] = useState(
    isTransitionActive ? CSSTransitionStage.From : CSSTransitionStage.To,
  );

  const timer = useRef();
  const stageChangeTimeoutRef = useRef();

  const [shouldMount, setShouldMount] = useState(isTransitionActive);

  useEffect(() => {
    if (isTransitionActive) {
      setStage(CSSTransitionStage.From);
      setShouldMount(true);
      stageChangeTimeoutRef.current = setTimeout(
        () => setStage(CSSTransitionStage.To),
        stageUpdateDelayTimeMs,
      );
    } else {
      setStage(CSSTransitionStage.From);
      timer.current = setTimeout(() => setShouldMount(false), timeout);
    }
    const localTimerRef = timer.current;
    const localRequestAnimationFrameRef = stageChangeTimeoutRef.current;

    return () => {
      cancelAnimationFrame(localRequestAnimationFrameRef);
      clearTimeout(localTimerRef);
    };
  }, [isTransitionActive, timeout]);

  return { shouldMount, stage };
};

export default useCSSTransition;

import PropTypes from 'prop-types';

import CardListContainer from '../../../components/CardListContainer';
import HubCard from '../HubCard';

const HubsCardList = (props) => {
  const { data, isInitialLoading, isLoading } = props;

  return (
    <CardListContainer
      isLoading={isLoading}
      isInitialLoading={isInitialLoading}
      isEmpty={data.length === 0}
    >
      {data.map((hub) => (
        <HubCard
          address={hub.address}
          client={hub.client}
          id={hub.id}
          key={hub.id}
          name={hub.name}
          postCodes={hub.postCodes}
          shortCode={hub.shortCode}
        />
      ))}
    </CardListContainer>
  );
};

HubsCardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
};

HubsCardList.defaultProps = {
  data: [],
  isLoading: false,
  isInitialLoading: false,
};

export default HubsCardList;

import PropTypes from 'prop-types';

import CardListContainer from '../../../components/CardListContainer';
import MemberCard from '../../members/MemberCard';

const MembersCardList = (props) => {
  const { data, isInitialLoading, isLoading } = props;

  return (
    <CardListContainer
      isLoading={isLoading}
      isInitialLoading={isInitialLoading}
      isEmpty={data.length === 0}
    >
      {data.map((member) => (
        <MemberCard
          email={member.email}
          id={member.id}
          key={member.id}
          name={`${member.firstName} ${member.lastName}`}
          organisationType={member?.organisation?.type}
          rating={member.rating}
          role={member.role}
          status={member.status}
          subcarrier={member.subcarrier?.shortCode}
          hubName={member?.hub?.name}
        />
      ))}
    </CardListContainer>
  );
};

MembersCardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
};

MembersCardList.defaultProps = {
  data: undefined,
  isLoading: false,
  isInitialLoading: false,
};

export default MembersCardList;

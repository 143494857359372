function parseSearchParams(searchParams) {
  const output = {};

  // Set will return only unique keys()
  new Set([...searchParams.keys()]).forEach((key) => {
    output[key] =
      searchParams.getAll(key).length > 1
        ? searchParams.getAll(key) // get multiple values
        : searchParams.get(key); // get single value
  });

  return output;
}
export default parseSearchParams;

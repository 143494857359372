// Inspired by https://usehooks.com/useLocalStorage/
import { useCallback, useState } from 'react';

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value) => {
      try {
        // Save to local storage first
        window.localStorage.setItem(key, JSON.stringify(value));
        // Save state
        setStoredValue(value);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    [key],
  );

  const removeValue = useCallback(() => {
    setStoredValue(undefined);
    window.localStorage.removeItem(key);
  }, [key]);

  return [storedValue, setValue, removeValue];
}

export default useLocalStorage;

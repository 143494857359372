import cn from 'classnames';
import PropTypes from 'prop-types';

import PageContent from './PageContent';

const Page = (props) => {
  const { children, className } = props;

  return (
    <div className={cn('relative flex flex-1 flex-col', className)}>
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Page.defaultProps = {
  children: undefined,
  className: undefined,
};

Page.Content = PageContent;

export default Page;

import PropTypes from 'prop-types';

const OrganisationDetailsSettingsCard = ({ children, text, title }) => (
  <div className="flex flex-col justify-between border-b border-grey-200 py-4 last:border-b-0 last:pb-0 md:flex-row md:items-center">
    <div className="mb-4 flex flex-col md:mb-0 md:pr-8">
      <span className="mb-1 text-sm font-medium">{title}</span>
      <span className="text-sm text-grey-700">{text}</span>
    </div>
    <div className="w-full md:w-auto">{children}</div>
  </div>
);

OrganisationDetailsSettingsCard.propTypes = {
  children: PropTypes.node,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
};

OrganisationDetailsSettingsCard.defaultProps = {
  children: undefined,
  text: undefined,
  title: undefined,
};

export default OrganisationDetailsSettingsCard;

import { useEffect, useRef } from 'react';

const useEvent = (name, callback, element = window) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = callback;
  }, [callback]);

  useEffect(() => {
    const listener = (event) => savedHandler.current(event);
    if (!element) {
      return () => {};
    }
    element.addEventListener(name, listener, true);
    return () => element.removeEventListener(name, listener, true);
  }, [name, element]);
};

export default useEvent;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Select from '../../../components/Select';
import SelectVariant from '../../../components/Select/SelectVariant';
import TaskType from '../../../enums/TaskType';
import useTaskTypeTranslations from '../../../hooks/useTaskTypeTranslations';

const TasksTypeSelect = (props) => {
  const { className, onChange, value, variant, ...rest } = props;
  const { t } = useTranslation();
  const typeTranslations = useTaskTypeTranslations();

  const taskTypeOptions = [
    {
      label: typeTranslations[TaskType.Delivery],
      value: TaskType.Delivery,
    },
    {
      label: typeTranslations[TaskType.Pickup],
      value: TaskType.Pickup,
    },
  ];

  return (
    <Select
      variant={variant}
      className={className}
      data-test="task-type-select"
      id="task-type-select"
      options={taskTypeOptions}
      placeholder={t('Task type')}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

TasksTypeSelect.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(SelectVariant)),
};

TasksTypeSelect.defaultProps = {
  className: undefined,
  value: undefined,
  onChange: undefined,
  variant: SelectVariant.Default,
};

export default TasksTypeSelect;

import PropTypes from 'prop-types';

import ListChoice from '../../../components/ListChoice';

const SubcarrierItem = ({ isSelected, onItemClick, subcarrier }) => (
  <ListChoice
    isSelected={isSelected}
    key={subcarrier.id}
    onClick={() => onItemClick(subcarrier)}
  >
    <div className="break-words text-sm font-medium">
      {subcarrier.shortCode} ({subcarrier.name})
    </div>
  </ListChoice>
);

SubcarrierItem.propTypes = {
  isSelected: PropTypes.bool,
  onItemClick: PropTypes.func,
  subcarrier: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    shortCode: PropTypes.string,
  }),
};

SubcarrierItem.defaultProps = {
  isSelected: false,
  onItemClick: () => {},
  subcarrier: undefined,
};

export default SubcarrierItem;

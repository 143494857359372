const getFullUrl = (endpoint, params) => {
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach((param) => {
    if (Array.isArray(params[param])) {
      params[param].forEach((arrayParam) =>
        searchParams.append(param, arrayParam),
      );
    } else {
      searchParams.append(param, params[param]);
    }
  });
  const searchParamsString = searchParams.toString();

  return searchParamsString ? `${endpoint}?${searchParamsString}` : endpoint;
};

export default getFullUrl;

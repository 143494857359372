import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import CancelPrompt from '../../../components/CancelPrompt';
import FormInput from '../../../components/FormInput';
import FormMultiSelect from '../../../components/FormMultiSelect';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import useBlocker from '../../../hooks/useBlocker';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import postcodeGroupPropType from '../../../prop-types/postcodeGroupPropType';

const PostcodeGroupModal = (props) => {
  const {
    allPostcodeGroups,
    allPostcodes,
    editingPostcodeGroup,
    hubId,
    isOpen,
    onClose: propOnClose,
  } = props;
  const { t } = useTranslation();
  const title = editingPostcodeGroup
    ? t('Edit Postcode Group')
    : t('Create Postcode Group');

  const isEdit = !!editingPostcodeGroup;

  const availablePostcodes = allPostcodes.filter(
    (postcode) =>
      editingPostcodeGroup?.postcodes.includes(postcode) ||
      !allPostcodeGroups.find((group) => group.postcodes.includes(postcode)),
  );

  const defaultValues = useMemo(
    () => ({
      name: editingPostcodeGroup?.name || '',
      postcodes: editingPostcodeGroup?.postcodes || [],
    }),
    [editingPostcodeGroup?.name, editingPostcodeGroup?.postcodes],
  );

  const methods = useForm({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    formState: { isDirty },
    reset,
  } = methods;

  const onClose = () => {
    propOnClose();
    reset();
  };

  const { fetch } = useFetch();
  const { toastSuccess } = useCustomToast();
  const { toastFetchError } = useToastFetchError();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (values) => {
      const url = isEdit
        ? `/postcode-groups/${editingPostcodeGroup?.id}`
        : '/postcode-groups';
      const response = await fetch(url, {
        method: isEdit ? 'PATCH' : 'POST',
        body: {
          hubId,
          name: values.name,
          postcodes: values.postcodes,
        },
      });

      return response.json();
    },
    {
      onError: (error) => toastFetchError(error),
      onSuccess: (response) => {
        onClose();
        if (response?.success) {
          queryClient.invalidateQueries({
            queryKey: [`/hubs/${hubId}/postcode-groups`],
          });

          if (isEdit) {
            toastSuccess(
              t('Postcode group {{groupName}} updated.', {
                groupName: response.data.name,
              }),
            );
          } else {
            toastSuccess(
              t('Postcode group {{groupName}} created.', {
                groupName: response.data.name,
              }),
            );
          }
        }
      },
    },
  );

  const shouldBlock = useMemo(() => {
    if (mutation.isSuccess) {
      return false;
    }
    return isDirty;
  }, [mutation.isSuccess, isDirty]);

  const blocker = useBlocker(shouldBlock);
  const [isPromptVisible, setIsPromptVisible] = useState(false);

  const modalOnClose = () => {
    if (isDirty) {
      setIsPromptVisible(true);
      return;
    }
    onClose();
  };

  return (
    <>
      <Modal
        onClose={modalOnClose}
        onOpen={() => {
          reset(defaultValues);
        }}
        body={
          <FormProvider {...methods}>
            <div className="flex flex-col gap-4">
              <FormInput
                id="name"
                name="name"
                label={t('Group Name')}
                required
                placeholder={t('Enter postcode group name')}
                minLength={{
                  message: t('Group name must be at least 3 characters long.'),
                  value: 3,
                }}
              />
              <FormMultiSelect
                id="postcodes"
                label={t('Postcodes')}
                name="postcodes"
                options={availablePostcodes.map((postcode) => ({
                  label: postcode,
                  value: postcode,
                }))}
                placeholder={t('Select postcodes')}
                required
              />
            </div>
          </FormProvider>
        }
        header={
          <div className="flex items-center gap-2">
            <Icon
              className="h-6 w-6 text-grey-700"
              icon={editingPostcodeGroup ? 'edit' : 'plus'}
            />
            <span>{title}</span>
          </div>
        }
        footer={
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Button
              className="order-last sm:order-none"
              data-test="modal-button-cancel"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={modalOnClose}
            />
            <Button
              data-test="modal-button-action"
              text={t('Save')}
              variant="solidBlue"
              onClick={methods.handleSubmit(mutation.mutate)}
              disabled={!methods.formState.isValid || mutation.isLoading}
            />
          </div>
        }
        isOpen={isOpen}
      />

      <CancelPrompt
        title={
          isEdit
            ? t('Cancel Editing Postcode Group?')
            : t('Cancel Creating Postcode Group?')
        }
        isOpen={isPromptVisible || blocker.state === 'blocked'}
        onClose={() => {
          setIsPromptVisible(false);
          if (blocker.state === 'blocked') {
            blocker.reset();
          }
        }}
        onExitClick={() => {
          setIsPromptVisible(false);
          onClose();
          if (blocker.state === 'blocked') {
            blocker.proceed();
          }
        }}
      />
    </>
  );
};

PostcodeGroupModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  editingPostcodeGroup: postcodeGroupPropType,
  allPostcodeGroups: PropTypes.arrayOf(postcodeGroupPropType),
  hubId: PropTypes.string,
  allPostcodes: PropTypes.arrayOf(PropTypes.string),
};

PostcodeGroupModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  editingPostcodeGroup: undefined,
  allPostcodeGroups: [],
  hubId: undefined,
  allPostcodes: [],
};

export default PostcodeGroupModal;

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Drawer from '../../../components/Drawer';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import TourStopDetailsDrawerContent from './TourStopDetailsDrawerContent';

const Loader = () => (
  <div className="relative left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2">
    <LoadingSpinner className="text-grey-700" />
  </div>
);

const TourStopDetailsDrawer = (props) => {
  const { children, isOpen, onClose, tour, tourStopId } = props;
  const { t } = useTranslation();

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const { data, isLoading } = useQuery(
    [`/tour-stops/${tourStopId}`],
    async () => {
      const response = await fetch(`/tour-stops/${tourStopId}`, {
        method: 'GET',
      });
      return response.json();
    },
    {
      enabled: isOpen && !!tourStopId,
      onError: toastFetchError,
    },
  );

  const tourStop = data?.data;

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      header={
        <span className="flex items-center gap-2 text-base font-medium">
          <span>{t('Tour Stop Details')}</span>
        </span>
      }
      body={
        isLoading || !tourStop ? (
          <Loader />
        ) : (
          <TourStopDetailsDrawerContent tour={tour} tourStop={tourStop} />
        )
      }
    >
      {children}
    </Drawer>
  );
};

TourStopDetailsDrawer.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  tourStopId: PropTypes.string,
  tour: PropTypes.shape({
    vehicle: PropTypes.string,
    date: PropTypes.string,
    shift: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

TourStopDetailsDrawer.defaultProps = {
  children: undefined,
  isOpen: false,
  tourStopId: undefined,
};

export default TourStopDetailsDrawer;

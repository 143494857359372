import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const usePlatformFeaturesTranslations = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      'deliveryDetails:description': t(
        'Enhances user and system interaction by enabling detailed delivery statuses and actions: 1. status checks and menu displays on the mobile app, and 2. backend parcel processing and e-mail communication functionalities',
      ),
      'deliveryDetails:name': t('Delivery Details'),
      'deliveryProof:description': t(
        'Enable Drivers to manually update Delivery status on each Tour stop, add images and take signature',
      ),
      'deliveryProof:name': t('Delivery Proof'),
      'deliveryProofSignatureRequired:description': t(
        'With providing delivery proof, taking signature will be required when delivering parcels through mobile app',
      ),
      'deliveryProofSignatureRequired:name': t(
        'Delivery Proof Signature Required',
      ),
      'showAdditionalTourFields:description': t(
        'Enables the display of specific Tour details - WA Rampe, WA Spur, Weight',
      ),
      'showAdditionalTourFields:name': t('Show Additional Tour Fields'),
      'allowDuplicateImport:description': t(
        'Allows duplicate delivery externalID import (in case following parcel external ID is different)',
      ),
      'allowDuplicateImport:name': t('Allow Duplicate Import'),
      'useImportDeliverySlot:description': t(
        'Use specific delivery slot on import',
      ),
      'useImportDeliverySlot:name': t('Use Import Delivery Slot'),
      'customEmailFields:description': t(
        'Custom e-mail parameters will be sent and populated instead of default ones',
      ),
      'customEmailFields:name': t('Custom e-mail Fields'),
      'TRP-rescheduleDate:description': t(
        'Allows end-recipients to reschedule their Delivery date through Tracking Delivery page',
      ),
      'TRP-rescheduleDate:name': t('Tracking Page Reschedule Date'),
      'TRP-rescheduleShift:description': t(
        'Allows end-recipients to reschedule their Delivery date and shift through Tracking Delivery page',
      ),
      'TRP-rescheduleShift:name': t('Tracking Page Reschedule Shift'),
      'rescheduleEmailAfterConfirmation:description': t(
        'If set, imported e-mails will not be sent to end-recipients, requires TRP-rescheduleDate feature flag to be active',
      ),
      'rescheduleEmailAfterConfirmation:name': t(
        'Send Reschedule e-mail After Confirmation',
      ),
      'manualDriverScanning:description': t(
        'Provides functionality to enable manual Driver scanning parcels',
      ),
      'manualDriverScanning:name': t('Manual Driver Scanning'),
      'restrictCheckOutLocation:description': t(
        'Allows check out to be done within Hub location (with all other restrictions included)',
      ),
      'restrictCheckOutLocation:name': t('Restrict Check Out Location'),
      'useConfigCheckInTimeWindow:description': t(
        'If set, Tour Check in will be enabled 60 min before Tour Start Time (reads it from the config). Without this, default check in time restriction will be set to 30 min',
      ),
      'useConfigCheckInTimeWindow:name': t('Use Config CheckIn Time Window'),
      'displayDeliverySlotRestrictionNote:name': t(
        'Display Delivery Slot Restriction Note',
      ),
      'displayDeliverySlotRestrictionNote:description': t(
        'Enable precision in delivery timing (strictly within delivery slot) for select clients by displaying warning notes on the driver’s stops list',
      ),
      'allowShiftStartBeforeDesiredWindow:name': t(
        'Allow Shift To Start Before Desired Window',
      ),
      'allowShiftStartBeforeDesiredWindow:description': t(
        "Utilized during shift assignment in imports, this feature prioritizes overlap with the client-defined delivery slot. By default, a shift's delivery window must start at the same time or after the delivery slot begins. Enabling this flag allows the assignment of a shift even if its delivery window starts before the client’s specified delivery slot",
      ),
    }),
    [t],
  );
};

export default usePlatformFeaturesTranslations;

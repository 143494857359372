import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import useDeliveryUnitStatusTranslations from '../../../hooks/useDeliveryUnitStatusTranslations';
import UnitProgressIcon from '../UnitProgressIcon/UnitProgressIcon';
import ChangeUnitlStatusContext from './ChangeUnitStatusContext';
import StatusNote from './StatusNote';

const SpecialUnitUpdateCases = ({ unit }) => {
  const { t } = useTranslation();
  const deliveryUnitStatusTranslations = useDeliveryUnitStatusTranslations();
  const { watch } = useFormContext();
  const changeUnitStatusContext = useContext(ChangeUnitlStatusContext);

  const { availableReturnReasons, specialUnitStatuses } =
    changeUnitStatusContext;

  const unitSpecialCase = watch('unitSpecialCase');

  const unitPpecialCase = useMemo(
    () => ({
      [DeliveryTaskUnitStatus.Misplaced]: {
        description: t('Unclear where the unit is, confirmation needed'),
        label: t('Misplaced'),
        actions: [
          {
            label: t('Unable to Locate'),
            info: t(
              'The Unit will be automatically updated to final status {{status}}',
              {
                status: deliveryUnitStatusTranslations[specialUnitStatuses[0]],
              },
            ),
            // negative value (index 0)
            value: specialUnitStatuses[0],
          },
          {
            label: t('Found'),
            info: t(
              'The Unit will be automatically updated to status {{status}}',
              {
                status: deliveryUnitStatusTranslations[specialUnitStatuses[1]],
              },
            ),
            // positive value (index 1)
            value: specialUnitStatuses[1],
          },
        ],
      },
      [DeliveryTaskUnitStatus.Damaged]: {
        description: t('Unit was damaged, assessment needed'),
        label: t('Damaged'),
        actions: [
          {
            label: t('Irreparable'),
            info: t(
              'The Unit will be automatically updated to status {{status}} with the remark "{{remark}}".',
              {
                status: deliveryUnitStatusTranslations[specialUnitStatuses[0]],
                remark: availableReturnReasons[0],
              },
            ),
            // negative value (index 0)
            value: specialUnitStatuses[0],
          },
          {
            label: t('Fixed'),
            info: t(
              'The Unit will be automatically updated to status {{status}}',
              {
                status: deliveryUnitStatusTranslations[specialUnitStatuses[1]],
              },
            ),
            // positive value (index 1)
            value: specialUnitStatuses[1],
          },
        ],
      },
    }),
    [
      deliveryUnitStatusTranslations,
      availableReturnReasons,
      specialUnitStatuses,
      t,
    ],
  );

  const statusItem = unitPpecialCase[unit.status];

  const getInfoLabel = useMemo(
    () =>
      statusItem.actions.find((item) => item.value === unitSpecialCase)?.info,
    [statusItem.actions, unitSpecialCase],
  );

  return (
    <div className="flex flex-col gap-4 pt-4">
      <div className="flex flex-col gap-2">
        <div className="text-sm text-primary-dark">{t('Status')}</div>

        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-1 text-sm">
            <div className="font-medium text-primary-dark flex items-center gap-2">
              <UnitProgressIcon unitStatus={unit.status} />
              <span>{deliveryUnitStatusTranslations[unit.status]}</span>
            </div>
            {statusItem.description && (
              <div className="text-grey-700">{statusItem.description}</div>
            )}
          </div>
        </div>

        {unit.unchangedStatusForSignificantTime && (
          <div className="p-4 bg-grey-100 flex gap-2 items-center rounded-md">
            <Icon icon="reminder" className="w-4 h-4 text-ui-red" />
            <div className="text-sm font-medium">
              {t('Unit has been in this status for more than 3 working days.')}
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col items-center lg:gap-4 gap-3 p-4 rounded-lg border border-grey-200">
        <div className="text-sm text-center text-primary-dark">
          {t('Update on {{status}} status', {
            status: statusItem.label,
          })}
        </div>
        <div className="flex w-full flex-col-reverse lg:flex-row gap-3">
          <Controller
            name="unitSpecialCase"
            render={({ field: { onChange: onOptionChange } }) =>
              statusItem.actions.map((action) => (
                <div className="w-full" key={action.label}>
                  <Button
                    isFullWidth
                    variant={
                      unitSpecialCase === action.value
                        ? 'solidBlack'
                        : 'outlineBlack'
                    }
                    onClick={() => onOptionChange(action.value)}
                    text={action.label}
                  />
                </div>
              ))
            }
          />
        </div>
        {unitSpecialCase && (
          <Alert
            className="w-full"
            fontWeight="normal"
            message={getInfoLabel}
            variant="info"
          />
        )}
      </div>

      <div className="w-full border-b border-grey-200" />

      <StatusNote />
    </div>
  );
};

export default SpecialUnitUpdateCases;

SpecialUnitUpdateCases.propTypes = {
  unit: PropTypes.shape({
    code: PropTypes.string,
    status: PropTypes.oneOf(Object.values(DeliveryTaskUnitStatus)),
    statusChangeTime: PropTypes.string,
    unchangedStatusForSignificantTime: PropTypes.bool,
  }).isRequired,
};
